{
    "released": true,
    "contractName": "SocialRecoveryModule",
    "version": "0.1.0",
    "networkAddresses": {
        "10": "0x4Aa5Bf7D840aC607cb5BD3249e6Af6FC86C04897",
        "11155111": "0x4Aa5Bf7D840aC607cb5BD3249e6Af6FC86C04897",
        "11155420": "0x4Aa5Bf7D840aC607cb5BD3249e6Af6FC86C04897"
    },
    "abi": [
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "_recoveryPeriod",
                    "type": "uint256"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "constructor"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "wallet",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "threshold",
                    "type": "uint256"
                }
            ],
            "name": "ChangedThreshold",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "wallet",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "guardian",
                    "type": "address"
                }
            ],
            "name": "GuardianAdded",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "wallet",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "guardian",
                    "type": "address"
                }
            ],
            "name": "GuardianRevoked",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "wallet",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "nonce",
                    "type": "uint256"
                }
            ],
            "name": "NonceInvalidated",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "wallet",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "nonce",
                    "type": "uint256"
                }
            ],
            "name": "RecoveryCanceled",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "wallet",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "address[]",
                    "name": "newOwners",
                    "type": "address[]"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "newThreshold",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "nonce",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "uint64",
                    "name": "executeAfter",
                    "type": "uint64"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "guardiansApprovalCount",
                    "type": "uint256"
                }
            ],
            "name": "RecoveryExecuted",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "wallet",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "address[]",
                    "name": "newOwners",
                    "type": "address[]"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "newThreshold",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "nonce",
                    "type": "uint256"
                }
            ],
            "name": "RecoveryFinalized",
            "type": "event"
        },
        {
            "inputs": [],
            "name": "NAME",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "VERSION",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_guardian",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "_threshold",
                    "type": "uint256"
                }
            ],
            "name": "addGuardianWithThreshold",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "cancelRecovery",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "_threshold",
                    "type": "uint256"
                }
            ],
            "name": "changeThreshold",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_wallet",
                    "type": "address"
                },
                {
                    "internalType": "address[]",
                    "name": "_newOwners",
                    "type": "address[]"
                },
                {
                    "internalType": "uint256",
                    "name": "_newThreshold",
                    "type": "uint256"
                },
                {
                    "internalType": "bool",
                    "name": "_execute",
                    "type": "bool"
                }
            ],
            "name": "confirmRecovery",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "domainSeparator",
            "outputs": [
                {
                    "internalType": "bytes32",
                    "name": "",
                    "type": "bytes32"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_wallet",
                    "type": "address"
                },
                {
                    "internalType": "address[]",
                    "name": "_newOwners",
                    "type": "address[]"
                },
                {
                    "internalType": "uint256",
                    "name": "_newThreshold",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "_nonce",
                    "type": "uint256"
                }
            ],
            "name": "encodeRecoveryData",
            "outputs": [
                {
                    "internalType": "bytes",
                    "name": "",
                    "type": "bytes"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_wallet",
                    "type": "address"
                },
                {
                    "internalType": "address[]",
                    "name": "_newOwners",
                    "type": "address[]"
                },
                {
                    "internalType": "uint256",
                    "name": "_newThreshold",
                    "type": "uint256"
                }
            ],
            "name": "executeRecovery",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_wallet",
                    "type": "address"
                }
            ],
            "name": "finalizeRecovery",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "getChainId",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_wallet",
                    "type": "address"
                }
            ],
            "name": "getGuardians",
            "outputs": [
                {
                    "internalType": "address[]",
                    "name": "",
                    "type": "address[]"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_wallet",
                    "type": "address"
                },
                {
                    "internalType": "address[]",
                    "name": "_newOwners",
                    "type": "address[]"
                },
                {
                    "internalType": "uint256",
                    "name": "_newThreshold",
                    "type": "uint256"
                }
            ],
            "name": "getRecoveryApprovals",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "approvalCount",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_wallet",
                    "type": "address"
                },
                {
                    "internalType": "address[]",
                    "name": "_newOwners",
                    "type": "address[]"
                },
                {
                    "internalType": "uint256",
                    "name": "_newThreshold",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "_nonce",
                    "type": "uint256"
                }
            ],
            "name": "getRecoveryHash",
            "outputs": [
                {
                    "internalType": "bytes32",
                    "name": "",
                    "type": "bytes32"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_wallet",
                    "type": "address"
                }
            ],
            "name": "getRecoveryRequest",
            "outputs": [
                {
                    "components": [
                        {
                            "internalType": "uint256",
                            "name": "guardiansApprovalCount",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "newThreshold",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint64",
                            "name": "executeAfter",
                            "type": "uint64"
                        },
                        {
                            "internalType": "address[]",
                            "name": "newOwners",
                            "type": "address[]"
                        }
                    ],
                    "internalType": "struct SocialRecoveryModule.RecoveryRequest",
                    "name": "request",
                    "type": "tuple"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_wallet",
                    "type": "address"
                }
            ],
            "name": "guardiansCount",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_wallet",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "_guardian",
                    "type": "address"
                },
                {
                    "internalType": "address[]",
                    "name": "_newOwners",
                    "type": "address[]"
                },
                {
                    "internalType": "uint256",
                    "name": "_newThreshold",
                    "type": "uint256"
                }
            ],
            "name": "hasGuardianApproved",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "invalidateNonce",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_wallet",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "_guardian",
                    "type": "address"
                }
            ],
            "name": "isGuardian",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_wallet",
                    "type": "address"
                },
                {
                    "internalType": "address[]",
                    "name": "_newOwners",
                    "type": "address[]"
                },
                {
                    "internalType": "uint256",
                    "name": "_newThreshold",
                    "type": "uint256"
                },
                {
                    "components": [
                        {
                            "internalType": "address",
                            "name": "signer",
                            "type": "address"
                        },
                        {
                            "internalType": "bytes",
                            "name": "signature",
                            "type": "bytes"
                        }
                    ],
                    "internalType": "struct SocialRecoveryModule.SignatureData[]",
                    "name": "_signatures",
                    "type": "tuple[]"
                },
                {
                    "internalType": "bool",
                    "name": "_execute",
                    "type": "bool"
                }
            ],
            "name": "multiConfirmRecovery",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_wallet",
                    "type": "address"
                }
            ],
            "name": "nonce",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "_nonce",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_prevGuardian",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "_guardian",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "_threshold",
                    "type": "uint256"
                }
            ],
            "name": "revokeGuardianWithThreshold",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_wallet",
                    "type": "address"
                }
            ],
            "name": "threshold",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_wallet",
                    "type": "address"
                },
                {
                    "internalType": "bytes32",
                    "name": "_signHash",
                    "type": "bytes32"
                },
                {
                    "internalType": "address",
                    "name": "_signer",
                    "type": "address"
                },
                {
                    "internalType": "bytes",
                    "name": "_signature",
                    "type": "bytes"
                }
            ],
            "name": "validateGuardianSignature",
            "outputs": [],
            "stateMutability": "view",
            "type": "function"
        }
    ]
}

{
    "released": true,
    "contractName": "AddModulesLib",
    "version": "0.2.0",
    "networkAddresses": {
        "1": "0x8EcD4ec46D4D2a6B64fE960B3D64e8B94B2234eb",
        "5": "0x8EcD4ec46D4D2a6B64fE960B3D64e8B94B2234eb",
        "10": "0x8EcD4ec46D4D2a6B64fE960B3D64e8B94B2234eb",
        "56": "0x8EcD4ec46D4D2a6B64fE960B3D64e8B94B2234eb",
        "100": "0x8EcD4ec46D4D2a6B64fE960B3D64e8B94B2234eb",
        "137": "0x8EcD4ec46D4D2a6B64fE960B3D64e8B94B2234eb",
        "8453": "0x8EcD4ec46D4D2a6B64fE960B3D64e8B94B2234eb",
        "42161": "0x8EcD4ec46D4D2a6B64fE960B3D64e8B94B2234eb",
        "42220": "0x8EcD4ec46D4D2a6B64fE960B3D64e8B94B2234eb",
        "80001": "0x8EcD4ec46D4D2a6B64fE960B3D64e8B94B2234eb",
        "80002": "0x8EcD4ec46D4D2a6B64fE960B3D64e8B94B2234eb",
        "84532": "0x8EcD4ec46D4D2a6B64fE960B3D64e8B94B2234eb",
        "421614": "0x8EcD4ec46D4D2a6B64fE960B3D64e8B94B2234eb",
        "11155111": "0x8EcD4ec46D4D2a6B64fE960B3D64e8B94B2234eb"
    },
    "abi": [
        {
            "inputs": [
                {
                    "internalType": "address[]",
                    "name": "modules",
                    "type": "address[]"
                }
            ],
            "name": "enableModules",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        }
    ]
}

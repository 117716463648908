{
    "released": true,
    "contractName": "SafeWebAuthnSharedSigner",
    "version": "0.2.1",
    "networkAddresses": {
        "1": "0x94a4F6affBd8975951142c3999aEAB7ecee555c2",
        "10": "0x94a4F6affBd8975951142c3999aEAB7ecee555c2",
        "137": "0x94a4F6affBd8975951142c3999aEAB7ecee555c2",
        "4078": "0x94a4F6affBd8975951142c3999aEAB7ecee555c2",
        "8453": "0x94a4F6affBd8975951142c3999aEAB7ecee555c2",
        "42161": "0x94a4F6affBd8975951142c3999aEAB7ecee555c2",
        "80002": "0x94a4F6affBd8975951142c3999aEAB7ecee555c2",
        "84532": "0x94a4F6affBd8975951142c3999aEAB7ecee555c2",
        "421614": "0x94a4F6affBd8975951142c3999aEAB7ecee555c2",
        "11155111": "0x94a4F6affBd8975951142c3999aEAB7ecee555c2",
        "11155420": "0x94a4F6affBd8975951142c3999aEAB7ecee555c2"
    },
    "abi": [
        {
            "inputs": [],
            "stateMutability": "nonpayable",
            "type": "constructor"
        },
        {
            "inputs": [],
            "name": "NotDelegateCalled",
            "type": "error"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "bytes32",
                    "name": "publicKeyHash",
                    "type": "bytes32"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "x",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "y",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "P256.Verifiers",
                    "name": "verifiers",
                    "type": "uint176"
                }
            ],
            "name": "SafeWebAuthnSharedSignerConfigured",
            "type": "event"
        },
        {
            "inputs": [],
            "name": "SIGNER_SLOT",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "components": [
                        {
                            "internalType": "uint256",
                            "name": "x",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "y",
                            "type": "uint256"
                        },
                        {
                            "internalType": "P256.Verifiers",
                            "name": "verifiers",
                            "type": "uint176"
                        }
                    ],
                    "internalType": "struct SafeWebAuthnSharedSigner.Signer",
                    "name": "signer",
                    "type": "tuple"
                }
            ],
            "name": "configure",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "account",
                    "type": "address"
                }
            ],
            "name": "getConfiguration",
            "outputs": [
                {
                    "components": [
                        {
                            "internalType": "uint256",
                            "name": "x",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "y",
                            "type": "uint256"
                        },
                        {
                            "internalType": "P256.Verifiers",
                            "name": "verifiers",
                            "type": "uint176"
                        }
                    ],
                    "internalType": "struct SafeWebAuthnSharedSigner.Signer",
                    "name": "signer",
                    "type": "tuple"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "bytes32",
                    "name": "message",
                    "type": "bytes32"
                },
                {
                    "internalType": "bytes",
                    "name": "signature",
                    "type": "bytes"
                }
            ],
            "name": "isValidSignature",
            "outputs": [
                {
                    "internalType": "bytes4",
                    "name": "magicValue",
                    "type": "bytes4"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "bytes",
                    "name": "data",
                    "type": "bytes"
                },
                {
                    "internalType": "bytes",
                    "name": "signature",
                    "type": "bytes"
                }
            ],
            "name": "isValidSignature",
            "outputs": [
                {
                    "internalType": "bytes4",
                    "name": "magicValue",
                    "type": "bytes4"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }
    ]
}

import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useGnosisSafe } from '../state/gnosis-state';
import { endpoints } from '../tools/common';
import { useBullaBackendHttpClient } from './useHttpClient';
import { useActingWalletAddress } from './useWalletAddress';
import { Tax } from '../components/display/views/new-invoice';
import { TaxType } from '../components/modals/create-claim-modal/create-claim-inputs';
import { useWeb3 } from './useWeb3';

export interface LinkInformation {
    link: string;
    linkId: string;
    attachment?: string;
    tax?: Tax;
}

export const LinksContext = React.createContext<{
    links: LinkInformation[] | null;
    isLoading: boolean;
    refetch: () => Promise<void>;
    deleteLink: (linkId: string) => Promise<void>;
    updateLink: (linkId: string, link: string, attachment?: string) => Promise<void>;
    addTxToLink: (txHash: string, link: string) => Promise<void>;
    getTaxByTxHash: (txHash: string) => Promise<Tax | null>;
}>({
    links: null,
    isLoading: false,
    refetch: () => Promise.resolve(),
    deleteLink: () => Promise.resolve(),
    updateLink: () => Promise.resolve(),
    addTxToLink: () => Promise.resolve(),
    getTaxByTxHash: () => Promise.resolve(null),
});

export type TaxDto = { rate: number; type: string };

export function LinksProvider({ children }: { children: React.ReactNode }) {
    const [links, setLinks] = useState<LinkInformation[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { fetchLinks, getTaxByTxHash, deleteLink: deleteLinkApi, updateLink: updateLinkApi, addTxToLink: addTxToLinkApi } = useLinksApi();
    const actingWallet = useActingWalletAddress();

    React.useEffect(() => {
        const loadLinks = async () => {
            setIsLoading(true);
            try {
                const data = await fetchLinks();
                setLinks(data);
            } catch (error) {
                console.error('Failed to fetch links:', error);
                setLinks([]);
            } finally {
                setIsLoading(false);
            }
        };

        loadLinks();
    }, [actingWallet]);

    const refetch = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const data = await fetchLinks();
            setLinks(data);
        } catch (error) {
            console.error('Failed to fetch links:', error);
        } finally {
            setIsLoading(false);
        }
    }, [fetchLinks]);

    const deleteLink = React.useCallback(
        async (linkId: string) => {
            try {
                const success = await deleteLinkApi(linkId);
                if (success) {
                    await refetch();
                } else {
                    throw new Error('Failed to delete link');
                }
            } catch (error) {
                console.error('Failed to delete link:', error);
                throw error instanceof Error ? error : new Error('Unknown error occurred');
            }
        },
        [deleteLinkApi, refetch],
    );

    const updateLink = React.useCallback(
        async (linkId: string, link: string, attachment?: string) => {
            try {
                const success = await updateLinkApi(linkId, link, attachment);
                if (success) {
                    await refetch();
                } else {
                    throw new Error('Failed to update link');
                }
            } catch (error) {
                console.error('Failed to update link:', error);
                throw error instanceof Error ? error : new Error('Unknown error occurred');
            }
        },
        [updateLinkApi, refetch],
    );

    const addTxToLink = React.useCallback(
        async (txHash: string, link: string) => {
            try {
                const success = await addTxToLinkApi(txHash, link);
                if (success) {
                    await refetch();
                } else {
                    throw new Error('Failed to add transaction to link');
                }
            } catch (error) {
                console.error('Failed to add transaction to link:', error);
                throw error instanceof Error ? error : new Error('Unknown error occurred');
            }
        },
        [addTxToLinkApi, refetch],
    );

    React.useEffect(() => {
        refetch();
    }, [actingWallet]);

    return (
        <LinksContext.Provider
            value={{
                links,
                isLoading,
                refetch,
                deleteLink,
                updateLink,
                addTxToLink,
                getTaxByTxHash,
            }}
        >
            {children}
        </LinksContext.Provider>
    );
}

export const useLinks = () => {
    const context = useContext(LinksContext);
    if (context.links === null) {
        throw new Error('useLinks must be used within a LinksProvider');
    }
    return {
        links: context.links,
        isLoading: context.isLoading,
        refetch: context.refetch,
        deleteLink: context.deleteLink,
        updateLink: context.updateLink,
        addTxToLink: context.addTxToLink,
        getTaxByTxHash: context.getTaxByTxHash,
    };
};

export const useLinksApi = () => {
    const { connectedSafeAddress } = useGnosisSafe();
    const { connectedNetwork } = useWeb3();
    const actingWallet = useActingWalletAddress();
    const httpClient = useBullaBackendHttpClient();

    const gnosisSafeQueryArgs = !!connectedSafeAddress ? `?account_type=gnosis&chain_id=${connectedNetwork}` : '';

    const fetchLinks = async (): Promise<LinkInformation[]> => {
        try {
            const { data } = await httpClient.get(`${endpoints.settingsApi}/get-links/${actingWallet}${gnosisSafeQueryArgs}`, {
                withCredentials: true,
            });
            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 401) {
                    throw new Error('Authentication failed');
                }
                throw new Error('Server error occurred. Please try again later');
            }
            throw new Error('Failed to fetch links');
        }
    };

    const getTaxByTxHash = async (txHash: string): Promise<Tax | null> => {
        try {
            const { data } = await httpClient.get<TaxDto>(
                `${endpoints.settingsApi}/get-tax/${txHash}/${actingWallet}${gnosisSafeQueryArgs}`,
                {
                    withCredentials: true,
                },
            );
            return data
                ? {
                      taxRate: data.rate.toString(),
                      taxType: data.type as TaxType,
                  }
                : null;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 401) {
                    throw new Error('Authentication failed');
                } else if (error.response?.status === 404) {
                    return null;
                }
                throw new Error('Server error occurred. Please try again later');
            }
            throw new Error('Failed to fetch tax information');
        }
    };

    const saveLink = async (link: string, attachment?: string, tax?: Tax): Promise<boolean> => {
        const payload = {
            link,
            attachment,
            tax: tax
                ? {
                      rate: Number(tax.taxRate),
                      type: tax.taxType,
                  }
                : undefined,
        };

        try {
            const { status } = await httpClient.post(
                `${endpoints.settingsApi}/upload-link/${actingWallet}${gnosisSafeQueryArgs}`,
                payload,
                {
                    withCredentials: true,
                    headers: { 'content-type': 'application/json' },
                },
            );

            return status === 200;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 400) {
                    throw new Error(error.response.data.message || 'Invalid link data');
                } else if (error.response?.status === 401) {
                    throw new Error('Authentication failed');
                }
                throw new Error('Server error occurred. Please try again later');
            }
            throw new Error('Failed to save link data');
        }
    };

    const deleteLink = async (linkId: string): Promise<boolean> => {
        try {
            const response = await httpClient.delete(
                `${endpoints.settingsApi}/delete-link/${actingWallet}/${linkId}${gnosisSafeQueryArgs}`,
                {
                    withCredentials: true,
                },
            );

            return response.status === 200;
        } catch (error) {
            console.error('Delete link error:', error);
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 401) {
                    throw new Error('Authentication failed');
                } else if (error.response?.status === 404) {
                    throw new Error('Link not found');
                } else if (error.response?.status && error.response?.status >= 500) {
                    throw new Error('Server error occurred. Please try again later');
                }
            }
            throw new Error('Failed to delete link');
        }
    };

    const updateLink = async (linkId: string, link: string, attachment?: string, tax?: Tax): Promise<boolean> => {
        const payload = {
            link,
            attachment,
            tax: tax
                ? {
                      rate: Number(tax.taxRate),
                      type: tax.taxType,
                  }
                : undefined,
        };

        try {
            const response = await httpClient.put(
                `${endpoints.settingsApi}/update-link/${actingWallet}/${linkId}${gnosisSafeQueryArgs}`,
                payload,
                {
                    withCredentials: true,
                    headers: { 'content-type': 'application/json' },
                },
            );
            return response.status === 200;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 400) {
                    throw new Error(error.response.data.message || 'Invalid link data');
                } else if (error.response?.status === 401) {
                    throw new Error('Authentication failed');
                } else if (error.response?.status === 404) {
                    throw new Error('Link not found');
                } else if (error.response?.status && error.response?.status >= 500) {
                    throw new Error('Server error occurred. Please try again later');
                }
            }
            throw new Error('Failed to update link');
        }
    };

    const addTxToLink = async (txHash: string, link: string): Promise<boolean> => {
        try {
            const response = await httpClient.put(`${endpoints.settingsApi}/add-tx/${txHash}/${actingWallet}${gnosisSafeQueryArgs}`, link, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'text/plain',
                },
            });
            return response.status === 200;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 400) {
                    throw new Error(error.response.data.message || 'Invalid link data');
                } else if (error.response?.status === 401) {
                    throw new Error('Authentication failed');
                } else if (error.response?.status === 404) {
                    throw new Error('Link not found');
                } else if (error.response?.status && error.response?.status >= 500) {
                    throw new Error('Server error occurred. Please try again later');
                }
            }
            throw new Error('Failed to add transaction to link');
        }
    };

    return { fetchLinks, getTaxByTxHash, saveLink, deleteLink, updateLink, addTxToLink };
};

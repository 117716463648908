{
    "released": true,
    "contractName": "FCLP256Verifier",
    "version": "0.2.1",
    "networkAddresses": {
        "1": "0xA86e0054C51E4894D88762a017ECc5E5235f5DBA",
        "10": "0xA86e0054C51E4894D88762a017ECc5E5235f5DBA",
        "137": "0xA86e0054C51E4894D88762a017ECc5E5235f5DBA",
        "4078": "0xA86e0054C51E4894D88762a017ECc5E5235f5DBA",
        "8453": "0xA86e0054C51E4894D88762a017ECc5E5235f5DBA",
        "42161": "0xA86e0054C51E4894D88762a017ECc5E5235f5DBA",
        "80002": "0xA86e0054C51E4894D88762a017ECc5E5235f5DBA",
        "84532": "0xA86e0054C51E4894D88762a017ECc5E5235f5DBA",
        "421614": "0xA86e0054C51E4894D88762a017ECc5E5235f5DBA",
        "11155111": "0xA86e0054C51E4894D88762a017ECc5E5235f5DBA",
        "11155420": "0xA86e0054C51E4894D88762a017ECc5E5235f5DBA"
    },
    "abi": [
        {
            "stateMutability": "nonpayable",
            "type": "fallback"
        }
    ]
}

import {
    Box,
    Collapse,
    Flex,
    HStack,
    Image,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Spinner,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react';
import { BigNumber, constants, utils } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';
import { Field, FieldProps, Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import RocifiLogo from 'url:../../../assets/rocifi_logo.svg';
import SpectralLogo from 'url:../../../assets/spectral_logo.jpg';
import * as Yup from 'yup';
import GradientChart from '../../../assets/gradient-chart';
import { processFinancingTermInputs } from '../../../data-lib/dto/bulla-finance-dto';
import { getFrendLendContract } from '../../../data-lib/dto/contract-interfaces';
import { buildGetLoanOfferMultisendTx, createFrendLendOffer } from '../../../data-lib/dto/frendlend-dto';
import { isValidAddress } from '../../../data-lib/ethereum';
import { addDaysToToday } from '../../../data-lib/helpers';
import { getMultihashStructFromCID } from '../../../data-lib/multihash';
import { TXStatus } from '../../../data-lib/networks';
import { useAllowances } from '../../../hooks/useAllowances';
import { emptyMultihash } from '../../../hooks/useBullaBanker';
import { useTokenBalances } from '../../../hooks/useChainData';
import { useGnosisTransaction } from '../../../hooks/useGnosisTransaction';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useSendTransaction } from '../../../hooks/useSendTransaction';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { UnderwriterData, useUnderwriter } from '../../../hooks/useUnderwriter';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useWeb3 } from '../../../hooks/useWeb3';
import { useAppState } from '../../../state/app-state';
import { useGnosisSafe } from '../../../state/gnosis-state';
import { useUIState } from '../../../state/ui-state';
import { apply } from '../../../tools/common';
import { enableWalletUnderwritingScore } from '../../../tools/featureFlags';
import { AlertInfo } from '../../base/alert';
import { OrangeButton } from '../../inputs/buttons';
import { CloseModalButton, LabelText, ModalFooterWithShadow } from '../common';
import {
    BullaFileObject,
    ClaimAmountField,
    claimAmountValidationSchema,
    ClaimDescriptionField,
    claimInfoSchemaFields,
    emptyFields,
    FileUploadField,
    NewAddressAlert,
    PercentAmountField,
    recipientErrorSchema,
    RecipientField,
    UploadedFile,
} from '../create-claim-modal/create-claim-inputs';
import {
    defaultFinancingTermsLabels,
    FinanceTermsLabel,
    FinancingDueByField,
    getFinancingTermLabels,
    interestRateSchema,
    TermsSummary,
} from '../financing-terms-modal/financing-inputs';
import { TokenApprovalModal } from '../token-approval.modal';

export const getRequiredFrendLendAllowance = (prev: BigNumber, principalAmount: BigNumber) =>
    prev.add(principalAmount.add(BigNumber.from(1)));

export type RiskLevel = 'VERY_HIGH_RISK' | 'HIGH_RISK' | 'MEDIUM_RISK' | 'LOW_RISK' | 'VERY_LOW_RISK';

const riskLevelDisplayMapping: Record<RiskLevel, { text: string; color: string }> = {
    VERY_HIGH_RISK: { text: 'Very High Risk', color: 'red' },
    HIGH_RISK: { text: 'High Risk', color: 'red' },
    MEDIUM_RISK: { text: 'Medium Risk', color: 'orange' },
    LOW_RISK: { text: 'Low Risk', color: '#32D583' },
    VERY_LOW_RISK: { text: 'Very Low Risk', color: '#32D583' },
};

const getRiskLevelSpectral = (score: number): RiskLevel => {
    if (score >= 0 && score <= 200) {
        return 'VERY_HIGH_RISK';
    }
    if (score > 200 && score <= 400) {
        return 'HIGH_RISK';
    }
    if (score > 400 && score <= 600) {
        return 'MEDIUM_RISK';
    }
    if (score > 600 && score <= 800) {
        return 'LOW_RISK';
    } else {
        return 'VERY_LOW_RISK';
    }
};

const getRiskLevelRocifi = (score: number): RiskLevel => {
    if (score >= 9 && score <= 10) {
        return 'VERY_LOW_RISK';
    }
    if (score >= 7 && score < 9) {
        return 'LOW_RISK';
    }
    if (score >= 5 && score < 7) {
        return 'MEDIUM_RISK';
    }
    if (score >= 3 && score < 5) {
        return 'HIGH_RISK';
    } else {
        return 'VERY_HIGH_RISK';
    }
};

export type UnderwriterFetchingState =
    | { type: 'init' }
    | { type: 'loading' }
    | { type: 'not-found' }
    | { type: 'fetched'; underwriterData: UnderwriterData };

type ScoreCardProps = {
    Logo: React.FC<{ onClick: () => void }>;
    score: number;
    riskLevel: { text: string; color: string };
    lastUpdated: number;
    title: string;
    url: string;
    hasBothScores?: boolean;
};

const ScoreCard: React.FC<ScoreCardProps> = ({ Logo, score, riskLevel, lastUpdated, title, url, hasBothScores }) => (
    <VStack border="1px solid #E2E8F0" borderRadius={'8px'} px="5" py="4" mb="4" boxShadow="sm" flex="1">
        <Flex direction="column" alignItems="left" w="100%">
            <Logo onClick={() => window.open(url, '_blank')} />
            <Text fontSize={'15px'} fontWeight="600" color={'gray.900'}>
                {title}
            </Text>
        </Flex>
        <Flex justifyContent={'space-between'} w="100%">
            <VStack fontSize={'14px'} fontWeight="500" color={'black'} alignItems="start" spacing={1} mt={2}>
                <Text fontSize={'29px'} as="b">
                    {score}
                </Text>

                <Flex color={'gray.500'}>
                    <Text>Score Rank:</Text>
                    <Text color={riskLevel.color} ml={1}>
                        {riskLevel.text}
                    </Text>
                </Flex>
                <Text color={'gray.500'}>Last updated: {moment(lastUpdated).format('D MMM YY')}</Text>
            </VStack>
            {!hasBothScores && (
                <VStack
                    fontSize={'14px'}
                    fontWeight="500"
                    color={'black'}
                    alignItems="center"
                    mr={2}
                    position="relative"
                    mt={-2}
                    mb={'-100px'}
                >
                    <GradientChart value={score.toString()} />
                    <Flex
                        direction={'column'}
                        alignItems="center"
                        position="absolute"
                        top="50%"
                        left="50%"
                        transform="translate(-50%, -100%)"
                    >
                        <Text fontSize={'21px'} as="b">
                            {score}
                        </Text>
                        <Text color={'gray.400'}>{moment(lastUpdated).format('D MMM YY')}</Text>
                    </Flex>
                </VStack>
            )}
        </Flex>
    </VStack>
);

export const UnderwriterBox = ({ underwriterFetchingState }: { underwriterFetchingState: UnderwriterFetchingState }) => {
    const scoreFetched = underwriterFetchingState.type === 'fetched';
    const hasBothScores = scoreFetched && underwriterFetchingState.underwriterData.rocifiScore != '0';
    const riskLevelSpectral =
        scoreFetched && riskLevelDisplayMapping[getRiskLevelSpectral(+underwriterFetchingState.underwriterData.spectralScore)!];
    const truncatedSpectralScore = scoreFetched && Math.floor(+underwriterFetchingState.underwriterData.spectralScore);
    const rocifiScore = hasBothScores && underwriterFetchingState.underwriterData.rocifiScore.replace('Some(', '').replace(')', '');
    const riskLevelRocifi = scoreFetched && rocifiScore && riskLevelDisplayMapping[getRiskLevelRocifi(+rocifiScore)!];
    const title = "Recipient's Wallet Score";

    const SpectralLogoComponent: React.FC<{ onClick: () => void }> = ({ onClick }) => (
        <Image src={SpectralLogo} w="180px" ml="-4" mt="-3" cursor={'pointer'} onClick={onClick} />
    );
    const RocifiLogoComponent: React.FC<{ onClick: () => void }> = ({ onClick }) => (
        <Image src={RocifiLogo} w="100px" ml="-2" mt="-6" mb="-4" cursor={'pointer'} onClick={onClick} />
    );

    return (
        <>
            {scoreFetched && truncatedSpectralScore && riskLevelSpectral ? (
                <Flex direction="row" justifyContent={'space-between'} gap="5">
                    <ScoreCard
                        Logo={SpectralLogoComponent}
                        score={truncatedSpectralScore}
                        riskLevel={riskLevelSpectral}
                        lastUpdated={underwriterFetchingState.underwriterData.spectralScore_timestamp}
                        title={title}
                        url="https://www.spectral.finance/"
                        hasBothScores={hasBothScores}
                    />
                    {hasBothScores && riskLevelRocifi && (
                        <ScoreCard
                            Logo={RocifiLogoComponent}
                            score={+rocifiScore}
                            riskLevel={riskLevelRocifi}
                            lastUpdated={underwriterFetchingState.underwriterData.rocifiScore_timestamp}
                            title={title}
                            url="https://roci.fi/"
                            hasBothScores={hasBothScores}
                        />
                    )}
                </Flex>
            ) : (
                <VStack border="1px solid #E2E8F0" borderRadius={'8px'} px="5" py="4" mb="4" boxShadow="sm">
                    <Flex w="100%">
                        <Text fontSize={'14px'} fontWeight="500" color={'gray.900'}>
                            {title}
                        </Text>
                    </Flex>
                    <Flex justifyContent={'center'} w="100%" py="12">
                        <HStack>
                            <Spinner />{' '}
                            <Text fontSize={'14px'} fontWeight="500" color={'gray.900'}>
                                Loading Wallet Score...
                            </Text>
                        </HStack>
                    </Flex>
                </VStack>
            )}
        </>
    );
};

type CreateFrendlendModalProps = { isOpen: boolean; onClose: VoidFunction };
export const CreateFrendlendModal = ({ isOpen, onClose }: CreateFrendlendModalProps) => {
    const modalContentRef = useRef<HTMLDivElement>(null);
    const modalBodyRef = useRef<HTMLDivElement>(null);
    const {
        connectedNetwork,
        connectedNetworkConfig: { frendlendAddress, nativeCurrency },
        provider,
    } = useWeb3();
    const { search } = useLocation();
    const defaults = React.useMemo(() => {
        const params = new URLSearchParams(search);
        const recipient = params.get('recipient');
        const tokenAddress = params.get('token');
        const amount = params.get('amount');
        const description = params.get('description');
        const dueInDays = params.get('dueInDays');
        return { recipient, tokenAddress, amount, description, dueInDays };
    }, [search]);
    const { transactionPending } = useUIState();
    const { readyToTransact } = useAppState();
    const { safeInfo } = useGnosisSafe();
    const { loading: executingViaGnosis, executeTransactions } = useGnosisTransaction();
    const senderAddress = useActingWalletAddress();
    const isMobile = useIsMobile();
    const [approvalPending, { getAllowanceForTokenAddress, approveTokens, approveModalOpen, closeApproveModal }] =
        useAllowances('exact-allowance');
    const { getTokenByChainIdAndAddress, erc20sByChainId } = useTokenRepo();
    const initialERC20 = erc20sByChainId[connectedNetwork][0].token;
    const [allowance, setAllowance] = React.useState<'init' | BigNumber>('init');
    const [originationFee, setFee] = React.useState<BigNumber | undefined>();
    const [approveStepComplete, setApprovalStepComplete] = React.useState(false);
    const tokenBalances = useTokenBalances({ chainId: connectedNetwork });
    const [executing, sendTransaction] = useSendTransaction();
    const isLoading = approvalPending || tokenBalances === undefined || allowance == 'init' || executing || executingViaGnosis;

    const errorSchema = Yup.object().shape({
        recipient: recipientErrorSchema(senderAddress),
        claimAmount: claimAmountValidationSchema,
        interestRate: interestRateSchema,
        description: claimInfoSchemaFields.description.required('Required'),
    });

    useEffect(() => {
        if (frendlendAddress) getFrendLendContract(frendlendAddress).connect(provider).callStatic.fee().then(setFee);
    }, [frendlendAddress, provider]);

    return (
        <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            motionPreset="slideInBottom"
            closeOnOverlayClick={false}
            closeOnEsc={false}
            size={isMobile ? 'full' : '2xl'}
            scrollBehavior="inside"
        >
            <ModalOverlay />
            <Formik
                initialValues={{
                    ...emptyFields,
                    token: !!defaults.tokenAddress
                        ? getTokenByChainIdAndAddress(connectedNetwork)(defaults.tokenAddress)?.token ?? initialERC20
                        : initialERC20,
                    attachment: { file: 'not-uploaded' } as BullaFileObject | UploadedFile,
                    interestRate: '0',
                    dueBy: addDaysToToday(!!defaults.dueInDays ? +defaults.dueInDays : 30),
                    recipient: defaults.recipient ?? undefined,
                    description: defaults.description ?? '',
                    claimAmount: defaults.amount ?? '',
                }}
                validationSchema={errorSchema}
                validateOnBlur
                onSubmit={async ({ claimAmount, interestRate, dueBy, recipient, description, attachment, token }) => {
                    try {
                        const terms = processFinancingTermInputs(claimAmount, '0', interestRate, token.decimals, dueBy);
                        if (allowance == 'init' || terms == 'invalid' || !originationFee) throw Error('Should not happen');
                        if (!frendlendAddress) throw Error('Frendlend is not deployed on this network');

                        const requiredAllowance =
                            allowance == constants.MaxUint256
                                ? constants.MaxUint256
                                : getRequiredFrendLendAllowance(allowance, terms.principalAmount);

                        const needsApprovalTx = !safeInfo && !allowance.eq(constants.MaxUint256) && !approveStepComplete;

                        const params = {
                            terms,
                            claimParams: {
                                debtor: recipient!,
                                creditor: senderAddress,
                                description,
                                ipfsHash: 'ipfsHash' in attachment ? getMultihashStructFromCID(attachment.ipfsHash) : emptyMultihash,
                                token,
                            },
                            originationFee: originationFee,
                        };

                        needsApprovalTx
                            ? approveTokens(frendlendAddress)([{ amount: requiredAllowance, token }]).then(({ success }) => {
                                  if (success) {
                                      setApprovalStepComplete(true);
                                      setAllowance(requiredAllowance);
                                  }
                              })
                            : !!safeInfo
                            ? executeTransactions(
                                  safeInfo,
                                  [
                                      buildGetLoanOfferMultisendTx(
                                          frendlendAddress,
                                          params.originationFee,
                                          {
                                              claimParams: params.claimParams,
                                              terms: params.terms,
                                          },
                                          requiredAllowance,
                                      ),
                                  ],
                                  true,
                              ).then(tx => {
                                  if (tx.success) {
                                      onClose();
                                      setApprovalStepComplete(false);
                                  }
                              })
                            : sendTransaction(
                                  signer =>
                                      createFrendLendOffer({
                                          contract: getFrendLendContract(frendlendAddress!).connect(signer),
                                          ...params,
                                      }),
                                  false,
                              ).then(tx => {
                                  if (tx && tx.status === TXStatus.SUCCESS) {
                                      onClose();
                                      setApprovalStepComplete(false);
                                  }
                              });
                    } catch (e) {
                        console.log(e);
                    }
                }}
            >
                {({ errors, touched, setFieldValue, isValid, values, setFieldTouched, dirty, setStatus, status, setFieldError }) => {
                    const token = values.token;
                    const terms = processFinancingTermInputs(values.claimAmount, '0', values.interestRate, token.decimals, values.dueBy);

                    const { getUnderwriterScore } = useUnderwriter();

                    useEffect(() => {
                        getAllowanceForTokenAddress(frendlendAddress!, connectedNetwork)(token.address).then(setAllowance);
                    }, [token.address]);

                    const [underwriterFetchingState, setUnderwriterFetchingState] = useState<UnderwriterFetchingState>({ type: 'init' });

                    const prevRecipientRef = useRef(values.recipient);

                    useEffect(() => {
                        if (
                            values.recipient !== '' &&
                            !!values.recipient &&
                            isValidAddress(values.recipient) &&
                            values.recipient !== prevRecipientRef.current &&
                            enableWalletUnderwritingScore
                        ) {
                            setUnderwriterFetchingState({ type: 'loading' });
                            const fetchData = async (recipient: string) => {
                                try {
                                    const result = await getUnderwriterScore(recipient);
                                    if (result) {
                                        setUnderwriterFetchingState({ type: 'fetched', underwriterData: result });
                                    } else {
                                        setUnderwriterFetchingState({ type: 'not-found' });
                                    }
                                } catch (error) {
                                    setUnderwriterFetchingState({ type: 'not-found' });
                                }
                            };
                            fetchData(values.recipient);
                        }
                        prevRecipientRef.current = values.recipient;
                    }, [values.recipient, getUnderwriterScore]);

                    const tokenBalance = tokenBalances.getBalanceForToken(values.token.address);
                    const insufficientFunds =
                        !tokenBalances ||
                        terms === 'invalid' ||
                        tokenBalance === undefined ||
                        terms.principalAmount.gt(parseUnits(tokenBalance.toFixed(token.decimals), token.decimals));

                    const isDisabled =
                        (!dirty && !Object.values(defaults).every(x => x !== null)) ||
                        status === 'editing' ||
                        !isValid ||
                        isLoading ||
                        !readyToTransact ||
                        insufficientFunds;

                    const termLabels =
                        terms !== 'invalid' ? getFinancingTermLabels(terms, token.symbol, token.decimals) : defaultFinancingTermsLabels;

                    const requiredAllowance =
                        allowance == constants.MaxUint256 || allowance == 'init' || terms == 'invalid'
                            ? constants.MaxUint256
                            : getRequiredFrendLendAllowance(allowance, terms.principalAmount);

                    const buttonText =
                        terms === 'invalid'
                            ? 'Offer Loan'
                            : insufficientFunds
                            ? 'Insufficient funds'
                            : !safeInfo && allowance !== 'init' && !allowance.eq(constants.MaxUint256) && !approveStepComplete
                            ? 'Create Loan (Approve Token)'
                            : 'Create Loan';

                    return (
                        <>
                            <Form placeholder={''}>
                                <ModalContent py="4" px="2" bg={'white'} ref={modalContentRef}>
                                    <ModalHeader display="flex">
                                        <Text color="heading" fontWeight={'700'} fontSize="18px" noOfLines={1} alignSelf="center">
                                            Offer a loan
                                        </Text>
                                    </ModalHeader>
                                    <CloseModalButton onClose={onClose} />
                                    <ModalBody ref={modalBodyRef} py="0">
                                        <Collapse in={!!touched.recipient && !!values.recipient && !errors.recipient} unmountOnExit>
                                            <NewAddressAlert newAddress={values.recipient!} />
                                        </Collapse>
                                        <Stack spacing="3" w={'100%'}>
                                            <AlertInfo message="Bulla Finance allows the recipient to pay you over time." mb={4} />
                                            <LabelText>Recipient Info</LabelText>
                                            <Field name="recipient">
                                                {({ field }: FieldProps) => (
                                                    <RecipientField
                                                        {...{
                                                            field,
                                                            isDisabled: transactionPending || !!defaults.recipient,
                                                            error: errors.recipient,
                                                            touched: touched.recipient,
                                                            setRecipient: apply(setFieldValue, field.name),
                                                            setEmailAddress: apply(setFieldValue, 'emailAddress'),
                                                            label: 'Recipient Address',
                                                            dropdownModalRef: modalContentRef,
                                                            initialValue: values.recipient,
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            {underwriterFetchingState.type != 'init' &&
                                                underwriterFetchingState.type != 'not-found' &&
                                                enableWalletUnderwritingScore && (
                                                    <UnderwriterBox underwriterFetchingState={underwriterFetchingState} />
                                                )}
                                            <LabelText>Loan Terms</LabelText>
                                            <Field name="claimAmount">
                                                {({ field }: FieldProps) => (
                                                    <ClaimAmountField
                                                        {...{
                                                            claimType: 'Payment',
                                                            field,
                                                            isDisabled: transactionPending,
                                                            includeNativeToken: values.instantPayment,
                                                            error: errors.claimAmount,
                                                            touched: touched.claimAmount,
                                                            setAmount: apply(setFieldValue, 'claimAmount'),
                                                            setToken: apply(setFieldValue, 'token'),
                                                            amount: values.claimAmount,
                                                            token: values.token,
                                                            setFieldTouched,
                                                            label: 'Token',
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="interestRate">
                                                {({ field }: FieldProps) => (
                                                    <PercentAmountField
                                                        {...{
                                                            field,
                                                            label: 'Interest Rate',
                                                            setAmount: apply(setFieldValue, field.name),
                                                            touched: touched.interestRate,
                                                            error: errors.interestRate,
                                                            disabled: transactionPending,
                                                            inputRightElement: (
                                                                <FinanceTermsLabel>{termLabels.financeChargeLabel}</FinanceTermsLabel>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="dueBy">
                                                {({ field }: FieldProps) => (
                                                    <FinancingDueByField
                                                        {...{
                                                            field,
                                                            label: 'Due Date',
                                                            setFinancingDueBy: apply(setFieldValue, field.name),
                                                            touched: touched.dueBy,
                                                            error: errors.dueBy,
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <TermsSummary
                                                terms={termLabels}
                                                feeInfo={
                                                    originationFee
                                                        ? `${utils.formatUnits(originationFee, nativeCurrency.decimals)} ${
                                                              nativeCurrency.symbol
                                                          }`
                                                        : undefined
                                                }
                                                hideDownPaymentRow
                                            />
                                            <LabelText>Additional Details</LabelText>
                                            <Field name="description">
                                                {({ field }: FieldProps) => (
                                                    <ClaimDescriptionField
                                                        {...{
                                                            field,
                                                            isDisabled: transactionPending,
                                                            error: errors.description,
                                                            touched: touched.description,
                                                            label: 'Description',
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="attachment">
                                                {({ field }: FieldProps) => (
                                                    <FileUploadField
                                                        attachment={values.attachment}
                                                        setAttachment={apply(setFieldValue, 'attachment')}
                                                        setErrorOn={() => setFieldError('attachment', 'Error uploading file')}
                                                        setErrorOff={() => setFieldError('attachment', undefined)}
                                                        uploadToIpfsOnAccept
                                                        error={errors.attachment}
                                                        label="File Upload"
                                                        field={field}
                                                        isDisabled={transactionPending}
                                                    />
                                                )}
                                            </Field>
                                        </Stack>
                                        <Box h="6" />
                                    </ModalBody>
                                    <ModalFooterWithShadow>
                                        <Spacer />
                                        <OrangeButton
                                            w={['100%', 'inherit', 'inherit']}
                                            type="submit"
                                            isLoading={isLoading}
                                            isDisabled={isDisabled}
                                        >
                                            {buttonText}
                                        </OrangeButton>
                                    </ModalFooterWithShadow>
                                </ModalContent>
                                <TokenApprovalModal
                                    modalOpen={approveModalOpen}
                                    closeModal={closeApproveModal}
                                    approvalInfos={[
                                        {
                                            approvalAmount: requiredAllowance,
                                            token: token,
                                        },
                                    ]}
                                />
                            </Form>
                        </>
                    );
                }}
            </Formik>
        </Modal>
    );
};

import { WarningTwoIcon } from '@chakra-ui/icons';
import { Box, Container, Flex, Grid, GridItem, HStack, Spacer, Stack, Text, Tooltip, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { WarningIconWithTooltip } from '../../../assets/warning';
import { addDaysToToday, isClaim } from '../../../data-lib/helpers';
import { BULLA_COLLECTION_ADDRESS, instantPaymentGasCost, TokenInfo } from '../../../data-lib/networks';
import { requireBatchCreate, useBullaBanker } from '../../../hooks/useBullaBanker';
import { useTokenBalances, useTokenPrices } from '../../../hooks/useChainData';
import { useSendClaimEmail } from '../../../hooks/useEmail';
import { ContactsRepo, isContactsReady, useExtendedContacts } from '../../../hooks/useExtendedContacts';
import { useInstantPayment } from '../../../hooks/useInstantPayment';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { useTokenSafety } from '../../../hooks/useTokenSafety';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useWeb3 } from '../../../hooks/useWeb3';
import { isUserReady, useAuth } from '../../../state/auth-state';
import { toUSD } from '../../../tools/common';
import { enableBatchFee } from '../../../tools/featureFlags';
import { InstantPaymentButton } from '../../display/claim-action-buttons';
import { ResponsiveStack } from '../../display/responsive-stack';
import { CreateOrangeButton } from '../../inputs/buttons';
import { ShadowBox } from '../../layout/cards';
import { ReadOnlyBullaItemRowNew } from './batch-rows';
import { InvoiceDetailsNew, NewBatchReviewState, PaymentDetailsNew } from './new-batch-wizard-modal';
import { StepCard } from './select-claim-types-card';
import { BatchFooter } from './shared-components';
import { CreateClaimFields } from '../create-claim-modal/create-claim-inputs';
import ReactApexChart from 'react-apexcharts';
import { CloseAllDetailsButton, OpenAllDetailsButton } from './add-payment-card';

const USD_FEE_PER_RECIPIENT = 2;
const MINIMUM_THRESHOLD = 100;

export const ClosedNewBatchReviewCard = () => {
    return <StepCard stepNumber={4} title="Review" />;
};

const toCreateClaimParams = (claim: any & { dueDate?: Date }, isClaim: boolean): CreateClaimFields => ({
    ...claim,
    instantPayment: !isClaim,
    token: claim.token!,
    recipient: claim.walletAddress,
    claimAmount: claim.amount,
    tags: claim.tags,
    dueBy: claim.dueDate!,
});

const TokenCannotBePricedIcon = () => (
    <Tooltip label={'This token cannot be priced. To price this token, contact us on Discord.'}>
        <WarningTwoIcon color={'#EDA321'} w="1.2em" h="1.2em" />
    </Tooltip>
);

const SomeTokensCouldNotBePricedIcon = () => (
    <Tooltip label={'Some tokens could not be priced.'}>
        <WarningTwoIcon color={'#EDA321'} w="1.2em" h="1.2em" />
    </Tooltip>
);

type OpenedNewBatchReviewCardProps = {
    state: NewBatchReviewState;
    onComplete: VoidFunction;
};

export const OpenedNewBatchReviewCard = ({ state, onComplete }: OpenedNewBatchReviewCardProps) => {
    const {
        connectedNetwork,
        connectedNetworkConfig: {
            gasLimitPerTransaction,
            batchCreate: { maxClaims },
        },
    } = useWeb3();
    const [executingClaims, { batchCreate }] = useBullaBanker();
    const { getTokenPrice } = useTokenPrices();
    const [executingIP, { createInstantPayments }] = useInstantPayment();
    const { authenticate, user } = useAuth();
    const sendClaimCreatedEmail = useSendClaimEmail();
    const contactsContext = useExtendedContacts();
    const {
        connectedNetworkConfig: { nativeCurrency },
    } = useWeb3();
    const tokenBalances = useTokenBalances({ chainId: connectedNetwork });
    const actingWallet = useActingWalletAddress();
    const { tokensByChainId, getTokenByChainIdAndAddress, resolveTokenInfo } = useTokenRepo();
    const { isTokenInfoSafe } = useTokenSafety();

    const [openStates, setOpenStates] = useState(state.claimDetails.map(() => false));

    const claimType = state.claimType;
    const isClaim = claimType === 'Invoice';
    const [saveToContacts, setSaveToContacts] = useState(false);
    const [batchIndex, setBatchIndex] = useState(0);

    const connectedNetworkTokens = tokensByChainId[connectedNetwork];
    const initialERC20 = connectedNetworkTokens.filter(x => !x.token.isNative)[0].token;
    const initialToken = isClaim ? initialERC20 : nativeCurrency.tokenInfo.token;

    const [feeToken, setFeeToken] = React.useState(initialToken);

    const paymentDateColumnSize = isClaim ? '3fr ' : '';
    const claimGridColumns = `4fr 8fr ${paymentDateColumnSize}3fr 3fr 2fr`;
    const columns = ['NAME', 'WALLET ADDRESS', isClaim ? 'PAYMENT DATE' : undefined, 'AMOUNT', 'DESCRIPTION', ''].filter(
        (x): x is string => x !== undefined,
    );

    const values = state.claimDetails as PaymentDetailsNew[] | InvoiceDetailsNew[];
    const claimDetailsWithDefaults = values.map(detail => ({
        ...detail,
        tags: (detail as any).tags || [],
    }));

    const setOfTokenAddresses = new Set(
        claimDetailsWithDefaults.map((x: any) => x.token?.address?.toLowerCase() || initialToken.address.toLowerCase()),
    );

    useEffect(
        () => [...setOfTokenAddresses].forEach(tokenAddress => resolveTokenInfo(connectedNetwork, tokenAddress)),
        [setOfTokenAddresses],
    );

    const executing = executingClaims || executingIP || user == 'authenticating';

    const batchSize = isClaim ? maxClaims : Math.floor(gasLimitPerTransaction / instantPaymentGasCost);
    const numberOfBatches = Math.ceil((claimDetailsWithDefaults.length + 1) / batchSize);
    const needsSignIn = useMemo(
        () => claimDetailsWithDefaults.some((value: any) => value.attachment === 'generate') && !isUserReady(user),
        [claimDetailsWithDefaults, user],
    );

    const getConnectedChainTokenByAddress = getTokenByChainIdAndAddress(connectedNetwork);

    const tokenInfoByTokenAddress = claimDetailsWithDefaults
        .map((detail: any) => getConnectedChainTokenByAddress(detail.token?.address || initialToken.address))
        .filter((x): x is TokenInfo => x !== undefined)
        .reduce<Record<string, TokenInfo>>((acc, item) => ({ ...acc, [item.token.address.toLowerCase()]: item }), {});

    const amountsByTokenAddress = claimDetailsWithDefaults.reduce<Record<string, { tokenInfo: TokenInfo | undefined; amount: number }>>(
        (acc, item: any) => ({
            ...acc,
            [item.token?.address?.toLowerCase() || initialToken.address.toLowerCase()]: {
                amount: (acc[item.token?.address?.toLowerCase() || initialToken.address.toLowerCase()]?.amount ?? 0) + +(item.amount || 0),
                tokenInfo: tokenInfoByTokenAddress[item.token?.address?.toLowerCase() || initialToken.address.toLowerCase()],
            },
        }),
        {},
    );

    const usdAmountsByRecipientAddress = claimDetailsWithDefaults.reduce<Record<string, number>>(
        (acc, item: any) => ({
            ...acc,
            [item.walletAddress.toLowerCase()]: Math.max(
                ((tokenInfoByTokenAddress[item.token?.address?.toLowerCase() || initialToken.address.toLowerCase()] &&
                    getTokenPrice(tokenInfoByTokenAddress[item.token?.address?.toLowerCase() || initialToken.address.toLowerCase()])) ||
                    0) *
                    +(item.amount || 0) +
                    (acc[item.walletAddress.toLowerCase()] ?? 0),
                USD_FEE_PER_RECIPIENT,
            ),
        }),
        {},
    );

    const bullaFeeUSD = enableBatchFee
        ? Object.values(usdAmountsByRecipientAddress)
              .map((x): number => (x >= MINIMUM_THRESHOLD ? USD_FEE_PER_RECIPIENT : 0))
              .reduce((a, b) => a + b, 0)
        : 0;

    const bullaFeeInToken =
        !getTokenPrice || bullaFeeUSD == 0
            ? '0'
            : (() => {
                  const tokenInfo = getConnectedChainTokenByAddress(feeToken.address);
                  if (!tokenInfo) return '0';
                  const tokenPrice = getTokenPrice(tokenInfo);
                  return tokenPrice ? (bullaFeeUSD / tokenPrice).toPrecision(3) : '0';
              })();

    const showTokenCannotBePrice = Object.entries(tokenInfoByTokenAddress).some(
        ([_, tokenInfo]) => tokenInfo === undefined || !getTokenPrice(tokenInfo),
    );

    const usdValuesNumbersByToken = Object.entries(amountsByTokenAddress).map(([address, { amount, tokenInfo }]) => [
        address,
        ((tokenInfo && getTokenPrice(tokenInfo)) || 0) * amount,
    ]);

    const usdValueOfTransaction = `${toUSD(
        usdValuesNumbersByToken.reduce<number>((acc, [_, amount]) => acc + +amount, 0) + bullaFeeUSD,
    )} USD`;
    const usdValueStringsByAddress = usdValuesNumbersByToken.reduce<Record<string, string | undefined>>(
        (acc, [address, usdValue]) => ({
            ...acc,
            [address]: +usdValue !== 0 ? toUSD(+usdValue) : undefined,
        }),
        {},
    );

    const addBullaFee = bullaFeeUSD != 0;
    const batches: any[][] = [...new Array(numberOfBatches).keys()].map((_, i) =>
        [...claimDetailsWithDefaults, ...(addBullaFee ? ['fee' as const] : [])].slice(i * batchSize, (i + 1) * batchSize),
    );

    const bullaFeeClaimParams: CreateClaimFields = {
        claimAmount: bullaFeeInToken,
        description: 'Bulla Batch Fee',
        dueBy: addDaysToToday(30),
        instantPayment: !isClaim,
        recipient: BULLA_COLLECTION_ADDRESS,
        tags: ['Expense', 'Bulla Fees'],
        token: feeToken,
        customCreditorAndDebtor: { creditor: BULLA_COLLECTION_ADDRESS, debtor: actingWallet },
    };

    const handleCreate = async (claimsInBatch: any[]) => {
        if (needsSignIn) await authenticate();
        const claimsParamsToCreate = claimsInBatch.map(
            (input): CreateClaimFields => (input == 'fee' ? bullaFeeClaimParams : toCreateClaimParams(input, isClaim)),
        );

        const createFn = isClaim
            ? () => {
                  requireBatchCreate(batchCreate);
                  return batchCreate.createClaimsBatch(claimType, claimsParamsToCreate);
              }
            : () => {
                  const inputs: CreateClaimFields[] = claimsParamsToCreate.map(params => ({
                      ...params,
                      tags: params.tags ?? [],
                      instantPayment: true,
                  }));
                  return createInstantPayments(inputs);
              };

        return createFn().then(tx => {
            if (tx) {
                try {
                    isContactsReady(contactsContext) && saveToContacts && contactsToBeAdded(contactsContext).length > 0
                        ? contactsContext.addOrUpdateContactsAsync(
                              contactsToBeAdded(contactsContext).map(x => ({
                                  name: x.name,
                                  walletAddress: x.walletAddress,
                                  emailAddress: x.emailAddress?.trim() === '' ? undefined : x.emailAddress,
                                  groups: [],
                              })),
                          )
                        : {};
                    sendClaimCreatedEmail(
                        claimType,
                        claimsInBatch
                            .filter((x): boolean => x !== 'fee')
                            .map(
                                ({
                                    amount,
                                    token,
                                    walletAddress: recipient,
                                    description,
                                    emailAddress,
                                    emailMessage,
                                    dueDate,
                                    confirmationEmailAddress,
                                }) => ({
                                    token: token || initialToken,
                                    claimAmount: amount,
                                    recipient,
                                    description,
                                    emailAddress: emailAddress,
                                    emailMessage: emailMessage,
                                    dueBy: dueDate ?? new Date(),
                                    emailCC: confirmationEmailAddress,
                                }),
                            ),
                        tx,
                    );
                } catch (e) {
                    console.error('cant send email', e);
                }

                return batchIndex + 1 == numberOfBatches ? onComplete() : setBatchIndex(batchIndex + 1);
            }
        });
    };

    const contactsToBeAdded = (contactsContext: ContactsRepo) =>
        claimDetailsWithDefaults
            .filter((x: any) => contactsContext.getContact(x.walletAddress) === 'not-found' && x.name?.trim() !== '')
            .reduce<any[]>((acc, item) => (acc.map(x => x.walletAddress).includes(item.walletAddress) ? acc : [...acc, item]), []);

    const selectableTokens = React.useMemo(
        () => tokensByChainId[connectedNetwork].filter(x => (!isClaim || !x.token.isNative) && isTokenInfoSafe(x)),
        [tokensByChainId[connectedNetwork], isTokenInfoSafe],
    );

    const usdValuesByCategories = Object.entries(
        values.reduce<Record<string, number>>((acc, { amount, groups, token }) => {
            if (!token || !groups || !amount) return acc;
            const tokenInfo = tokenInfoByTokenAddress[token.address.toLowerCase()];
            const tags = groups.length > 0 ? [groups] : ['Uncategorized'];

            return tags.reduce<Record<string, number>>(
                (acc, tag) => ({ ...acc, [tag[0]]: (acc[tag[0]] ?? 0) + ((tokenInfo && getTokenPrice(tokenInfo)) || 0) * +amount }),
                acc,
            );
        }, {}),
    ).sort(([_, a], [__, b]) => b - a);

    return (
        <Container maxW="100%" px="0" pb="12">
            <VStack spacing={6} align="stretch">
                <StepCard stepNumber={4} title="Review" isActive={true}>
                    <Box p="0" w="100%" mt={4}>
                        <ShadowBox p="8" mb="4">
                            <ResponsiveStack px="0" alignItems={'flex-start'} py="2" spacing={16} key="recap">
                                <VStack alignItems="flex-start" flex="1">
                                    <Text fontWeight={600} fontSize={'22px'} color="#353739" mb={4}>
                                        Overview
                                    </Text>
                                    <Stack spacing={4} w="fit-content" key="amount">
                                        <Stack alignItems="flex-start" spacing={2}>
                                            <Text fontWeight={400} fontSize={'16px'} color="#353739" mb={1}>
                                                Total Amount
                                            </Text>
                                            <Text fontWeight={600} fontSize={'30px'} color="#353739" whiteSpace={'nowrap'}>
                                                {usdValueOfTransaction}
                                            </Text>
                                            {showTokenCannotBePrice && <SomeTokensCouldNotBePricedIcon />}
                                        </Stack>
                                        <HStack spacing={24} w="100%" key="transfers">
                                            <Stack>
                                                <Text fontWeight={400} fontSize={'16px'} color="#353739" mb={1}>
                                                    Total Transfers
                                                </Text>
                                                <Text fontWeight={500} fontSize={'2.3em'} color="#353739" key="total transfers val">
                                                    {claimDetailsWithDefaults.length}
                                                </Text>
                                            </Stack>
                                            <Stack>
                                                <Text fontWeight={400} fontSize={'16px'} color="#353739" mb={1}>
                                                    Total Wallets
                                                </Text>

                                                <Text fontWeight={500} fontSize={'2.3em'} color="#353739" key="total wallets val">
                                                    {
                                                        [
                                                            ...new Set(
                                                                claimDetailsWithDefaults.map((x: any) => x.walletAddress.toLowerCase()),
                                                            ),
                                                        ].length
                                                    }
                                                </Text>
                                            </Stack>
                                        </HStack>
                                    </Stack>
                                </VStack>

                                <Stack spacing={0.5} alignItems={'flex-start'} w="fit-content" key="asset" flex="1">
                                    <Text fontWeight={600} fontSize={'22px'} color="#353739" mb={4}>
                                        Asset Breakdown
                                    </Text>
                                    {Object.entries(amountsByTokenAddress)
                                        .sort((a, b) => b[1].amount - a[1].amount)
                                        .map(amountByTokenAddress => {
                                            const address = amountByTokenAddress[0];
                                            const usdValue = usdValueStringsByAddress[address];
                                            const tokenBalance = tokenBalances.getBalanceForToken(address) ?? 0;
                                            const amount = amountByTokenAddress[1].amount;

                                            const insufficientFunds = tokenBalance < amount && !isClaim;
                                            const color = insufficientFunds ? '#C53030' : '#353739';

                                            return (
                                                <Box key={address} borderBottom="1px solid #E0E0E0" pb={1} w="100%">
                                                    <HStack>
                                                        <Text fontWeight={400} fontSize={'1.2em'} color={color}>
                                                            {amount}
                                                        </Text>
                                                        <Text fontWeight={700} fontSize={'1.2em'} color={color}>
                                                            {getConnectedChainTokenByAddress(amountByTokenAddress[0])?.token.symbol ??
                                                                '---'}
                                                        </Text>
                                                        {insufficientFunds && (
                                                            <WarningIconWithTooltip
                                                                label={`Insufficient funds. Current balance: ${tokenBalance} ${address}`}
                                                                warningOverrides={{ color, w: '1.2em', h: '1.2em' }}
                                                            />
                                                        )}
                                                        {usdValue === undefined && <TokenCannotBePricedIcon />}
                                                    </HStack>
                                                    {usdValue !== undefined && (
                                                        <Text fontWeight={500} fontSize={'0.9em'} color="#818181">
                                                            {usdValue} USD
                                                        </Text>
                                                    )}
                                                </Box>
                                            );
                                        })}
                                </Stack>

                                <Box flex="1" minWidth={'350px'} key="categories">
                                    <HStack>
                                        <Text fontWeight={600} fontSize={'22px'} color="#353739" mb={4}>
                                            Category Breakdown
                                        </Text>
                                        {showTokenCannotBePrice && <SomeTokensCouldNotBePricedIcon />}
                                    </HStack>
                                    <ReactApexChart
                                        options={{
                                            chart: {
                                                type: 'donut',
                                                toolbar: { show: false },
                                                fontFamily: 'Inter',
                                            },
                                            colors: ['#E66C0A', '#F6AD55', '#FBD38D', '#FEEBCB', '#E9EAEB'],
                                            legend: {
                                                position: 'right',
                                                fontSize: '14px',
                                                fontFamily: 'Inter',
                                                labels: {
                                                    colors: '#353739',
                                                },
                                            },
                                            dataLabels: {
                                                enabled: false,
                                            },
                                            tooltip: {
                                                enabled: false,
                                            },
                                            labels: usdValuesByCategories.map(([category]) => category),
                                        }}
                                        series={usdValuesByCategories.map(([_, value]) => value)}
                                        type="donut"
                                        height={250}
                                    />
                                </Box>
                            </ResponsiveStack>
                        </ShadowBox>
                        <Flex px="4" mb="4">
                            <Spacer />
                            {openStates.every(state => state) ? (
                                <CloseAllDetailsButton onClick={() => setOpenStates(prev => prev.map(() => false))} />
                            ) : (
                                <OpenAllDetailsButton onClick={() => setOpenStates(prev => prev.map(() => true))} />
                            )}
                        </Flex>
                        <Flex p="4" borderBottom="1px solid" borderColor="gray.200">
                            <Grid templateColumns={claimGridColumns} gap={4} w="100%">
                                {columns.map((col, i) => (
                                    <GridItem key={i} colSpan={1}>
                                        <Text fontSize={12} fontWeight={700} opacity={0.5}>
                                            {col}
                                        </Text>
                                    </GridItem>
                                ))}
                            </Grid>
                        </Flex>

                        <Box maxH="400px" overflowY="auto">
                            {batches.map((values, batchI) => (
                                <Box pt={4} key={batchI}>
                                    {batches.length !== 1 && (
                                        <Container px="4" py="2" w="fit-content">
                                            <Text>Transaction {batchI + 1}</Text>
                                        </Container>
                                    )}

                                    {values.map(
                                        (item, i) =>
                                            item !== 'fee' && (
                                                <ReadOnlyBullaItemRowNew
                                                    key={i}
                                                    columnString={claimGridColumns}
                                                    hasDueDate={isClaim}
                                                    value={item}
                                                    openCloseOverride={openStates[i] ? 'Open' : 'Close'}
                                                />
                                            ),
                                    )}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </StepCard>
            </VStack>

            <Formik onSubmit={_ => handleCreate(batches[batchIndex])} initialValues={{}} key="form">
                <Form placeholder={''}>
                    <BatchFooter>
                        <Spacer />
                        <HStack spacing={4}>
                            {isClaim ? (
                                CreateOrangeButton(
                                    needsSignIn
                                        ? 'Sign In'
                                        : `Create Claims${batches.length == 1 ? '' : ` (${batchIndex + 1} of ${batches.length})`}`,
                                )({
                                    type: 'submit',
                                    isDisabled: executing,
                                })
                            ) : (
                                <InstantPaymentButton
                                    isDisabled={executing}
                                    isLoading={executing}
                                    paymentInfo={batches[batchIndex].map((v: any) =>
                                        v == 'fee'
                                            ? { amount: bullaFeeClaimParams.claimAmount, token: bullaFeeClaimParams.token }
                                            : { amount: v.amount, token: v.token || initialToken },
                                    )}
                                    colorScheme={'accent'}
                                    withText={currText => {
                                        if (currText.includes('Send') && batches.length > 1) {
                                            return `${currText} (${batchIndex + 1} of ${batches.length})`;
                                        }
                                        return currText;
                                    }}
                                    h="14"
                                    py="6"
                                    px="10"
                                />
                            )}
                        </HStack>
                    </BatchFooter>
                </Form>
            </Formik>
        </Container>
    );
};

{
    "released": true,
    "contractName": "SafeModuleSetup",
    "version": "0.3.0",
    "networkAddresses": {
        "1": "0x2dd68b007B46fBe91B9A7c3EDa5A7a1063cB5b47",
        "10": "0x2dd68b007B46fBe91B9A7c3EDa5A7a1063cB5b47",
        "56": "0x2dd68b007B46fBe91B9A7c3EDa5A7a1063cB5b47",
        "97": "0x2dd68b007B46fBe91B9A7c3EDa5A7a1063cB5b47",
        "100": "0x2dd68b007B46fBe91B9A7c3EDa5A7a1063cB5b47",
        "130": "0x2dd68b007B46fBe91B9A7c3EDa5A7a1063cB5b47",
        "137": "0x2dd68b007B46fBe91B9A7c3EDa5A7a1063cB5b47",
        "480": "0x2dd68b007B46fBe91B9A7c3EDa5A7a1063cB5b47",
        "8453": "0x2dd68b007B46fBe91B9A7c3EDa5A7a1063cB5b47",
        "42161": "0x2dd68b007B46fBe91B9A7c3EDa5A7a1063cB5b47",
        "80002": "0x2dd68b007B46fBe91B9A7c3EDa5A7a1063cB5b47",
        "84532": "0x2dd68b007B46fBe91B9A7c3EDa5A7a1063cB5b47",
        "421614": "0x2dd68b007B46fBe91B9A7c3EDa5A7a1063cB5b47",
        "11155111": "0x2dd68b007B46fBe91B9A7c3EDa5A7a1063cB5b47"
    },
    "abi": [
        {
            "inputs": [
                {
                    "internalType": "address[]",
                    "name": "modules",
                    "type": "address[]"
                }
            ],
            "name": "enableModules",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        }
    ]
}

import { providers, Signer } from 'ethers';
import React, { useEffect, useState } from 'react';
import { useWeb3Unsafe } from './useWeb3';
import { useSafeAppsSDK } from '@safe-global/safe-apps-react-sdk';

type EoaSignerState =
    | {
          signer: Signer;
          address: string;
      }
    | 'not-connected';

const EoaSignerContext = React.createContext<EoaSignerState>('not-connected');

export const ConnectedEoaProvider = ({ children }: { children: React.ReactNode }) => {
    const { signer, userAddress, connectedNetworkConfigUnsafe } = useWeb3Unsafe();
    const {
        safe: { safeAddress },
        connected: inSafeApp,
    } = useSafeAppsSDK();
    const [eoaSigner, setEoaSigner] = useState<EoaSignerState>('not-connected');

    const getEoaSigner = async (signer: Signer, userAddress: string, isRetry?: boolean): Promise<EoaSignerState> => {
        if (inSafeApp) {
            if (!!window.ethereum) {
                const windowSigner = new providers.Web3Provider(window.ethereum).getSigner();
                try {
                    const address = await windowSigner.getAddress();
                    return { signer: windowSigner, address };
                } catch (e) {
                    return isRetry ? 'not-connected' : await ethereum.enable().then(() => getEoaSigner(signer, userAddress, true));
                }
            } else return 'not-connected';
        } else {
            return { signer, address: userAddress };
        }
    };

    useEffect(() => {
        if (!connectedNetworkConfigUnsafe || !userAddress || !signer) return;

        getEoaSigner(signer, userAddress).then(setEoaSigner);
    }, [window.ethereum, signer, safeAddress, connectedNetworkConfigUnsafe]);

    const context = React.useMemo(() => eoaSigner, [eoaSigner]);
    return <EoaSignerContext.Provider value={context}>{children}</EoaSignerContext.Provider>;
};

export const useConnectedEOA = () => {
    const eoaSigner = React.useContext(EoaSignerContext);
    if (eoaSigner == undefined) throw new Error('useConnectedEOA must be used within a ConnectedEoaProvider');
    return eoaSigner;
};

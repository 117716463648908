import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
    Alert,
    AlertIcon,
    Box,
    ButtonGroup,
    Grid,
    HStack,
    Link,
    ModalBody,
    Spacer,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { InstantPaymentInfo, PendingInstantPaymentInfo } from '../../../data-lib/data-model';
import { addressEquality, weiToDisplayAmt } from '../../../data-lib/ethereum';
import { getGnosisSafeURL } from '../../../data-lib/gnosis-tools/gnosis';
import { getItemRelationToUser } from '../../../data-lib/helpers';
import { NETWORKS, TokenDto } from '../../../data-lib/networks';
import { TokenDisplay } from '../../../data-lib/tokens';
import { useEditNotesDisplay } from '../../../hooks/useEditNotesDisplay';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useDataReadiness } from '../../../hooks/useUserData';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useWeb3 } from '../../../hooks/useWeb3';
import { useGnosisSafe } from '../../../state/gnosis-state';
import { toDateWithTime } from '../../../tools/common';
import { Bytes32Label } from '../../base/address-label';
import { WithSkeleton } from '../../base/skeleton';
import { getStatusBadge } from '../../base/status-badge';
import { ChainSymbol } from '../../chain-symbol';
import { TokenAmount } from '../../currency/token-display-amount';
import { ViewTagOnItem } from '../../inputs/account-tag-input';
import { IPFSLink } from '../../inputs/attachment-input';
import { BullaBlueTextButton } from '../../inputs/buttons';
import { CloseModalButton, ModalFooterWithShadow, ShareItemButton } from '../common';
import { PremiumPricingModal } from '../premium-pricing-modal';
import { claimDetailVariants, CreditorDebtorBox, ItemDesc, logVariants, ReceiptLine } from './item-details-components';
import { ViewLogModal } from './view-log';
import { useLinks } from '../../../hooks/useLinksApi';
import { Tax } from '../../display/views/new-invoice';

const TaxDetailsTable = ({
    instantPayment,
    token,
    tax,
}: {
    instantPayment: InstantPaymentInfo | PendingInstantPaymentInfo;
    tax: Tax;
    token: TokenDto;
}) => {
    const tokenSymbol = token.symbol;
    const total = weiToDisplayAmt({ amountWei: instantPayment.paidAmount, token: token });
    const subtotal = total / (1 + Number(tax.taxRate) / 100);
    const taxAmount = total - subtotal;

    return (
        <Box border="1px solid" borderColor="gray.200" borderRadius="md" mt={4} boxShadow="sm" overflow="hidden">
            <Table size="sm" variant="simple">
                <Thead bg="#FAFAFA">
                    <Tr>
                        <Th textTransform="none" fontSize="sm" fontWeight="600" color="gray.500" py={3}>
                            Tax Details
                        </Th>
                        <Th textTransform="none" fontSize="sm" fontWeight="600" color="gray.500" py={3}></Th>
                        <Th textTransform="none" fontSize="sm" fontWeight="600" color="gray.500" py={3}></Th>
                        <Th textTransform="none" fontSize="sm" fontWeight="600" color="gray.500" py={3}></Th>
                    </Tr>
                </Thead>
                <Tfoot>
                    <Tr>
                        <Td colSpan={3} fontWeight="600" py={3}>
                            Subtotal
                        </Td>
                        <Td isNumeric py={3} color="gray.700">
                            {`${tokenSymbol} ${subtotal.toFixed(2)}`}
                        </Td>
                    </Tr>
                    <Tr>
                        <Td colSpan={3} fontWeight="600" py={3}>
                            Tax ({tax.taxRate}%)
                        </Td>
                        <Td isNumeric py={3} color="gray.700">
                            {`${tokenSymbol} ${taxAmount.toFixed(2)}`}
                        </Td>
                    </Tr>
                    <Tr>
                        <Td colSpan={3} fontWeight="700" fontSize="sm" py={3} borderTop="1px solid" borderColor="gray.200">
                            Total
                        </Td>
                        <Td isNumeric fontWeight="700" fontSize="sm" py={3} color="gray.900" borderTop="1px solid" borderColor="gray.200">
                            {`${tokenSymbol} ${total.toFixed(2)}`}
                        </Td>
                    </Tr>
                </Tfoot>
            </Table>
        </Box>
    );
};

export type InstantPaymentDetailsProps = {
    instantPayment?: InstantPaymentInfo | PendingInstantPaymentInfo;
    handleClose: () => void;
    modalContentRef?: React.RefObject<HTMLDivElement>;
};

export const InstantPaymentDetails = ({ instantPayment, handleClose, modalContentRef }: InstantPaymentDetailsProps) => {
    const userAddress = useActingWalletAddress();
    const { connectedNetwork, connectedNetworkConfig } = useWeb3();
    const { isChainInitialized } = useDataReadiness();
    //TODO: implement banner for pending Instant Payments on a Gnosis safe
    const { pendingPayments, safeInfo } = useGnosisSafe();
    const isMobile = useIsMobile();
    const [taxInfo, setTaxInfo] = React.useState<Tax | null>(null);

    const [viewLogs, setViewLogs] = React.useState(false);
    const bodyRef = React.useRef<HTMLDivElement>(null);

    const isLoading = !instantPayment || !isChainInitialized(instantPayment.chainId);
    const isUserRelatedToItem =
        !isLoading && (addressEquality(userAddress, instantPayment.creditor) || addressEquality(userAddress, instantPayment.debtor));
    const isPendingGnosisApproval = (
        instantPayment: InstantPaymentInfo | PendingInstantPaymentInfo | undefined,
    ): instantPayment is PendingInstantPaymentInfo =>
        !!pendingPayments.pendingInstantPaymentInfos.find(item => item.id === instantPayment?.id);
    const toggleViewLogs = () => setViewLogs(viewLogs => !viewLogs);
    const { labelRow, notesRow, modalOpen, closeModal } = useEditNotesDisplay({
        isLoading,
        item:
            !!instantPayment && instantPayment.__type == 'InstantPayment'
                ? { ...instantPayment, initialNotes: instantPayment.notes, tags: [], description: '' }
                : undefined, // tags and description are not managed by backend
    });

    const { getTaxByTxHash } = useLinks();
    const txHash = instantPayment?.logs[0].txHash;

    React.useEffect(() => {
        const fetchTaxInfo = async () => {
            if (!instantPayment || !txHash) return;
            const tax = await getTaxByTxHash(txHash);
            setTaxInfo(tax);
        };

        fetchTaxInfo();
    }, [instantPayment, txHash]);

    const templateColumns = isMobile ? '1fr 1fr' : '1fr 1fr 1fr';

    const buttonGroup = (
        <HStack spacing="4" w="100%" justify="center" minH="50px">
            <ButtonGroup spacing="4">
                {!isPendingGnosisApproval(instantPayment) && (
                    <BullaBlueTextButton textDecoration={'none'} onClick={toggleViewLogs} minW="80px">
                        {viewLogs ? 'Back' : 'View Logs'}
                    </BullaBlueTextButton>
                )}
            </ButtonGroup>
            <Spacer />
            {/* {!isMobile && (
                <>
                    <Spacer />
                    {shareButton}
                </>
            )} */}
        </HStack>
    );

    return (
        <>
            <CloseModalButton onClose={handleClose} />

            <PremiumPricingModal modalOpen={modalOpen} closeModal={closeModal} />

            <ModalBody pb={10} pt={4} ref={bodyRef} px="0" overflowY="auto" overflowX={'hidden'}>
                <Box width="200%">
                    <AnimatePresence initial={false}>
                        <motion.div
                            key="details"
                            style={{
                                width: `50%`,
                                display: 'inline-block',
                                float: 'left',
                                padding: '0 2em',
                                maxHeight: 'fit-content',
                                height: '100%',
                            }}
                            initial={'active'}
                            animate={viewLogs ? 'inactive' : 'active'}
                            transition={{
                                x: { type: 'just' },
                            }}
                            variants={claimDetailVariants}
                        >
                            <WithSkeleton isLoading={isLoading} fixedWidth="16em" height="32px">
                                <Text color="gray.700" fontWeight={'700'} fontSize="24px" noOfLines={1} lineHeight="32px">
                                    Instant Payment Details
                                    {instantPayment && instantPayment.__type !== 'PendingInstantPayment' && (
                                        <ShareItemButton item={instantPayment} />
                                    )}
                                </Text>
                            </WithSkeleton>

                            <Box h="16px" />
                            {isPendingGnosisApproval(instantPayment) && (
                                <Alert status="info" mb="4">
                                    <AlertIcon />
                                    Payment is pending approval in your Gnosis-Safe
                                    <Link href={getGnosisSafeURL(connectedNetworkConfig, safeInfo!.safeAddress)} isExternal ml="2" mt="1">
                                        <Box color={'scheme.accent_dark'}>
                                            <ExternalLinkIcon mt="-4px" />
                                        </Box>
                                    </Link>
                                </Alert>
                            )}
                            <Stack spacing="3">
                                <ItemDesc title="Chain">
                                    <WithSkeleton isLoading={isLoading} fixedWidth="10em">
                                        {instantPayment && (
                                            <HStack>
                                                <ChainSymbol chainId={instantPayment.chainId} />
                                                <Text>{NETWORKS[instantPayment.chainId].label}</Text>
                                            </HStack>
                                        )}
                                    </WithSkeleton>
                                </ItemDesc>
                                <ItemDesc title="Token">
                                    <WithSkeleton isLoading={isLoading} fixedWidth="10em">
                                        {instantPayment && <TokenDisplay token={instantPayment.tokenInfo} />}
                                    </WithSkeleton>
                                </ItemDesc>
                                <ItemDesc title="Paid">
                                    <WithSkeleton isLoading={isLoading} fixedWidth="10em">
                                        {instantPayment && toDateWithTime(instantPayment.created)}
                                    </WithSkeleton>
                                </ItemDesc>

                                <ItemDesc title="Payment Id">
                                    <WithSkeleton isLoading={isLoading} fixedWidth="6em">
                                        <HStack>
                                            {instantPayment ? (
                                                <Bytes32Label bytes32={instantPayment.id} children={instantPayment.id} />
                                            ) : (
                                                'loading'
                                            )}
                                        </HStack>
                                    </WithSkeleton>
                                </ItemDesc>
                                <ItemDesc title="Description">
                                    <WithSkeleton
                                        children={instantPayment ? instantPayment.description : 'loading'}
                                        isLoading={isLoading}
                                        randomW
                                    />
                                </ItemDesc>
                                <ItemDesc title="File">
                                    <WithSkeleton
                                        children={instantPayment ? <IPFSLink ipfsHash={instantPayment.ipfsHash} /> : 'loading'}
                                        isLoading={isLoading}
                                        randomW
                                    />
                                </ItemDesc>
                                <ItemDesc title="Categories">
                                    <WithSkeleton isLoading={isLoading} fixedWidth="14em">
                                        {instantPayment ? (
                                            <ViewTagOnItem
                                                item={instantPayment}
                                                isDisabled={isUserRelatedToItem === false || instantPayment.chainId !== connectedNetwork}
                                                dropdownPortalRef={modalContentRef}
                                            />
                                        ) : (
                                            'loading'
                                        )}
                                    </WithSkeleton>
                                </ItemDesc>
                                <ItemDesc title="Status">
                                    <WithSkeleton
                                        children={
                                            instantPayment
                                                ? getStatusBadge(
                                                      isPendingGnosisApproval(instantPayment) ? 'Pending' : 'Paid',
                                                      isPendingGnosisApproval(instantPayment)
                                                          ? 'Out'
                                                          : getItemRelationToUser(userAddress, instantPayment).direction,
                                                  )
                                                : ''
                                        }
                                        isLoading={isLoading}
                                        randomW
                                    />
                                </ItemDesc>
                                {taxInfo && instantPayment && (
                                    <TaxDetailsTable instantPayment={instantPayment} tax={taxInfo} token={instantPayment.tokenInfo.token} />
                                )}
                            </Stack>
                            <Box h="2" />
                            <CreditorDebtorBox
                                maxW="380px"
                                creditor={instantPayment?.creditor ?? 'loading'}
                                debtor={instantPayment?.debtor ?? 'loading'}
                                creditorLabel="To"
                                debtorLabel="From"
                                chainId={instantPayment?.chainId ?? 1}
                            />
                            {instantPayment?.__type == 'InstantPayment' && (
                                <>
                                    {labelRow}
                                    {notesRow}
                                </>
                            )}
                            <Grid templateColumns={templateColumns} w={'100%'} mt="4">
                                <ReceiptLine
                                    tokenAmount={
                                        <WithSkeleton isLoading={isLoading}>
                                            {instantPayment && (
                                                <TokenAmount amount={instantPayment.paidAmount} tokenInfo={instantPayment.tokenInfo} />
                                            )}
                                        </WithSkeleton>
                                    }
                                    title="Paid Amount"
                                    isMobile={!!isMobile}
                                    isLoading={isLoading}
                                />
                            </Grid>
                        </motion.div>
                        <motion.div
                            style={{
                                width: `50%`,
                                display: 'inline-block',
                                float: 'left',
                                padding: '0 2em',
                                maxHeight: 'fit-content',
                            }}
                            key="logs"
                            initial={['invisible', 'out']}
                            animate={viewLogs ? ['visible', 'in'] : ['invisible', 'out']}
                            transition={{
                                x: { type: 'just' },
                            }}
                            variants={logVariants}
                        >
                            {instantPayment && !isPendingGnosisApproval(instantPayment) && <ViewLogModal item={instantPayment} />}
                        </motion.div>
                    </AnimatePresence>
                </Box>
            </ModalBody>
            <ModalFooterWithShadow {...(isMobile ? { px: '0' } : {})}>
                {isMobile ? (
                    <Stack w="100%" spacing={4}>
                        {/* {<Container w="fit-content">{shareButton}</Container>} */}
                        {buttonGroup}
                    </Stack>
                ) : (
                    buttonGroup
                )}
            </ModalFooterWithShadow>
        </>
    );
};

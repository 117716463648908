import { Box, Flex, Heading, HStack, Input, Spacer, Spinner, Stack, Text, VStack } from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import React, { useEffect, useRef } from 'react';
import { WarningIconWithTooltip } from '../../../assets/warning';
import { BullaItemInfo, BullaSwapInfoWithUSDMark } from '../../../data-lib/data-model';
import { TransferMetadata } from '../../../data-lib/dto/external-transactions-dto';
import { EthAddress, weiToDisplayAmt } from '../../../data-lib/ethereum';
import { getItemRelationToUser } from '../../../data-lib/helpers';
import { ChainId, NETWORKS, SUPPORTED_NETWORKS, TokenInfo } from '../../../data-lib/networks';
import { TokenDisplay } from '../../../data-lib/tokens';
import { useTokenPrices } from '../../../hooks/useChainData';
import { useOpenBullaItem } from '../../../hooks/useClaimDetailDisclosure';
import { useExtendedContacts } from '../../../hooks/useExtendedContacts';
import { getBackendTxIdForItem, useExternalTransactionsApi } from '../../../hooks/useExternalTransactionsApi';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useEditOffchainInvoiceMetadata } from '../../../hooks/useOffchainInvoices';
import { usePagination } from '../../../hooks/usePagination';
import { useSelectedNetworks } from '../../../hooks/useSelectedNetworks';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { useTokenSafety } from '../../../hooks/useTokenSafety';
import { BullaItemInfoWithPayment, useGlobalUserData, useUserData } from '../../../hooks/useUserData';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useWeb3 } from '../../../hooks/useWeb3';
import { isUserDataReady, useAppState } from '../../../state/app-state';
import { toDateDisplay, toUSD } from '../../../tools/common';
import { handleExport, toReportDateTimeString } from '../../../tools/excelExport';
import ExportMenu from '../../../tools/exportMenu';
import { FromAndToWallet } from '../../base/address-label';
import { useBullaToast } from '../../base/toast';
import { ChainSymbol } from '../../chain-symbol';
import { TokenAmount } from '../../currency/token-display-amount';
import { NewDirectionBadge } from '../../direction-badge';
import { OneTagPillOrMore } from '../../inputs/account-tag-input';
import { OrangeButton, SecondaryButton, ViewDetailsButton } from '../../inputs/buttons';
import {
    AMOUNT_COLUMN_WIDTH,
    CHAIN_COLUMN_WIDTH,
    DATE_COLUMN_WIDTH,
    FROM_TO_COLUMN_WIDTH,
    ListViewCard,
    ListViewCardProps,
    shadowProps,
    TwoLineTextWithLabel,
    USD_MARK_COLUMN_WIDTH,
    VIEW_COLUMN_WIDTH,
} from '../../layout/cards';
import { usePageScrollRef } from '../../layout/page-layout';
import { AreYouSureModal } from '../../modals/are-you-sure-modal';
import { AccountTagField, ClaimDescriptionField, disabledInputProps } from '../../modals/create-claim-modal/create-claim-inputs';
import { Direction, MOBILE_HEADERS, PageSelector } from '../claim-table';
import { ResponsiveStack } from '../responsive-stack';
import {
    buildPaymentsFilters,
    getCategoryFromParam,
    getRecipientFromParam,
    PaymentFilter,
    paymentFilterToPills,
    PaymentFilterValues,
} from './filters/claim-filter';
import { ClearFilterPillsStack, PillProps } from './filters/common';
import { buildFilters, emptyFilterValues, NetFlowsFilter, NetFlowsFilterValues } from './filters/net-flows-filter';

const safeAddition = (lhs: number, rhs: number, denominator: number) => (lhs * denominator + rhs * denominator) / denominator;
const safeSubtraction = (lhs: number, rhs: number, denominator: number) => (lhs * denominator - rhs * denominator) / denominator;

// subTotalOut is negative number
class TrialBalanceTotal {
    readonly subTotalIn: number;
    readonly subTotalOut: number;
    readonly total: number;
    readonly decimals: number;
    readonly denominator: number;

    constructor(subTotalIn: number, subTotalOut: number, decimals: number) {
        this.subTotalIn = subTotalIn;
        this.subTotalOut = subTotalOut;
        this.decimals = decimals;
        this.denominator = 10 ** decimals;
        this.total = safeAddition(subTotalIn, subTotalOut, this.denominator);
    }

    increment(absIncrementalIn: number, absIncrementalOut: number) {
        return new TrialBalanceTotal(
            safeAddition(this.subTotalIn, absIncrementalIn, this.denominator),
            safeSubtraction(this.subTotalOut, absIncrementalOut, this.denominator),
            this.decimals,
        );
    }

    static zero = (decimal: number) => new TrialBalanceTotal(0, 0, decimal);
}

type PaymentMetadata = {
    balanceInUsd: number;
    priceInformation: 'not-found' | 'fetching' | { tokenPrice: number; usdAmount: number };
    displayAmount: number;
    tokenBalance: TrialBalanceTotal;
};

export type PaymentTableItem = BullaItemInfoWithPayment & {
    onClick: VoidFunction;
    direction: Direction;
};

const mapBullaItemWithPaymentToTableItem = (
    items: (BullaItemInfoWithPayment | BullaSwapInfoWithPayment)[],
    userAddress: EthAddress,
    openItem: (item: BullaItemInfo) => void,
): PaymentTableItem[] =>
    items.map(item => {
        const onClick = () => openItem(item);
        const { direction } = getItemRelationToUser(userAddress, item);
        return { ...item, onClick, direction, payment: item.payment };
    });

const NET_FLOWS_REPORT_HEADERS = [
    'from',
    'to',
    'chain',
    'date',
    'categories',
    'token',
    'usd mark',
    'usd amount',
    'token in',
    'token out',
    'token balance',
    'usd balance',
];

const NET_FLOWS_TABLE_HEADERS: ListViewCardProps['headers'] = [
    { label: 'from / to' },
    { label: 'date' },
    { label: 'usd mark' },
    { label: 'usd amount' },
    { label: 'token in' },
    { label: 'token out' },
    { label: 'token balance' },
    { label: 'usd balance', tooltip: 'Token balance USD value at the specified date' },
    { label: '', relativeColumnWidth: VIEW_COLUMN_WIDTH },
];

export const NetFlows = () => {
    const { connectedNetwork } = useWeb3();
    const isMobile = useIsMobile();
    const tableRef = useRef<HTMLDivElement>(null);
    const ribbonRef = useRef<HTMLDivElement>(null);
    const [scrollTop, setScrollTop] = React.useState(0);
    const { getTokenByChainIdAndAddress, allTokensLength } = useTokenRepo();
    const { paidBullaItemsWithPayments, receivedBullaItemsWithPayments } = useGlobalUserData('include-originating-claims');
    const userDataByChainId = useUserData();
    const userAddress: EthAddress = useActingWalletAddress();
    const openItem = useOpenBullaItem();
    const { getTokenPrice, hash } = useTokenPrices();
    const { isTokenInfoSafe } = useTokenSafety();
    const contactsContext = useExtendedContacts();

    const scrollRef = usePageScrollRef();
    const scrollRefCurrent = scrollRef?.current;

    const onScroll = React.useCallback((e: any) => {
        setScrollTop(e.target.scrollTop ?? 0);
    }, []);

    React.useEffect(() => {
        scrollRefCurrent?.addEventListener('scroll', onScroll);
        return () => scrollRefCurrent?.removeEventListener('scroll', onScroll);
    }, [scrollRefCurrent ?? 'none']);

    const [claimFilters, setClaimFilters] = React.useState<((item: PaymentTableItem) => boolean)[]>([]);

    const { addNetwork } = useSelectedNetworks();

    const [filters, setFilters] = React.useState<NetFlowsFilterValues>(emptyFilterValues(connectedNetwork));
    React.useEffect(() => setClaimFilters(buildFilters(filters, contactsContext)), [filters, contactsContext]);

    const items: BullaItemInfoWithPayment[] = React.useMemo(() => {
        return [...paidBullaItemsWithPayments, ...receivedBullaItemsWithPayments]
            .filter(x => x.chainId == filters.chain)
            .sort((a, b) => a.paymentTimestamp.getTime() - b.paymentTimestamp.getTime());
    }, [paidBullaItemsWithPayments.length, receivedBullaItemsWithPayments.length, filters.chain]);

    const filtersOptions = React.useMemo(() => {
        const chains = [...new Set(items.map(x => x.chainId && x.chainId))];
        const paymentTimestamps = [...new Set(items.map(x => x.paymentTimestamp))];
        const firstPaymentTimestamp = new Date(Math.min(...items.map(item => item.paymentTimestamp.getTime())));
        const lastPaymentTimestamp = new Date(Math.max(...items.map(item => item.paymentTimestamp.getTime())));
        const tokens = [...new Set(items.map(x => x.tokenInfo.token.address.toLowerCase()))]
            .map(address => getTokenByChainIdAndAddress(filters.chain)(address))
            .filter((x): x is TokenInfo => x !== undefined)
            .sort((a, b) => -Number(isTokenInfoSafe(a)) + Number(isTokenInfoSafe(b)));
        const tags = [...new Set(items.map(x => x.tags.map(tag => tag)).flat())];

        return { chains, paymentTimestamps, firstPaymentTimestamp, lastPaymentTimestamp, tags, tokens };
    }, [items, filters.chain, allTokensLength]);

    const isFilteredChainReady = React.useMemo(
        () => userDataByChainId !== 'uninitialized' && isUserDataReady(userDataByChainId[filters.chain]),
        [userDataByChainId, filters.chain],
    );

    useEffect(() => addNetwork(filters.chain), [filters.chain]);

    const lineItems: PaymentTableItem[] = mapBullaItemWithPaymentToTableItem(items, userAddress, openItem);
    const filterItems = (items: PaymentTableItem[]) => claimFilters.reduce((claims, filterFunc) => claims.filter(filterFunc), [...items]);
    const filteredItems = filterItems(lineItems);

    const { rows: paymentsWithPricingInfo, accTokenBalance } = React.useMemo(
        () =>
            filteredItems.reduce<{
                rows: (PaymentTableItem & PaymentMetadata)[];
                accTokenBalance: TrialBalanceTotal;
            }>(
                ({ rows, accTokenBalance }, item) => {
                    const { tokenInfo, direction, payment, USDMark } = item;
                    const displayAmount = weiToDisplayAmt({ amountWei: payment, token: tokenInfo.token });

                    const priceInformation =
                        typeof USDMark == 'number' ? { tokenPrice: USDMark, usdAmount: USDMark * displayAmount } : USDMark;

                    const usdAmount = typeof priceInformation == 'string' ? 0 : priceInformation.usdAmount;

                    const [_, [tokenIncrementalIn, tokenIncrementalOut]] =
                        direction == 'In'
                            ? [
                                  [usdAmount, 0],
                                  [displayAmount, 0],
                              ]
                            : [
                                  [0, usdAmount],
                                  [0, displayAmount],
                              ];

                    const tokenBalance = accTokenBalance.increment(tokenIncrementalIn, tokenIncrementalOut);
                    const balanceInUsd =
                        typeof priceInformation === 'string' ? Number.NaN : tokenBalance.total * priceInformation.tokenPrice;

                    const rowMetadata: PaymentTableItem & PaymentMetadata = {
                        ...item,
                        priceInformation,
                        balanceInUsd,
                        displayAmount,
                        tokenBalance,
                    };

                    return { rows: [...rows, rowMetadata], accTokenBalance: tokenBalance };
                },
                {
                    rows: [],
                    accTokenBalance: TrialBalanceTotal.zero(filteredItems.length == 0 ? 0 : filteredItems[0].tokenInfo.token.decimals),
                },
            ),
        [filteredItems, filters.chain, isFilteredChainReady],
    );

    const pageSelectorProps = usePagination(paymentsWithPricingInfo);
    const visibleItems = pageSelectorProps.shownItems;

    const stringifiedPaymentRows = JSON.stringify(visibleItems);

    const displayPaymentsItems = React.useMemo(
        () =>
            visibleItems.map(
                ({
                    displayAmount,
                    tokenInfo,
                    paymentTimestamp,
                    direction,
                    chainId,
                    debtor,
                    creditor,
                    balanceInUsd,
                    priceInformation,
                    onClick,
                    tokenBalance,
                }: PaymentMetadata & PaymentTableItem) => {
                    const tokenAmountDisplay = (
                        <TokenAmount amount={displayAmount} tokenInfo={tokenInfo} isDisplayAmount removeSymbol withRounding />
                    );
                    const date = toDateDisplay(paymentTimestamp);

                    return {
                        columnValues: isMobile
                            ? [date, tokenAmountDisplay]
                            : [
                                  <FromAndToWallet chainId={chainId} from={debtor} to={creditor} />,
                                  <Flex>
                                      <Text textStyle="cell" minW="20px">
                                          {date}
                                      </Text>
                                  </Flex>,
                                  <Flex>
                                      {priceInformation == 'fetching'
                                          ? 'Loading'
                                          : priceInformation == 'not-found'
                                          ? 'Not Found'
                                          : toUSD(priceInformation.tokenPrice)}
                                  </Flex>,
                                  <Flex>
                                      {priceInformation == 'fetching'
                                          ? 'Loading'
                                          : priceInformation == 'not-found'
                                          ? 'Not Found'
                                          : toUSD(priceInformation.usdAmount)}
                                  </Flex>,
                                  <Flex>{direction == 'In' ? tokenAmountDisplay : 0}</Flex>,
                                  <Flex>{direction == 'Out' ? tokenAmountDisplay : 0}</Flex>,
                                  <TokenAmount
                                      amount={tokenBalance.total}
                                      tokenInfo={tokenInfo}
                                      isDisplayAmount
                                      removeSymbol
                                      withRounding
                                  />,
                                  Number.isNaN(balanceInUsd) ? '---' : toUSD(balanceInUsd),
                                  <ViewDetailsButton onClick={onClick} />,
                              ],
                    };
                },
            ),
        [stringifiedPaymentRows, isMobile],
    );

    const tokenInfoOrUndefined = visibleItems.length == 0 ? undefined : visibleItems[0].tokenInfo;

    const listView = React.useMemo(() => {
        return isFilteredChainReady ? (
            <ListViewCard
                headers={isMobile ? MOBILE_HEADERS : NET_FLOWS_TABLE_HEADERS}
                displayedListItems={displayPaymentsItems}
                bordered={false}
                flexHeight={true}
                totalItemCount={visibleItems.length}
                emptyMessage={'No payments to display'}
            />
        ) : (
            <Flex w="full" h="50vh" justifyContent={'center'} alignItems={'center'} flexDir="column">
                <Stack alignItems={'center'}>
                    <Spinner />
                    <Text fontWeight={500} fontSize="16px">
                        Loading
                    </Text>
                </Stack>
            </Flex>
        );
    }, [isFilteredChainReady, isMobile, stringifiedPaymentRows]);

    const currentTokenPrice = React.useMemo(
        () => filters.tokenInfo && getTokenPrice(filters.tokenInfo),
        [hash, filters.tokenInfo ?? 'no-token'],
    );

    const handleExportNetFlows = React.useCallback(
        async (method: 'excel' | 'csv') => {
            const headers = NET_FLOWS_REPORT_HEADERS;

            // All reports should be sorted earliest to latest
            const paymentsWithPricingInfoEarliestToLatest = [...paymentsWithPricingInfo].sort(
                (a, b) => a.paymentTimestamp.getTime() - b.paymentTimestamp.getTime(),
            );
            const dataRows = paymentsWithPricingInfoEarliestToLatest.map(x => {
                const tags = x.tags.filter(x => x !== '');
                const displayAmount = x.displayAmount;
                const chainLabel = NETWORKS[x.chainId].label;
                const formattedDate = toReportDateTimeString(x.paymentTimestamp);

                return [
                    x.debtor,
                    x.creditor,
                    chainLabel,
                    formattedDate,
                    tags.length == 0 ? '' : `"${x.tags.join(',')}"`,
                    x.tokenInfo.token.symbol,
                    typeof x.priceInformation == 'string' ? x.priceInformation : x.priceInformation.tokenPrice,
                    typeof x.priceInformation == 'string' ? x.priceInformation : x.priceInformation.usdAmount,
                    x.direction == 'In' ? displayAmount : 0,
                    x.direction == 'Out' ? displayAmount : 0,
                    x.tokenBalance.total,
                    x.balanceInUsd,
                ];
            });

            await handleExport(method, 'Net-Flows', headers, dataRows);
        },
        [stringifiedPaymentRows],
    );

    const mainSection = (
        <>
            <ResponsiveStack align="flex-start">
                <NetFlowsFilter filtersOptions={filtersOptions} filters={filters} setFilters={setFilters} />
                <Spacer />
                <ExportMenu handleExport={handleExportNetFlows} />
            </ResponsiveStack>
            <ClearFilterPillsStack
                pb="4"
                clearAll={() =>
                    setFilters(filters => {
                        const emptyValues = emptyFilterValues(filters.chain);
                        return { ...emptyValues, tokenInfo: filters.tokenInfo };
                    })
                }
                filters={filters}
                filtersToPills={filters => {
                    const searchPillProps: PillProps[] =
                        filters.search == undefined || filters.search.trim() == ''
                            ? []
                            : [{ label: `"${filters.search}"`, clear: () => setFilters(filters => ({ ...filters, search: '' })) }];

                    const dateFilterPills: PillProps[] = [
                        ...(!!filters.date.startDate
                            ? [
                                  {
                                      label: `From: ${toDateDisplay(filters.date.startDate)}`,
                                      clear: () => setFilters(filters => ({ ...filters, date: { ...filters.date, startDate: undefined } })),
                                  },
                              ]
                            : []),
                        ...(!!filters.date.endDate
                            ? [
                                  {
                                      label: `To: ${toDateDisplay(filters.date.endDate)}`,
                                      clear: () => setFilters(filters => ({ ...filters, date: { ...filters.date, endDate: undefined } })),
                                  },
                              ]
                            : []),
                    ];

                    return [...searchPillProps, ...dateFilterPills];
                }}
            />
            <Flex {...shadowProps} mb="4" p={3} px={5}>
                <Heading textStyle="listTitle" verticalAlign={'center'} margin={'auto auto'}>
                    Net Flows
                </Heading>
                <Spacer />
                <HStack spacing="8" textStyle="columnName" alignItems={'start'}>
                    <VStack alignItems={'center'}>
                        <Box>
                            Chain
                            <Spacer />
                        </Box>
                        <Box fontWeight={700} fontSize="16px" color="black">
                            <ChainSymbol chainId={filters.chain} />
                        </Box>
                    </VStack>
                    <VStack alignItems={'center'}>
                        <Box>
                            Token
                            <Spacer />
                        </Box>
                        <Box fontWeight={700} fontSize="16px" color="black">
                            {tokenInfoOrUndefined ? <TokenDisplay token={tokenInfoOrUndefined} /> : '---'}
                        </Box>
                    </VStack>
                    <VStack alignItems={'left'}>
                        <Box>
                            <NewDirectionBadge direction={'In'} />
                            <Spacer />
                        </Box>
                        <Box fontWeight={700} fontSize="16px" color="black">
                            {tokenInfoOrUndefined ? (
                                <TokenAmount
                                    amount={accTokenBalance.subTotalIn}
                                    tokenInfo={tokenInfoOrUndefined}
                                    isDisplayAmount
                                    removeSymbol
                                    withRounding
                                />
                            ) : (
                                '---'
                            )}
                        </Box>
                    </VStack>
                    <VStack alignItems={'left'}>
                        <Box>
                            <NewDirectionBadge direction={'Out'} />
                            <Spacer />
                        </Box>
                        <Box fontWeight={700} fontSize="16px" color="black">
                            {tokenInfoOrUndefined ? (
                                <TokenAmount
                                    amount={accTokenBalance.subTotalOut}
                                    tokenInfo={tokenInfoOrUndefined}
                                    isDisplayAmount
                                    removeSymbol
                                    withRounding
                                />
                            ) : (
                                '---'
                            )}
                        </Box>
                    </VStack>
                    <VStack alignItems={'left'}>
                        <Box>
                            Total
                            <Spacer />
                        </Box>
                        <Box fontWeight={700} fontSize="16px" color="black">
                            {tokenInfoOrUndefined ? (
                                <TokenAmount
                                    amount={accTokenBalance.total}
                                    tokenInfo={tokenInfoOrUndefined}
                                    isDisplayAmount
                                    removeSymbol
                                    withRounding
                                />
                            ) : (
                                '---'
                            )}
                        </Box>
                    </VStack>
                    <VStack alignItems={'left'}>
                        <Box>
                            Current Mark
                            <Spacer />
                        </Box>
                        <Text fontWeight={700} fontSize="16px" color="black">
                            {currentTokenPrice ? toUSD(currentTokenPrice) : '---'}
                        </Text>
                    </VStack>
                    <VStack alignItems={'left'}>
                        <Box>
                            Total USD
                            <Spacer />
                        </Box>
                        <Text fontWeight={700} fontSize="16px" color="black">
                            {currentTokenPrice ? toUSD(accTokenBalance.total * currentTokenPrice) : '---'}
                        </Text>
                    </VStack>
                </HStack>
            </Flex>
            <Flex {...shadowProps} direction={'column'} flex="1" overflowX="auto" ref={tableRef}>
                {listView}
            </Flex>
            <PageSelector {...pageSelectorProps} justifySelf="center" pt="6" />
        </>
    );

    return mainSection;
};

const PAYMENT_HEADERS: ListViewCardProps['headers'] = [
    { label: 'from / to', relativeColumnWidth: FROM_TO_COLUMN_WIDTH },
    { label: 'chain', relativeColumnWidth: CHAIN_COLUMN_WIDTH },
    { label: 'date', relativeColumnWidth: DATE_COLUMN_WIDTH },
    { label: 'amount', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'USD Mark', relativeColumnWidth: USD_MARK_COLUMN_WIDTH },
    { label: 'USD Amount', relativeColumnWidth: USD_MARK_COLUMN_WIDTH },
    { label: 'description' },
    { label: 'categories' },
    { label: 'notes' },
    { label: '', relativeColumnWidth: VIEW_COLUMN_WIDTH },
];

const ORGANIZE_PAYMENT_HEADERS: ListViewCardProps['headers'] = [
    { label: 'from / to', relativeColumnWidth: FROM_TO_COLUMN_WIDTH },
    { label: 'chain', relativeColumnWidth: CHAIN_COLUMN_WIDTH },
    { label: 'date', relativeColumnWidth: DATE_COLUMN_WIDTH },
    { label: 'amount', relativeColumnWidth: 'auto' },
    { label: 'USD Mark', relativeColumnWidth: USD_MARK_COLUMN_WIDTH },
    { label: 'USD Amount', relativeColumnWidth: USD_MARK_COLUMN_WIDTH },
    { label: 'description' },
    { label: 'categories' },
    { label: 'notes' },
    { label: '', relativeColumnWidth: VIEW_COLUMN_WIDTH },
];

const EditableTableDescriptionField: React.FC<{
    value: string;
    onChange: (newVal: string) => void;
    editMode: boolean;
    isDisabled?: boolean;
}> = ({ value, editMode, onChange, isDisabled }) => {
    return editMode && !isDisabled ? (
        <ClaimDescriptionField
            field={{
                name: 'description',
                value,
                onBlur: undefined,
                onChange: e => onChange(e.target.value),
            }}
            isDisabled={false}
        ></ClaimDescriptionField>
    ) : (
        <TwoLineTextWithLabel color={editMode && !!isDisabled ? 'gray.400' : 'black'}>{value}</TwoLineTextWithLabel>
    );
};

const EditableTableNotesField: React.FC<{
    value: string;
    onChange: (newVal: string) => void;
    editMode: boolean;
    isDisabled?: boolean;
}> = ({ value, editMode, onChange, isDisabled }) => {
    return editMode ? (
        <Input
            placeholder="Enter private notes"
            value={value}
            onChange={e => onChange(e.target.value)}
            isDisabled={!!isDisabled}
            {...disabledInputProps}
        />
    ) : (
        <TwoLineTextWithLabel color={'black'}>{value}</TwoLineTextWithLabel>
    );
};

const EditableTableCategoriesField: React.FC<{
    tags: string[];
    onChange: (newTags: string[]) => void;
    editMode: boolean;
    isDisabled?: boolean;
    dropdownPortalRef?: React.RefObject<HTMLDivElement>;
}> = ({ editMode, tags, onChange, isDisabled, dropdownPortalRef }) => {
    return editMode && !isDisabled ? (
        <AccountTagField
            field={{
                name: 'categories',
                value: tags,
                onBlur: undefined,
                onChange: undefined,
            }}
            isDisabled={false}
            setTags={onChange}
            setStatus={() => {}}
            creatingExpense={false}
            dropdownModalRef={dropdownPortalRef}
        />
    ) : (
        <OneTagPillOrMore tags={tags} />
    );
};
const initialOtherPendingChanges = Object.fromEntries(SUPPORTED_NETWORKS.map(chainId => [chainId, {}]));

type PendingMetadataChange = { newDescription?: string; newNotes?: string; newTags?: string[]; chainId?: ChainId };
type PendingMetadataChangesByChain = { [chainId: number]: { [id: string]: PendingMetadataChange | undefined } };
type AllPendingMetadataChanges = {
    offchainInvoiceChanges: Record<string, PendingMetadataChange>;
    otherChanges: PendingMetadataChangesByChain;
};

const initialPendingChanges: AllPendingMetadataChanges = { offchainInvoiceChanges: {}, otherChanges: initialOtherPendingChanges };

const getInitialPaymentFilterValues = (initialSelectedWallet?: string, initialCategory?: string): PaymentFilterValues => ({
    search: '',
    date: { startDate: undefined, endDate: undefined },
    type: undefined,
    priceRange: undefined,
    direction: 'In And Out',
    selectedWallets: new Set(initialSelectedWallet ? [initialSelectedWallet] : []),
    selectedNetworks: new Set(),
    selectedTokenSymbols: new Set(),
    category: initialCategory ?? undefined,
});

type BullaSwapInfoWithPayment = BullaSwapInfoWithUSDMark & {
    payment: BigNumber;
    paymentTimestamp: Date;
    USDMark: number | 'not-found' | 'fetching';
};

export const Payments = () => {
    const isMobile = useIsMobile();
    const actingWallet = useActingWalletAddress();
    const openItem = useOpenBullaItem();
    const triggerToast = useBullaToast();
    const { saveExternalTransactions } = useExternalTransactionsApi();
    const userDataStateByChain = useAppState();
    const [organizeMode, setOrganizeMode] = React.useState(false);
    const [isSaving, setSaving] = React.useState(false);
    const [isDirty, setIsDirty] = React.useState(false);
    const [pendingMetadataChanges, _setPendingMetadataChanges] = React.useState<AllPendingMetadataChanges>(initialPendingChanges);
    const editInvoiceMetadata = useEditOffchainInvoiceMetadata();
    const { isTokenInfoSafe } = useTokenSafety();
    const contactsContext = useExtendedContacts();

    const listViewCardRef = useRef<HTMLDivElement>(null);

    const setPendingMetadataChanges = React.useCallback((f: React.SetStateAction<AllPendingMetadataChanges>) => {
        setIsDirty(true);
        _setPendingMetadataChanges(f);
    }, []);

    const discardChanges = React.useCallback(() => {
        setPendingMetadataChanges(initialPendingChanges);
        setOrganizeMode(false);
        setIsDirty(false);
    }, []);

    const getPendingChangesForItem = React.useCallback(
        (chainId: ChainId, itemId: string, __type: BullaItemInfo['__type']): PendingMetadataChange =>
            __type == 'OffchainInvoiceInfo'
                ? ((pendingMetadataChanges.offchainInvoiceChanges[itemId] ?? {}) as PendingMetadataChange)
                : (pendingMetadataChanges.otherChanges[chainId] ?? {})[itemId] ?? {},
        [pendingMetadataChanges],
    );

    const { paidBullaItemsWithPayments, receivedBullaItemsWithPayments, swapsWithUSDMark } =
        useGlobalUserData('include-originating-claims');

    const items: (BullaItemInfoWithPayment | BullaSwapInfoWithPayment)[] = [
        ...paidBullaItemsWithPayments,
        ...receivedBullaItemsWithPayments,
    ].sort((a, b) => b.paymentTimestamp.getTime() - a.paymentTimestamp.getTime());
    const [claimFilters, setClaimFilters] = React.useState<((item: PaymentTableItem) => boolean)[]>([]);
    const recipientParam = getRecipientFromParam() ?? undefined;
    const categoryParam = getCategoryFromParam() ?? undefined;
    const [filters, setFilters] = React.useState<PaymentFilterValues>(() => getInitialPaymentFilterValues(recipientParam, categoryParam));

    React.useEffect(() => setClaimFilters(buildPaymentsFilters(filters, contactsContext)), [filters, contactsContext]);

    const lineItems: PaymentTableItem[] = mapBullaItemWithPaymentToTableItem(items, actingWallet, openItem);
    const filterItems = (items: PaymentTableItem[]) => claimFilters.reduce((claims, filterFunc) => claims.filter(filterFunc), [...items]);
    const filteredItems = filterItems(lineItems);
    const pageSelectorProps = usePagination(filteredItems);
    const visiblePayments = pageSelectorProps.shownItems;

    const setNewPendingChanges = React.useCallback(
        (chainId: ChainId, itemId: string, __type: BullaItemInfo['__type']) =>
            <T extends keyof PendingMetadataChange>(field: T, initialValue: PendingMetadataChange[T]) =>
            (newVal: PendingMetadataChange[T]) => {
                const nonOffchainInvoiceChange = (prev: AllPendingMetadataChanges): AllPendingMetadataChanges => ({
                    ...prev,
                    otherChanges: {
                        ...prev.otherChanges,
                        [chainId]: {
                            ...prev.otherChanges[chainId],
                            [itemId]: {
                                ...(prev.otherChanges[chainId][itemId] ?? {}),
                                chainId: __type == 'ImportedExternalTransaction' ? undefined : chainId,
                                [field]: newVal == initialValue ? undefined : newVal,
                            },
                        },
                    },
                });

                const offchainInvoiceChanges = (prev: AllPendingMetadataChanges): AllPendingMetadataChanges => ({
                    ...prev,
                    offchainInvoiceChanges: {
                        ...prev.offchainInvoiceChanges,
                        [itemId]: {
                            ...(prev.offchainInvoiceChanges[itemId] ?? {}),
                            [field]: newVal == initialValue ? undefined : newVal,
                        },
                    },
                });

                setPendingMetadataChanges(__type == 'OffchainInvoiceInfo' ? offchainInvoiceChanges : nonOffchainInvoiceChange);
            },
        [],
    );

    const stringifiedPaymentRows = JSON.stringify(visiblePayments);

    const displayPaymentsItems = React.useMemo(
        () =>
            visiblePayments.map((item: PaymentTableItem) => {
                const {
                    tokenInfo,
                    paymentTimestamp,
                    chainId,
                    debtor,
                    creditor,
                    onClick,
                    id,
                    description: initialDescription,
                    payment,
                    USDMark,
                    tags: initialTags,
                    notes: initialNotes,
                    __type,
                } = item;

                const isTokenSafe = isTokenInfoSafe(tokenInfo);

                const tokenAmountDisplay = (
                    <HStack>
                        <TokenAmount amount={payment} tokenInfo={tokenInfo} />
                        {!isTokenSafe && (
                            <WarningIconWithTooltip
                                label="Beware of airdropped scam tokens."
                                warningOverrides={{ color: 'red', w: '14px', h: '14px' }}
                            />
                        )}
                    </HStack>
                );
                const tokenAmountDisplayNumber = weiToDisplayAmt({ amountWei: payment, token: tokenInfo.token });
                const date = toDateDisplay(paymentTimestamp);
                const pendingMetadataChanges = getPendingChangesForItem(chainId, id, __type);

                const description = pendingMetadataChanges.newDescription ?? initialDescription;
                const tags = pendingMetadataChanges.newTags ?? initialTags;
                const notes = pendingMetadataChanges.newNotes ?? initialNotes;

                const setNewPendingChangeForItem = setNewPendingChanges(chainId, id, __type);

                return {
                    columnValues: isMobile
                        ? [date, tokenAmountDisplay]
                        : [
                              <FromAndToWallet chainId={chainId} from={debtor} to={creditor} />,
                              <ChainSymbol chainId={chainId} />,
                              date,
                              tokenAmountDisplay,
                              <Flex>{USDMark == 'fetching' ? 'Loading' : USDMark == 'not-found' ? 'Not Found' : toUSD(USDMark)}</Flex>,
                              <Flex>
                                  {USDMark == 'fetching'
                                      ? 'Loading'
                                      : USDMark == 'not-found'
                                      ? 'Not Found'
                                      : toUSD(tokenAmountDisplayNumber * USDMark)}
                              </Flex>,
                              <EditableTableDescriptionField
                                  editMode={organizeMode}
                                  value={description}
                                  onChange={setNewPendingChangeForItem('newDescription', initialDescription)}
                                  isDisabled={
                                      isSaving || __type == 'Claim' || __type == 'InstantPayment' || __type == 'OffchainInvoiceInfo'
                                  }
                              />,
                              <EditableTableCategoriesField
                                  tags={tags}
                                  onChange={setNewPendingChangeForItem('newTags', initialTags)}
                                  editMode={organizeMode}
                                  isDisabled={isSaving || __type == 'Claim' || __type == 'InstantPayment'}
                                  dropdownPortalRef={listViewCardRef}
                              />,
                              <EditableTableNotesField
                                  value={notes}
                                  editMode={organizeMode}
                                  onChange={notes => setNewPendingChangeForItem('newNotes', initialNotes)(notes)}
                                  isDisabled={isSaving}
                              />,
                              <ViewDetailsButton onClick={onClick} />,
                          ],
                    selectId: id,
                    isUnsafe: !isTokenSafe,
                };
            }),
        [stringifiedPaymentRows, isMobile, organizeMode, pendingMetadataChanges, isTokenInfoSafe],
    );

    const handleExportPayments = React.useCallback(
        async (method: 'excel' | 'csv') => {
            const headers = [
                'Date',
                'Chain',
                'From',
                'To',
                'Token',
                'Amount',
                'USD Mark',
                'USD Amount',
                'Description',
                'Categories',
                'Notes',
                'TXHash',
            ];

            // All reports should be sorted earliest to latest
            const filteredItemsEarliestToLatest = [...filteredItems].sort(
                (a, b) => a.paymentTimestamp.getTime() - b.paymentTimestamp.getTime(),
            );

            const dataRows = filteredItemsEarliestToLatest.map(payment => {
                const displayAmount = weiToDisplayAmt({ amountWei: payment.payment, token: payment.tokenInfo.token });
                const tags = payment.tags.filter(x => x !== '');
                const chainLabel = NETWORKS[payment.chainId].label;
                const formattedDate = toReportDateTimeString(payment.paymentTimestamp);

                return [
                    formattedDate,
                    chainLabel,
                    payment.debtor,
                    payment.creditor,
                    payment.tokenInfo.token.symbol,
                    displayAmount,
                    payment.USDMark,
                    typeof payment.USDMark == 'number' ? payment.USDMark * displayAmount : payment.USDMark,
                    payment.description,
                    tags.length == 0 ? '' : `"${payment.tags.join(',')}"`,
                    payment.notes,
                    payment.txHash,
                ];
            });

            await handleExport(method, 'Payments', headers, dataRows);
        },
        [filteredItems],
    );

    const mainSection = React.useMemo(
        () => (
            <>
                <ResponsiveStack align="flex-start">
                    <PaymentFilter filters={filters} setFilters={setFilters} searchPlaceholder={'payments'} hideClaimStatusFilter />
                    <Spacer />
                    {organizeMode ? (
                        <HStack>
                            <OrangeButton
                                isDisabled={isSaving}
                                isLoading={isSaving}
                                onClick={async () => {
                                    const userDataByChain = userDataStateByChain.userDataByChain;
                                    if (userDataByChain == 'uninitialized') return; // impossible

                                    setSaving(true);

                                    const networksToUpdate = Object.entries(pendingMetadataChanges.otherChanges)
                                        .map(([chainId, changesByItemId]): [string, Record<string, TransferMetadata>] => [
                                            chainId,
                                            Object.entries(changesByItemId).reduce<Record<string, TransferMetadata>>(
                                                (acc, [itemId, change]) =>
                                                    change == undefined
                                                        ? acc
                                                        : {
                                                              ...acc,
                                                              [getBackendTxIdForItem(itemId, change.chainId)]: {
                                                                  id: itemId,
                                                                  description: change.newDescription,
                                                                  tags: change.newTags,
                                                                  notes: change.newNotes,
                                                                  chainId: change.chainId,
                                                              },
                                                          },
                                                {},
                                            ),
                                        ])
                                        .filter(([_, txMetadata]) => Object.entries(txMetadata).length !== 0);

                                    const externalTxCalls = networksToUpdate.map(([chainId, transferMetadataById]) =>
                                        saveExternalTransactions(transferMetadataById, 'skip-refresh')
                                            .then(async () => {
                                                const userDataState = userDataByChain[+chainId];
                                                if (!isUserDataReady(userDataState)) {
                                                    console.error('User Data state not ready after saving, not normal');
                                                    return false;
                                                }

                                                await userDataState.userData.refetchExternalTransactions();
                                                return true;
                                            })
                                            .catch(e => {
                                                console.error('error while saving or refetching', chainId, e);
                                                return false;
                                            }),
                                    );

                                    const offchainInvoiceChanges = Object.entries(pendingMetadataChanges.offchainInvoiceChanges);
                                    const offchainInvoiceCalls =
                                        offchainInvoiceChanges.length > 0
                                            ? [
                                                  editInvoiceMetadata(
                                                      Object.fromEntries(
                                                          offchainInvoiceChanges.map(([id, changes]) => [
                                                              id,
                                                              { notes: changes.newNotes, tags: changes.newTags },
                                                          ]),
                                                      ),
                                                  ),
                                              ]
                                            : [];

                                    if (offchainInvoiceCalls.length + externalTxCalls.length > 0)
                                        await Promise.all([...externalTxCalls, ...offchainInvoiceCalls]).then(results =>
                                            results.every(x => x)
                                                ? (() => {
                                                      triggerToast({ title: 'Changes saved successfully!' });
                                                      discardChanges();
                                                  })()
                                                : triggerToast({
                                                      title: 'Error saving changes. Please try again later.',
                                                      status: 'error',
                                                  }),
                                        );

                                    setSaving(false);
                                }}
                                h="10"
                                px="6"
                            >
                                Save
                            </OrangeButton>
                            <AreYouSureModal
                                triggerElement={onOpen => (
                                    <SecondaryButton isDisabled={isSaving} onClick={isDirty ? onOpen : discardChanges} h="10" px="6">
                                        Cancel
                                    </SecondaryButton>
                                )}
                                title={'Discard Changes'}
                                message="Are you sure you want to discard changes? All unsaved changes will be lost."
                                buttonText="Discard"
                                onClick={discardChanges}
                            />
                        </HStack>
                    ) : (
                        <>
                            <SecondaryButton onClick={() => setOrganizeMode(true)} h="10" px="6">
                                Organize
                            </SecondaryButton>
                            <ExportMenu handleExport={handleExportPayments} />
                        </>
                    )}
                </ResponsiveStack>
                <ClearFilterPillsStack
                    pb="4"
                    clearAll={() => setFilters(getInitialPaymentFilterValues())}
                    filters={filters}
                    filtersToPills={paymentFilterToPills(setFilters)}
                />
                <Flex {...shadowProps} direction={'column'} flex="1" overflowX="auto" overflow={'visible'} ref={listViewCardRef}>
                    <ListViewCard
                        headers={isMobile ? MOBILE_HEADERS : organizeMode ? ORGANIZE_PAYMENT_HEADERS : PAYMENT_HEADERS}
                        displayedListItems={displayPaymentsItems}
                        bordered={false}
                        totalItemCount={visiblePayments.length}
                        rowSizeOverride={organizeMode ? 'auto' : undefined}
                        emptyMessage="No payments available"
                    />
                </Flex>
                <PageSelector {...pageSelectorProps} justifySelf="center" pt="6" />
            </>
        ),
        [stringifiedPaymentRows, isMobile, organizeMode, pendingMetadataChanges, isDirty, isSaving, filters],
    );

    return mainSection;
};

import { Box, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';

interface EntryDescriptionProps {
    description: string;
    maxLines?: number;
    minWidth?: string;
}

export const EntryDescription: React.FC<EntryDescriptionProps> = ({ description, maxLines = 2, minWidth = '150px' }) => {
    return (
        <Tooltip label={description}>
            <Box minW={minWidth} maxW="100%">
                <Text noOfLines={maxLines} overflow="hidden" textOverflow="ellipsis" whiteSpace="pre-wrap">
                    {description}
                </Text>
            </Box>
        </Tooltip>
    );
};

import { Badge, Box, Button, Flex, Heading, HStack, Spacer, Spinner, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { CheckCircle, Wallet } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { ClaimInfo } from '../../../data-lib/data-model';
import { FactoringConfig } from '../../../data-lib/networks';
import { useBullaFactoring } from '../../../hooks/useBullaFactoring';
import {
    BullaFactoringEligibilityState,
    BullaFactoringEligibilityTerms,
    useCheckFactoringEligibility,
} from '../../../hooks/useCheckFactoringEligibility';
import { useFactoringAndDepositPermissions } from '../../../hooks/useFactoringAndDepositPermissions';
import { MultisendTransaction } from '../../../hooks/useGnosisMultisend';
import { useGnosisTransaction } from '../../../hooks/useGnosisTransaction';
import { useWeb3 } from '../../../hooks/useWeb3';
import { useGnosisSafe } from '../../../state/gnosis-state';
import { toDateDisplay } from '../../../tools/common';
import { TokenAmount } from '../../currency/token-display-amount';
import { SelectPoolCard } from '../../factoring/factoring-pool-card';
import { ViewDetailsButton } from '../../inputs/buttons';
import { ListItemProps, ListViewCard, ListViewCardProps } from '../../layout/cards';
import { MaxWidthWrapper, PageLayoutProvider } from '../../layout/page-layout';
import { BullaFactorInvoiceModal } from '../../modals/item-details-modal/item-details-components';
import { BullaCloseButton } from '../common';
import { ProgressSteps, Step } from '../common-reporting/progress-steps';

// Define wizard step types
export type BatchFactoringWizardStep = 'selectPool' | 'approveInvoices';

// Define wizard state types
type SelectPoolStep = {
    step: 'selectPool';
    selectedClaims: ClaimInfo[];
};

type ApproveInvoicesStep = {
    step: 'approveInvoices';
    selectedClaims: ClaimInfo[];
    selectedPool: FactoringConfig;
};

type BatchFactoringWizardState = SelectPoolStep | ApproveInvoicesStep;

// Define wizard steps for progress bar
const steps: Step<BatchFactoringWizardStep>[] = [
    { icon: Wallet, label: 'Select Pool', step: 'selectPool' },
    { icon: CheckCircle, label: 'Approve Invoices', step: 'approveInvoices' },
];

const mapStepToProgress = (step: BatchFactoringWizardStep): number => {
    switch (step) {
        case 'selectPool':
            return 50;
        case 'approveInvoices':
            return 100;
        default:
            return 0;
    }
};

interface BatchFactoringWizardProps {
    selectedClaims: ClaimInfo[];
    onClose: () => void;
}

// Mapping function to get the appropriate display for each status
const getStatusDisplay = (status: BullaFactoringEligibilityState) => {
    switch (status.type) {
        case 'eligible':
            return <Badge colorScheme="green">Approved</Badge>;
        case 'not-eligible':
            return (
                <Badge colorScheme="red" title={status.reason}>
                    Not Eligible
                </Badge>
            );
        case 'not-supported':
            return <Badge colorScheme="gray">Not Supported</Badge>;
        case 'init':
            return <Spinner size="sm" />;
        default:
            return null;
    }
};

// Select Pool Step Component
interface SelectPoolStepProps {
    selectedClaims: ClaimInfo[];
    onPoolSelected: (factoringConfig: FactoringConfig) => void;
}

const SelectPoolStep: React.FC<SelectPoolStepProps> = ({ onPoolSelected }) => {
    const { poolsWithPermissions, isLoading } = useFactoringAndDepositPermissions();

    if (isLoading) {
        return <Text>Loading available pools...</Text>;
    }

    return (
        <VStack spacing={4} width="100%">
            {poolsWithPermissions
                .filter(pool => pool.hasFactoringPermissions)
                .map(pool => (
                    <SelectPoolCard
                        key={`${pool.address}-${pool.chainId}`}
                        factoringConfig={pool.factoringConfig}
                        onSelectPool={() => onPoolSelected(pool.factoringConfig)}
                    />
                ))}
        </VStack>
    );
};

// Approve Invoices Step Component
interface ApproveInvoicesStepProps {
    selectedClaims: ClaimInfo[];
    selectedPool: FactoringConfig;
    onBack: () => void;
    onClose: () => void;
}

// Define headers for the ListViewCard
const INVOICE_HEADERS: ListViewCardProps['headers'] = [
    { label: 'Status', relativeColumnWidth: '1fr' },
    { label: 'Description', relativeColumnWidth: '3fr' },
    { label: 'Amount', relativeColumnWidth: '2fr' },
    { label: 'Due Date', relativeColumnWidth: '2fr' },
    { label: 'Details', relativeColumnWidth: '2fr' },
];

const ApproveInvoicesStep: React.FC<ApproveInvoicesStepProps> = ({ selectedClaims, selectedPool, onBack, onClose }) => {
    const [approvalStatus, setApprovalStatus] = useState<Record<string, BullaFactoringEligibilityState>>({});
    const [, { checkEligibility }] = useCheckFactoringEligibility();
    const [selectedClaimForTerms, setSelectedClaimForTerms] = useState<ClaimInfo | null>(null);
    const [selectedTerms, setSelectedTerms] = useState<BullaFactoringEligibilityTerms | null>(null);
    const { isOpen, onOpen, onClose: closeModal } = useDisclosure();
    const { executeTransactions } = useGnosisTransaction();
    const { connectedNetworkConfig } = useWeb3();
    const { safeInfo } = useGnosisSafe();
    const [isFactoring, setIsFactoring] = useState(false);
    const [_, { approveInvoiceInputToMultisendTxDTO, fundInvoiceInputToMultisendTxDTO }] = useBullaFactoring(selectedPool);

    // Create list items for the ListViewCard based on selected claims
    const getListItems = (): ListItemProps[] => {
        return selectedClaims.map(claim => ({
            columnValues: [
                getStatusDisplay(approvalStatus[claim.id] || { type: 'init' }),
                claim.description,
                <TokenAmount amount={claim.claimAmount} tokenInfo={claim.tokenInfo} />,
                toDateDisplay(claim.dueBy),
                (() => {
                    const status = approvalStatus[claim.id] || { type: 'init' };

                    if (status.type === 'eligible') {
                        // Show ViewDetailsButton for eligible claims
                        return (
                            <ViewDetailsButton
                                onClick={() => {
                                    setSelectedClaimForTerms(claim);
                                    setSelectedTerms(status.terms);
                                    onOpen();
                                }}
                            />
                        );
                    } else if (status.type === 'not-eligible') {
                        // Show reason for not eligible claims
                        return (
                            <Text fontSize="sm" color="red.500">
                                {status.reason || 'Not eligible for factoring'}
                            </Text>
                        );
                    } else if (status.type === 'not-supported') {
                        return (
                            <Text fontSize="sm" color="gray.500">
                                Not supported
                            </Text>
                        );
                    } else if (status.type === 'init') {
                        return (
                            <Text fontSize="sm" color="gray.500">
                                Getting approval...
                            </Text>
                        );
                    }

                    return null;
                })(),
            ],
        }));
    };

    // Check if all items are ready (not in init state)
    const areAllItemsReady = () => {
        return (
            selectedClaims.every(claim => {
                const status = approvalStatus[claim.id];
                return status && status.type !== 'init';
            }) && selectedClaims.filter(claim => approvalStatus[claim.id]?.type === 'eligible').length > 0
        );
    };

    // Initialize claim statuses and check eligibility
    useEffect(() => {
        // Initialize all claims with loading (init) state
        const initialStatus: Record<string, BullaFactoringEligibilityState> = {};
        selectedClaims.forEach(claim => {
            initialStatus[claim.id] = { type: 'init' };
        });
        setApprovalStatus(initialStatus);

        const getTermsAndClaims = async () => {
            for (const claim of selectedClaims) {
                try {
                    const approvalResult = await checkEligibility(claim, selectedPool.bullaFactoringToken.token.address);

                    // Update the approvalStatus with the actual eligibility result
                    setApprovalStatus(prev => ({
                        ...prev,
                        [claim.id]: approvalResult,
                    }));
                } catch (error) {
                    console.error(`Error checking eligibility for claim ${claim.id}:`, error);
                    setApprovalStatus(prev => ({
                        ...prev,
                        [claim.id]: { type: 'not-eligible', reason: 'Error checking eligibility' },
                    }));
                }
            }
        };

        getTermsAndClaims();
    }, []);

    // Function to handle factoring the selected claims - fixed executeTransactions call
    const handleFactorClaims = async () => {
        if (isFactoring || !safeInfo) return;

        setIsFactoring(true);
        try {
            // Get eligible claims with their terms
            const termsAndClaims = selectedClaims
                .map((claim): [BullaFactoringEligibilityState, ClaimInfo] => [approvalStatus[claim.id], claim])
                .filter(
                    (statusAndClaims): statusAndClaims is [{ type: 'eligible'; terms: BullaFactoringEligibilityTerms }, ClaimInfo] =>
                        statusAndClaims[0].type === 'eligible',
                )
                .map(([{ terms }, claim]) => ({ terms, claim }));

            if (termsAndClaims.length === 0) {
                console.error('No eligible claims to factor');
                return;
            }

            // Generate multisend transactions using the hook methods
            const multisendTransactions = await termsAndClaims.reduce<Promise<MultisendTransaction[]>>(async (_acc, { terms, claim }) => {
                const acc = await _acc;
                const { transaction: approveInvoiceTransaction } = await approveInvoiceInputToMultisendTxDTO(
                    connectedNetworkConfig.bullaClaimAddress,
                    BigNumber.from(claim.id),
                    selectedPool.bullaFactoringToken.token.address,
                );
                const { transaction: fundInvoiceTransaction } = await fundInvoiceInputToMultisendTxDTO(
                    selectedPool.bullaFactoringToken.token.address,
                    BigNumber.from(claim.id),
                    terms.upfrontBps,
                );

                return [...acc, approveInvoiceTransaction, fundInvoiceTransaction];
            }, Promise.resolve([]));

            console.log(`Adding ${multisendTransactions.length} txs to batch`);

            // Execute the transactions with correct parameters
            try {
                const { success } = await executeTransactions(
                    safeInfo,
                    multisendTransactions,
                    true, // Include description
                );

                if (success) {
                    onClose(); // Close the wizard after successful factoring
                }
            } catch (error) {
                console.error('Error executing transactions:', error);
            }
        } catch (error) {
            console.error('Error factoring claims:', error);
        } finally {
            setIsFactoring(false);
        }
    };

    return (
        <VStack spacing={4} width="100%">
            <ListViewCard w="100%" headers={INVOICE_HEADERS} displayedListItems={getListItems()} emptyMessage="No invoices selected" />
            <HStack w="100%" spacing={4} pt={4}>
                <Button colorScheme="white" color="dark" border="1px" borderColor="dark" px="8" py="6" onClick={onBack}>
                    Back
                </Button>
                <Spacer />
                <Button
                    px="8"
                    py="6"
                    colorScheme="accent"
                    isDisabled={!areAllItemsReady() || isFactoring}
                    isLoading={isFactoring}
                    onClick={handleFactorClaims}
                >
                    Factor Invoices
                </Button>
            </HStack>

            {/* Factoring Terms Modal */}
            {selectedClaimForTerms && selectedTerms && (
                <BullaFactorInvoiceModal
                    modalOpen={isOpen}
                    closeModal={closeModal}
                    closeInvoice={closeModal}
                    claim={selectedClaimForTerms}
                    terms={selectedTerms}
                    factoringConfig={selectedPool}
                    viewOnly={true}
                />
            )}
        </VStack>
    );
};

// Main Wizard Component
export const BatchFactoringWizard: React.FC<BatchFactoringWizardProps> = ({ selectedClaims, onClose }) => {
    const [wizardState, setWizardState] = useState<BatchFactoringWizardState>({
        step: 'selectPool',
        selectedClaims,
    });
    const { connectedNetworkConfig } = useWeb3();
    const { executeTransactions } = useGnosisTransaction();
    const { connectedSafeAddress, safeInfo } = useGnosisSafe();

    if (!safeInfo) {
        return <Text>No safe found</Text>;
    }

    // Function to handle pool selection
    const handlePoolSelection = (factoringConfig: FactoringConfig) => {
        // Move to the approveInvoices step with the selected pool
        setWizardState({
            step: 'approveInvoices',
            selectedClaims,
            selectedPool: factoringConfig,
        });
    };

    // Render the appropriate step content
    const renderStepContent = () => {
        switch (wizardState.step) {
            case 'selectPool':
                return <SelectPoolStep selectedClaims={selectedClaims} onPoolSelected={handlePoolSelection} />;

            case 'approveInvoices':
                return (
                    <ApproveInvoicesStep
                        selectedClaims={wizardState.selectedClaims}
                        selectedPool={wizardState.selectedPool}
                        onBack={() =>
                            setWizardState({
                                step: 'selectPool',
                                selectedClaims: wizardState.selectedClaims,
                            })
                        }
                        onClose={onClose}
                    />
                );
        }
    };

    return (
        <PageLayoutProvider>
            <Box h="100vh" display="flex" flexDir="column" overflowY="scroll" p="12">
                <MaxWidthWrapper>
                    <Flex width="100%" justifyContent="space-between" pb="8">
                        <Heading color="heading" flex={1}>
                            Factor Invoices
                        </Heading>
                        <BullaCloseButton onClose={onClose} />
                    </Flex>
                    <VStack spacing={8} align="center" width="100%">
                        <Box pb="16" w="100%" display="flex" justifyContent="center">
                            <ProgressSteps mapStepToProgress={mapStepToProgress} steps={steps} currentStep={wizardState.step} />
                        </Box>
                        {renderStepContent()}
                    </VStack>
                </MaxWidthWrapper>
            </Box>
        </PageLayoutProvider>
    );
};

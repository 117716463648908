import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { TitledPage } from './components/base/title-route';
import { Contacts } from './components/display/views/contacts';
import { FinancingDashboard } from './components/display/views/financing';
import { NewInvoice } from './components/display/views/new-invoice';
import { _1099Wizard } from './components/modals/1099-export-modal/1099-wizard';
import { _8949Wizard } from './components/modals/8949-export-modal/8949-wizard';
import { BatchWizard } from './components/modals/batch-wizard-modal/batch-wizard-modal';
import { NewBatchWizard } from './components/modals/batch-wizard-modal/new-batch-wizard-modal';
import { LedgerWizard } from './components/modals/ledger-export-wizard-modal/ledger-wizard-modal';
import { LinkPayPage } from './components/modals/link-pay-page';
import { isClaim } from './data-lib/helpers';
import { CompanyDetailsProvider } from './hooks/useCompanyDetailsRepo';
import { ExtendedContactsProvider } from './hooks/useExtendedContacts';
import { FactoringAndDepositPermissionsProvider } from './hooks/useFactoringAndDepositPermissions';
import { FactoringVersionProvider } from './hooks/useFactoringVersion';
import { MultisendProvider } from './hooks/useGnosisMultisend';
import { HistoricalPricesProvider } from './hooks/useHistoricalPrices';
import { LinksProvider } from './hooks/useLinksApi';
import { MembershipProvider, useMembership } from './hooks/useMembership';
import { NamingServiceProvider } from './hooks/useNamingService';
import { OffchainInvoiceProvider } from './hooks/useOffchainInvoices';
import { PoolDetailsProvider } from './hooks/usePoolDetailsRepo';
import { SelectedNetworkProvider } from './hooks/useSelectedNetworks';
import { TokenSafetyProvider } from './hooks/useTokenSafety';
import { useGlobalUserData, UserDataProvider } from './hooks/useUserData';
import { NewHome } from './pages/enterprise-home/new-home';
import { FactoringPoolsList } from './pages/financing/factoring-pools-list';
import { PoolDetails } from './pages/financing/pool-details';
import { ExpiredWithItemsPage } from './pages/onboard/expired-with-items';
import { UpgradePage } from './pages/onboard/trial-expired';
import { ReportingExplorer } from './pages/reporting/reporting-explorer';
import { MyLinksPage } from './pages/settings/my-links/my-links-page';
import { MyLinksPageNew } from './pages/settings/my-links/my-links-page-new';
import { SettingsPage } from './pages/settings/settings';
import { AppProvider } from './state/app-state';
import { GnosisProvider } from './state/gnosis-state';
import {
    bypassProForReportingExplorer,
    enable1099wizard,
    enable8949wizard,
    enableFactoringDashboard,
    enableMandatoryPremium,
    enableNewBatchPage,
    enableNewInvoiceCreation,
    enableNewLinksPage,
    enableReporting,
    testingUpgradePath,
} from './tools/featureFlags';
import { TokenPricesProvider, ChainDataProvider } from './hooks/useChainData';
import { GettingStartedProvider } from './hooks/useGettingStarted';

const FreeTrialExpiredPage = () => (
    <UpgradePage heading="Your free trial has expired" text="Decide on one of our pricing options to continue using Bulla" />
);

const BatchInvoice = () => BatchWizard({ claimType: 'Invoice' });
const BatchPayment = () => BatchWizard({ claimType: 'Payment' });

export const RoutingComponent = () => {
    const premiumMembership = useMembership();
    const now = new Date();
    const nowInSeconds = now.getTime() / 1000;
    const userHasFreeTrial = premiumMembership?.isFreeTrial && nowInSeconds < premiumMembership.exp;
    const userWithoutMembership = premiumMembership == null;
    const { payables, receivables } = useGlobalUserData('exclude-originating-claims');
    const hasPendingItems = [...payables.filter(isClaim), ...receivables.filter(isClaim)].length > 0;

    return enableMandatoryPremium && (userWithoutMembership || testingUpgradePath) ? (
        hasPendingItems ? (
            <Routes>
                <Route
                    path="/"
                    element={
                        <TitledPage title="Trial Expired">
                            <ExpiredWithItemsPage />
                        </TitledPage>
                    }
                />
                <Route
                    path={'/upgrade'}
                    element={
                        <TitledPage title="Upgrade">
                            <UpgradePage />
                        </TitledPage>
                    }
                />
                {enableNewLinksPage && <Route path="/link-pay" element={<LinkPayPage />} />}
                <Route element={<Navigate replace to="/" />} />
            </Routes>
        ) : (
            <Routes>
                <Route
                    path="/"
                    element={
                        <TitledPage title="Trial Expired">
                            <FreeTrialExpiredPage />
                        </TitledPage>
                    }
                />
                {enableNewLinksPage && <Route path="/link-pay" element={<LinkPayPage />} />}
                <Route element={<Navigate replace to="/" />} />
            </Routes>
        )
    ) : (
        <Routes>
            <Route
                path="/"
                element={
                    <TitledPage title="Home">
                        <NewHome />
                    </TitledPage>
                }
            />
            <Route
                path={'/settings/*'}
                element={
                    <TitledPage title="Settings">
                        <SettingsPage />
                    </TitledPage>
                }
            />
            <Route
                path="/batch-invoice"
                element={
                    <TitledPage title="Batch">
                        <BatchInvoice />
                    </TitledPage>
                }
            />
            <Route
                path="/batch-payment"
                element={
                    <TitledPage title="Batch">
                        <BatchPayment />
                    </TitledPage>
                }
            />
            {enableNewBatchPage && (
                <Route
                    path="/batch"
                    element={
                        <TitledPage title="Batch">
                            <NewBatchWizard />
                        </TitledPage>
                    }
                />
            )}
            <Route path={'/payables'} element={<Navigate replace to="/?tab=Payables" />} />
            <Route path={'/receivables'} element={<Navigate replace to="/?tab=Receivables" />} />
            <Route
                path={'/contacts'}
                element={
                    <TitledPage title="Contacts">
                        <Contacts />
                    </TitledPage>
                }
            />
            <Route
                path={'/financing/new'}
                element={
                    <TitledPage title="Financing">
                        <FinancingDashboard />
                    </TitledPage>
                }
            />
            <Route
                path={'/financing'}
                element={
                    <TitledPage title="Financing">
                        <FinancingDashboard />
                    </TitledPage>
                }
            />
            {enableMandatoryPremium && userHasFreeTrial && (
                <Route
                    path={'/upgrade'}
                    element={
                        <TitledPage title="Upgrade">
                            <UpgradePage />
                        </TitledPage>
                    }
                />
            )}
            {enableMandatoryPremium && (
                <Route
                    path={'/trial-expired'}
                    element={
                        <TitledPage title="Trial Expired">
                            <FreeTrialExpiredPage />
                        </TitledPage>
                    }
                />
            )}
            {enableMandatoryPremium && (
                <Route
                    path={'/trial-expired-with-items'}
                    element={
                        <TitledPage title="Trial Expired">
                            <ExpiredWithItemsPage />
                        </TitledPage>
                    }
                />
            )}
            {enableFactoringDashboard && (
                <>
                    <Route
                        path={'/factoring/pools'}
                        element={
                            <TitledPage title="Bulla Factoring Pools">
                                <FactoringPoolsList />
                            </TitledPage>
                        }
                    />
                    <Route path={'/factoring/pools/:poolAddress'} element={<PoolDetails />} />
                </>
            )}

            {enableReporting && (premiumMembership?.tier == 'pro' || bypassProForReportingExplorer) && (
                <>
                    <Route
                        path={'/reporting'}
                        element={
                            <TitledPage title="Reporting Explorer">
                                <ReportingExplorer />
                            </TitledPage>
                        }
                    />
                    <Route
                        path="/ledger-wizard"
                        element={
                            <TitledPage title="Ledger Wizard">
                                <LedgerWizard />
                            </TitledPage>
                        }
                    />
                    {enable1099wizard && (
                        <Route
                            path="/1099-wizard"
                            element={
                                <TitledPage title="1099 Wizard">
                                    <_1099Wizard />
                                </TitledPage>
                            }
                        />
                    )}
                    {enable8949wizard && (
                        <Route
                            path="/8949-wizard"
                            element={
                                <TitledPage title="8949 Wizard">
                                    <_8949Wizard />
                                </TitledPage>
                            }
                        />
                    )}
                </>
            )}

            {enableNewInvoiceCreation && (
                <Route
                    path="/new-invoice"
                    element={
                        <TitledPage title="New Invoice">
                            <NewInvoice />
                        </TitledPage>
                    }
                />
            )}
            {enableNewLinksPage && <Route path="/link-pay" element={<LinkPayPage />} />}
            {<Route path="/my-links" element={enableNewLinksPage ? <MyLinksPageNew /> : <MyLinksPage />} />}
        </Routes>
    );
};

export const App = () => (
    <GnosisProvider>
        <SelectedNetworkProvider>
            <OffchainInvoiceProvider>
                <AppProvider>
                    <FactoringVersionProvider>
                        <LinksProvider>
                            <HistoricalPricesProvider>
                                <TokenSafetyProvider>
                                    <UserDataProvider>
                                        <NamingServiceProvider>
                                            <MembershipProvider>
                                                <MultisendProvider>
                                                    <ExtendedContactsProvider>
                                                        <CompanyDetailsProvider>
                                                            <TokenPricesProvider>
                                                                <ChainDataProvider>
                                                                    <GettingStartedProvider>
                                                                        <FactoringAndDepositPermissionsProvider>
                                                                            <PoolDetailsProvider>
                                                                                <RoutingComponent />
                                                                            </PoolDetailsProvider>
                                                                        </FactoringAndDepositPermissionsProvider>
                                                                    </GettingStartedProvider>
                                                                    {/* //TODO: add a page not found page */}
                                                                </ChainDataProvider>
                                                            </TokenPricesProvider>
                                                        </CompanyDetailsProvider>
                                                    </ExtendedContactsProvider>
                                                </MultisendProvider>
                                            </MembershipProvider>
                                        </NamingServiceProvider>
                                    </UserDataProvider>
                                </TokenSafetyProvider>
                            </HistoricalPricesProvider>
                        </LinksProvider>
                    </FactoringVersionProvider>
                </AppProvider>
            </OffchainInvoiceProvider>
        </SelectedNetworkProvider>
    </GnosisProvider>
);

import { Box, Container, Flex, Heading, HStack, Spacer, Text } from '@chakra-ui/react';
import { default as React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addDaysToToday } from '../../../data-lib/helpers';
import { TokenDto } from '../../../data-lib/networks';
import { Contact } from '../../../hooks/useExtendedContacts';
import { TemporaryCategoriesProvider } from '../../../hooks/useTemporaryCategories';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { useWeb3 } from '../../../hooks/useWeb3';
import { useUIState } from '../../../state/ui-state';
import { MaxWidthWrapper, NotificationWindow } from '../../layout/page-layout';
import { BullaCloseButton } from '../common';
import { ClosedAddPaymentDetailsCard, OpenedAddPaymentDetailsCard } from './add-payment-card';
import { initialDefaultValues, PaymentTime } from './batch-state';
import { ClosedNewBatchReviewCard, OpenedNewBatchReviewCard } from './new-batch-review-card';
import { NewStartDraftOrNewCard } from './new-start-new-or-draft-card';
import { ClosedSelectClaimTypesCard, OpenedSelectClaimTypesCard } from './select-claim-types-card';
import { ClosedSelectWalletsCard, OpenedSelectWalletsCard } from './select-wallets-card';
import { BullaItemAttachment } from '../create-claim-modal/create-claim-inputs';
import { useLocalStorage } from '../../../hooks/useStorage';
import { STORAGE_KEYS } from '../../../tools/storage';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useDebounce } from 'use-debounce';

export type PaymentDetailErrors = {
    amount?: string;
    walletAddress?: string;
    description?: string;
    emailAddress?: string;
    confirmationEmailAddress?: string;
};

export type PaymentDetailsNew = {
    walletAddress: string;
    name?: string;
    emailAddress?: string;
    groups?: string[];
    confirmationEmailAddress?: string;
    token?: TokenDto;
    amount?: string;
    description?: string;
    tags?: string[];
    emailMessage?: string;
    attachment?: BullaItemAttachment;
    errors?: PaymentDetailErrors;
};

export type InvoiceDetailsNew = PaymentDetailsNew & { dueDate?: Date };

export type NewBatchMetadata = {
    batchId: string;
    createdOn: string;
    name: string;
};

// Initial state
export type StartFromNewState = {
    kind: 'StartFromNewOrDraft';
};

// Step 1: Select batch name
export type SelectBatchNameState = {
    kind: 'SelectBatchName';
} & NewBatchMetadata;

// Step 2: Select claim type
export type SelectClaimTypeState = {
    kind: 'SelectClaimType';
    claimType: 'Invoice' | 'Payment';
} & NewBatchMetadata;

// Step 3: Select wallets
export type SelectWalletsTypeState = {
    kind: 'SelectWallets';
    claimDetails: PaymentDetailsNew[] | InvoiceDetailsNew[];
    contacts?: Contact[];
    claimType: 'Invoice' | 'Payment';
} & NewBatchMetadata;

// Step 4: Add payment details
export type AddPaymentDetailsTypeState = {
    kind: 'AddPaymentDetails';
    claimType: 'Invoice' | 'Payment';
    claimDetails: PaymentDetailsNew[] | InvoiceDetailsNew[];
    paymentTime?: PaymentTime;
    batchIndex?: number;
} & NewBatchMetadata;

// Step 5: Review
export type NewBatchReviewState = {
    kind: 'NewBatchReview';
    claimType: 'Invoice' | 'Payment';
    claimDetails: PaymentDetailsNew[] | InvoiceDetailsNew[];
    paymentTime?: PaymentTime;
    batchIndex?: number;
} & NewBatchMetadata;

export type NewBatchCreationState =
    | SelectBatchNameState
    | SelectClaimTypeState
    | SelectWalletsTypeState
    | AddPaymentDetailsTypeState
    | NewBatchReviewState;

export type NewBatchWizardState = StartFromNewState | NewBatchCreationState;

export const isBatchCreationState = (wizardState: NewBatchWizardState): wizardState is NewBatchCreationState =>
    (wizardState as NewBatchCreationState)?.batchId !== undefined;

export type SavingStatus = {
    isSaving: boolean;
    showText: boolean;
};

export const NewBatchWizard = () => {
    const { pendingTxns, alerts } = useUIState();
    const navigate = useNavigate();
    const { connectedNetwork } = useWeb3();
    const userAddress = useActingWalletAddress();
    const [initialBatchId] = useState(crypto.randomUUID());

    const initialNewBatchCreationState: SelectBatchNameState = {
        kind: 'SelectBatchName',
        name: '',
        batchId: initialBatchId,
        createdOn: new Date().toLocaleDateString(),
    };

    const [savingStatus, setSavingStatus] = useState<SavingStatus>({ isSaving: false, showText: false });
    const [wizardState, setWizardState] = useState<NewBatchWizardState>({ kind: 'StartFromNewOrDraft' });
    const [debouncedWizardState] = useDebounce(wizardState, 500);

    useEffect(() => {
        if (isBatchCreationState(debouncedWizardState)) {
            setSavingStatus({ isSaving: true, showText: true });

            setDrafts(prev => [...prev.filter(x => x.batchId !== debouncedWizardState.batchId), debouncedWizardState]);

            const savingTimer = setTimeout(() => {
                setSavingStatus(prev => ({ ...prev, isSaving: false }));

                const textTimer = setTimeout(() => {
                    setSavingStatus(prev => ({ ...prev, showText: false }));
                }, 2000);

                return () => clearTimeout(textTimer);
            }, 1000);

            return () => clearTimeout(savingTimer);
        }
    }, [debouncedWizardState]);

    const storageKey = `${userAddress}:${connectedNetwork}:${STORAGE_KEYS.batchDraftsNew}`;
    const [drafts, setDrafts] = useLocalStorage<NewBatchCreationState[]>(storageKey, []);

    const onClose = () => navigate('/');

    const { tokensByChainId } = useTokenRepo();

    const initialDefaultValuesWithToken = {
        ...initialDefaultValues,
        defaultToken: tokensByChainId[connectedNetwork][0].token,
        defaultDueDate: addDaysToToday(30),
    };

    const onWizardComplete = () => {
        setDrafts(prev => prev.filter(x => x.batchId !== (wizardState as NewBatchMetadata).batchId));
        onClose();
    };

    const startCard =
        wizardState.kind == 'StartFromNewOrDraft' ? (
            <NewStartDraftOrNewCard
                onDelete={deleted => {
                    setDrafts(prev => prev.filter(x => x.batchId !== deleted));
                }}
                initialNewBatchCreationState={initialNewBatchCreationState}
                setWizardState={setWizardState}
                onCancel={onClose}
                drafts={drafts}
            />
        ) : (
            <></>
        );

    const selectClaimTypeCard =
        wizardState.kind == 'StartFromNewOrDraft' ? (
            <></>
        ) : wizardState.kind == 'SelectClaimType' ? (
            <OpenedSelectClaimTypesCard
                state={wizardState}
                setWizardState={setWizardState}
                onCancel={onClose}
                savingStatus={savingStatus}
            />
        ) : (
            <ClosedSelectClaimTypesCard setWizardState={setWizardState} />
        );

    const selectWalletTypeCard =
        wizardState.kind == 'StartFromNewOrDraft' ? (
            <></>
        ) : wizardState.kind == 'SelectWallets' ? (
            <OpenedSelectWalletsCard
                state={wizardState}
                setWizardState={setWizardState}
                onCancel={onClose}
                initialDefaultValuesWithToken={initialDefaultValuesWithToken}
                savingStatus={savingStatus}
            />
        ) : (
            <ClosedSelectWalletsCard setWizardState={setWizardState} wizardState={wizardState} />
        );

    const addPaymentDetailsCard =
        wizardState.kind == 'StartFromNewOrDraft' ? (
            <></>
        ) : wizardState.kind == 'AddPaymentDetails' ? (
            <OpenedAddPaymentDetailsCard
                state={wizardState}
                setWizardState={setWizardState}
                onCancel={onClose}
                initialDefaultValuesWithToken={initialDefaultValuesWithToken}
                savingStatus={savingStatus}
            />
        ) : (
            <ClosedAddPaymentDetailsCard setWizardState={setWizardState} wizardState={wizardState} />
        );

    const reviewCard =
        wizardState.kind == 'StartFromNewOrDraft' ? (
            <></>
        ) : wizardState.kind == 'NewBatchReview' ? (
            <OpenedNewBatchReviewCard state={wizardState} onComplete={onWizardComplete} />
        ) : (
            <ClosedNewBatchReviewCard />
        );

    const getTitleContent = () => {
        const defaultTitle = {
            title: 'Batch',
            subtitle: 'Pay or invoice multiple wallets at once',
        };

        if (wizardState.kind === 'StartFromNewOrDraft' || wizardState.kind === 'SelectBatchName') {
            return defaultTitle;
        }

        const { claimType } = wizardState;
        if (!claimType) return defaultTitle;
        return {
            title: claimType === 'Payment' ? 'Batch Payment' : 'Batch Invoice',
            subtitle: claimType === 'Payment' ? 'Pay multiple wallets at once' : 'Invoice multiple wallets at once',
        };
    };

    return (
        <MaxWidthWrapper>
            <TemporaryCategoriesProvider>
                <Box h="100vh" display={'flex'} flexDir="column" flex="1 1 auto" overflowY="scroll" pb="12">
                    <NotificationWindow pendingTxns={pendingTxns} alerts={alerts} />
                    <Container maxW="100%" p={['4', '4', '8']} minH="fit-content">
                        <HStack>
                            <Box p="0" flex="1">
                                <Heading color="heading">{getTitleContent().title}</Heading>
                                <Text color="heading" mt="2" fontSize={'20px'} textStyle={'noWrap'}>
                                    {getTitleContent().subtitle}
                                </Text>
                            </Box>
                            <Spacer />
                            <BullaCloseButton onClose={onClose} />
                        </HStack>
                        <Flex pt="8" direction={'column'} w="full" gap="6">
                            {startCard}
                            {selectClaimTypeCard}
                            {selectWalletTypeCard}
                            {addPaymentDetailsCard}
                            {reviewCard}
                        </Flex>
                    </Container>
                </Box>
            </TemporaryCategoriesProvider>
        </MaxWidthWrapper>
    );
};

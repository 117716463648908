import { Box, Container, Image, Spinner, VStack } from '@chakra-ui/react';
import { Clipboard, Sparkle, Wallet } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FileIcon from 'url:../../../assets/file-icon.svg';
import { intToDate } from '../../../data-lib/helpers';
import { ChainId, chainIds } from '../../../data-lib/networks';
import { useAuthApi } from '../../../hooks/useAuthApi';
import { Build1099Response, useExternalTransactionsApi } from '../../../hooks/useExternalTransactionsApi';
import { useHistoricalPrices } from '../../../hooks/useHistoricalPrices';
import { useMembership } from '../../../hooks/useMembership';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { goStraightToLastPageIn1099 } from '../../../tools/featureFlags';
import { PageLayoutProvider } from '../../layout/page-layout';
import { BullaCloseButton } from '../common';
import { AddWalletsChainsCard, WalletChainSelection } from '../common-reporting/add-wallets-chains-card';
import { ProgressSteps, Step } from '../common-reporting/progress-steps';
import { ReportNameCard } from '../common-reporting/report-name-card';
import { _1099SuccessCard } from './1099-success-card';
import { AddRecipientsCard } from './add-recipients-card';

type ReportNameStep = { step: 'reportName'; reportName: string };
type SelectOwnWalletsStep = Omit<ReportNameStep, 'step'> & { step: 'selectOwnWallets' };
type AddRecipientsStep = Omit<SelectOwnWalletsStep, 'step'> & { step: 'addRecipients'; walletChainSelection: WalletChainSelection };
type BuildReportStep = Omit<AddRecipientsStep, 'step'> & { step: 'buildReport'; recipientWallets: Set<string> };
type ReportSuccessStep = Omit<BuildReportStep, 'step'> & { step: 'reportSuccess'; build1099Response: Build1099Response };

type _1099WizardState = ReportNameStep | SelectOwnWalletsStep | AddRecipientsStep | BuildReportStep | ReportSuccessStep;

type _1099WizardStep = _1099WizardState['step'];

const steps: Step<_1099WizardStep>[] = [
    {
        icon: Clipboard,
        label: 'Start 1099 Report',
        step: 'reportName',
    },
    { icon: Wallet, label: 'Add Wallets', step: 'selectOwnWallets' },
    { icon: Wallet, label: 'Add Recipients', step: 'addRecipients' },
    { icon: Sparkle, label: 'Build report', step: 'buildReport' },
];

const mapStepToProgress = (step: _1099WizardStep): number => {
    switch (step) {
        case 'reportName':
            return 0;
        case 'selectOwnWallets':
            return 33;
        case 'addRecipients':
            return 66;
        case 'buildReport':
            return 100;
        default:
            return 0;
    }
};

const FetchingCard: React.FC<{ state: BuildReportStep; onSuccess: (_: Build1099Response) => void }> = ({ state, onSuccess }) => {
    const { build1099Export } = useExternalTransactionsApi();
    const { loadTokenPrices } = useHistoricalPrices();
    const { resolveTokenInfo } = useTokenRepo();

    useEffect(() => {
        build1099Export({
            chainIds: state.walletChainSelection.chainIds,
            ownAddresses: state.walletChainSelection.addresses,
            recipients: state.recipientWallets,
        })
            .then(async response => {
                // Prepare data for the historical price cache
                const priceFetchParams = [
                    ...response.map(x => ({
                        chainId: x.chainId as ChainId,
                        timestamp: intToDate(x.timestamp),
                        tokenAddress: x.token.address,
                    })),
                ];

                const uniqueTokens = Array.from(
                    new Map(priceFetchParams.map(item => [`${item.chainId}-${item.tokenAddress}`, item])).values(),
                );

                await Promise.all([
                    loadTokenPrices(priceFetchParams),
                    ...uniqueTokens.map(x => resolveTokenInfo(x.chainId, x.tokenAddress)),
                ]);
                return response;
            })
            .then(onSuccess);
    }, []);
    return <Spinner />;
};

export const _1099Wizard: React.FC = () => {
    const navigate = useNavigate();
    const onClose = () => navigate('/reporting');
    const actingWallet = useActingWalletAddress();
    const [wizardState, setWizardState] = useState<_1099WizardState>(
        goStraightToLastPageIn1099
            ? {
                  step: 'buildReport',
                  walletChainSelection: {
                      chainIds: [chainIds.MATIC],
                      addresses: new Set(['0xf734908501a0B8d8d57C291ea1849490ccEdc16D']),
                  },
                  recipientWallets: new Set(['0xcd003c72BF78F9C56C8eDB9DC4d450be8292d339', '0x0000000000000000000000000000000000000000']),
                  reportName: '',
              }
            : { step: 'reportName', reportName: '' },
    );
    const { getFullMembershipInfo } = useAuthApi();
    const membership = useMembership();

    const [selectableWallets, setSelectableWallets] = useState<Set<string>>(new Set());

    const membershipIds = membership?.membershipIds ?? [];

    useEffect(() => {
        if (membership == null) return;
        if (membership.isFreeTrial || membershipIds.length == 0) {
            setSelectableWallets(new Set([actingWallet.toLowerCase()]));
            return;
        }

        Promise.all(membershipIds.map(getFullMembershipInfo)).then(x => {
            const selectableWallets = new Set(
                x.flatMap(dto => (dto ? [dto.mainAddress, ...dto.additionalAddresses] : [])).map(x => x.toLowerCase()),
            );
            return setSelectableWallets(selectableWallets);
        });
    }, [membershipIds, membership]);

    const startCard = wizardState.step === 'reportName' && (
        <ReportNameCard
            image={<Image src={FileIcon} />}
            welcomeMessage="Welcome to 1099 Generator"
            explanation="Export 1099 information for selected wallets and recipients."
            reportName={wizardState.reportName}
            setReportName={str => setWizardState(prev => ({ ...prev, reportName: str }))}
            onStartExport={() => setWizardState(prev => ({ ...prev, step: 'selectOwnWallets' }))}
        />
    );

    const selectOwnWalletsCard = wizardState.step === 'selectOwnWallets' && (
        <AddWalletsChainsCard
            selectableWallets={selectableWallets}
            onContinue={walletChainSelection =>
                setWizardState(prev => ({
                    ...(prev as SelectOwnWalletsStep),
                    step: 'addRecipients',
                    walletChainSelection,
                }))
            }
            onCancel={() =>
                setWizardState(prev => ({
                    ...prev,
                    step: 'reportName',
                }))
            }
        />
    );

    const addRecipientsCard = wizardState.step === 'addRecipients' && (
        <AddRecipientsCard
            ownWallets={wizardState.walletChainSelection.addresses}
            onContinue={wallets =>
                setWizardState(prev => ({
                    ...(prev as AddRecipientsStep),
                    step: 'buildReport',
                    recipientWallets: wallets,
                }))
            }
            onCancel={() =>
                setWizardState(prev => ({
                    ...prev,
                    step: 'selectOwnWallets',
                }))
            }
        />
    );

    const buildReportCard = wizardState.step === 'buildReport' && (
        <FetchingCard
            state={wizardState}
            onSuccess={wallets =>
                setWizardState(prev => ({
                    ...(prev as BuildReportStep),
                    step: 'reportSuccess',
                    build1099Response: wallets,
                }))
            }
        />
    );

    return (
        <PageLayoutProvider>
            {wizardState.step == 'reportSuccess' ? (
                <_1099SuccessCard
                    recipients={wizardState.recipientWallets}
                    data={wizardState.build1099Response}
                    reportName={wizardState.reportName}
                />
            ) : (
                <Box h="100vh" display={'flex'} flexDir="column" flex="1 1 auto" overflowY="scroll" p="12">
                    <Box display="flex" justifyContent="flex-end" width="100%">
                        <BullaCloseButton onClose={onClose} />
                    </Box>
                    <Container maxW="xl" p="0">
                        <VStack spacing={8} align="center">
                            {startCard}
                            {selectOwnWalletsCard}
                            {addRecipientsCard}
                            {buildReportCard}
                            <ProgressSteps mapStepToProgress={mapStepToProgress} steps={steps} currentStep={wizardState.step} />
                        </VStack>
                    </Container>
                </Box>
            )}
        </PageLayoutProvider>
    );
};

import {
    Container,
    HStack,
    Box,
    Spacer,
    Text,
    VStack,
    Input,
    Grid,
    GridItem,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Radio,
} from '@chakra-ui/react';
import { FilePlus, FloppyDisk, Trash } from 'phosphor-react';
import React from 'react';
import { ContinueButton, WhiteButton } from '../../inputs/buttons';
import { BatchFooter } from './shared-components';
import { NewBatchCreationState, NewBatchWizardState, SelectWalletsTypeState } from './new-batch-wizard-modal';
import { DeleteDraftModal } from './batch-exit-modal';

type NewStartDraftOrNewCardProps = {
    onDelete: (deleted: string) => void;
    setWizardState: React.Dispatch<React.SetStateAction<NewBatchWizardState>>;
    initialNewBatchCreationState: NewBatchWizardState;
    onCancel: () => void;
    drafts: NewBatchCreationState[];
};

export const NewStartDraftOrNewCard = ({
    onDelete,
    setWizardState,
    initialNewBatchCreationState,
    onCancel,
    drafts,
}: NewStartDraftOrNewCardProps) => {
    const [showCreateNewInput, setShowCreateNewInput] = React.useState(drafts.length === 0);
    const [showStartFromDraft, setShowStartFromDraft] = React.useState(false);
    const [batchName, setBatchName] = React.useState('');
    const [selectedDraft, setSelectedDraft] = React.useState<NewBatchCreationState | null>(null);
    const createNewBoxRef = React.useRef<HTMLDivElement>(null);
    const sortedDrafts = [...drafts].sort((a, b) => {
        const parseDate = (dateStr: string) => {
            const [day, month, year] = dateStr.split('/');
            return new Date(`${year}-${month}-${day}`);
        };

        return parseDate(b.createdOn).getTime() - parseDate(a.createdOn).getTime();
    });

    return (
        <Container maxW="100%" p="0">
            <VStack gap="6">
                <Box
                    ref={createNewBoxRef}
                    _hover={{ cursor: 'pointer' }}
                    border={showCreateNewInput ? '1.5px solid #EF6820' : '1.5px solid #e1e2e2'}
                    onClick={() => {
                        setShowCreateNewInput(true);
                        setShowStartFromDraft(false);
                        setSelectedDraft(null);
                    }}
                    borderRadius="xl"
                    display="flex"
                    flexDirection="column"
                    justifyContent="left"
                    shadow="xs"
                    w="100%"
                >
                    <HStack ml="8">
                        <FilePlus size={32} color=" #EF6820" />
                        <Text fontWeight={700} color="#101828" w="fit-content" py="8" fontSize="20px" ml="2">
                            Create New Batch
                        </Text>
                    </HStack>
                    {showCreateNewInput && (
                        <Box px="8" pb="6">
                            <Input
                                placeholder="Batch name (optional)"
                                value={batchName}
                                onChange={e => setBatchName(e.target.value)}
                                onClick={e => e.stopPropagation()}
                                w="500px"
                                border="1px solid"
                                borderColor="gray.400"
                            />
                        </Box>
                    )}
                </Box>

                {drafts.length > 0 && (
                    <Box
                        border={showStartFromDraft ? '1.5px solid #EF6820' : '1.5px solid #e1e2e2'}
                        onClick={() => {
                            setShowStartFromDraft(true);
                            setShowCreateNewInput(false);
                        }}
                        borderRadius="xl"
                        display="flex"
                        justifyContent="left"
                        flexDirection="column"
                        shadow="xs"
                        w="100%"
                        _disabled={{ cursor: 'not-allowed' }}
                    >
                        <HStack ml="8" _hover={{ cursor: 'pointer' }}>
                            <FloppyDisk size={32} color=" #EF6820" />
                            <Text fontWeight={700} color="#101828" w="fit-content" py="8" fontSize="20px" ml="2">
                                Start from Draft or Previous Batch
                            </Text>
                        </HStack>
                        {showStartFromDraft && (
                            <Box>
                                {drafts.length > 0 ? (
                                    <Table size="sm" variant="unstyled">
                                        <Thead bg="#FAFAFA">
                                            <Tr>
                                                <Th width="40px"></Th>
                                                <Th textTransform="none" fontSize="sm" fontWeight="600" color="gray.500" py={3}>
                                                    Description
                                                </Th>
                                                <Th textTransform="none" fontSize="sm" fontWeight="600" color="gray.500" py={3}>
                                                    Created On
                                                </Th>
                                                <Th textTransform="none" fontSize="sm" fontWeight="600" color="gray.500" py={3}>
                                                    Recipients
                                                </Th>
                                                <Th width="50px"></Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {sortedDrafts.map((draft, i) => (
                                                <Tr key={i} _hover={{ cursor: 'pointer' }} onClick={() => setSelectedDraft(draft)}>
                                                    <Td>
                                                        <Radio
                                                            value={draft.batchId}
                                                            isChecked={selectedDraft?.batchId === draft.batchId}
                                                            onChange={() => setSelectedDraft(draft)}
                                                            sx={{
                                                                '[data-checked]': {
                                                                    borderColor: '#EF6820',
                                                                    backgroundColor: '#EF6820',
                                                                },
                                                            }}
                                                            colorScheme="orange"
                                                        />
                                                    </Td>
                                                    <Td p="6">{draft.name}</Td>
                                                    <Td p="6">{draft.createdOn}</Td>
                                                    <Td p="6">{(draft as SelectWalletsTypeState).claimDetails?.length ?? 0}</Td>
                                                    <Td p="6">
                                                        <DeleteDraftModal
                                                            triggerElement={onClick => (
                                                                <Box color="gray.400" _hover={{ cursor: 'pointer' }} onClick={onClick}>
                                                                    <Trash size="18px" weight="bold" />
                                                                </Box>
                                                            )}
                                                            onDelete={() => onDelete(draft.batchId)}
                                                        />
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                ) : (
                                    <Box p="6">
                                        <Text color="gray.500">No drafts found</Text>
                                    </Box>
                                )}
                            </Box>
                        )}
                    </Box>
                )}
            </VStack>

            <BatchFooter>
                <Spacer />
                <WhiteButton onClick={onCancel}>Cancel</WhiteButton>
                <ContinueButton
                    onClick={() => {
                        if (selectedDraft) {
                            setWizardState(selectedDraft);
                        } else {
                            setWizardState({
                                ...initialNewBatchCreationState,
                                name: batchName === '' ? 'Untitled' : batchName,
                                kind: 'SelectClaimType',
                            } as NewBatchCreationState);
                        }
                    }}
                    isDisabled={!selectedDraft && !showCreateNewInput}
                />
            </BatchFooter>
        </Container>
    );
};

import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Flex,
    Heading,
    HStack,
    MenuItem,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Spacer,
    Stack,
    Text,
    Tooltip,
    VStack,
} from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { ArrowLeft, CaretDown } from 'phosphor-react';
import React, { ReactElement, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WarningIconWithTooltip } from '../../../assets/warning';
import { weiToDisplayAmt } from '../../../data-lib/ethereum';
import { intToDate } from '../../../data-lib/helpers';
import { ChainId, NETWORKS, TokenInfo } from '../../../data-lib/networks';
import { isContactsReady, useExtendedContacts } from '../../../hooks/useExtendedContacts';
import { ClaimLedgerEntryDto, PaymentLedgerEntryDto } from '../../../hooks/useExternalTransactionsApi';
import { useIsFactoringToken } from '../../../hooks/useFactoringVersion';
import { useHistoricalPrices } from '../../../hooks/useHistoricalPrices';
import { usePagination } from '../../../hooks/usePagination';
import { useSelection } from '../../../hooks/useSelection';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { TokenSafetyContext, useTokenSafety } from '../../../hooks/useTokenSafety';
import { useGlobalUserData } from '../../../hooks/useUserData';
import { ReportingBadge } from '../../../pages/reporting/reporting-explorer';
import { fillToCount, quickHash, toDateDisplay, toUSD } from '../../../tools/common';
import { ContactNameOrAddress, shortAddress } from '../../base/address-label';
import { PageSelector } from '../../display/claim-table';
import { ResponsiveStack } from '../../display/responsive-stack';
import { TokenMultiselect } from '../../display/token-multiselect';
import { NetworkMultiselect } from '../../display/view-network-selector';
import { TabOptions, TabSwitcher } from '../../display/views/account-tag-view';
import { checkDate, filterButtonProps, TableFilters } from '../../display/views/filters/claim-filter';
import {
    ClearFilterPillsStack,
    DateSelect,
    filterMenuProps,
    LedgerAccountFilter,
    PillProps,
    SetDate,
    TableFilter,
    WalletFilter,
} from '../../display/views/filters/common';
import { MenuDropdownButton, SecondaryButton } from '../../inputs/buttons';
import {
    ColumnDefinition,
    CR_DR_COLUMN_WIDTH,
    FROM_TO_COLUMN_WIDTH,
    ListItemProps,
    ListViewCard,
    ListViewCardProps,
} from '../../layout/cards';
import { MaxWidthWrapper, SummaryPanel } from '../../layout/page-layout';
import { isTcsToken } from '../common';
import { LEDGER_ACCOUNTS, LedgerAccount, toLedgerAccountNumber } from './ledger-account';
import { LedgerExportModal } from './ledger-export-modal';
import { SuccessState } from './ledger-wizard-modal';
import { LedgerExportTab, ledgerExportTabs } from './select-export-type-card';
import { EntryDescription } from '../../entry-description';

interface LedgerExportSummaryItemProps {
    badgeNumber: string;
    title: string;
    amount: number;
}

export type DomainPaymentLedgerEntry = Omit<PaymentLedgerEntryDto, 'token' | 'timestamp'> & {
    __typename: 'Payment';
    token: TokenInfo;
    timestamp: Date;
    displayAmount: number;
    id: string;
    amount: string;
};

export type DomainCashDisbursement = Omit<DomainPaymentLedgerEntry, '__typename' | 'adjustments' | 'instantPaymentId'> & {
    __typename: 'CashDisbursement';
};

export type DomainClaimLedgerEntry = Omit<ClaimLedgerEntryDto, 'token'> & {
    __typename: 'Claim';
    timestamp: Date;
    token: TokenInfo;
    displayAmount: number;
    id: string;
};

type ExportFilterValues = {
    date: { startDate?: Date; endDate?: Date };
    selectedWallets: Set<string>;
    selectedNetworks: Set<ChainId>;
    selectedTokenSymbols: Set<string>;
    selectedLedgerAccount?: LedgerAccount;
};

type SetFilter = (key: keyof ExportFilterValues, value: ExportFilterValues[keyof ExportFilterValues]) => void;

type FilterProps = {
    filters: ExportFilterValues;
    setFilters: React.Dispatch<SetStateAction<ExportFilterValues>>;
    selectableTokenSymbols: Set<string>;
    selectableWallets: Set<string>;
    selectableNetworks: Set<ChainId>;
};

const ExportFilter = ({ setFilters, filters, selectableTokenSymbols, selectableNetworks, selectableWallets }: FilterProps) => {
    const setFilter: SetFilter = (key, value) => setFilters(filters => ({ ...filters, [key]: value }));
    const setDate: SetDate = (type, date) => setFilters(filters => ({ ...filters, date: { ...filters.date, [type]: date } }));
    const resetDates = () => setFilters(filters => ({ ...filters, date: {} }));

    return (
        <Box w="100%" pb="4">
            <ResponsiveStack>
                <DateSelect
                    startDate={filters.date.startDate}
                    endDate={filters.date.endDate}
                    setDate={setDate}
                    resetDates={resetDates}
                    paymentTimestamps={[]}
                />
                <WalletFilter
                    selectableWallets={selectableWallets}
                    selectedWallets={filters.selectedWallets}
                    onClick={address =>
                        setFilter(
                            'selectedWallets',
                            filters.selectedWallets.has(address)
                                ? (() => {
                                      filters.selectedWallets.delete(address);
                                      return filters.selectedWallets;
                                  })()
                                : filters.selectedWallets.add(address),
                        )
                    }
                />
                <NetworkMultiselect
                    selectableNetworks={selectableNetworks}
                    selectedNetworks={filters.selectedNetworks}
                    toggleNetwork={chainId =>
                        setFilter(
                            'selectedNetworks',
                            filters.selectedNetworks.has(chainId)
                                ? (() => {
                                      filters.selectedNetworks.delete(chainId);
                                      return filters.selectedNetworks;
                                  })()
                                : filters.selectedNetworks.add(chainId),
                        )
                    }
                />
                <TokenMultiselect
                    seletectableTokenSymbols={selectableTokenSymbols}
                    selectedTokenSymbols={filters.selectedTokenSymbols}
                    toggleTokenSymbol={_symbol => {
                        const symbol = _symbol.toUpperCase();
                        return setFilter(
                            'selectedTokenSymbols',
                            filters.selectedTokenSymbols.has(symbol)
                                ? (() => {
                                      filters.selectedTokenSymbols.delete(symbol);
                                      return filters.selectedTokenSymbols;
                                  })()
                                : filters.selectedTokenSymbols.add(symbol),
                        );
                    }}
                />
                <LedgerAccountFilter
                    selectedAccount={filters.selectedLedgerAccount}
                    onSelect={account => setFilter('selectedLedgerAccount', account!)}
                />
            </ResponsiveStack>
        </Box>
    );
};

type SummaryFilterValues = {
    date: { startDate?: Date; endDate?: Date };
};

type SummaryFilterProps = {
    filters: SummaryFilterValues;
    setFilters: React.Dispatch<SetStateAction<SummaryFilterValues>>;
};

const SummaryFilter = ({ setFilters, filters }: SummaryFilterProps) => {
    const setDate: SetDate = (type, date) => setFilters(filters => ({ ...filters, date: { ...filters.date, [type]: date } }));
    const resetDates = () => setFilters(filters => ({ ...filters, date: {} }));

    return (
        <Box w="100%" pb="4">
            <ResponsiveStack>
                <DateSelect
                    startDate={filters.date.startDate}
                    endDate={filters.date.endDate}
                    setDate={setDate}
                    resetDates={resetDates}
                    paymentTimestamps={[]}
                />
            </ResponsiveStack>
        </Box>
    );
};

export const LedgerExportSummaryItem: React.FC<LedgerExportSummaryItemProps> = ({ badgeNumber, title, amount }) => {
    return (
        <VStack align="start" spacing="2">
            <ReportingBadge backgroundColor="#EEF4FF" color="#3538CD" borderColor="#C7D7FE" borderRadius="xl" ml="0">
                {badgeNumber}
            </ReportingBadge>
            <Text fontWeight="bold">{title}</Text>
            <Text fontSize="2xl" fontWeight="bold">
                $ {amount.toFixed(2)}
            </Text>
        </VStack>
    );
};

interface FilterPopoverProps {
    label: string;
    icon: ReactElement;
}

export const FilterPopover: React.FC<FilterPopoverProps> = ({ label, icon }) => {
    return (
        <Popover placement="bottom-start">
            <PopoverTrigger>
                <Button leftIcon={icon} rightIcon={<CaretDown size={16} />} {...filterButtonProps} width="fit-content" color="gray.700">
                    {label}
                </Button>
            </PopoverTrigger>
            <PopoverContent {...filterMenuProps}>
                <PopoverBody>
                    <Text>Mock Filter</Text>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

const PAYMENT_HEADERS: ColumnDefinition[] = [
    { label: 'DATE', relativeColumnWidth: 'auto' },
    { label: 'FROM' },
    { label: 'TO' },
    { label: 'DESCRIPTION', relativeColumnWidth: '1fr' },
    { label: 'CHAIN', relativeColumnWidth: '1fr' },
    { label: 'TOKEN AMOUNT' },
    { label: 'SYMBOL', relativeColumnWidth: '0.5fr' },
    { label: 'USD MARK', relativeColumnWidth: '1fr' },
    { label: 'USD AMOUNT', relativeColumnWidth: '1fr' },
    { label: 'DR', relativeColumnWidth: CR_DR_COLUMN_WIDTH },
    { label: 'CR', relativeColumnWidth: CR_DR_COLUMN_WIDTH },
];

const CASH_DISBURSEMENT_HEADERS: ColumnDefinition[] = [
    { label: 'DATE', relativeColumnWidth: 'auto' },
    { label: 'FROM' },
    { label: 'TO' },
    { label: 'CHAIN', relativeColumnWidth: '1fr' },
    { label: 'TOKEN AMOUNT' },
    { label: 'SYMBOL', relativeColumnWidth: '0.5fr' },
    { label: 'USD MARK', relativeColumnWidth: '1fr' },
    { label: 'USD AMOUNT', relativeColumnWidth: '1fr' },
];

const CLAIM_HEADERS: ColumnDefinition[] = [
    { label: 'DATE', relativeColumnWidth: 'auto' },
    { label: 'CREDITOR' },
    { label: 'DEBTOR' },
    { label: 'CLAIM ID' },
    { label: 'DESCRIPTION', relativeColumnWidth: '1fr' },
    { label: 'ACTION', relativeColumnWidth: '1fr' },
    { label: 'CHAIN', relativeColumnWidth: '1fr' },
    { label: 'TOKEN AMOUNT' },
    { label: 'SYMBOL', relativeColumnWidth: '0.5fr' },
    { label: 'USD MARK', relativeColumnWidth: '1fr' },
    { label: 'USD AMOUNT', relativeColumnWidth: '1fr' },
    { label: 'DR', relativeColumnWidth: CR_DR_COLUMN_WIDTH },
    { label: 'CR', relativeColumnWidth: CR_DR_COLUMN_WIDTH },
];

const T_ACCOUNTS_HEADERS: ColumnDefinition[] = [
    { label: 'DATE', relativeColumnWidth: 'auto' },
    { label: 'FROM', relativeColumnWidth: FROM_TO_COLUMN_WIDTH },
    { label: 'TO', relativeColumnWidth: FROM_TO_COLUMN_WIDTH },
    { label: 'CHAIN', relativeColumnWidth: '1fr' },
    { label: 'TOKEN AMOUNT' },
    { label: 'SYMBOL', relativeColumnWidth: '0.5fr' },
    { label: 'USD MARK', relativeColumnWidth: '1fr' },
    { label: 'LEDGER', relativeColumnWidth: '1fr' },
    { label: 'DR', relativeColumnWidth: CR_DR_COLUMN_WIDTH },
    { label: 'CR', relativeColumnWidth: CR_DR_COLUMN_WIDTH },
    { label: 'DESCRIPTION', relativeColumnWidth: '1fr' },
    { label: 'CLAIM ID' },
    { label: 'ACTION', relativeColumnWidth: '1fr' },
];

export const toLedgerAccountDisplayValue = (account: LedgerAccount | undefined) => {
    switch (account) {
        case 'ap':
            return 'Crypto AP (USD)';
        case 'ar':
            return 'Crypto AR (USD)';
        case 'cash':
            return 'Crypto Asset Cash (USD)';
        case 'sales':
            return 'Crypto Sales (USD)';
        case 'expense':
            return 'Crypto Expense (USD)';
        case 'factoringexpense':
            return 'Crypto Factoring Expense (USD)';
        case 'factoringincome':
            return 'Crypto Factoring Income (USD)';
        case 'loanpayable':
            return 'Crypto Loan Payable (USD)';
        case 'loanreceivable':
            return 'Crypto Loan Receivable (USD)';
        case 'interestearned':
            return 'Crypto Interest Earned (USD)';
        case 'interestexpense':
            return 'Crypto Interest Expense (USD)';
        case 'duefromfactor':
            return 'Crypto Due From Factoring (USD)';
        case 'duetooriginalcreditor':
            return 'Crypto Due To Original Creditor (USD)';
        case 'currencygain':
            return 'Crypto Currency Gain (USD)';
        case 'currencyloss':
            return 'Crypto Currency Loss (USD)';
        case 'unknownincome':
            return 'Crypto Unknown Income (USD)';
        case 'unearnedincome':
            return 'Crypto Unearned Income (USD)';
        case undefined:
            return '';
        default:
            return 'NOT NAMED YET';
    }
};

const getHeaderForActiveTab = (activeTab: LedgerExportTab): (string | ColumnDefinition)[] => {
    switch (activeTab) {
        case 'Summary':
            return SUMMARY_HEADERS;
        case 'TAccounts':
            return T_ACCOUNTS_HEADERS;
        case 'CashDisbursements':
            return CASH_DISBURSEMENT_HEADERS;
        case 'Transfers':
            return PAYMENT_HEADERS;
        case 'AccountsReceivables':
        case 'AccountsPayables':
        case 'LoanReceivables':
        case 'LoanPayables':
            return CLAIM_HEADERS;
    }
};

const paymentEntryToRow = (
    entry: DomainPaymentLedgerEntry | DomainCashDisbursement,
    isTokenInfoSafe: TokenSafetyContext['isTokenInfoSafe'],
): ListItemProps => {
    const chainId = entry.chainId as ChainId;
    const isTokenSafe = isTokenInfoSafe(entry.token);

    return {
        columnValues: [
            <Text noOfLines={1} whiteSpace={'nowrap'}>
                {toDateDisplay(entry.timestamp)}
            </Text>,
            <ContactNameOrAddress chainId={chainId}>{entry.from}</ContactNameOrAddress>,
            <ContactNameOrAddress chainId={chainId}>{entry.to}</ContactNameOrAddress>,
            ...(entry.__typename == 'Payment' ? [<EntryDescription description={entry.description} />] : []),
            NETWORKS[chainId].label,
            toUSD(entry.displayAmount, true).replace('$', ''),
            <Tooltip label={entry.token.token.symbol}>
                <HStack>
                    {!isTokenSafe && (
                        <WarningIconWithTooltip
                            label="Beware of using airdropped scam tokens."
                            warningOverrides={{ color: 'red', w: '14px', h: '14px' }}
                        />
                    )}
                    <Text noOfLines={1} textOverflow={'ellipsis'} fontWeight={'bold'}>
                        {entry.token.token.symbol}
                    </Text>
                </HStack>
            </Tooltip>,
            entry.usdMark !== null ? `$${entry.usdMark}` : 'N/A',
            entry.usdValue !== null ? `$${entry.usdValue}` : 'N/A',
            ...(entry.__typename == 'Payment'
                ? [
                      ...fillToCount(entry.adjustments.dr, 1).map(text => (
                          <Text whiteSpace={'nowrap'} noOfLines={1}>
                              {toLedgerAccountDisplayValue(text)}
                          </Text>
                      )),
                      ...fillToCount(entry.adjustments.cr, 1).map(text => (
                          <Text whiteSpace={'nowrap'} noOfLines={1}>
                              {toLedgerAccountDisplayValue(text)}
                          </Text>
                      )),
                  ]
                : []),
        ],
        selectId: entry.id,
        isUnsafe: !isTokenSafe,
    };
};

const claimEntryToRow = (entry: DomainClaimLedgerEntry, isTokenInfoSafe: TokenSafetyContext['isTokenInfoSafe']): ListItemProps => {
    const chainId = entry.chainId as ChainId;
    const isTokenSafe = isTokenInfoSafe(entry.token);

    return {
        selectId: entry.id,
        columnValues: [
            <Text noOfLines={1} whiteSpace={'nowrap'}>
                {toDateDisplay(entry.timestamp)}
            </Text>,
            <ContactNameOrAddress chainId={chainId}>{entry.creditor}</ContactNameOrAddress>,
            <ContactNameOrAddress chainId={chainId}>{entry.debtor}</ContactNameOrAddress>,
            entry.claimId,
            <EntryDescription description={entry.description} />,
            entry.eventName.split('Event')[0],
            NETWORKS[chainId].label,
            toUSD(weiToDisplayAmt({ amountWei: BigNumber.from(entry.amount), decimals: entry.token.token.decimals }), true).replace(
                '$',
                '',
            ),
            <Tooltip label={entry.token.token.symbol}>
                <HStack>
                    {!isTokenSafe && (
                        <WarningIconWithTooltip
                            label="Beware of using airdropped scam tokens."
                            warningOverrides={{ color: 'red', w: '14px', h: '14px' }}
                        />
                    )}
                    <Text noOfLines={1} textOverflow={'ellipsis'} fontWeight={'bold'}>
                        {entry.token.token.symbol}
                    </Text>
                </HStack>
            </Tooltip>,
            entry.usdMark !== null ? `$${entry.usdMark}` : 'N/A',
            entry.usdValue !== null ? `$${entry.usdValue}` : 'N/A',
            ...fillToCount(entry.adjustments.dr, 1).map(text => (
                <Text whiteSpace={'nowrap'} noOfLines={1}>
                    {toLedgerAccountDisplayValue(text)}
                </Text>
            )),
            ...fillToCount(entry.adjustments.cr, 1).map(text => (
                <Text whiteSpace={'nowrap'} noOfLines={1}>
                    {toLedgerAccountDisplayValue(text)}
                </Text>
            )),
        ],
        isUnsafe: !isTokenSafe,
    };
};

const entryToTAccountRows = (
    entry: DomainClaimLedgerEntry | DomainPaymentLedgerEntry,
    isTokenInfoSafe: TokenSafetyContext['isTokenInfoSafe'],
    selectedLedgerAccount?: LedgerAccount,
): ListItemProps[] => {
    const chainId = entry.chainId as ChainId;
    const isTokenSafe = isTokenInfoSafe(entry.token);

    return [
        ...entry.adjustments.cr.map(account => ({ account, isCr: true })),
        ...entry.adjustments.dr.map(account => ({ account, isCr: false })),
    ]
        .filter(x => !selectedLedgerAccount || selectedLedgerAccount == x.account)
        .map(({ account, isCr }) => ({
            selectId: entry.id,
            columnValues: [
                <Text noOfLines={1} whiteSpace={'nowrap'}>
                    {toDateDisplay(entry.timestamp)}
                </Text>,
                <ContactNameOrAddress chainId={chainId}>{entry.__typename == 'Claim' ? entry.debtor : entry.from}</ContactNameOrAddress>,
                <ContactNameOrAddress chainId={chainId}>{entry.__typename == 'Claim' ? entry.creditor : entry.to}</ContactNameOrAddress>,
                NETWORKS[chainId].label,
                toUSD(weiToDisplayAmt({ amountWei: BigNumber.from(entry.amount), decimals: entry.token.token.decimals }), true).replace(
                    '$',
                    '',
                ),
                <Tooltip label={entry.token.token.symbol}>
                    <HStack>
                        {!isTokenSafe && (
                            <WarningIconWithTooltip
                                label="Beware of using airdropped scam tokens."
                                warningOverrides={{ color: 'red', w: '14px', h: '14px' }}
                            />
                        )}
                        <Text noOfLines={1} textOverflow={'ellipsis'} fontWeight={'bold'}>
                            {entry.token.token.symbol}
                        </Text>
                    </HStack>
                </Tooltip>,
                entry.usdMark !== null ? `$${entry.usdMark}` : 'N/A',
                toLedgerAccountNumber(account),
                !isCr ? (entry.usdValue !== null ? `$${entry.usdValue}` : 'N/A') : '',
                isCr ? (entry.usdValue !== null ? `$${entry.usdValue}` : 'N/A') : '',
                entry.description,
                entry.__typename == 'Claim' ? entry.claimId : '',
                entry.__typename == 'Claim' ? entry.eventName.split('Event')[0] : '',
            ],
            isUnsafe: !isTokenSafe,
        }));
};

export const getLedgerExportTypeDisplayName = (type: LedgerExportTab): string => {
    switch (type) {
        case 'AccountsReceivables':
            return 'Accounts Receivables';
        case 'AccountsPayables':
            return 'Accounts Payables';
        case 'LoanReceivables':
            return 'Loan Receivables';
        case 'LoanPayables':
            return 'Loan Payables';
        case 'CashDisbursements':
            return 'Cash Disbursements';
        case 'TAccounts':
            return 'T Accounts';
        default:
            return type;
    }
};

const getPathnameForLedgerExport = (tab: LedgerExportTab) => {
    return {
        pathname: '/ledger-wizard',
        search: `?tab=${tab}`,
    };
};

const buildExportFilters = (
    filterValues: ExportFilterValues,
): TableFilters<DomainClaimLedgerEntry | DomainPaymentLedgerEntry | DomainCashDisbursement> => {
    const {
        date: { startDate, endDate },
        selectedWallets,
        selectedNetworks,
        selectedTokenSymbols,
        selectedLedgerAccount,
    } = filterValues;
    const filterByDate =
        !startDate && !endDate
            ? undefined
            : ({ timestamp }: DomainClaimLedgerEntry | DomainPaymentLedgerEntry | DomainCashDisbursement) =>
                  checkDate(timestamp, startDate, endDate);

    const filterByWallets =
        selectedWallets.size !== 0
            ? (item: DomainClaimLedgerEntry | DomainPaymentLedgerEntry | DomainCashDisbursement) =>
                  (item.__typename == 'Claim' ? [item.creditor, item.debtor] : [item.to, item.from])
                      .map(x => x.toLowerCase())
                      .some(x => selectedWallets.has(x))
            : undefined;

    const filterByNetwork =
        selectedNetworks.size == 0
            ? undefined
            : (payment: DomainClaimLedgerEntry | DomainPaymentLedgerEntry | DomainCashDisbursement) =>
                  selectedNetworks.has(payment.chainId as ChainId);

    const filterByToken =
        selectedTokenSymbols.size == 0
            ? undefined
            : (payment: DomainClaimLedgerEntry | DomainPaymentLedgerEntry | DomainCashDisbursement) =>
                  selectedTokenSymbols.has(payment.token.token.symbol.toUpperCase());

    const filterByLedgerAccount = !selectedLedgerAccount
        ? undefined
        : (entry: DomainClaimLedgerEntry | DomainPaymentLedgerEntry | DomainCashDisbursement) =>
              entry.__typename !== 'CashDisbursement' &&
              (entry.adjustments.dr.includes(selectedLedgerAccount) || entry.adjustments.cr.includes(selectedLedgerAccount));

    return [filterByDate, filterByWallets, filterByNetwork, filterByToken, filterByLedgerAccount].filter(
        (x): x is TableFilter<DomainClaimLedgerEntry | DomainPaymentLedgerEntry | DomainCashDisbursement> => x !== undefined,
    );
};

const initialExportFilterValues = (): ExportFilterValues => ({
    date: { startDate: undefined, endDate: undefined },
    selectedWallets: new Set(),
    selectedNetworks: new Set(),
    selectedTokenSymbols: new Set(),
    selectedLedgerAccount: undefined,
});

const exportFilterToPills = (setFilters: React.Dispatch<SetStateAction<ExportFilterValues>>) => (filters: ExportFilterValues) => {
    const contactsContext = useExtendedContacts();

    const dateFilterPills: PillProps[] = [
        ...(!!filters.date.startDate
            ? [
                  {
                      label: `From: ${toDateDisplay(filters.date.startDate)}`,
                      clear: () => setFilters(filters => ({ ...filters, date: { ...filters.date, startDate: undefined } })),
                  },
              ]
            : []),
        ...(!!filters.date.endDate
            ? [
                  {
                      label: `To: ${toDateDisplay(filters.date.endDate)}`,
                      clear: () => setFilters(filters => ({ ...filters, date: { ...filters.date, endDate: undefined } })),
                  },
              ]
            : []),
    ];

    const walletPills: PillProps[] = [...filters.selectedWallets].map(wallet => {
        const contact = isContactsReady(contactsContext) ? contactsContext.getContact(wallet) : 'not-found';
        return {
            label: contact == 'not-found' ? shortAddress(wallet, 3) : contact.name,
            clear: () =>
                setFilters(filters => {
                    const selectedWallets = new Set([...filters.selectedWallets]);
                    selectedWallets.delete(wallet);
                    return { ...filters, selectedWallets };
                }),
        };
    });

    const networkPills: PillProps[] = [...filters.selectedNetworks].map(network => ({
        label: NETWORKS[network].label,
        clear: () =>
            setFilters(filters => {
                const selectedNetworks = new Set([...filters.selectedNetworks]);
                selectedNetworks.delete(network);
                return { ...filters, selectedNetworks };
            }),
    }));

    const tokenPills: PillProps[] = [...filters.selectedTokenSymbols].map(symbol => ({
        label: symbol,
        clear: () =>
            setFilters(filters => {
                const selectedTokenSymbols = new Set([...filters.selectedTokenSymbols]);
                selectedTokenSymbols.delete(symbol);
                return { ...filters, selectedTokenSymbols };
            }),
    }));
    return [...dateFilterPills, ...walletPills, ...networkPills, ...tokenPills];
};

const SUMMARY_HEADERS: ListViewCardProps['headers'] = [
    { label: 'account #', relativeColumnWidth: 'auto' },
    { label: 'account name', relativeColumnWidth: '1fr' },
    { label: 'debit', relativeColumnWidth: 'auto' },
    { label: 'credit', relativeColumnWidth: 'auto' },
]; // I'll add start and end afters

export const LedgerExportSuccessCard = ({ wizardState }: { wizardState: SuccessState }) => {
    const ledgerExportTabOptions: TabOptions<LedgerExportTab>[] = ledgerExportTabs.map(tab => ({
        label: getLedgerExportTypeDisplayName(tab),
        value: tab,
    }));

    const navigate = useNavigate();
    const setTab = (tab: LedgerExportTab) => {
        setActiveTab(tab);
        navigate(getPathnameForLedgerExport(tab), { replace: true });
    };

    const [deletedEntries, setDeletedEntries] = React.useState<Set<String>>(new Set());
    const [activeTab, setActiveTab] = useState<LedgerExportTab>(ledgerExportTabs[0]);
    const { getTokenByChainIdAndAddress } = useTokenRepo();
    const { isTokenInfoSafe } = useTokenSafety();
    const { getHistoricalTokenPrice } = useHistoricalPrices();
    const isFactoringToken = useIsFactoringToken();

    const [filters, setFilters] = React.useState<ExportFilterValues>(initialExportFilterValues);

    const { hybridInvoiceDataByDescription } = useGlobalUserData('include-originating-claims');

    // Workaround, it seems the date picker bricks when it starts with a default value
    React.useEffect(() => {
        const now = new Date();
        const year = now.getFullYear();
        const yearToDisplay = now.getMonth() >= 5 ? year : year - 1;
        setFilters(prev => ({ ...prev, date: { startDate: new Date(yearToDisplay, 0, 1), endDate: new Date(yearToDisplay, 11, 31) } }));
    }, []);

    const aggregatedFilter = React.useMemo(() => buildExportFilters(filters), [filters]);

    const {
        payables: _cashOutTransfers,
        receivables: _cashInTransfers,
        transfers: _cashTransfers,
    } = React.useMemo(() => {
        return wizardState.ledgerExportResponse.cashTransfers
            .flatMap(x => {
                const token = getTokenByChainIdAndAddress(x.chainId as ChainId)(x.token.address);
                if (!token) return [];

                const timestamp = intToDate(x.timestamp);
                const displayAmount = weiToDisplayAmt({ amountWei: BigNumber.from(x.amountWei), decimals: token.token.decimals });

                const isTcs = isTcsToken(x.chainId, x.token.address);
                const isFactoringToken_ = isFactoringToken(x.token.address, x.chainId);

                const usdMark = isTcs
                    ? '0.1'
                    : isFactoringToken_
                    ? (() => {
                          const historicalPrice = getHistoricalTokenPrice({
                              tokenAddress: token.token.address,
                              chainId: x.chainId as ChainId,
                              timestamp,
                          });
                          return typeof historicalPrice === 'number' ? historicalPrice.toString() : x.usdMark;
                      })()
                    : x.usdMark;

                const usdValue = isTcs
                    ? (displayAmount * 0.1).toString()
                    : isFactoringToken_
                    ? (() => {
                          const historicalPrice = getHistoricalTokenPrice({
                              tokenAddress: token.token.address,
                              chainId: x.chainId as ChainId,
                              timestamp,
                          });
                          return typeof historicalPrice === 'number' ? (historicalPrice * displayAmount).toString() : x.usdValue;
                      })()
                    : x.usdValue;

                const entry: Omit<DomainCashDisbursement, 'id'> = {
                    ...x,
                    __typename: 'CashDisbursement',
                    token: token,
                    displayAmount,
                    timestamp,
                    usdMark,
                    usdValue,
                    amount: x.amountWei,
                };

                const id = quickHash(entry);

                return [{ ...entry, id }];
            })
            .reduce<{
                payables: DomainCashDisbursement[];
                receivables: DomainCashDisbursement[];
                transfers: DomainCashDisbursement[];
            }>(
                (acc, item) =>
                    item.direction == 'Payable'
                        ? { ...acc, payables: [...acc.payables, item] }
                        : item.direction == 'Receivable'
                        ? { ...acc, receivables: [...acc.receivables, item] }
                        : item.direction == 'Transfer'
                        ? { ...acc, transfers: [...acc.transfers, item] }
                        : acc,
                { payables: [], receivables: [], transfers: [] },
            );
    }, []);

    const {
        payables: paymentOutEntries,
        receivables: paymentInEntries,
        transfers: paymentTransfers,
    } = React.useMemo(() => {
        return wizardState.ledgerExportResponse.paymentEntries
            .flatMap(x => {
                const token = getTokenByChainIdAndAddress(x.chainId as ChainId)(x.token.address);
                if (!token) return [];

                const timestamp = intToDate(x.timestamp);
                const displayAmount = weiToDisplayAmt({ amountWei: BigNumber.from(x.amountWei), decimals: token.token.decimals });
                const isTcs = isTcsToken(x.chainId, x.token.address);
                const isFactoringToken_ = isFactoringToken(x.token.address, x.chainId);

                const usdMark = isTcs
                    ? '0.1'
                    : isFactoringToken_
                    ? (() => {
                          const historicalPrice = getHistoricalTokenPrice({
                              tokenAddress: token.token.address,
                              chainId: x.chainId as ChainId,
                              timestamp,
                          });
                          return typeof historicalPrice === 'number' ? historicalPrice.toString() : x.usdMark;
                      })()
                    : x.usdMark;

                const usdValue = isTcs
                    ? (displayAmount * 0.1).toString()
                    : isFactoringToken_
                    ? (() => {
                          const historicalPrice = getHistoricalTokenPrice({
                              tokenAddress: token.token.address,
                              chainId: x.chainId as ChainId,
                              timestamp,
                          });
                          return typeof historicalPrice === 'number' ? (historicalPrice * displayAmount).toString() : x.usdValue;
                      })()
                    : x.usdValue;

                const entry: Omit<DomainPaymentLedgerEntry, 'id'> = {
                    ...x,
                    __typename: 'Payment',
                    token: token,
                    displayAmount,
                    timestamp,
                    usdMark,
                    usdValue,
                    amount: x.amountWei,
                };

                const id = quickHash(entry);
                return [{ ...entry, id }];
            })
            .reduce<{
                payables: DomainPaymentLedgerEntry[];
                receivables: DomainPaymentLedgerEntry[];
                transfers: DomainPaymentLedgerEntry[];
            }>(
                (acc, item) =>
                    item.direction == 'Payable'
                        ? { ...acc, payables: [...acc.payables, item] }
                        : item.direction == 'Receivable'
                        ? { ...acc, receivables: [...acc.receivables, item] }
                        : item.direction == 'Transfer'
                        ? { ...acc, transfers: [...acc.transfers, item] }
                        : acc,
                { payables: [], receivables: [], transfers: [] },
            );
    }, []);

    const { ap, ar, lp, lr } = React.useMemo(() => {
        return wizardState.ledgerExportResponse.claimEntries
            .flatMap(x => {
                const token = getTokenByChainIdAndAddress(x.chainId as ChainId)(x.token.address);
                if (!token) return [];
                const timestamp = intToDate(x.eventTimestamp);
                const isTcs = isTcsToken(x.chainId, x.token.address);
                const isFactoringToken_ = isFactoringToken(x.token.address, x.chainId);

                const displayAmount = weiToDisplayAmt({ amountWei: BigNumber.from(x.amount), decimals: token.token.decimals });
                const hybridData = hybridInvoiceDataByDescription[x.description];

                let usdMark: string | null;
                let usdValue: string | null;

                if (isTcs) {
                    usdMark = '0.1';
                    usdValue = (displayAmount * 0.1).toString();
                } else if (isFactoringToken_) {
                    const historicalPrice = getHistoricalTokenPrice({
                        tokenAddress: token.token.address,
                        chainId: x.chainId as ChainId,
                        timestamp,
                    });

                    if (typeof historicalPrice === 'number') {
                        usdMark = historicalPrice.toString();
                        usdValue = (historicalPrice * displayAmount).toString();
                    } else {
                        usdMark = null;
                        usdValue = null;
                    }
                } else {
                    usdMark = x.usdMark;
                    usdValue = x.usdValue;
                }

                const entry: Omit<DomainClaimLedgerEntry, 'id'> = {
                    ...x,
                    __typename: 'Claim',
                    timestamp,
                    displayAmount,
                    token: token,
                    description: hybridData ? hybridData.description : x.description,
                    usdMark,
                    usdValue,
                };

                const id = quickHash(entry);
                return [{ ...entry, id }];
            })
            .reduce<{
                ap: DomainClaimLedgerEntry[];
                ar: DomainClaimLedgerEntry[];
                lp: DomainClaimLedgerEntry[];
                lr: DomainClaimLedgerEntry[];
            }>(
                (acc, item) =>
                    item.direction == 'Payable' || item.direction == 'OriginalCreditorOfFactoredClaim'
                        ? item.eventName.includes('Loan')
                            ? { ...acc, lp: [...acc.lp, item] }
                            : { ...acc, ap: [...acc.ap, item] }
                        : item.direction == 'Receivable'
                        ? item.eventName.includes('Loan')
                            ? { ...acc, lr: [...acc.lr, item] }
                            : { ...acc, ar: [...acc.ar, item] }
                        : acc,
                { ap: [], ar: [], lp: [], lr: [] },
            );
    }, []);

    const allLedgerEntries = React.useMemo(
        () =>
            [...ap, ...ar, ...lp, ...lr, ...paymentInEntries, ...paymentOutEntries, ...paymentTransfers].filter(
                x => !deletedEntries.has(x.id),
            ),
        [ap, ar, paymentInEntries, paymentOutEntries, deletedEntries, paymentTransfers, lr, lp],
    );

    const entries: (DomainClaimLedgerEntry | DomainPaymentLedgerEntry | DomainCashDisbursement)[] = React.useMemo(() => {
        let _entries: (DomainClaimLedgerEntry | DomainPaymentLedgerEntry | DomainCashDisbursement)[];
        switch (activeTab) {
            case 'AccountsPayables':
                _entries = ap;
                break;
            case 'AccountsReceivables':
                _entries = ar;
                break;
            case 'LoanPayables':
                _entries = lp;
                break;
            case 'LoanReceivables':
                _entries = lr;
                break;
            case 'CashDisbursements':
                _entries = [..._cashOutTransfers, ..._cashInTransfers];
                break;
            case 'Transfers':
                _entries = paymentTransfers;
                break;
            case 'Summary':
            case 'TAccounts':
                _entries = allLedgerEntries;
                break;
            default:
                _entries = [];
                break;
        }
        return _entries.filter(x => !deletedEntries.has(x.id));
    }, [ap, ar, lp, lr, _cashOutTransfers, _cashInTransfers, activeTab, deletedEntries]);

    const selectOptions = useSelection(entries.map(x => x.id));
    const selected = selectOptions.selected;

    const summary = React.useMemo(
        () =>
            aggregatedFilter
                .reduce((entries, filterFunc) => entries.filter(filterFunc), allLedgerEntries)
                .filter(x => !isNaN(Number(x.usdValue)))
                .reduce<Record<LedgerAccount, { dr: number; cr: number }>>((acc, item) => {
                    const drChanges: [LedgerAccount, number][] = item.adjustments.dr.map(x => [x, +item.usdValue!]);
                    const crChanges: [LedgerAccount, number][] = item.adjustments.cr.map(x => [x, +item.usdValue!]);
                    const afterDrChanges = drChanges.reduce<Record<LedgerAccount, { dr: number; cr: number }>>(
                        (acc, [account, usdValue]) => ({ ...acc, [account]: { ...acc[account], dr: acc[account].dr + usdValue } }),
                        acc,
                    );
                    return crChanges.reduce<Record<LedgerAccount, { dr: number; cr: number }>>(
                        (acc, [account, usdValue]) => ({ ...acc, [account]: { ...acc[account], cr: acc[account].cr + usdValue } }),
                        afterDrChanges,
                    );
                }, Object.fromEntries(LEDGER_ACCOUNTS.map(x => [x, { dr: 0, cr: 0 }])) as Record<LedgerAccount, { dr: number; cr: number }>),
        [allLedgerEntries, aggregatedFilter],
    );

    const summaryRows = React.useMemo(() => {
        const rows = LEDGER_ACCOUNTS.filter(x => summary[x].dr !== 0 || summary[x].cr !== 0).map(x => [
            toLedgerAccountNumber(x),
            toLedgerAccountDisplayValue(x),
            toUSD(summary[x].dr),
            toUSD(summary[x].cr),
        ]);
        return [
            ...rows,
            [
                '',
                <Text fontWeight={700}>Totals</Text>,
                <Text fontWeight={700}>
                    {toUSD(
                        Object.values(summary)
                            .map(x => x.dr)
                            .reduce((acc, item) => acc + item, 0),
                    )}
                </Text>,
                <Text fontWeight={700}>
                    {toUSD(
                        Object.values(summary)
                            .map(x => x.cr)
                            .reduce((acc, item) => acc + item, 0),
                    )}
                </Text>,
            ],
        ].map(x => ({ columnValues: x }));
    }, [summary]);

    const [selectableNetworks, selectableTokenSymbols, selectableWallets] = React.useMemo(() => {
        return [
            new Set(wizardState.walletChainSelection.chainIds),
            new Set(entries.map(x => x.token.token.symbol.toUpperCase())),
            new Set(entries.flatMap(x => (x.__typename == 'Claim' ? [x.creditor, x.debtor] : [x.from, x.to])).map(x => x.toLowerCase())),
        ];
    }, [entries]);

    const filteredEntries: (DomainClaimLedgerEntry | DomainPaymentLedgerEntry | DomainCashDisbursement)[] = React.useMemo(
        () =>
            aggregatedFilter
                .reduce((entries, filterFunc) => entries.filter(filterFunc), entries)
                .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime()),
        [entries, aggregatedFilter],
    );

    const expandedFilteredRows = React.useMemo(
        () =>
            filteredEntries.flatMap(x =>
                activeTab == 'TAccounts' && x.__typename !== 'CashDisbursement'
                    ? entryToTAccountRows(x, isTokenInfoSafe, filters.selectedLedgerAccount)
                    : x.__typename == 'Payment' || x.__typename == 'CashDisbursement'
                    ? [paymentEntryToRow(x, isTokenInfoSafe)]
                    : [claimEntryToRow(x, isTokenInfoSafe)],
            ),
        [filteredEntries, activeTab, filters.selectedLedgerAccount ?? ''],
    );

    const pageSelectorProps = usePagination(expandedFilteredRows);
    const visibleRows = pageSelectorProps.shownItems;

    const summaryPageSelectorProps = usePagination(summaryRows, 10);
    const summaryVisibleRows = summaryPageSelectorProps.shownItems;

    return (
        <Flex p="12" direction="column" flex="1">
            <SummaryPanel>
                <HStack cursor="pointer" onClick={() => navigate('/reporting')} mb="6" color="gray.600">
                    <ArrowLeft size={18} weight="bold" />
                    <Text fontWeight="semibold" fontSize="md">
                        Back to Reporting Explorer
                    </Text>
                </HStack>
                <Flex>
                    <Heading textStyle="bullaViewHeader">Ledger Export</Heading>
                    <Spacer />
                    <LedgerExportModal
                        allLedgerEntries={allLedgerEntries}
                        cashDisbursements={[..._cashInTransfers, ..._cashOutTransfers, ..._cashTransfers]}
                        triggerElement={onClick => (
                            <SecondaryButton onClick={onClick} h="10" px="6">
                                Export
                            </SecondaryButton>
                        )}
                    />
                </Flex>
            </SummaryPanel>
            <MaxWidthWrapper display={'flex'} flexDirection="column" flex="1">
                <Stack pos="relative" spacing="0" overflow={'auto'}>
                    <HStack mt="4" alignItems="flex-end">
                        <TabSwitcher tab={activeTab} setTab={setTab} options={ledgerExportTabOptions} activeColor="brand.bulla_blue" />
                    </HStack>
                </Stack>
                {activeTab == 'Summary' ? (
                    <Flex flexDirection={'column'} my="8">
                        <SummaryFilter
                            filters={filters}
                            setFilters={dataOrFunction =>
                                setFilters(prev => ({
                                    ...prev,
                                    ...(typeof dataOrFunction == 'function' ? dataOrFunction(prev as SummaryFilterValues) : dataOrFunction),
                                }))
                            }
                        />
                        <ClearFilterPillsStack
                            clearAll={() => setFilters(initialExportFilterValues())}
                            filters={filters}
                            filtersToPills={exportFilterToPills(setFilters)}
                        />
                        <Box my="8">
                            <ListViewCard
                                headers={SUMMARY_HEADERS}
                                displayedListItems={summaryVisibleRows}
                                emptyMessage="No ledger export data available"
                                alternateRowColor="#F4F6F9"
                                showOverflowX
                            />
                        </Box>
                        <PageSelector {...summaryPageSelectorProps} justifySelf="center" pt="6" />
                    </Flex>
                ) : (
                    <Flex direction={'column'} flex="1">
                        <Flex mt="8">
                            <ExportFilter
                                filters={filters}
                                setFilters={setFilters}
                                selectableNetworks={selectableNetworks}
                                selectableTokenSymbols={selectableTokenSymbols}
                                selectableWallets={selectableWallets}
                            />
                            <Spacer />
                            <MenuDropdownButton
                                colorScheme={'accent'}
                                h="10"
                                py="0"
                                px="4"
                                text={`Actions${selected.length == 0 ? '' : ` (${selected.length})`}`}
                                icon={<ChevronDownIcon w="6" h="6" />}
                                actions={[
                                    <MenuItem
                                        onClick={() =>
                                            setDeletedEntries(prev => {
                                                const newSet = new Set([...prev]);
                                                selected.forEach(x => newSet.add(x));
                                                selectOptions.resetSelection();
                                                return newSet;
                                            })
                                        }
                                    >
                                        Remove from report
                                    </MenuItem>,
                                ]}
                                isDisabled={selected.length == 0}
                            />
                        </Flex>

                        <ClearFilterPillsStack
                            clearAll={() => setFilters(initialExportFilterValues())}
                            filters={filters}
                            filtersToPills={exportFilterToPills(setFilters)}
                        />
                        <Box my="8">
                            <ListViewCard
                                headers={getHeaderForActiveTab(activeTab)}
                                displayedListItems={visibleRows}
                                emptyMessage="No ledger export data available"
                                alternateRowColor="#F4F6F9"
                                showOverflowX
                                selectOptions={selectOptions}
                            />
                            <PageSelector {...pageSelectorProps} justifySelf="center" pt="6" />
                        </Box>
                    </Flex>
                )}
            </MaxWidthWrapper>
        </Flex>
    );
};

import { Center, Flex, HStack, Heading, IconButton, Spinner, Stack, useToast } from '@chakra-ui/react';
import { PencilSimple, QrCode, Trash } from 'phosphor-react';
import React, { useRef } from 'react';
import { CopyableTextLabel } from '../../../components/base/address-label';
import { PageSelector } from '../../../components/display/claim-table';
import { LinksFilter } from '../../../components/display/views/filters/claim-filter';
import { OrangeButton } from '../../../components/inputs/buttons';
import {
    AMOUNT_COLUMN_WIDTH,
    DATE_COLUMN_WIDTH,
    FROM_TO_COLUMN_WIDTH,
    LINK_COLUMN_WIDTH,
    ListItemProps,
    ListViewCard,
    ListViewCardProps,
    VIEW_COLUMN_WIDTH,
    shadowProps,
} from '../../../components/layout/cards';
import { MaxWidthWrapper, PageLayoutProvider } from '../../../components/layout/page-layout';
import { AreYouSureModal } from '../../../components/modals/are-you-sure-modal';
import { QRViewerModalNew } from '../../../components/modals/create-claim-modal/qr-viewer-modal';
import { ChainId } from '../../../data-lib/networks';
import { LinkInformation, useLinks } from '../../../hooks/useLinksApi';
import { usePagination } from '../../../hooks/usePagination';
import { TokenInfoByChainIdAndAddress, useTokenRepo } from '../../../hooks/useTokenRepo';
import { MyLinkModal } from './my-link-modal';

const LINKS_HEADERS: ListViewCardProps['headers'] = [
    { label: 'link name', relativeColumnWidth: FROM_TO_COLUMN_WIDTH },
    { label: 'date created', relativeColumnWidth: DATE_COLUMN_WIDTH },
    { label: 'amount collected', relativeColumnWidth: AMOUNT_COLUMN_WIDTH },
    { label: 'link', relativeColumnWidth: LINK_COLUMN_WIDTH },
    { label: '', relativeColumnWidth: VIEW_COLUMN_WIDTH },
];

const parseLinkForTable = (linkInfo: LinkInformation, getTokenByChainIdAndAddress: TokenInfoByChainIdAndAddress) => {
    const urlObj = new URL(linkInfo.link);
    const params = new URLSearchParams(
        urlObj.hash.includes('link-pay') ? urlObj.hash.replace('#/link-pay/', '').replace('#/link-pay', '') : urlObj.hash.substring(2),
    );

    const tokenAddress = params.get('token') as string;
    const chainId = Number(params.get('network')) as ChainId;

    const tokenSymbol = getTokenByChainIdAndAddress(chainId)(tokenAddress)?.token.symbol;

    const date = new Date(Number(params.get('date')));

    const description = params.get('description');

    return {
        name: params.get('linkName') || 'Unnamed Link',
        date: date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }),
        amount: params.get('amount') ? `${params.get('amount')} ${tokenSymbol}` : 'Not specified',
        url: linkInfo.link,
        description,
    };
};

export const MyLinksPageNew = () => {
    const [isLinkModalOpen, setIsLinkModalOpen] = React.useState(false);
    const listViewCardRef = useRef<HTMLDivElement>(null);
    const { links, isLoading, refetch, deleteLink } = useLinks();
    const { getTokenByChainIdAndAddress } = useTokenRepo();
    const [isDeletingId, setIsDeletingId] = React.useState<string | null>(null);
    const [selectedLink, setSelectedLink] = React.useState<LinkInformation>();
    const toast = useToast();
    const onLinkModalClose = () => {
        setIsLinkModalOpen(false);
        setSelectedLink(undefined);
    };

    const handleDeleteLink = async (linkId: string) => {
        try {
            setIsDeletingId(linkId);
            await deleteLink(linkId);
            toast({
                description: 'Link deleted',
                duration: 5000,
                status: 'success',
                position: 'top',
                isClosable: true,
            });
        } catch (error) {
            toast({
                description: 'Failed to delete link',
                duration: 5000,
                status: 'error',
                position: 'top',
                isClosable: true,
            });
        } finally {
            setIsDeletingId(null);
        }
    };

    const displayedListItems: ListItemProps[] = [...links]
        .sort((a, b) => {
            const aParams = new URLSearchParams(
                new URL(a.link).hash.includes('link-pay')
                    ? new URL(a.link).hash.replace('#/link-pay/', '').replace('#/link-pay', '')
                    : new URL(a.link).hash.substring(2),
            );
            const bParams = new URLSearchParams(
                new URL(b.link).hash.includes('link-pay')
                    ? new URL(b.link).hash.replace('#/link-pay/', '').replace('#/link-pay', '')
                    : new URL(b.link).hash.substring(2),
            );
            const aDate = Number(aParams.get('date'));
            const bDate = Number(bParams.get('date'));
            return bDate - aDate;
        })
        .map(link => {
            const parsedLink = parseLinkForTable(link, getTokenByChainIdAndAddress);
            return {
                columnValues: [
                    parsedLink.name.toString(),
                    parsedLink.date.toString(),
                    parsedLink.amount.toString(),
                    <HStack spacing="0.5" overflow="hidden">
                        <CopyableTextLabel fontWeight={600} color="#14282D" displayValue={parsedLink.url.toString()}>
                            {parsedLink.url.toString()}
                        </CopyableTextLabel>
                        <QRViewerModalNew
                            triggerElement={onOpen => (
                                <IconButton
                                    variant="ghost"
                                    borderRadius={'full'}
                                    aria-label="QR code"
                                    icon={<QrCode size={16} color="#14282D" />}
                                    onClick={onOpen}
                                />
                            )}
                            url={parsedLink.url.toString()}
                            sharedDescription={parsedLink.description ?? undefined}
                        />
                    </HStack>,
                    <HStack justifyContent="flex-end" width="100%" height="100%" display="flex" alignItems="center">
                        <AreYouSureModal
                            triggerElement={onOpen => (
                                <IconButton
                                    variant="ghost"
                                    borderRadius={'full'}
                                    aria-label="Delete link"
                                    icon={isDeletingId === link.linkId ? <Spinner size="sm" /> : <Trash size={16} color="#14282D" />}
                                    onClick={onOpen}
                                    isLoading={isDeletingId === link.linkId}
                                />
                            )}
                            title={'Delete link'}
                            message={'Are you sure? This action cannot be undone.'}
                            onClick={() => handleDeleteLink(link.linkId)}
                            buttonText={`Delete`}
                        />

                        <IconButton
                            variant="ghost"
                            borderRadius={'full'}
                            aria-label="Edit link"
                            icon={<PencilSimple size={16} color="#14282D" />}
                            onClick={() => {
                                setSelectedLink(link);
                                setIsLinkModalOpen(true);
                            }}
                        />
                    </HStack>,
                ],
                linkId: link.linkId,
            };
        });

    const pageSelectorProps = usePagination(displayedListItems);
    const visibleItems = pageSelectorProps.shownItems;

    return (
        <PageLayoutProvider>
            <MaxWidthWrapper flex="1">
                <Heading color="heading" flex={1} my={8}>
                    My Links
                </Heading>
                <Stack spacing="8" mb="8">
                    <HStack justifyContent={'space-between'} alignItems={'center'}>
                        <LinksFilter filters={{ search: undefined }} setFilters={() => {}} searchPlaceholder="Links" />
                        <OrangeButton onClick={() => setIsLinkModalOpen(true)}>Create new link</OrangeButton>
                    </HStack>

                    <Flex {...shadowProps} direction={'column'} flex="1" overflowX="auto" overflow={'visible'} ref={listViewCardRef}>
                        {isLoading ? (
                            <Center h="500px" w="100%">
                                <Spinner />
                            </Center>
                        ) : (
                            <>
                                <ListViewCard
                                    headers={LINKS_HEADERS}
                                    displayedListItems={visibleItems}
                                    bordered={false}
                                    totalItemCount={displayedListItems.length}
                                    emptyMessage="No links to display"
                                />
                                <PageSelector {...pageSelectorProps} justifySelf="center" pt="6" />
                            </>
                        )}
                    </Flex>

                    <MyLinkModal isOpen={isLinkModalOpen} onClose={onLinkModalClose} onSuccess={refetch} linkToEdit={selectedLink} />
                </Stack>
            </MaxWidthWrapper>
        </PageLayoutProvider>
    );
};

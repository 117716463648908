import { AxiosError } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { CreateClaimFieldsNew } from '../components/display/views/new-invoice';
import { ClaimType } from '../data-lib/data-model';
import { ChainId, NETWORKS } from '../data-lib/networks';
import { CompanyDetails } from '../pages/settings/company-details/common';
import { isUserReady, useAuth } from '../state/auth-state';
import { useGnosisSafe } from '../state/gnosis-state';
import { endpoints } from '../tools/common';
import { PropsWithChildren } from '../tools/props-with-children';
import { LineItemDto, TaxDto } from './useCustodianApi';
import { Contact } from './useExtendedContacts';
import { useBullaBackendHttpClient } from './useHttpClient';
import { useActingWalletAddress } from './useWalletAddress';
import { useWeb3 } from './useWeb3';

export type AttachmentLinkGenerator = (
    type: ClaimType,
    recipient: string,
    amount: string,
    tokenSymbol: string,
    description: string,
) => string;

export type AttachmentLinkGeneratorNew = (
    formikInputs: CreateClaimFieldsNew,
    chainId: ChainId,
    contact?: Contact,
) => Promise<string | null>;

interface InvoicePreviewPayload {
    payeeAddress: string;
    recipientAddress: string;
    recipientContactName?: string;
    recipientContactEmail?: string;
    amount: string;
    symbol: string;
    claimDate: string;
    dueDate: string;
    lineItems: LineItemDto[];
    description?: string;
    tax?: TaxDto;
    chain: string;
}

/**
 * Private hook for making API calls related to company details
 * Contains all the low-level API functions without state management
 * Only used internally by useCompanyDetailsRepo
 */
const useCompanyDetailsApi = () => {
    const { connectedNetwork, userAddress } = useWeb3();
    const { connectedSafeAddress } = useGnosisSafe();
    const actingWallet = useActingWalletAddress();
    const httpClient = useBullaBackendHttpClient();

    const gnosisSafeQueryArgs = !!connectedSafeAddress ? `?account_type=gnosis&chain_id=${connectedNetwork}` : '';
    const path = !!connectedSafeAddress ? `${connectedSafeAddress}?account_type=gnosis&chain_id=${connectedNetwork}` : userAddress;
    const pathWithAdditionalQueryParams = !!connectedSafeAddress ? `${path}&` : `${path}?`;

    const getCompanyDetails = useCallback(
        async () =>
            await httpClient
                .get<CompanyDetails>(`${endpoints.settingsApi}/company-details/${path}`, {
                    withCredentials: true,
                })
                .then(({ data }) => data)
                .catch((error: AxiosError) => {
                    if (error.response?.status === 404) {
                        return null;
                    }
                    throw error;
                }),
        [path, httpClient],
    );

    const postCompanyDetails = useCallback(
        async (details: CompanyDetails) =>
            await httpClient.post(`${endpoints.settingsApi}/company-details/${path}`, details, {
                withCredentials: true,
            }),
        [path, httpClient],
    );

    const getAttachmentGenerationLink = useCallback<AttachmentLinkGenerator>(
        (type: ClaimType, recipient: string, amount: string, tokenSymbol: string, description: string) =>
            `${endpoints.settingsApi}/invoice/${pathWithAdditionalQueryParams}kind=${type.toLowerCase()}&recipientAddress=${
                type == 'Invoice' ? actingWallet : recipient
            }&amount=${amount}&symbol=${tokenSymbol}&date=${Math.floor(new Date().getTime() / 1000)}&description=${description}`,
        [pathWithAdditionalQueryParams, actingWallet],
    );

    const getAttachmentGenerationLinkNew = useCallback<AttachmentLinkGeneratorNew>(
        async (formikInputs: CreateClaimFieldsNew, chainId: ChainId, contact?: Contact) => {
            const contactName = contact?.name;
            const contactEmail = contact?.emailAddress || formikInputs.emailAddress;

            const invoiceData: InvoicePreviewPayload = {
                payeeAddress: actingWallet,
                recipientAddress: formikInputs.recipient,
                recipientContactName: contactName,
                recipientContactEmail: contactEmail === '' ? undefined : contactEmail,
                amount: formikInputs.claimAmount,
                symbol: formikInputs.token.symbol,
                claimDate: Math.floor(Date.now() / 1000).toString(),
                dueDate: Math.floor(new Date(formikInputs.dueBy).getTime() / 1000).toString(),
                lineItems: formikInputs.lineItems.map(item => ({
                    description: item.description,
                    referenceNumber: item.referenceNumber || undefined,
                    quantity: Number(item.quantity),
                    unitPrice: Number(item.unitPrice),
                })),
                description: formikInputs.description,
                tax: formikInputs.tax
                    ? {
                          rate: Number(formikInputs.tax.taxRate),
                          type: formikInputs.tax.taxType,
                      }
                    : undefined,
                chain: NETWORKS[chainId].label,
            };

            const payload = { invoiceData: JSON.stringify(invoiceData) };

            try {
                const { data } = await httpClient.post(
                    `${endpoints.settingsApi}/invoice-new/${actingWallet}${gnosisSafeQueryArgs}`,
                    payload,
                    {
                        withCredentials: true,
                        headers: { 'content-type': 'application/json' },
                    },
                );

                return `data:text/html;charset=utf-8,${encodeURIComponent(data)}`;
            } catch (error) {
                console.warn(error);
                return null;
            }
        },
        [actingWallet, gnosisSafeQueryArgs, httpClient],
    );

    return {
        getCompanyDetails,
        postCompanyDetails,
        getAttachmentGenerationLink,
        getAttachmentGenerationLinkNew,
    };
};

export type CompanyDetailsState = 'init' | 'fetching' | null | CompanyDetails | { errorMessage: string };

export interface CompanyDetailsContextType {
    companyDetails: CompanyDetailsState;
    isLoading: boolean;
    getCompanyDetails: () => Promise<CompanyDetails | null>;
    postCompanyDetails: (companyDetails: CompanyDetails) => Promise<void>;
    getAttachmentGenerationLink: AttachmentLinkGenerator;
    getAttachmentGenerationLinkNew: AttachmentLinkGeneratorNew;
    refreshCompanyDetails: () => void;
    hasCompanyDetails: boolean;
}

export const isCompanyDetailsReady = (state: CompanyDetailsState): state is CompanyDetails =>
    !!state && state !== 'init' && state !== 'fetching' && !('errorMessage' in state);

export const isCompanyDetailsLoading = (state: CompanyDetailsState): boolean => state === 'init' || state === 'fetching';

export const hasCompanyDetailsError = (state: CompanyDetailsState): state is { errorMessage: string } =>
    !!state && typeof state === 'object' && 'errorMessage' in state;

const CompanyDetailsContext = React.createContext<CompanyDetailsContextType | undefined>(undefined);

export const CompanyDetailsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const actingWallet = useActingWalletAddress();
    const { user } = useAuth();
    const api = useCompanyDetailsApi();
    const [companyDetails, setCompanyDetails] = useState<CompanyDetailsState>('init');
    const [fetchTrigger, setFetchTrigger] = useState<boolean>(false);

    // Fetch company details when wallet address changes or fetch is triggered
    useEffect(() => {
        if (!isUserReady(user)) {
            setCompanyDetails('init');
            return;
        }

        setCompanyDetails('fetching');
        getCompanyDetails()
            .then(setCompanyDetails)
            .catch(e => setCompanyDetails({ errorMessage: e.message }));
    }, [actingWallet, fetchTrigger]);

    const getCompanyDetails = async () => await api.getCompanyDetails();

    const postCompanyDetails = async (details: CompanyDetails) => {
        await api.postCompanyDetails(details);
        setCompanyDetails(details);
    };

    const refreshCompanyDetails = () => {
        setFetchTrigger(prev => !prev);
    };

    const context: CompanyDetailsContextType = {
        companyDetails,
        isLoading: isCompanyDetailsLoading(companyDetails),
        getCompanyDetails,
        postCompanyDetails,
        getAttachmentGenerationLink: api.getAttachmentGenerationLink,
        getAttachmentGenerationLinkNew: api.getAttachmentGenerationLinkNew,
        refreshCompanyDetails,
        hasCompanyDetails: isCompanyDetailsReady(companyDetails),
    };

    return <CompanyDetailsContext.Provider value={context}>{children}</CompanyDetailsContext.Provider>;
};

/**
 * Hook that provides centralized access to company details
 *
 * This replaces the previous direct use of useCompanyDetailsRepo
 * and ensures company details are fetched only once and shared
 * across all components that need them.
 */
export const useCompanyDetailsRepo = (): CompanyDetailsContextType => {
    const context = React.useContext(CompanyDetailsContext);
    if (!context) {
        throw new Error('useCompanyDetailsRepo must be used within a CompanyDetailsProvider');
    }
    return context;
};

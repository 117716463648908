import { BigNumber } from 'ethers';
import React, { useEffect, useMemo } from 'react';
import { Direction } from '../components/display/claim-table';
import {
    AccountTagInfo,
    BullaItemInfo,
    BullaSwapInfoWithUSDMark,
    ClaimInfo,
    FrendLendOffer,
    InstantPaymentInfo,
} from '../data-lib/data-model';
import { NonFungibleTransferType, TransferDTO } from '../data-lib/dto/external-transactions-dto';
import { addressEquality } from '../data-lib/ethereum';
import { addPayments, addUSDMark, getPayables, getReceivables } from '../data-lib/helpers';
import { ChainId } from '../data-lib/networks';
import { AppContext, UserDataReadyState, UserDataState } from '../state/app-state';
import { Replace } from '../tools/types';
import { emptyUserData, UserData } from '../tools/userData';
import {
    HybridInvoiceData,
    isValidCustodianId,
    isValidUUID,
    isValidVersion,
    ProcessedHybridClaimsRecord,
    useCustodianApi,
} from './useCustodianApi';
import { OffchainInvoiceDto } from './useExternalTransactionsApi';
import { GetHistoricalTokenPrice, useHistoricalPrices } from './useHistoricalPrices';
import { OffchainInvoiceFactory, OffchainInvoiceInfo, useOffchainInvoiceFactory } from './useOffchainInvoiceFactory';
import { useOffchainInvoices } from './useOffchainInvoices';
import { useLocalStorage } from './useStorage';
import { useActingWalletAddress } from './useWalletAddress';
import { useWeb3 } from './useWeb3';

type UserDataContext = {
    globalUserDataWithFinanceAcceptedOriginatingClaims: ExtendedUserData<GlobalUserData>;
    globalUserDataWithoutFinanceAcceptedOriginatingClaims: ExtendedUserData<GlobalUserData>;
    currentChainUserData: ExtendedUserData<UserData>;
};

const extendEmptyData = <T extends UserData | GlobalUserData>(emptyData: T): ExtendedUserData<T> => ({
    ...emptyData,
    payables: [],
    receivables: [],
    bullaItems: [],
    accountTags: [],
    paidBullaItemsWithPayments: [],
    receivedBullaItemsWithPayments: [],
    nftTransfers: [],
    offchainInvoices: [],
    swapsWithUSDMark: [],
    hybridInvoiceDataByDescription: {} as ProcessedHybridClaimsRecord,
});

const emptyGlobalUserData: GlobalUserData = emptyUserData;
const emptyExtendedGlobalUserData = extendEmptyData(emptyGlobalUserData);
const extendEmptyUserData = extendEmptyData(emptyUserData);

const UserDataContext = React.createContext<UserDataContext>({
    globalUserDataWithFinanceAcceptedOriginatingClaims: emptyExtendedGlobalUserData,
    globalUserDataWithoutFinanceAcceptedOriginatingClaims: emptyExtendedGlobalUserData,
    currentChainUserData: extendEmptyUserData,
});

type GlobalUserData = Omit<
    UserData,
    | 'refetch'
    | 'bullaItemEvents'
    | 'financeAcceptedClaimOriginatingClaimIds'
    | 'bullaTxHashBitmap'
    | 'bullaItemMetadataById'
    | 'fetched'
    | 'importFetched'
    | 'refetchExternalTransactions'
>;

export type BullaItemInfoWithPayment = BullaItemInfo & {
    payment: BigNumber;
    paymentTimestamp: Date;
    USDMark: 'not-found' | 'fetching' | number;
};

export type NftTransfer = Omit<TransferDTO, 'transferType'> &
    Omit<NonFungibleTransferType, 'kind'> & { timestamp: Date; direction: Direction };

type ExtendedUserData<T extends UserData | GlobalUserData> = T & {
    payables: BullaItemInfo[];
    receivables: BullaItemInfo[];
    bullaItems: BullaItemInfo[];
    accountTags: AccountTagInfo[];
    paidBullaItemsWithPayments: BullaItemInfoWithPayment[];
    receivedBullaItemsWithPayments: BullaItemInfoWithPayment[];
    nftTransfers: NftTransfer[];
    offchainInvoices: OffchainInvoiceInfo[];
    swapsWithUSDMark: BullaSwapInfoWithUSDMark[];
    hybridInvoiceDataByDescription: ProcessedHybridClaimsRecord;
};

export const useUserData = () => {
    const context = React.useContext(AppContext);
    if (context === undefined) throw new Error('useUserData must me used within the AppState provider');
    return context.userDataByChain;
};

export const applyHybridClaimInfo = (claims: ClaimInfo[], hybridInvoiceDataByDescription: ProcessedHybridClaimsRecord): ClaimInfo[] =>
    claims.map(claim => {
        const hybridData = hybridInvoiceDataByDescription[claim.description];
        return hybridData ? { ...claim, description: hybridData.description, hybridInvoiceData: hybridData } : claim;
    });

export const isMaybeHybridClaim = (claim: BullaItemInfo): claim is ClaimInfo =>
    claim.__type === 'Claim' && claim.description.includes('"version":') && !claim.description.includes('mockDevCustodianId'); // mockDevCustodianId is giving me an error as it was not a valid json format;

const getExtendedUserData = <T extends UserData | GlobalUserData>(
    userData: T,
    queryAddress: string,
    getHistoricalTokenPrice: GetHistoricalTokenPrice,
    offchainInvoices: OffchainInvoiceDto[],
    createOffchainInvoice: OffchainInvoiceFactory,
    hybridInvoiceDataByDescription: ProcessedHybridClaimsRecord,
): ExtendedUserData<T> => {
    const {
        userClaims: originalUserClaims,
        instantPayments: allInstantPayments,
        importedExternalTxs,
        nonImportedExternalTxs,
        poolDeposits,
        poolRedemptions,
        swaps,
        frendLends,
    } = userData;
    const offchainInvoicesById: Record<string, OffchainInvoiceDto | undefined> = Object.fromEntries(offchainInvoices.map(x => [x.id, x]));

    const userClaims = originalUserClaims.map(claim => {
        if (!isMaybeHybridClaim(claim)) return claim;

        const hybridData = hybridInvoiceDataByDescription[claim.description];

        if (!hybridData) return claim;

        // Return claim with hybrid data and updated description
        return {
            ...claim,
            description: hybridData.description,
            hybridInvoiceData: hybridData,
        };
    });

    const { normalInstantPayments: instantPayments, paidOffchainInvoices } = allInstantPayments.reduce<{
        normalInstantPayments: InstantPaymentInfo[];
        paidOffchainInvoices: OffchainInvoiceInfo[];
    }>(
        (acc, item) => {
            const offchainInvoiceMaybe = offchainInvoicesById[item.description];
            const isOffchainInvoicePayment = !!offchainInvoiceMaybe && !acc.paidOffchainInvoices.map(x => x.id).includes(item.description);
            if (isOffchainInvoicePayment) {
                const offchainInvoiceInfo = createOffchainInvoice(offchainInvoiceMaybe, item);
                if (offchainInvoiceInfo) return { ...acc, paidOffchainInvoices: [...acc.paidOffchainInvoices, offchainInvoiceInfo] };
            }
            return { ...acc, normalInstantPayments: [...acc.normalInstantPayments, item] };
        },
        { normalInstantPayments: [], paidOffchainInvoices: [] },
    );

    const paidOffchainInvoiceIds = new Set(paidOffchainInvoices.map(x => x.id));
    const allOffchainInvoices = [
        ...paidOffchainInvoices,
        ...offchainInvoices
            .filter(x => !paidOffchainInvoiceIds.has(x.id))
            .map(x => createOffchainInvoice(x))
            .filter((x): x is OffchainInvoiceInfo => x !== undefined),
    ].sort((a, b) => b.dueBy.getTime() - a.dueBy.getTime());

    const bullaItems = [...userClaims, ...instantPayments, ...importedExternalTxs, ...allOffchainInvoices].sort(
        (a, b) => b.created.getTime() - a.created.getTime(),
    );

    const allUserClaims = [...userClaims, ...allOffchainInvoices].sort((a, b) => b.dueBy.getTime() - a.dueBy.getTime());
    const payables = getPayables(queryAddress, allUserClaims);
    const receivables = getReceivables(queryAddress, allUserClaims);
    const accountTags = getAccountTags(bullaItems);

    const receivedItems = getReceivables(queryAddress, bullaItems);
    const paidItems = getPayables(queryAddress, bullaItems);

    const loanOffersByIdByChainId = frendLends.reduce((acc, item) => {
        if (!acc[item.chainId]) {
            acc[item.chainId] = {};
        }
        acc[item.chainId][item.loanId] = item;
        return acc;
    }, {} as Record<ChainId, Record<string, FrendLendOffer>>);

    const receivedBullaItemsWithPayments = addPayments(
        getHistoricalTokenPrice,
        receivedItems,
        paidItems,
        swaps,
        queryAddress,
        'In',
        poolRedemptions,
        loanOffersByIdByChainId,
    );
    const paidBullaItemsWithPayments = addPayments(
        getHistoricalTokenPrice,
        receivedItems,
        paidItems,
        swaps,
        queryAddress,
        'Out',
        poolDeposits,
        loanOffersByIdByChainId,
    );

    const nftTransfers = nonImportedExternalTxs
        .flatMap(x => x.allTransfers.map(y => ({ ...y, timestamp: x.timestamp })))
        .filter(
            (
                x,
            ): x is Replace<T['nonImportedExternalTxs'][number]['allTransfers'][number], 'transferType', NonFungibleTransferType> & {
                timestamp: Date;
            } => x.transferType.kind == 'non-fungible',
        )
        .map(transfer => ({
            ...transfer,
            ...transfer.transferType,
            direction: (addressEquality(transfer.to, queryAddress) ? 'In' : 'Out') as Direction,
        }));

    const swapsWithUSDMark = addUSDMark(getHistoricalTokenPrice, swaps);

    return {
        ...userData,
        payables,
        receivables,
        bullaItems,
        accountTags,
        paidBullaItemsWithPayments,
        receivedBullaItemsWithPayments,
        nftTransfers,
        offchainInvoices: allOffchainInvoices,
        swapsWithUSDMark,
        hybridInvoiceDataByDescription,
    };
};

const combineUserData = (left: GlobalUserData, right: GlobalUserData): GlobalUserData => ({
    userClaims: [...left.userClaims, ...right.userClaims].sort((a, b) => b.created.getTime() - a.created.getTime()),
    instantPayments: [...left.instantPayments, ...right.instantPayments].sort((a, b) => b.created.getTime() - a.created.getTime()),
    poolDeposits: [...left.poolDeposits, ...right.poolDeposits].sort((a, b) => b.created.getTime() - a.created.getTime()),
    poolRedemptions: [...left.poolRedemptions, ...right.poolRedemptions].sort((a, b) => b.created.getTime() - a.created.getTime()),
    swaps: [...left.swaps, ...right.swaps].sort((a, b) => b.created.getTime() - a.created.getTime()),
    userBatchesByTxHash: { ...left.userBatchesByTxHash, ...right.userBatchesByTxHash },
    frendLends: [...left.frendLends, ...right.frendLends].sort((a, b) => b.offerDate.getTime() - a.offerDate.getTime()),
    importedExternalTxs: [...left.importedExternalTxs, ...right.importedExternalTxs].sort(
        (a, b) => b.created.getTime() - a.created.getTime(),
    ),
    nonImportedExternalTxs: [...left.nonImportedExternalTxs, ...right.nonImportedExternalTxs].sort(
        (a, b) => b.timestamp.getTime() - a.timestamp.getTime(),
    ),
});

type HybridClaimCache = Record<string, HybridInvoiceData>;
const getCacheKey = (custodianId: string, payloadId: string) => `${custodianId}_${payloadId}`.toLowerCase();

type FetchResult = { success: true; cacheKey: string; description: string; data: HybridInvoiceData } | { success: false; cacheKey: string };

export const UserDataProvider = ({ children }: { children: React.ReactNode }) => {
    const userDataByChain = useUserData();
    const { connectedNetwork } = useWeb3();
    const queryAddress = useActingWalletAddress();
    const offchainInvoices = useOffchainInvoices();
    const { loadTokenPrices, getHistoricalTokenPrice, USDMark } = useHistoricalPrices();
    const { createOffchainInvoiceInfo } = useOffchainInvoiceFactory();
    const { getInvoiceData, getInvoiceDataBatch } = useCustodianApi();
    const [hybridClaims, setHybridClaims] = React.useState<ProcessedHybridClaimsRecord | 'loading'>('loading');
    const [fetchingClaims, setFetchingClaims] = React.useState<Set<string>>(new Set());
    const [cachedClaims, setCachedClaims] = useLocalStorage<HybridClaimCache>('hybrid-claims-cache', {});

    const [_globalDataWithFinanceAcceptedOriginatingClaims, _globalDataWithoutFinanceAcceptedOriginatingClaims] = React.useMemo(
        () =>
            userDataByChain === 'uninitialized'
                ? [emptyGlobalUserData, emptyGlobalUserData]
                : [aggregateGlobalUserData(userDataByChain, true), aggregateGlobalUserData(userDataByChain, false)],
        [JSON.stringify(userDataByChain), queryAddress],
    );

    const hybridClaimIds = useMemo(
        () =>
            _globalDataWithFinanceAcceptedOriginatingClaims.userClaims
                .filter(x => isMaybeHybridClaim(x))
                .map(x => {
                    try {
                        const parsed = JSON.parse(x.description);

                        if (!isValidVersion(parsed.version)) {
                            console.warn('Invalid version:', parsed.version);
                            return null;
                        }

                        if (!isValidCustodianId(parsed.custodianId)) {
                            console.warn('Invalid custodian ID:', parsed.custodianId);
                            return null;
                        }

                        if (!isValidUUID(parsed.payloadId)) {
                            console.warn('Invalid UUID:', parsed.payloadId);
                            return null;
                        }

                        return { description: x.description, payloadId: parsed.payloadId, custodianId: parsed.custodianId };
                    } catch (error) {
                        console.error('Error parsing claim description:', error);
                        return null;
                    }
                })
                .filter(Boolean),
        [_globalDataWithFinanceAcceptedOriginatingClaims.userClaims.length],
    );

    React.useEffect(() => {
        const processHybridClaims = async () => {
            setHybridClaims('loading');

            const claimsToFetch = hybridClaimIds
                .filter((claim): claim is { description: string; payloadId: string; custodianId: string } => claim !== null)
                .filter(({ payloadId, custodianId }) => {
                    const cacheKey = getCacheKey(custodianId, payloadId);
                    return !cachedClaims[cacheKey] && !fetchingClaims.has(cacheKey);
                });

            let newCachedClaims = { ...cachedClaims };

            if (claimsToFetch.length > 0) {
                setFetchingClaims(prev => new Set([...prev, ...claimsToFetch.map(c => getCacheKey(c.custodianId, c.payloadId))]));

                const results = await Promise.all(
                    Object.entries(
                        claimsToFetch.reduce((acc, claim) => {
                            acc[claim.custodianId] = acc[claim.custodianId] || [];
                            acc[claim.custodianId].push(claim);
                            return acc;
                        }, {} as Record<string, typeof claimsToFetch>),
                    ).map(async ([custodianId, claims]): Promise<FetchResult[]> => {
                        try {
                            const payloadIds = claims.map(claim => claim.payloadId);
                            const backendDataArray = await getInvoiceDataBatch(payloadIds, custodianId);

                            const dataByPayloadId = backendDataArray.reduce(
                                (acc, data) => ({
                                    ...acc,
                                    [data.payloadId]: {
                                        description: data.description,
                                        lineItems: data.lineItems,
                                        tax: data.tax,
                                        version: data.version,
                                        payloadId: data.payloadId,
                                    },
                                }),
                                {} as Record<string, HybridInvoiceData>,
                            );

                            return claims.map(claim => {
                                const data = dataByPayloadId[claim.payloadId];
                                if (!data) {
                                    return {
                                        success: false,
                                        cacheKey: getCacheKey(custodianId, claim.payloadId),
                                    };
                                }

                                return {
                                    success: true,
                                    cacheKey: getCacheKey(custodianId, claim.payloadId),
                                    description: claim.description,
                                    data,
                                };
                            });
                        } catch (error) {
                            console.error(`Error fetching batch data for custodian ${custodianId}:`, error);
                            return claims.map(claim => ({
                                success: false,
                                cacheKey: getCacheKey(custodianId, claim.payloadId),
                            }));
                        }
                    }),
                ).then(results => results.flat());

                const successfulResults = results.filter((r): r is FetchResult & { success: true } => r.success);
                const newCacheEntries = Object.fromEntries(successfulResults.map(r => [r.cacheKey, r.data]));

                newCachedClaims = { ...cachedClaims, ...newCacheEntries };
                setCachedClaims(newCachedClaims);

                setFetchingClaims(prev => {
                    const next = new Set(prev);
                    results.forEach(r => next.delete(r.cacheKey));
                    return next;
                });
            }

            const allClaims = hybridClaimIds.reduce((acc, claim) => {
                if (!claim) return acc;

                const cached = newCachedClaims[getCacheKey(claim.custodianId, claim.payloadId)];
                if (cached) {
                    return { ...acc, [claim.description]: cached };
                }
                return acc;
            }, {} as ProcessedHybridClaimsRecord);

            setHybridClaims(allClaims);
        };

        processHybridClaims();
    }, [_globalDataWithFinanceAcceptedOriginatingClaims.userClaims.length, getInvoiceData]);

    const globalUserDataWithFinanceAcceptedOriginatingClaims = React.useMemo(
        () =>
            getExtendedUserData(
                _globalDataWithFinanceAcceptedOriginatingClaims,
                queryAddress,
                getHistoricalTokenPrice,
                offchainInvoices,
                createOffchainInvoiceInfo,
                hybridClaims === 'loading' ? {} : hybridClaims,
            ),
        [
            JSON.stringify(_globalDataWithFinanceAcceptedOriginatingClaims),
            queryAddress,
            USDMark,
            offchainInvoices,
            createOffchainInvoiceInfo,
            hybridClaims,
        ],
    );

    useEffect(() => {
        const priceQueries = [
            ...globalUserDataWithFinanceAcceptedOriginatingClaims.receivedBullaItemsWithPayments,
            ...globalUserDataWithFinanceAcceptedOriginatingClaims.paidBullaItemsWithPayments,
            ...globalUserDataWithoutFinanceAcceptedOriginatingClaims.swapsWithUSDMark.flatMap(swap => [
                {
                    chainId: swap.chainId,
                    tokenAddress: swap.signerToken.token.address,
                    timestamp: swap.created,
                },
                {
                    chainId: swap.chainId,
                    tokenAddress: swap.senderToken.token.address,
                    timestamp: swap.created,
                },
                ...(swap.executed
                    ? [
                          {
                              chainId: swap.chainId,
                              tokenAddress: swap.signerToken.token.address,
                              timestamp: swap.executed,
                          },
                          {
                              chainId: swap.chainId,
                              tokenAddress: swap.senderToken.token.address,
                              timestamp: swap.executed,
                          },
                      ]
                    : []),
            ]),
        ].map(item => {
            if ('tokenInfo' in item) {
                return {
                    chainId: item.chainId,
                    tokenAddress: item.tokenInfo.token.address,
                    timestamp: item.paymentTimestamp,
                };
            }
            return item;
        });

        loadTokenPrices(priceQueries);
    }, [
        globalUserDataWithFinanceAcceptedOriginatingClaims.receivedBullaItemsWithPayments.length,
        globalUserDataWithFinanceAcceptedOriginatingClaims.paidBullaItemsWithPayments.length,
        globalUserDataWithFinanceAcceptedOriginatingClaims.swaps.length,
    ]);

    const globalUserDataWithoutFinanceAcceptedOriginatingClaims = React.useMemo(
        () =>
            getExtendedUserData(
                _globalDataWithoutFinanceAcceptedOriginatingClaims,
                queryAddress,
                getHistoricalTokenPrice,
                offchainInvoices,
                createOffchainInvoiceInfo,
                hybridClaims === 'loading' ? {} : hybridClaims,
            ),
        [
            JSON.stringify(_globalDataWithoutFinanceAcceptedOriginatingClaims),
            queryAddress,
            USDMark,
            offchainInvoices,
            createOffchainInvoiceInfo,
        ],
    );

    const currentChainUserData = React.useMemo(() => {
        if (userDataByChain === 'uninitialized') return extendEmptyUserData;

        const userDataState = userDataByChain[connectedNetwork];

        if (userDataState.kind !== 'fetched') return extendEmptyUserData;

        return getExtendedUserData(
            applyBullaItemMetadata(userDataState.userData),
            queryAddress,
            getHistoricalTokenPrice,
            offchainInvoices,
            createOffchainInvoiceInfo,
            hybridClaims === 'loading' ? {} : hybridClaims,
        );
    }, [
        userDataByChain === 'uninitialized' ? 'uninit' : JSON.stringify(userDataByChain[connectedNetwork]),
        connectedNetwork,
        queryAddress,
        USDMark,
        offchainInvoices,
        createOffchainInvoiceInfo,
    ]);

    return (
        <UserDataContext.Provider
            value={{
                globalUserDataWithFinanceAcceptedOriginatingClaims,
                globalUserDataWithoutFinanceAcceptedOriginatingClaims,
                currentChainUserData,
            }}
        >
            {children}
        </UserDataContext.Provider>
    );
};

const applyBullaItemMetadata = (userData: UserData): UserData => ({
    ...userData,
    userClaims: userData.userClaims.map(x => ({ ...x, notes: userData.bullaItemMetadataById[x.id]?.notes ?? '' })),
    instantPayments: userData.instantPayments.map(x => ({ ...x, notes: userData.bullaItemMetadataById[x.id]?.notes ?? '' })),
});

const getAccountTags = (items: BullaItemInfo[]) => {
    const accountTagsWithoutExpense = Object.values(
        items.reduce<{ [tag: string]: AccountTagInfo }>(
            (tags, item) =>
                item.tags
                    .filter(x => x !== '')
                    .reduce<Record<string, AccountTagInfo>>(
                        (acc, tag) => ({
                            ...acc,
                            [tag]: {
                                name: tag,
                                items: [...(tags[tag]?.items ?? []), item],
                            },
                        }),
                        tags,
                    ),
            {},
        ),
    );

    const accountTagsWithExpense = accountTagsWithoutExpense.map(x => x.name.trim().toLowerCase()).includes('expense')
        ? accountTagsWithoutExpense
        : [...accountTagsWithoutExpense, { name: 'Expense', items: [] }];

    return accountTagsWithExpense.sort((a, b) => a.name.localeCompare(b.name));
};

export const useDataReadiness = () => {
    const userDataByChainId = useUserData();
    const isInitialized = userDataByChainId !== 'uninitialized';
    return {
        isInitialized,
        isChainInitialized: (chainId: number) => isInitialized && userDataByChainId[chainId].kind !== 'loading',
        isChainFullyLoaded: (chainId: number) => {
            if (!isInitialized) return false;
            const userDataState = userDataByChainId[chainId];
            return userDataState.kind === 'fetched' && userDataState.userData.fetched && userDataState.userData.importFetched;
        },
    };
};

const aggregateGlobalUserData = (
    userDataByChainId: Record<number, UserDataState>,
    includeFinanceAcceptedOriginatingClaim: boolean,
): GlobalUserData =>
    Object.values(userDataByChainId)
        .filter((state): state is UserDataReadyState => state.kind == 'fetched')
        .reduce<GlobalUserData>((acc, _userData) => {
            const userData = applyBullaItemMetadata(_userData.userData);
            const claimIdsToIgnore = new Set(userData.financeAcceptedClaimOriginatingClaimIds);
            return combineUserData(acc, {
                ...userData,
                userClaims: userData.userClaims.filter(x => includeFinanceAcceptedOriginatingClaim || !claimIdsToIgnore.has(x.id)),
            });
        }, emptyGlobalUserData);

export const useGlobalUserData = (
    includeFinanceAcceptedOriginatingClaim: 'include-originating-claims' | 'exclude-originating-claims',
): ExtendedUserData<GlobalUserData> => {
    const context = React.useContext(UserDataContext);
    if (context === undefined) throw new Error('useGlobalData must me used within the UserData provider');

    return includeFinanceAcceptedOriginatingClaim == 'include-originating-claims'
        ? context.globalUserDataWithFinanceAcceptedOriginatingClaims
        : context.globalUserDataWithoutFinanceAcceptedOriginatingClaims;
};

export const useCurrentChainUserData = (): ExtendedUserData<UserData> => {
    const context = React.useContext(UserDataContext);
    if (context === undefined) throw new Error('useGlobalData must me used within the UserData provider');

    return context.currentChainUserData;
};

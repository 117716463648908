import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter } from '@chakra-ui/react';
import { ClaimType } from '../../../data-lib/data-model';
import { DefaultBatchClaimValues } from './batch-state';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { paymentDateSchema } from './shared-components';
import { ClaimDescriptionField, ClaimAmountField, DueByField, AccountTagField } from '../create-claim-modal/create-claim-inputs';
import { Stack, Box, HStack, Checkbox, Tooltip } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { useAttachmentGenerationState } from '../../../hooks/useAttachmentGenerationState';
import { useUIState } from '../../../state/ui-state';
import { SignInAndOnboardAttachemntGeneration } from '../../../pages/settings/company-details/company-details-page';
import { apply } from '../../../tools/common';
import { validate } from '../../../hooks/useExtendedContacts';
import { OrangeButton, WhiteButton } from '../../inputs/buttons';

interface DefaultValuesModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (values: DefaultBatchClaimValues) => void;
    initialValues: DefaultBatchClaimValues;
    claimType: ClaimType;
}

const defaultValuesSchema = (checkDueDate: boolean) => {
    const description = { defaultDescription: Yup.string() };
    return Yup.object().shape(checkDueDate ? { ...description, defaultDueDate: paymentDateSchema.required() } : description);
};

export const DefaultValuesModal: React.FC<DefaultValuesModalProps> = ({ isOpen, onClose, onSubmit, initialValues, claimType }) => {
    const { transactionPending } = useUIState();
    const { attachmentGenerationState, getCompanyDetails, setAttachmentGenerationState } = useAttachmentGenerationState();
    const checkDueDate = !!initialValues.defaultDueDate;
    const schema = defaultValuesSchema(checkDueDate);
    const isInitialValid = validate(() => schema.validateSync(initialValues)) == true;

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent maxW="800px">
                <ModalHeader color="gray.900">Add Default Values</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <Formik
                        validateOnChange
                        onSubmit={values => {
                            onSubmit(values);
                            onClose();
                        }}
                        initialValues={initialValues}
                        isInitialValid={isInitialValid}
                        validationSchema={schema}
                        validateOnMount
                    >
                        {({ isValid, status, touched, setFieldValue, values, errors, setStatus, submitForm }) => (
                            <>
                                <Form placeholder="">
                                    <Stack spacing="4">
                                        {claimType === 'Invoice' && (
                                            <Field name="defaultDueDate">
                                                {({ field }: FieldProps) => (
                                                    <DueByField
                                                        field={field}
                                                        isDisabled={transactionPending}
                                                        error={errors.defaultDueDate}
                                                        touched={!!touched.defaultDueDate}
                                                        setDueBy={apply(setFieldValue, 'defaultDueDate')}
                                                        label="Default Payment Date"
                                                        required={true}
                                                    />
                                                )}
                                            </Field>
                                        )}

                                        <Field name="defaultDescription">
                                            {({ field }: FieldProps) => (
                                                <ClaimDescriptionField
                                                    field={field}
                                                    isDisabled={transactionPending}
                                                    error={errors.defaultDescription}
                                                    touched={touched.defaultDescription}
                                                    required={false}
                                                    label="Default Description"
                                                />
                                            )}
                                        </Field>

                                        <Field name="defaultAmount">
                                            {({ field }: FieldProps) => (
                                                <ClaimAmountField
                                                    claimType={claimType}
                                                    field={field}
                                                    isDisabled={transactionPending}
                                                    error={errors.defaultAmount}
                                                    touched={touched.defaultAmount}
                                                    setAmount={apply(setFieldValue, 'defaultAmount')}
                                                    setToken={apply(setFieldValue, 'defaultToken')}
                                                    amount={values.defaultAmount}
                                                    token={values.defaultToken}
                                                    required={false}
                                                    label="Default Amount"
                                                    disableBalanceLabels
                                                    includeNativeToken={claimType === 'Payment'}
                                                    disableMaxButton
                                                />
                                            )}
                                        </Field>

                                        <Field name="defaultAccountTags">
                                            {({ field }: FieldProps) => (
                                                <AccountTagField
                                                    field={field}
                                                    isDisabled={transactionPending}
                                                    error={errors.defaultAccountTags}
                                                    touched={touched.defaultAccountTags}
                                                    setTags={apply(setFieldValue, field.name)}
                                                    setStatus={setStatus}
                                                    label="Default Categories"
                                                    creatingExpense={false}
                                                />
                                            )}
                                        </Field>

                                        <Box py="2">
                                            <Field name="generatePDF">
                                                {({ field }: FieldProps) => (
                                                    <HStack>
                                                        <Checkbox
                                                            isDisabled={
                                                                transactionPending ||
                                                                (field.value !== true && attachmentGenerationState != 'ready')
                                                            }
                                                            isChecked={field.value}
                                                            onChange={e => setFieldValue('generatePDF', e.target.checked)}
                                                        >
                                                            {claimType == 'Payment' ? 'Generate Payment Receipts' : 'Generate Invoices'}
                                                        </Checkbox>
                                                        <Tooltip
                                                            label={`Have Bulla auto-generate a PDF for each ${claimType.toLowerCase()}`}
                                                            placement="top-start"
                                                        >
                                                            <InfoOutlineIcon />
                                                        </Tooltip>
                                                        <HStack>
                                                            {(attachmentGenerationState == 'no-details' ||
                                                                attachmentGenerationState == 'unauthenticated') && (
                                                                <SignInAndOnboardAttachemntGeneration
                                                                    onSignIn={() =>
                                                                        getCompanyDetails().then(details => {
                                                                            if (details) {
                                                                                setFieldValue('generatePDF', true);
                                                                                setAttachmentGenerationState('ready');
                                                                            } else {
                                                                                setAttachmentGenerationState('no-details');
                                                                            }
                                                                        })
                                                                    }
                                                                    onDetailsReady={() => setFieldValue('generatePDF', true)}
                                                                />
                                                            )}
                                                        </HStack>
                                                    </HStack>
                                                )}
                                            </Field>
                                        </Box>
                                    </Stack>
                                </Form>
                                <ModalFooter px={0}>
                                    <WhiteButton mr={3} onClick={onClose}>
                                        Cancel
                                    </WhiteButton>
                                    <OrangeButton onClick={submitForm} isDisabled={!isValid || status === 'editing'}>
                                        Save
                                    </OrangeButton>
                                </ModalFooter>
                            </>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

{
    "released": true,
    "contractName": "MultiSendCallOnly",
    "version": "1.4.1",
    "deployments": {
        "canonical": {
            "address": "0x9641d764fc13c8B624c04430C7356C1C7C8102e2",
            "codeHash": "0xecd5bd14a08c5d2122379900b2f272bdf107a7e92423c10dd5fe3254386c9939"
        }
    },
    "networkAddresses": {
        "1": "canonical",
        "5": "canonical",
        "10": "canonical",
        "14": "canonical",
        "16": "canonical",
        "19": "canonical",
        "25": "canonical",
        "31": "canonical",
        "39": "canonical",
        "40": "canonical",
        "41": "canonical",
        "44": "canonical",
        "56": "canonical",
        "71": "canonical",
        "81": "canonical",
        "88": "canonical",
        "97": "canonical",
        "100": "canonical",
        "114": "canonical",
        "122": "canonical",
        "130": "canonical",
        "133": "canonical",
        "137": "canonical",
        "146": "canonical",
        "155": "canonical",
        "169": "canonical",
        "177": "canonical",
        "181": "canonical",
        "196": "canonical",
        "250": "canonical",
        "252": "canonical",
        "255": "canonical",
        "314": "canonical",
        "336": "canonical",
        "338": "canonical",
        "369": "canonical",
        "466": "canonical",
        "478": "canonical",
        "480": "canonical",
        "530": "canonical",
        "545": "canonical",
        "592": "canonical",
        "690": "canonical",
        "919": "canonical",
        "938": "canonical",
        "970": "canonical",
        "995": "canonical",
        "1001": "canonical",
        "1030": "canonical",
        "1101": "canonical",
        "1111": "canonical",
        "1112": "canonical",
        "1125": "canonical",
        "1135": "canonical",
        "1284": "canonical",
        "1285": "canonical",
        "1287": "canonical",
        "1329": "canonical",
        "1337": "canonical",
        "1442": "canonical",
        "1516": "canonical",
        "1625": "canonical",
        "1663": "canonical",
        "1729": "canonical",
        "1750": "canonical",
        "1811": "canonical",
        "1923": "canonical",
        "1924": "canonical",
        "2000": "canonical",
        "2039": "canonical",
        "2187": "canonical",
        "2192": "canonical",
        "2358": "canonical",
        "2442": "canonical",
        "2810": "canonical",
        "2818": "canonical",
        "3338": "canonical",
        "3501": "canonical",
        "3636": "canonical",
        "3776": "canonical",
        "4002": "canonical",
        "4061": "canonical",
        "4062": "canonical",
        "4157": "canonical",
        "4162": "canonical",
        "4202": "canonical",
        "4337": "canonical",
        "4653": "canonical",
        "4661": "canonical",
        "5000": "canonical",
        "5003": "canonical",
        "5115": "canonical",
        "5611": "canonical",
        "6001": "canonical",
        "6321": "canonical",
        "6322": "canonical",
        "6688": "canonical",
        "7000": "canonical",
        "7001": "canonical",
        "7171": "canonical",
        "7200": "canonical",
        "7560": "canonical",
        "7771": "canonical",
        "8192": "canonical",
        "8194": "canonical",
        "8217": "canonical",
        "8453": "canonical",
        "9001": "canonical",
        "9700": "canonical",
        "10081": "canonical",
        "10242": "canonical",
        "10243": "canonical",
        "11235": "canonical",
        "11501": "canonical",
        "11503": "canonical",
        "13337": "canonical",
        "13746": "canonical",
        "17000": "canonical",
        "17069": "canonical",
        "18233": "canonical",
        "23294": "canonical",
        "23295": "canonical",
        "32769": "canonical",
        "33101": "canonical",
        "33139": "canonical",
        "34443": "canonical",
        "35441": "canonical",
        "35443": "canonical",
        "41455": "canonical",
        "42161": "canonical",
        "42220": "canonical",
        "42421": "canonical",
        "43111": "canonical",
        "43114": "canonical",
        "44787": "canonical",
        "47763": "canonical",
        "54211": "canonical",
        "57000": "canonical",
        "57054": "canonical",
        "57073": "canonical",
        "59140": "canonical",
        "59141": "canonical",
        "59144": "canonical",
        "80001": "canonical",
        "80085": "canonical",
        "80094": "canonical",
        "81457": "canonical",
        "84531": "canonical",
        "84532": "canonical",
        "90001": "canonical",
        "98864": "canonical",
        "98865": "canonical",
        "105105": "canonical",
        "111188": "canonical",
        "167000": "canonical",
        "167009": "canonical",
        "175188": "canonical",
        "205205": "canonical",
        "314159": "canonical",
        "381931": "canonical",
        "421614": "canonical",
        "444444": "canonical",
        "534351": "canonical",
        "534352": "canonical",
        "555666": "canonical",
        "713715": "canonical",
        "743111": "canonical",
        "839999": "canonical",
        "984122": "canonical",
        "1501869": "canonical",
        "3441006": "canonical",
        "6038361": "canonical",
        "7225878": "canonical",
        "7777777": "canonical",
        "9999999": "canonical",
        "11155111": "canonical",
        "11155420": "canonical",
        "12227332": "canonical",
        "52164803": "canonical",
        "94204209": "canonical",
        "111557560": "canonical",
        "123420111": "canonical",
        "168587773": "canonical",
        "253368190": "canonical",
        "476462898": "canonical",
        "666666666": "canonical",
        "999999999": "canonical",
        "1313161554": "canonical",
        "1313161555": "canonical",
        "1511670449": "canonical",
        "1570754601": "canonical",
        "1666600000": "canonical",
        "1666700000": "canonical",
        "88153591557": "canonical",
        "123420000220": "canonical"
    },
    "abi": [
        {
            "inputs": [
                {
                    "internalType": "bytes",
                    "name": "transactions",
                    "type": "bytes"
                }
            ],
            "name": "multiSend",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
        }
    ]
}

import {
    Alert,
    AlertIcon,
    Box,
    Divider,
    HStack,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { isAddress, parseUnits } from 'ethers/lib/utils';
import { Field, FieldProps, Form, Formik, FormikProps, isNaN, validateYupSchema, yupToFormErrors } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import { SwapCoins } from '../../../assets/swap-coins';
import { TransactionResult, findEventLogs } from '../../../data-lib/dto/events-dto';
import { EthAddress, addressEquality } from '../../../data-lib/ethereum';
import { ChainId, NETWORKS, SWAP_SUPPORTED_NETWORKS, TokenDto } from '../../../data-lib/networks';
import { useAllowances } from '../../../hooks/useAllowances';
import { CreateOrderParams, useBullaSwap } from '../../../hooks/useBullaSwap';
import { useTokenBalances } from '../../../hooks/useChainData';
import { useOpenSwapDetails } from '../../../hooks/useClaimDetailDisclosure';
import { useSendSwapEmail } from '../../../hooks/useEmail';
import { useGnosisTransaction } from '../../../hooks/useGnosisTransaction';
import { useGlobalUserData } from '../../../hooks/useUserData';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { useOnboard, useWeb3 } from '../../../hooks/useWeb3';
import { useAppState } from '../../../state/app-state';
import { useGnosisSafe } from '../../../state/gnosis-state';
import { useUIState } from '../../../state/ui-state';
import { apply } from '../../../tools/common';
import { OrangeButton, WhiteButton } from '../../inputs/buttons';
import { CloseModalButton } from '../common';
import { IconBox } from '../common-reporting/progress-steps';
import {
    ChainSelector,
    ClaimAmountField,
    EmailField,
    RecipientField,
    SwapRateField,
    claimAmountValidationSchema,
    isNumberInputValid,
    recipientErrorSchema,
} from '../create-claim-modal/create-claim-inputs';
import { PastSwapsTable } from './past-swaps-table';

const supportedSwapNetworks = [...SWAP_SUPPORTED_NETWORKS] as ChainId[];

type CreateSwapModalProps = { isOpen: boolean; onClose: VoidFunction };

const errorSchema = (actingWallet: EthAddress) =>
    Yup.object().shape({
        offerAmount: claimAmountValidationSchema.required('Offer amount is required'),
        computedReceiveAmount: claimAmountValidationSchema.required('Receive amount is required'),
        offerTo: recipientErrorSchema(actingWallet)
            .required('Recipient address is required')
            .test('is-valid-address', 'Invalid wallet address', value => isAddress(value || '')),
        emailAddress: Yup.string()
            .email('Invalid email address')
            .optional()
            .transform(value => (value === '' ? undefined : value)),
        offerToken: Yup.object().required('Offer token is required'),
        receiveToken: Yup.object().required('Receive token is required'),
    });

interface SwapFormValues {
    offerAmount: string;
    offerToken: TokenDto;
    receiveAmount: string;
    receiveToken: TokenDto;
    offerTo: string;
    emailAddress?: string;
    rate: string;
    computedRate: string;
    computedReceiveAmount: string;
    lastChanged: 'rate' | 'receiveAmount';
}

export const CreateSwapModal = ({ isOpen, onClose }: CreateSwapModalProps) => {
    const {
        connectedNetwork,
        connectedNetworkConfig: { bullaSwap },
    } = useWeb3();
    const { swaps } = useGlobalUserData('exclude-originating-claims');
    const [approvalPending, { getAllowanceForTokenAddress, approveTokens }] = useAllowances('exact-allowance');
    const [allowance, setAllowance] = React.useState<'init' | BigNumber>('init');
    const [executingSafeTx, setExecutingSafeTx] = useState<boolean>(false);
    const [executing, { createOrder, createOrderInputToMultisendTxDTO }] = useBullaSwap();
    const isLoading = approvalPending || executingSafeTx || executing;
    const { changeNetwork } = useOnboard();
    const { transactionPending } = useUIState();
    const modalContentRef = useRef<HTMLDivElement>(null);
    const tokenBalances = useTokenBalances({ chainId: connectedNetwork, poll: isOpen });
    const { readyToTransact } = useAppState();
    const wrongNetwork = !SWAP_SUPPORTED_NETWORKS.includes(connectedNetwork);
    const isDisabled = !readyToTransact || allowance == 'init' || wrongNetwork;
    const { safeInfo } = useGnosisSafe();
    const actingWallet = useActingWalletAddress();
    const { executeTransactions } = useGnosisTransaction();
    const openSwap = useOpenSwapDetails();
    const sendSwapEmail = useSendSwapEmail();

    const outstandingAllowanceNeededByTokenAddress = useMemo(() => {
        return swaps
            .filter(x => x.chainId == connectedNetwork && x.status == 'Pending' && addressEquality(x.signerWallet, actingWallet))
            .reduce<Record<string, BigNumber>>((acc, item) => {
                const tokenAddress = item.signerToken.token.address.toLowerCase();
                return { ...acc, [tokenAddress]: (acc[tokenAddress] ?? BigNumber.from(0)).add(item.signerAmount) };
            }, {});
    }, [swaps, connectedNetwork]);

    const formikRef = useRef<FormikProps<SwapFormValues>>(null);

    const wrongNetworkLabel = wrongNetwork && (
        <Box p="0">
            <Alert status="warning" bg={'white'} py="0">
                <AlertIcon />
                <span>Current network does not yet support swaps. Please select a valid network above.</span>
            </Alert>
        </Box>
    );

    // I changed the logic of how we pick the default tokens because checking balances for all tokens everytime is quite expensive.
    const supportedTokens = Object.values(NETWORKS[connectedNetwork].supportedTokens);
    const defaultOfferToken = supportedTokens[0].token;
    const defaultReceiveToken = supportedTokens[1].token;

    useEffect(() => {
        if (formikRef.current) {
            formikRef.current.setFieldValue('offerToken', defaultOfferToken);
            formikRef.current.setFieldValue('receiveToken', defaultReceiveToken);
        }
    }, [connectedNetwork, !!formikRef.current]);

    const handleSuccessfulOrder = (transactionResult: TransactionResult) => {
        const orderCreatedEvent = findEventLogs(transactionResult.events, 'OrderCreatedEvent')[0];
        onClose();
        if (!!orderCreatedEvent && orderCreatedEvent.__typename == 'OrderCreatedEvent')
            openSwap(orderCreatedEvent.order.orderId.toString(), connectedNetwork);
    };

    const handleSwap = async (
        offerToken: TokenDto,
        offerAmount: string,
        receiveToken: TokenDto,
        receiveAmount: string,
        offerTo: string,
        emailAddress: string | undefined,
    ) => {
        if (offerAmount === '' || receiveAmount === '') return;

        const parsedOfferAmount = parseUnits(offerAmount, offerToken.decimals);
        const parsedReceiveAmount = parseUnits(receiveAmount, receiveToken.decimals);
        const oneWeekFromNow = Math.floor(new Date().setDate(new Date().getDate() + 7) / 1000);

        const order: CreateOrderParams = {
            expiry: BigNumber.from(oneWeekFromNow),
            signerWallet: actingWallet,
            signerToken: offerToken.address,
            signerAmount: parsedOfferAmount,
            senderWallet: offerTo,
            senderToken: receiveToken.address,
            senderAmount: parsedReceiveAmount,
        };

        const amountToApprove = parsedOfferAmount.add(outstandingAllowanceNeededByTokenAddress[offerToken.address.toLowerCase()] ?? 0);

        if (safeInfo) {
            setExecutingSafeTx(true);
            try {
                const { transaction } = await createOrderInputToMultisendTxDTO(bullaSwap!, order, offerToken);
                const { success, transactionResult } = await executeTransactions(safeInfo, [transaction], true);
                if (success && !!transactionResult) {
                    if (emailAddress) {
                        sendSwapEmail({ ...order, emailAddress }, transactionResult, !!safeInfo);
                    }
                    handleSuccessfulOrder(transactionResult);
                }
            } finally {
                setExecutingSafeTx(false);
            }
        } else if (allowance !== 'init' && allowance.gte(amountToApprove)) {
            await createOrder(order).then(({ success, transactionResult }): void => {
                if (success && !!transactionResult) {
                    if (emailAddress) {
                        sendSwapEmail({ ...order, emailAddress }, transactionResult, false);
                    }
                    handleSuccessfulOrder(transactionResult);
                }
            });
        } else {
            try {
                await approveTokens(bullaSwap!)([
                    {
                        amount: amountToApprove,
                        token: offerToken,
                    },
                ]).then(({ success }) => {
                    if (success) {
                        setAllowance(amountToApprove);
                    }
                });
            } catch (error) {
                console.warn('Token approval failed:', error);
                // Optionally handle the error (e.g., show a toast notification)
            }
        }
    };

    return (
        <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}
            motionPreset="slideInBottom"
            closeOnOverlayClick={true}
            closeOnEsc={true}
            size="2xl"
        >
            <ModalOverlay />
            <ModalContent ref={modalContentRef}>
                <Formik
                    innerRef={formikRef}
                    enableReinitialize
                    validateOnChange
                    initialValues={{
                        offerAmount: '',
                        offerToken: defaultOfferToken,
                        receiveAmount: '',
                        receiveToken: defaultReceiveToken,
                        offerTo: '',
                        emailAddress: '',
                        rate: '1',
                        computedReceiveAmount: '',
                        computedRate: '1',
                        lastChanged: 'rate',
                    }}
                    onSubmit={(values, actions) => {
                        const { offerAmount, offerToken, receiveToken, offerTo, computedReceiveAmount, emailAddress } = values;

                        handleSwap(offerToken, offerAmount, receiveToken, computedReceiveAmount, offerTo, emailAddress).then(() => {
                            actions.setSubmitting(false);
                        });
                    }}
                    validate={values => {
                        try {
                            validateYupSchema(values, errorSchema(actingWallet), true);
                            return {};
                        } catch (err) {
                            return yupToFormErrors(err);
                        }
                    }}
                >
                    {({ errors, touched, values, setFieldValue, setFieldTouched, dirty, setValues }) => {
                        const update = ({
                            newOfferAmount,
                            newReceiveAmount,
                            newRate,
                        }: {
                            newOfferAmount?: string;
                            newReceiveAmount?: string;
                            newRate?: string;
                        }) =>
                            setValues(prev => {
                                const rate = newRate ?? prev.rate;
                                const receiveAmount = newReceiveAmount ?? prev.receiveAmount;
                                const offerAmount = newOfferAmount ?? prev.offerAmount;

                                const lastChanged =
                                    newRate !== undefined ? 'rate' : newReceiveAmount !== undefined ? 'receiveAmount' : prev.lastChanged;

                                const parsedRate = parseFloat(rate);
                                const parsedReceiveAmount = parseFloat(receiveAmount);
                                const parsedOfferAmount = parseFloat(offerAmount);

                                const [computedRate, computedReceiveAmount] =
                                    lastChanged == 'rate'
                                        ? [
                                              rate,
                                              isNaN(parsedRate) || isNaN(parsedOfferAmount) ? '0' : String(parsedRate * parsedOfferAmount),
                                          ]
                                        : [
                                              isNaN(parsedReceiveAmount) || isNaN(parsedOfferAmount)
                                                  ? '0'
                                                  : String(parsedReceiveAmount / parsedOfferAmount),
                                              receiveAmount,
                                          ];

                                return { ...prev, computedRate, computedReceiveAmount, lastChanged, offerAmount, receiveAmount, rate };
                            });

                        const changeRate = (rate: string) => update({ newRate: rate });
                        const changeReceiveAmount = (receiveAmount: string) => update({ newReceiveAmount: receiveAmount });
                        const changeOfferAmount = (OfferAmount: string) => update({ newOfferAmount: OfferAmount });

                        const pastSwapsToSender =
                            values.offerTo &&
                            swaps
                                .filter(
                                    p => addressEquality(p.senderWallet, values.offerTo) || addressEquality(p.signerWallet, values.offerTo),
                                )
                                .slice(0, 2);

                        useEffect(() => {
                            if (!values.offerToken || !isOpen) {
                                return;
                            }
                            setAllowance('init');
                            getAllowanceForTokenAddress(bullaSwap!, connectedNetwork)(values.offerToken.address).then(setAllowance);
                        }, [values.offerToken.address, connectedNetwork, isOpen]);

                        const insufficientFunds = useMemo(() => {
                            if (tokenBalances && values.offerAmount != '') {
                                const insufficientFundsResult: boolean =
                                    +(tokenBalances.getBalanceForToken(values.offerToken.address) ?? 0) < +values.offerAmount;
                                return insufficientFundsResult;
                            } else return false;
                        }, [connectedNetwork, values.offerToken, values.offerAmount, !!tokenBalances]);

                        const isApproved = useMemo(() => {
                            if (allowance === 'init' || values.offerAmount === '') return false;
                            if (!values.offerToken) return false;
                            const parsedOfferAmount = parseUnits(values.offerAmount, values.offerToken.decimals);
                            return allowance.gte(
                                parsedOfferAmount.add(
                                    outstandingAllowanceNeededByTokenAddress[values.offerToken.address.toLowerCase()] ?? 0,
                                ),
                            );
                        }, [allowance, values.offerAmount, values.offerToken.decimals, outstandingAllowanceNeededByTokenAddress]);

                        return (
                            <Form placeholder={''}>
                                <ModalHeader display="flex" flexDirection="row">
                                    <HStack alignItems="center">
                                        <IconBox icon={SwapCoins} boxShadow="none" w="50px" h="50px" />
                                        <VStack align="flex-start" ml={3} spacing={0}>
                                            <Text color="heading" fontWeight={'700'} fontSize="20px">
                                                Swap Offer
                                            </Text>
                                            <Text fontSize={'15px'} fontWeight={'400'} color="gray.600">
                                                Offer to swap coins with another wallet.
                                            </Text>
                                        </VStack>
                                    </HStack>
                                </ModalHeader>
                                <Box>
                                    <Divider width="calc(100%)" sx={{ height: '0.5px' }} />
                                </Box>
                                <ModalBody>
                                    <Box>
                                        <Text fontSize="14px" fontWeight="500" mb="2">
                                            Chain
                                        </Text>
                                        <ChainSelector
                                            chainId={connectedNetwork}
                                            isDisabled={isLoading}
                                            selectableChains={supportedSwapNetworks}
                                            textAlign={'left'}
                                            onChainSelected={changeNetwork}
                                            w="100%"
                                            mb="4"
                                        />
                                    </Box>
                                    <VStack spacing="3">
                                        <Field name="offerTo">
                                            {({ field }: FieldProps) => (
                                                <RecipientField
                                                    {...{
                                                        field,
                                                        isDisabled: transactionPending,
                                                        error: errors.offerTo,
                                                        touched: touched.offerTo,
                                                        setRecipient: name => !!name && setFieldValue('offerTo', name),
                                                        label: 'Offer to',
                                                        dropdownModalRef: modalContentRef,
                                                        required: true,
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <HStack align={'flex-start'}>
                                            <Field name="offerAmount">
                                                {({ field }: FieldProps) => (
                                                    <ClaimAmountField
                                                        {...{
                                                            claimType: 'Swap',
                                                            field,
                                                            isDisabled: transactionPending,
                                                            includeNativeToken: false,
                                                            error: errors.offerAmount,
                                                            touched: touched.offerAmount,
                                                            setAmount: (value: string) => {
                                                                if (isNumberInputValid(value) && !value.match(/^\.$/)) {
                                                                    const decimalIndex = value.indexOf('.');
                                                                    if (
                                                                        decimalIndex !== -1 &&
                                                                        value.length - decimalIndex - 1 > values.offerToken.decimals
                                                                    ) {
                                                                        const limitedValue = value.slice(
                                                                            0,
                                                                            decimalIndex + values.offerToken.decimals + 1,
                                                                        );
                                                                        changeOfferAmount(limitedValue);
                                                                    } else {
                                                                        changeOfferAmount(value);
                                                                    }
                                                                }
                                                            },
                                                            setToken: apply(setFieldValue, 'offerToken'),
                                                            amount: values.offerAmount,
                                                            token: values.offerToken,
                                                            setFieldTouched,
                                                            label: 'Offer',
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <Box w="250px">
                                                <Field name="computedRate">
                                                    {({ field }: FieldProps) => (
                                                        <SwapRateField
                                                            {...{
                                                                field: {
                                                                    ...field,
                                                                    onChange: e => {
                                                                        const value = e.target.value;
                                                                        if (isNumberInputValid(value) && !value.match(/^\.$/)) {
                                                                            const decimalIndex = value.indexOf('.');
                                                                            if (
                                                                                decimalIndex !== -1 &&
                                                                                value.length - decimalIndex - 1 > values.offerToken.decimals
                                                                            ) {
                                                                                const limitedValue = value.slice(
                                                                                    0,
                                                                                    decimalIndex + values.offerToken.decimals + 1,
                                                                                );
                                                                                changeRate(limitedValue);
                                                                            } else {
                                                                                changeRate(value);
                                                                            }
                                                                        }
                                                                    },
                                                                    value: values.computedRate,
                                                                },
                                                                isDisabled: transactionPending,
                                                                error: errors.computedRate,
                                                                touched: touched.computedRate,
                                                                setFieldTouched,
                                                                label: `${values.offerToken.symbol} per ${values.receiveToken.symbol} rate`,
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </Box>
                                        </HStack>
                                        <Field name="computedReceiveAmount">
                                            {({ field }: FieldProps) => (
                                                <ClaimAmountField
                                                    {...{
                                                        claimType: 'Invoice',
                                                        field,
                                                        isDisabled: transactionPending,
                                                        includeNativeToken: false,
                                                        error: errors.computedReceiveAmount,
                                                        touched: touched.computedReceiveAmount,
                                                        setAmount: (value: string) => {
                                                            if (isNumberInputValid(value) && !value.match(/^\.$/)) {
                                                                const decimalIndex = value.indexOf('.');
                                                                if (
                                                                    decimalIndex !== -1 &&
                                                                    value.length - decimalIndex - 1 > values.offerToken.decimals
                                                                ) {
                                                                    const limitedValue = value.slice(
                                                                        0,
                                                                        decimalIndex + values.offerToken.decimals + 1,
                                                                    );
                                                                    changeReceiveAmount(limitedValue);
                                                                } else {
                                                                    changeReceiveAmount(value);
                                                                }
                                                            }
                                                        },
                                                        setToken: apply(setFieldValue, 'receiveToken'),
                                                        amount: values.computedReceiveAmount,
                                                        token: values.receiveToken,
                                                        setFieldTouched,
                                                        label: 'Receive',
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        {values.offerToken &&
                                            values.receiveToken &&
                                            values.offerAmount !== '' &&
                                            values.computedReceiveAmount !== '' && (
                                                <HStack w="100%" pb="1">
                                                    <Text fontWeight={'bold'}>
                                                        1 {values.offerToken.symbol} = {+values.computedReceiveAmount / +values.offerAmount}{' '}
                                                        {values.receiveToken.symbol}
                                                    </Text>
                                                    <Spacer />
                                                </HStack>
                                            )}
                                        {pastSwapsToSender && (
                                            <PastSwapsTable swaps={pastSwapsToSender} readyToTransact={readyToTransact} />
                                        )}

                                        <Field name="emailAddress">
                                            {({ field }: FieldProps) => (
                                                <EmailField
                                                    {...{
                                                        field,
                                                        isDisabled: transactionPending,
                                                        error: errors.emailAddress,
                                                        touched: touched.emailAddress,
                                                        label: 'Recipient Email',
                                                        required: false,
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </VStack>
                                </ModalBody>
                                <Box mt={4}>
                                    <Divider width="calc(100%)" sx={{ height: '0.5px' }} />
                                </Box>
                                <ModalFooter>
                                    <Stack w="100%">
                                        <HStack w="100%" spacing={4}>
                                            <WhiteButton onClick={onClose} isDisabled={isLoading} h="12" w="50%">
                                                Cancel
                                            </WhiteButton>
                                            <>
                                                <OrangeButton
                                                    isLoading={isLoading}
                                                    isDisabled={
                                                        isDisabled ||
                                                        isLoading ||
                                                        Object.keys(errors).length > 0 ||
                                                        insufficientFunds ||
                                                        !dirty
                                                    }
                                                    type="submit"
                                                    w="50%"
                                                >
                                                    {insufficientFunds
                                                        ? 'Insufficient funds'
                                                        : isApproved || safeInfo
                                                        ? 'Send Offer'
                                                        : 'Send Offer (Approve Token)'}
                                                </OrangeButton>
                                            </>
                                        </HStack>
                                        {wrongNetworkLabel}
                                    </Stack>
                                </ModalFooter>
                            </Form>
                        );
                    }}
                </Formik>

                <CloseModalButton onClose={onClose} />
            </ModalContent>
        </Modal>
    );
};

import {
    Flex,
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { QRCode } from 'react-qrcode-logo';
import BullaIcon from 'url:../../../assets/logo_orange.svg';
import { useIsMobile } from '../../../hooks/useIsMobile';
import CopyElement from '../../base/copy-element';
import { OrangeButton, WhiteButton } from '../../inputs/buttons';
import { CloseModalButton } from '../common';

const QRCodeModal = ({ headerText, url, onClose }: { onClose: VoidFunction; headerText: string; url: string }) => {
    const isMobile = useIsMobile();

    return (
        <Modal isCentered isOpen={true} onClose={onClose} closeOnEsc={false} size={isMobile ? '3xl' : 'xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Heading color="heading" size={'lg'} pr="10">
                        {headerText}
                    </Heading>
                    <CloseModalButton onClose={onClose} color="black" />
                </ModalHeader>
                <ModalBody>
                    <Stack alignItems="center" p="10">
                        <QRCode
                            logoWidth={100}
                            size={340}
                            quietZone={5}
                            value={url}
                            logoImage={BullaIcon}
                            removeQrCodeBehindLogo={true}
                            qrStyle="dots"
                        />
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

const QRCodeModalNew = ({ url, onClose, sharedDescription }: { onClose: VoidFunction; url: string; sharedDescription?: string }) => {
    const isMobile = useIsMobile();

    return (
        <Modal isCentered isOpen={true} onClose={onClose} closeOnEsc={false} size={isMobile ? '3xl' : 'xl'}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Heading color="heading" size={'lg'} pr="10">
                        Pay with QR
                    </Heading>
                    <Text color="gray.600" size={'sm'}>
                        Share the QR code with who you want to pay.{' '}
                    </Text>
                    <CloseModalButton onClose={onClose} color="black" />
                </ModalHeader>
                <ModalBody>
                    <VStack alignItems="center" p="10" pt={2}>
                        <QRCode
                            logoWidth={60}
                            size={240}
                            quietZone={3}
                            value={url}
                            logoImage={BullaIcon}
                            removeQrCodeBehindLogo={true}
                            qrStyle="dots"
                        />

                        {sharedDescription && (
                            <Text mt={2} textAlign="center" color="gray.600">
                                {sharedDescription}
                            </Text>
                        )}

                        <Flex direction="column" w="100%" gap={4} mt={4}>
                            <CopyElement valueToCopy={url}>
                                <WhiteButton w="100%">Share</WhiteButton>
                            </CopyElement>

                            <OrangeButton w="100%" onClick={onClose}>
                                Done
                            </OrangeButton>
                        </Flex>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export const QRViewerModal = ({
    triggerElement,
    url,
    headerText,
}: {
    headerText: string;
    url: string;
    triggerElement: (onOpen: VoidFunction) => React.ReactNode;
}) => {
    const { onClose, isOpen, onOpen } = useDisclosure();

    return (
        <>
            {triggerElement(onOpen)}
            {isOpen && <QRCodeModal {...{ url, headerText, onClose }} />}
        </>
    );
};

export const QRViewerModalNew = ({
    triggerElement,
    url,
    sharedDescription,
}: {
    url: string;
    sharedDescription?: string;
    triggerElement: (onOpen: VoidFunction) => React.ReactNode;
}) => {
    const { onClose, isOpen, onOpen } = useDisclosure();

    return (
        <>
            {triggerElement(onOpen)}
            {isOpen && <QRCodeModalNew {...{ url, onClose, sharedDescription }} />}
        </>
    );
};

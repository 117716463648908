import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Heading, HStack, Tab, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React from 'react';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { MaxWidthWrapper, PageLayoutProvider } from '../../components/layout/page-layout';
import { AuthenticatedCompanyDetails } from './company-details/company-details-page';
import { SubscriptionsPage } from './subscriptions/subscriptions-page';

export const TABS = ['company-details', 'subscriptions'] as const;
export type TAB = typeof TABS[number];

export const [DEFAULT_TAB] = TABS;

export const tabURLToLabel: Record<string, string | undefined> = {
    'company-details': 'Company Details',
    'my-links': 'My Links',
    subscriptions: 'Subscriptions',
};

export const StyledTab = ({ children, ...props }: { children: React.ReactNode }) => (
    <Tab _selected={{ color: 'brand.bulla_blue', borderColor: 'brand.bulla_blue', whiteSpace: 'nowrap' }} whiteSpace="nowrap" {...props}>
        {children}
    </Tab>
);

const Settings = () => {
    const navigate = useNavigate();
    const { tab } = useParams<{ tab: TAB }>();

    const currentRoute = tab ?? DEFAULT_TAB;
    const changeTab = (tab: TAB) => navigate({ pathname: `/settings/${tab}` });

    return (
        <PageLayoutProvider>
            <Flex p={['8', '8', '12']} bg={'white'} direction="column" flex="1">
                <MaxWidthWrapper>
                    <Breadcrumb textColor={'#707EAE'}>
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={() => navigate('/')}>Home</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <BreadcrumbLink onClick={() => changeTab(DEFAULT_TAB)}>Settings</BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>
                            <BreadcrumbLink>{tabURLToLabel[currentRoute]}</BreadcrumbLink>
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <Box h="2" />
                    <Heading color="heading" flex={1}>
                        Settings
                    </Heading>
                    <Box h="8" />
                    <Tabs index={TABS.indexOf(currentRoute)} onChange={i => changeTab(TABS[i])}>
                        <HStack>
                            {TABS.map(tab => (
                                <StyledTab key={tab}>{tabURLToLabel[tab]}</StyledTab>
                            ))}
                        </HStack>
                        <Box h="6" />
                        <TabPanels>
                            <TabPanel>
                                <AuthenticatedCompanyDetails />
                            </TabPanel>
                            <TabPanel>
                                <SubscriptionsPage />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </MaxWidthWrapper>
            </Flex>
        </PageLayoutProvider>
    );
};

export const SettingsPage = () => (
    <Routes>
        <Route path=":tab" element={<Settings />}></Route>
        <Route path="*" element={<Navigate replace to={`/settings/${DEFAULT_TAB}`} />} />
    </Routes>
);

import { BigNumber } from 'ethers';
import { OffchainInvoiceInfo } from '../hooks/useOffchainInvoiceFactory';
import { Replace } from '../tools/types';
import { ClaimEvent } from './data-transforms';
import { FinancingTerms, TermLength } from './dto/bulla-finance-dto';
import { FrendLendEvent, InstantPayContractEvent } from './dto/mapped-event-types';
import { EthAddress } from './ethereum';
import { ChainId, TokenInfo } from './networks';
import { HybridInvoiceData } from '../hooks/useCustodianApi';

export const ClaimStatuses = ['Pending', 'Paid', 'Rejected', 'Rescinded', 'Repaying', 'Factored', 'Unfactored', 'Impaired'] as const;
export type ClaimStatus = typeof ClaimStatuses[number];
export type ClaimType = 'Invoice' | 'Payment';
export type BullaItemType = BullaItemInfo['__type'];
export const TAG_SEPARATOR = '%>%';
export type VendorFinancingOrigination = { kind: 'vendor-financing'; originatingClaimId: string };
export type FinancingOrigination = VendorFinancingOrigination | { kind: 'frendlend'; originatingLoanId: string };

export type FinancingOffered = { kind: 'offered'; terms: FinancingTerms };
export type FinancingAccepted = { kind: 'accepted'; terms: FinancingTerms; origination: FinancingOrigination };
export type FinanciableClaimState = FinancingOffered | FinancingAccepted;

export type FinancingState = FinanciableClaimState | { kind: 'no-financing' };

export type AccountTagInfo = {
    name: string;
    items: BullaItemInfo[];
};

export type InstantPaymentTagInfo = {
    id: string; // keccak256(bytes(transactionHash) + logIndex)
    updatedBy: EthAddress;
    tag: string;
    created: Date;
};

export type BaseBullaItemInfo = {
    chainId: ChainId;
    id: string; //ClaimInfo = tokenId, InstantPaymentInfo = keccak256(bytes(transactionHash) + logIndex)), PendingInstantPaymentInfo = keccak256(...item + itemIndex)), swap = orderId
    creditor: EthAddress;
    debtor: EthAddress;
    paidAmount: BigNumber;
    tokenInfo: TokenInfo;
    description: string;
    tags: string[];
    txHash: string;
    created: Date;
};

export type ClaimInfo = BaseBullaItemInfo & {
    __type: 'Claim';
    claimType: ClaimType;
    origin: EthAddress;
    claimAmount: BigNumber;
    originalCreditor: EthAddress;
    isTransferred: boolean;
    dueBy: Date;
    claimStatus: ClaimStatus;
    logs: ClaimEvent[];
    financingState: FinancingState;
    ipfsHash: string;
    lastPaymentDate: Date;
    notes: string;
    hybridInvoiceData?: HybridInvoiceData;
    isLoadingDescription?: boolean;
};

export type FrendLendOffer = {
    __type: 'FrendLend';
    chainId: ChainId;
    loanId: string; // loanId
    creditor: EthAddress;
    debtor: EthAddress;
    status: 'Offered' | 'Accepted' | 'Rejected';
    interestBPS: number;
    termLength: TermLength;
    loanAmount: BigNumber;
    description: string;
    tokenInfo: TokenInfo;
    ipfsHash: string;
    offerDate: Date;
    logs: FrendLendEvent[];
    offerTxHash: string;
};

export type AcceptedFrendLend = FrendLendOffer & {
    status: 'Accepted';
    acceptedTxHash: string;
    acceptedDate: Date;
    tags: string[];
    paidAmount: BigNumber;
    lastPaymentDate: Date;
    claimId: string;
    claimStatus: ClaimStatus;
    claimAmount: BigNumber;
    dueBy: Date;
    isTransferred: boolean;
    origin: EthAddress;
};

export type FrendLendInfo = FrendLendOffer | AcceptedFrendLend;

export type FinanciableClaimInfo = Replace<ClaimInfo, 'financingState', FinanciableClaimState>;
export type FinancingOfferedClaimInfo = Replace<ClaimInfo, 'financingState', FinancingOffered>;
export type FinancedClaimInfo = Replace<ClaimInfo, 'financingState', FinancingAccepted>;
export type VendorFinancingAcceptedState = Replace<FinancingAccepted, 'origination', VendorFinancingOrigination>;
export type VendorFinancingAcceptedClaimInfo = Replace<ClaimInfo, 'financingState', VendorFinancingAcceptedState>;

export type InstantPaymentInfo = BaseBullaItemInfo & {
    __type: 'InstantPayment';
    logs: InstantPayContractEvent[];
    ipfsHash: string;
    notes: string;
};

export type PendingInstantPaymentInfo = Omit<InstantPaymentInfo, 'txHash' | '__type'> & {
    __type: 'PendingInstantPayment';
};
export type ImportedExternalTransactionInfo = BaseBullaItemInfo & {
    __type: 'ImportedExternalTransaction';
    notes: string;
};
export type BullaItemInfo =
    | ClaimInfo
    | InstantPaymentInfo
    | ImportedExternalTransactionInfo
    | OffchainInvoiceInfo
    | PoolEventInfo
    | BullaSwapInfoWithUSDMark;

export type BullaLineItems = BullaItemInfo | PendingInstantPaymentInfo;

export const lastPaymentDate = (item: BullaItemInfo): Date => {
    if (item.__type === 'Claim') {
        return item.lastPaymentDate;
    }
    if (item.__type == 'OffchainInvoiceInfo') {
        return item.status.kind == 'Paid' ? lastPaymentDate(item.status.instantPayment) : item.created;
    }
    return item.created;
};

export type PoolDepositInfo = BaseBullaItemInfo & {
    __type: 'PoolDeposit';
    depositor: EthAddress;
    shares: BigNumber;
    poolAddress: EthAddress;
    poolName: string;
    poolTokenInfo: TokenInfo;
    priceAfterTransaction: BigNumber;
    ipfsHash?: string;
    notes: string;
};

export type PoolRedemptionInfo = BaseBullaItemInfo & {
    __type: 'PoolRedemption';
    redeemer: EthAddress;
    shares: BigNumber;
    poolAddress: EthAddress;
    poolName: string;
    poolTokenInfo: TokenInfo;
    priceAfterTransaction: BigNumber;
    ipfsHash?: string;
    notes: string;
};

export type BullaSwapInfo = BaseBullaItemInfo & {
    __type: 'Swap';
    orderId: BigNumber;
    expiry: Date;
    signerWallet: EthAddress;
    signerToken: TokenInfo;
    signerAmount: BigNumber;
    senderWallet: EthAddress;
    senderToken: TokenInfo;
    senderAmount: BigNumber;
    status: 'Pending' | 'Executed' | 'Canceled';
    notes: string;
    executed?: Date;
};

export type BullaSwapInfoWithUSDMark = BullaSwapInfo & {
    USDMarkSignerToken: 'not-found' | 'fetching' | number;
    USDMarkSenderToken: 'not-found' | 'fetching' | number;
};

export type PoolEventInfo = PoolDepositInfo | PoolRedemptionInfo;

export type BullaEventSourcedItemInfo = ClaimInfo | InstantPaymentInfo | FrendLendInfo | OffchainInvoiceInfo;

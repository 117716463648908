import { Box, Flex, Heading } from '@chakra-ui/react';
import React from 'react';
import { FactoringPoolCard } from '../../components/factoring/factoring-pool-card';
import { MaxWidthWrapper, PageLayoutProvider } from '../../components/layout/page-layout';
import { NetworkConfig } from '../../data-lib/networks';
import { useFactoringAndDepositPermissions } from '../../hooks/useFactoringAndDepositPermissions';

export function hasFactoringConfig(
    network: NetworkConfig,
): network is NetworkConfig & { factoringConfig: NonNullable<NetworkConfig['factoringConfig']> } {
    return network.factoringConfig !== undefined;
}

export const FactoringPoolsList = () => {
    const { poolsWithPermissions } = useFactoringAndDepositPermissions();

    return (
        <PageLayoutProvider>
            <Flex p={{ sm: '8', md: '12' }} bg={'gray.25'} direction="column" flex="1">
                <MaxWidthWrapper>
                    <Flex justifyContent="space-between" alignItems="center">
                        <Heading color="heading" flex={1}>
                            Bulla Finance Pool List
                        </Heading>
                    </Flex>
                    <Box h="8" />
                    {poolsWithPermissions.map(pool => {
                        return (
                            <FactoringPoolCard
                                key={pool.chainId}
                                factoringConfig={pool.factoringConfig}
                                hasDepositPermissions={pool.hasDepositPermissions}
                            />
                        );
                    })}
                </MaxWidthWrapper>
            </Flex>
        </PageLayoutProvider>
    );
};

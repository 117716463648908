import {
    Box,
    Container,
    Flex,
    Grid,
    GridItem,
    Input,
    Spacer,
    Text,
    VStack,
    Image,
    Collapse,
    useDisclosure,
    HStack,
    Tooltip,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { ContinueButton, CreateSmallWhiteButton, WhiteButton } from '../../inputs/buttons';
import { ShadowBox } from '../../layout/cards';
import {
    AddPaymentDetailsTypeState,
    InvoiceDetailsNew,
    NewBatchReviewState,
    NewBatchWizardState,
    PaymentDetailErrors,
    PaymentDetailsNew,
    SavingStatus,
    SelectWalletsTypeState,
} from './new-batch-wizard-modal';
import { SavingIndicator, StepCard } from './select-claim-types-card';
import { ClaimAmountField, DueByField, AttachmentField, BullaItemAttachment } from '../create-claim-modal/create-claim-inputs';
import { DefaultBatchClaimValues } from './batch-state';
import OpenDetailsIcon from 'url:../../../assets/open-details.svg';
import { Trash, Warning, CheckCircle } from 'phosphor-react';
import { TokenDto } from '../../../data-lib/networks';
import { DefaultValuesModal } from './default-values-modal';
import * as Yup from 'yup';
import { addressEquality, isValidAddress } from '../../../data-lib/ethereum';
import { claimAmountValidationSchema } from '../create-claim-modal/create-claim-inputs';
import { BatchFooter } from './shared-components';
import { filterBatchSearchBar } from './select-wallets-card';
import { getErrorStrings } from './batch-rows';
import { useActingWalletAddress } from '../../../hooks/useWalletAddress';
import { AccountTagField } from '../create-claim-modal/create-claim-inputs';

const AddDefaultValuesButton = CreateSmallWhiteButton('Add default values');
export const OpenAllDetailsButton = CreateSmallWhiteButton('Open all details');
export const CloseAllDetailsButton = CreateSmallWhiteButton('Close all details');

interface ClosedAddPaymentDetailsCardProps {
    setWizardState: React.Dispatch<React.SetStateAction<NewBatchWizardState>>;
    wizardState: NewBatchWizardState;
}

export const ClosedAddPaymentDetailsCard = ({ setWizardState, wizardState }: ClosedAddPaymentDetailsCardProps) => {
    const displayEditButton = wizardState.kind !== 'SelectClaimType' && wizardState.kind !== 'SelectWallets';

    return (
        <StepCard
            stepNumber={3}
            title="Add Payment Details to Selected Wallets"
            rightElement={
                displayEditButton && (
                    <WhiteButton
                        onClick={() =>
                            setWizardState(previousState => ({
                                ...(previousState as Omit<AddPaymentDetailsTypeState, 'kind'>),
                                kind: 'AddPaymentDetails',
                            }))
                        }
                    >
                        Edit
                    </WhiteButton>
                )
            }
        />
    );
};

type PaymentItemProps = {
    detail: PaymentDetailsNew | InvoiceDetailsNew;
    claimType: 'Invoice' | 'Payment';
    index: number;
    onUpdate: (index: number, field: string, value: string | Date | BullaItemAttachment | TokenDto | string[]) => void;
    onDelete: (index: number) => void;
    initialDefaultValuesWithToken: DefaultBatchClaimValues;
    cardRef: React.MutableRefObject<null>;
    columns: string;
    isOpen: boolean;
    onToggle: () => void;
};

const isInvoiceDetail = (detail: PaymentDetailsNew | InvoiceDetailsNew, claimType: 'Invoice' | 'Payment'): detail is InvoiceDetailsNew => {
    return claimType === 'Invoice';
};

const PaymentItem: React.FC<PaymentItemProps> = ({
    detail,
    claimType,
    index,
    onUpdate,
    onDelete,
    initialDefaultValuesWithToken,
    cardRef,
    columns,
    isOpen,
    onToggle,
}) => {
    const handleAmountChange = (value: string) => {
        onUpdate(index, 'amount', value);
    };

    const handleTokenChange = (token: TokenDto) => {
        onUpdate(index, 'token', token);
    };

    const handleUpdateAttachment = (attachment: BullaItemAttachment) => {
        onUpdate(index, 'attachment', attachment);
    };

    const handleDelete = () => {
        onDelete(index);
    };

    const errorStrings = getErrorStrings(detail.errors);

    const drawerColumns = '2fr 2fr 2fr 2fr 2fr';
    const drawerLabelProps = { fontWeight: 500, fontSize: '12px', color: '#2D3748' };
    const border = '1px solid #E2E8F0';

    return (
        <Box>
            <Grid templateColumns={columns} gap={4} w="100%" p={4} borderBottom="1px solid" borderColor="gray.200">
                <GridItem display="flex" alignItems="center">
                    {errorStrings.length === 0 ? (
                        <CheckCircle size={20} color="green" style={{ marginRight: '8px' }} />
                    ) : (
                        <Tooltip
                            placement="left"
                            label={
                                <>
                                    {errorStrings.map(x => (
                                        <Text key={x}>{x}</Text>
                                    ))}
                                </>
                            }
                        >
                            <Box>
                                <Warning size={20} color="red" style={{ marginRight: '8px' }} />
                            </Box>
                        </Tooltip>
                    )}
                    <Input value={detail.name || ''} placeholder="Recipient name" onChange={e => onUpdate(index, 'name', e.target.value)} />
                </GridItem>
                <GridItem>
                    <Input
                        value={detail.walletAddress || ''}
                        placeholder="Wallet address"
                        onChange={e => onUpdate(index, 'walletAddress', e.target.value)}
                    />
                </GridItem>
                <GridItem>
                    <ClaimAmountField
                        claimType="Payment"
                        field={{
                            name: `amount-${index}`,
                            onChange: (e: React.ChangeEvent<any>) => handleAmountChange(e.target.value),
                            value: detail.amount?.toString() || '',
                        }}
                        isDisabled={false}
                        error={undefined}
                        touched={undefined}
                        setAmount={handleAmountChange}
                        setToken={handleTokenChange}
                        amount={detail.amount?.toString() || ''}
                        token={detail.token || initialDefaultValuesWithToken.defaultToken}
                        required={true}
                        disableErrorLabels
                        includeNativeToken={claimType === 'Payment'}
                        disableBalanceLabels
                        tokenMenuRef={cardRef}
                        disableMaxButton
                    />
                </GridItem>
                {claimType === 'Invoice' && isInvoiceDetail(detail, claimType) && (
                    <GridItem w="fit-content">
                        <DueByField
                            field={{
                                name: `dueDate-${index}`,
                                onChange: (e: React.ChangeEvent<any>) => onUpdate(index, 'dueDate', e.target.value),
                                value: detail.dueDate || initialDefaultValuesWithToken.defaultDueDate,
                            }}
                            isDisabled={false}
                            error={undefined}
                            touched={undefined}
                            setDueBy={(date: Date) => onUpdate(index, 'dueDate', date)}
                            required={true}
                            hideDateDifference
                        />
                    </GridItem>
                )}
                <GridItem display="flex" justifyContent="center" alignItems="center" w="100%" gap={2}>
                    <Image src={OpenDetailsIcon} cursor="pointer" onClick={onToggle} />
                    <Trash size={20} color="#A4A7AE" cursor="pointer" onClick={handleDelete} />
                </GridItem>
            </Grid>

            <Collapse in={isOpen}>
                <Box bgColor="#EDF2F7" borderBottom={border} p="4">
                    <Grid templateColumns={drawerColumns} gap={4} mb={4}>
                        <GridItem>
                            <Text {...drawerLabelProps}>Description</Text>
                        </GridItem>
                        <GridItem>
                            <Text {...drawerLabelProps}>Categories (optional)</Text>
                        </GridItem>
                        <GridItem>
                            <Text {...drawerLabelProps}>Email (optional)</Text>
                        </GridItem>
                        <GridItem>
                            <Text {...drawerLabelProps}>Confirmation Email (optional)</Text>
                        </GridItem>
                        <GridItem>
                            <Text {...drawerLabelProps}>File Upload (optional)</Text>
                        </GridItem>
                    </Grid>

                    <Grid templateColumns={drawerColumns} gap={4}>
                        <GridItem>
                            <Input
                                placeholder="Description"
                                value={detail.description || ''}
                                onChange={e => onUpdate(index, 'description', e.target.value)}
                                bg="white"
                            />
                        </GridItem>
                        <GridItem>
                            <AccountTagField
                                field={{
                                    name: `groups-${index}`,
                                    onChange: (e: React.ChangeEvent<any>) => onUpdate(index, 'groups', e.target.value),
                                    value: detail.tags || detail.groups || [],
                                }}
                                isDisabled={false}
                                error={undefined}
                                touched={undefined}
                                setTags={(tags: string[]) => onUpdate(index, 'groups', tags)}
                                setStatus={() => {}}
                                creatingExpense={false}
                            />
                        </GridItem>
                        <GridItem>
                            <Input
                                placeholder="Email address"
                                value={detail.emailAddress || ''}
                                onChange={e => onUpdate(index, 'emailAddress', e.target.value)}
                                bg="white"
                            />
                        </GridItem>
                        <GridItem>
                            <Input
                                placeholder="Confirmation email"
                                value={detail.confirmationEmailAddress || ''}
                                onChange={e => onUpdate(index, 'confirmationEmailAddress', e.target.value)}
                                bg="white"
                            />
                        </GridItem>
                        <GridItem>
                            <AttachmentField
                                field={{
                                    name: `attachment-${index}`,
                                    onChange: (e: React.ChangeEvent<any>) => onUpdate(index, 'attachment', e.target.value),
                                    value: detail.attachment || '',
                                }}
                                setAttachment={handleUpdateAttachment}
                                attachment={detail.attachment}
                                amount={detail.amount?.toString() || ''}
                                description={detail.description || ''}
                                recipient={detail.walletAddress || ''}
                                tokenSymbol={detail.token?.symbol || ''}
                                type={claimType}
                                uploadToIpfsOnAccept
                                transactionPending={false}
                            />
                        </GridItem>
                    </Grid>
                </Box>
            </Collapse>
        </Box>
    );
};

type OpenedAddPaymentDetailsCardProps = {
    state: AddPaymentDetailsTypeState;
    setWizardState: React.Dispatch<React.SetStateAction<NewBatchWizardState>>;
    onCancel: () => void;
    initialDefaultValuesWithToken: DefaultBatchClaimValues;
    savingStatus: SavingStatus;
};

const errorMessageSchema = (userAddress: string) =>
    Yup.object().shape({
        amount: claimAmountValidationSchema,
        walletAddress: Yup.string()
            .required('Wallet address required')
            .test('is-valid-address', 'Invalid wallet address', value => isValidAddress(value || ''))
            .test('is-own-wallet', 'Cannot use own wallet as recipient', value => !addressEquality(userAddress, value || '')),
        description: Yup.string(),
        emailAddress: Yup.string().email('Invalid email address'),
        confirmationEmailAddress: Yup.string().email('Invalid email address'),
    });

const validateDetails = (detail: PaymentDetailsNew | InvoiceDetailsNew, userAddress: string): PaymentDetailErrors | undefined => {
    try {
        errorMessageSchema(userAddress).validateSync(detail, { abortEarly: false });
        return undefined;
    } catch (error: any) {
        return error.inner?.reduce((errors: PaymentDetailErrors, err: any) => {
            const path = err.path as keyof PaymentDetailErrors;
            errors[path] = err.message;
            return errors;
        }, {});
    }
};

export const OpenedAddPaymentDetailsCard: React.FC<OpenedAddPaymentDetailsCardProps> = ({
    state,
    setWizardState,
    onCancel,
    initialDefaultValuesWithToken,
    savingStatus,
}) => {
    const cardRef: React.MutableRefObject<null> = useRef(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [openStates, setOpenStates] = useState(state.claimDetails.map(() => false));
    const [searchQuery, setSearchQuery] = useState('');
    const userAddress = useActingWalletAddress();

    const handleUpdatePaymentDetail = (index: number, field: string, value: string | Date | BullaItemAttachment | TokenDto | string[]) => {
        setWizardState(prev => {
            if (!('claimDetails' in prev)) return prev;

            const updatedDetails = prev.claimDetails.map((detail, i) => {
                if (i !== index) return detail;

                const updatedDetail = {
                    ...detail,
                    [field]: value,
                };

                const errors = validateDetails(updatedDetail, userAddress);
                return {
                    ...updatedDetail,
                    errors,
                };
            });

            return {
                ...prev,
                claimDetails: updatedDetails,
            };
        });
    };

    const handleDeletePaymentDetail = (index: number) => {
        setWizardState(prev => {
            if (!('claimDetails' in prev)) return prev;
            const updatedDetails = prev.claimDetails.filter((_, i) => i !== index);

            // If we've deleted the last item, go back to the SelectWallets step
            if (updatedDetails.length === 0) {
                setWizardState(
                    prev =>
                        ({
                            ...prev,
                            kind: 'SelectWallets',
                            claimDetails: [],
                        } as SelectWalletsTypeState),
                );
            }

            return {
                ...prev,
                claimDetails: updatedDetails,
            };
        });

        setOpenStates(prev => prev.filter((_, i) => i !== index));
    };

    const handleContinue = () => {
        setWizardState(
            prev =>
                ({
                    ...prev,
                    kind: 'NewBatchReview',
                    claimDetails: (prev as NewBatchReviewState).claimDetails,
                } as NewBatchReviewState),
        );
    };

    const isFormValid = () => {
        if (state.claimDetails?.length === 0) return false;
        return state.claimDetails.every(detail => {
            return !detail.errors && detail.amount && parseFloat(detail.amount.toString()) > 0;
        });
    };

    const columns: string = state.claimType === 'Invoice' ? '5fr 3fr 3fr 3fr 40px' : '5fr 3fr 3fr 40px';

    const handleDefaultValuesSubmit = (defaultValues: DefaultBatchClaimValues) => {
        const updatedDetails = state.claimDetails.map(detail => ({
            ...detail,
            description: defaultValues.defaultDescription || detail.description,
            dueDate: defaultValues.defaultDueDate || state.claimType === 'Invoice' ? (detail as InvoiceDetailsNew).dueDate : undefined,
            groups: defaultValues.defaultAccountTags || detail.groups,
            amount: defaultValues.defaultAmount || detail.amount,
            token: defaultValues.defaultToken || detail.token,
            attachment: defaultValues.generatePDF ? 'generate' : detail.attachment,
        }));

        setWizardState(prev => ({
            ...prev,
            claimDetails: updatedDetails,
        }));
    };

    return (
        <Container maxW="100%" p="0">
            <VStack spacing={6} align="stretch">
                <StepCard stepNumber={3} title="Add Payment Details to Selected Wallets" isActive={true}>
                    <Flex justifyContent="space-between" mb={4}>
                        <HStack gap={2} w="100%">
                            <Input
                                placeholder="Search by name, wallet, email, or group..."
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                maxW="500px"
                            />
                            <AddDefaultValuesButton onClick={onOpen} />
                        </HStack>
                        {openStates.every(state => state) ? (
                            <CloseAllDetailsButton onClick={() => setOpenStates(prev => prev.map(() => false))} />
                        ) : (
                            <OpenAllDetailsButton onClick={() => setOpenStates(prev => prev.map(() => true))} />
                        )}
                    </Flex>
                    <Box ref={cardRef} w="100%">
                        <ShadowBox p="0" mb="4">
                            <Flex p="4" borderBottom="1px solid" borderColor="gray.200">
                                <Grid templateColumns={columns} gap={4} w="100%">
                                    <GridItem>
                                        <Text fontWeight="bold">Name (Optional)</Text>
                                    </GridItem>
                                    <GridItem>
                                        <Text fontWeight="bold">Wallet Address</Text>
                                    </GridItem>
                                    <GridItem>
                                        <Text fontWeight="bold">Amount</Text>
                                    </GridItem>
                                    {state.claimType === 'Invoice' && (
                                        <GridItem>
                                            <Text fontWeight="bold">Due Date</Text>
                                        </GridItem>
                                    )}
                                    <GridItem></GridItem>
                                </Grid>
                            </Flex>

                            <VStack spacing={0} align="stretch" maxH="400px" overflowY="auto" ref={cardRef}>
                                {filterBatchSearchBar(state.claimDetails, searchQuery).map((detail, index) => (
                                    <PaymentItem
                                        claimType={state.claimType}
                                        key={index}
                                        index={index}
                                        detail={detail}
                                        onUpdate={handleUpdatePaymentDetail}
                                        onDelete={handleDeletePaymentDetail}
                                        initialDefaultValuesWithToken={initialDefaultValuesWithToken}
                                        cardRef={cardRef}
                                        columns={columns}
                                        isOpen={openStates[index]}
                                        onToggle={() => {
                                            setOpenStates(prev => prev.map((state, i) => (i === index ? !state : state)));
                                        }}
                                    />
                                ))}
                            </VStack>
                        </ShadowBox>
                    </Box>
                    <DefaultValuesModal
                        isOpen={isOpen}
                        onClose={onClose}
                        onSubmit={handleDefaultValuesSubmit}
                        initialValues={initialDefaultValuesWithToken}
                        claimType={state.claimType}
                    />
                </StepCard>
            </VStack>

            <BatchFooter>
                <Spacer />
                <SavingIndicator savingStatus={savingStatus} />
                <WhiteButton onClick={onCancel}>Cancel</WhiteButton>
                <ContinueButton isDisabled={!isFormValid()} onClick={handleContinue} />
            </BatchFooter>
        </Container>
    );
};

import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import { Box, Flex, Heading, HStack, Input, InputGroup, InputLeftElement, Link, Skeleton, Spacer, Text } from '@chakra-ui/react';
import { ExportToCsv } from 'export-to-csv';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Contact, ContactsContext, isContactsReady, useExtendedContacts } from '../../../hooks/useExtendedContacts';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useSearchFilter } from '../../../hooks/useSearchFilter';
import { useSelection } from '../../../hooks/useSelection';
import { useGnosisSafe } from '../../../state/gnosis-state';
import { enableNewInvoiceCreation } from '../../../tools/featureFlags';
import { AddressLabel, EmailAddressLabel } from '../../base/address-label';
import { OneTagPillOrMore } from '../../inputs/account-tag-input';
import { ActionDropdownButton, CreateOrangeButton, CreateSecondaryButton, TextButton } from '../../inputs/buttons';
import { ColumnDefinition, ListItemProps, ListViewCard, SortDirection } from '../../layout/cards';
import { MaxWidthWrapper, PageLayoutProvider, SummaryPanel } from '../../layout/page-layout';
import { CreateClaimModal } from '../../modals/create-claim-modal/create-claim-modal';
import { CreatePaymentModal } from '../../modals/create-claim-modal/create-payment-modal';
import { CreateContactModal } from '../../modals/create-contact-modal';
import { DeleteContactModal } from '../../modals/delete-contacts-modal';
import { ImportContactsModal } from '../../modals/import-contacts-modal';
import { PopoverAction } from '../actionPopover';

const Header = ({ contactsReady, isMobile }: { contactsReady: boolean; isMobile: boolean }) => (
    <SummaryPanel>
        {!isMobile ? (
            <Heading color="heading">Contacts</Heading>
        ) : (
            <HStack justifyContent={'space-between'}>
                <Heading color="heading">Contacts</Heading>

                <CreateContactModal
                    triggerElement={onOpen => (
                        <AddContactsButton isDisabled={!contactsReady} onClick={onOpen} fontSize="12px" w="110px" h="30px" />
                    )}
                />
            </HStack>
        )}
    </SummaryPanel>
);

export const SearchBar = ({
    queryString,
    setQueryString,
    isMobile,
}: {
    queryString: string;
    setQueryString: (q: string) => void;
    isMobile?: boolean;
}) => (
    <InputGroup maxW={isMobile ? '100%' : 'fit-content'} maxH="fit-content" justifyContent="center" bg={'white'}>
        <InputLeftElement pointerEvents="none" h="100%" children={<SearchIcon color="scheme.icon_dark" transform="scaleX(-1)" />} />
        <Input
            key="search-input"
            value={queryString}
            onChange={e => setQueryString(e.target?.value ?? '')}
            placeholder="Search..."
            borderColor="rgba(138, 130, 122, 0.5)"
            minH="14"
            focusBorderColor="rgba(138, 130, 122, 0.5)"
        />
    </InputGroup>
);

const AddContactsButton = CreateOrangeButton('New Contact');
const ImportContactsButton = CreateSecondaryButton('Import');
const ExportContactsButton = CreateSecondaryButton('Export');

const TopBar = ({ children }: { children: React.ReactNode }) => (
    <Box py="8">
        <Flex>{children}</Flex>
    </Box>
);

const ContactsPage = ({ contactsReady, isMobile, children }: { contactsReady: boolean; isMobile: boolean; children: React.ReactNode }) => (
    <Box p="12" bg="scheme.accent_light" display="flex" flexDirection={'column'} flex="1">
        <Header contactsReady={contactsReady} isMobile={isMobile} />
        <MaxWidthWrapper display={'flex'} flexDirection="column" flex="1">
            {children}
        </MaxWidthWrapper>
    </Box>
);

export function emptyMessageForContactsContextState(contactsContext: ContactsContext) {
    const { inSafeApp } = useGnosisSafe();
    if (isContactsReady(contactsContext)) {
        return (
            <Box alignItems={'center'}>
                <Text textStyle="faint" p="1">
                    You have no contacts.
                </Text>
                <Text textStyle="faint" p="1">
                    Adding contacts makes it easier when creating invoices and remittances.
                </Text>
                <HStack width="fit-content" p="2" m="auto">
                    <CreateContactModal triggerElement={onOpen => <TextButton onClick={onOpen}>New contact</TextButton>} />
                    <Spacer />
                    <ImportContactsModal triggerElement={onOpen => <TextButton onClick={onOpen}>Import from CSV</TextButton>} />
                </HStack>
            </Box>
        );
    } else if (contactsContext == 'unauthorized') {
        return (
            <Box alignItems={'center'}>
                <Text textStyle="faint" p="1">
                    {`Not authorized to access contacts.${inSafeApp ? ' Only signers can view/add/edit contacts.' : ''}`}
                </Text>
            </Box>
        );
    }

    return <Box />;
}

export const getDisplayedListItemsForContacts = (contacts: Contact[], withEditContact = true) => {
    return contacts.map((contact): ListItemProps => {
        const { name, walletAddress, emailAddress, groups } = contact;
        const actions = [
            <CreateContactModal
                editState={contact}
                triggerElement={onOpen => <PopoverAction onClick={onOpen}>Edit contact</PopoverAction>}
            />,
            <DeleteContactModal
                triggerElement={onOpen => <PopoverAction onClick={onOpen}>Delete contact</PopoverAction>}
                selectedContacts={[contact]}
            />,
            <PopoverAction onClick={() => null}>
                <Link
                    as={RouterLink}
                    to={`/?recipientFilter=${walletAddress}`}
                    _hover={{ textDecoration: 'none' }}
                    fontSize="14px"
                    fontWeight={'500'}
                >
                    View Payments
                </Link>
            </PopoverAction>,
            <CreatePaymentModal
                defaults={{ recipient: `${walletAddress}`, emailAddress: `${emailAddress}` }}
                header={
                    <Text color="heading" fontWeight={'700'} fontSize="18px" noOfLines={1} alignSelf="center">
                        Pay {contact.name}
                    </Text>
                }
                triggerElement={onOpen => <PopoverAction onClick={onOpen}>Send Payment</PopoverAction>}
            />,
            enableNewInvoiceCreation ? (
                <PopoverAction onClick={() => null}>
                    <Link
                        as={RouterLink}
                        to={`/new-invoice?recipient=${walletAddress}`}
                        _hover={{ textDecoration: 'none' }}
                        fontSize="14px"
                        fontWeight={'500'}
                    >
                        Send Invoice
                    </Link>
                </PopoverAction>
            ) : (
                <CreateClaimModal
                    claimType="Invoice"
                    defaults={{ recipient: `${walletAddress}` }}
                    triggerElement={onOpen => <PopoverAction onClick={onOpen}>Send Invoice</PopoverAction>}
                />
            ),
        ];
        return {
            actions: withEditContact ? actions : undefined,
            columnValues: [
                <Text whiteSpace="nowrap">{name}</Text>,
                <AddressLabel>{walletAddress}</AddressLabel>,
                !!emailAddress ? <EmailAddressLabel>{emailAddress}</EmailAddressLabel> : '',
                <OneTagPillOrMore tags={groups ?? []} />,
            ],
            selectId: walletAddress,
        };
    });
};

const exportContactsToCSV = (contacts: Contact[]) => {
    const data = contacts.map(contact => ({
        Name: contact.name,
        'Wallet Address': contact.walletAddress,
        'Email Address': contact.emailAddress !== undefined ? contact.emailAddress : '',
        Groups: contact.groups !== undefined ? contact.groups.join(', ') : '',
    }));

    const options = {
        filename: 'Bulla_Contacts',
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
    };

    const exporter = new ExportToCsv(options);

    exporter.generateCsv(data);
};

enum SortableColumn {
    NAME = 'NAME',
    WALLET_ADDRESS = 'WALLET_ADDRESS',
    EMAIL_ADDRESS = 'EMAIL_ADDRESS',
    GROUPS = 'GROUPS',
}

type ContactsSortConfig =
    | {
          column: SortableColumn;
          direction: SortDirection;
      }
    | undefined;

export const Contacts = () => {
    const contactsContext = useExtendedContacts();
    const contactsReady = isContactsReady(contactsContext);

    const [filteredContacts, queryString, setQueryString] = useSearchFilter(
        contactsReady ? contactsContext.contacts : [],
        (item: Contact) => [item.name, item.walletAddress, item.emailAddress].filter((x): x is string => x !== undefined),
    );

    const showSkeleton = contactsContext == 'fetching';

    const isMobile = useIsMobile();

    const [sortConfig, setSortConfig] = useState<ContactsSortConfig>({ column: SortableColumn.NAME, direction: 'asc' });

    const sortedContacts = React.useMemo(() => {
        if (!sortConfig) {
            return filteredContacts;
        }

        return [...filteredContacts].sort((a, b) => {
            if (sortConfig.column === SortableColumn.NAME) {
                const aValue = a.name || '';
                const bValue = b.name || '';
                return sortConfig.direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }
            if (sortConfig.column === SortableColumn.WALLET_ADDRESS) {
                const aValue = a.walletAddress || '';
                const bValue = b.walletAddress || '';
                return sortConfig.direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }
            if (sortConfig.column === SortableColumn.EMAIL_ADDRESS) {
                const aValue = a.emailAddress || '';
                const bValue = b.emailAddress || '';
                return sortConfig.direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }
            if (sortConfig.column === SortableColumn.GROUPS) {
                const aValue = (a.groups || []).join(',');
                const bValue = (b.groups || []).join(',');
                return sortConfig.direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }
            return 0;
        });
    }, [filteredContacts, sortConfig]);

    const selectOptions = useSelection(sortedContacts.map(({ walletAddress }) => walletAddress));
    const selectedContacts = sortedContacts.filter(x => selectOptions.selected.includes(x.walletAddress));
    const someSelected = selectedContacts.length > 0;
    const buttonsReady = !contactsReady || !someSelected;

    const handleSort = (column: SortableColumn) => {
        setSortConfig(prev => ({
            column,
            direction: prev?.column === column ? (prev.direction === 'asc' ? 'desc' : 'asc') : 'asc',
        }));
    };

    const columnDefinitions: (ColumnDefinition | string)[] = [
        {
            label: 'Name',
            sortOptions: {
                direction: sortConfig?.column === SortableColumn.NAME ? sortConfig.direction : undefined,
                onSort: () => handleSort(SortableColumn.NAME),
            },
        },
        {
            label: 'Wallet',
            sortOptions: {
                direction: sortConfig?.column === SortableColumn.WALLET_ADDRESS ? sortConfig.direction : undefined,
                onSort: () => handleSort(SortableColumn.WALLET_ADDRESS),
            },
        },
        {
            label: 'Email',
            sortOptions: {
                direction: sortConfig?.column === SortableColumn.EMAIL_ADDRESS ? sortConfig.direction : undefined,
                onSort: () => handleSort(SortableColumn.EMAIL_ADDRESS),
            },
        },
        {
            label: 'Groups',
            sortOptions: {
                direction: sortConfig?.column === SortableColumn.GROUPS ? sortConfig.direction : undefined,
                onSort: () => handleSort(SortableColumn.GROUPS),
            },
        },
    ];

    const actions = [
        <DeleteContactModal
            triggerElement={onOpen => (
                <PopoverAction onClick={onOpen} isDisabled={buttonsReady}>
                    Delete
                </PopoverAction>
            )}
            selectedContacts={selectedContacts}
        />,
    ];

    const bottomBar = (
        <Box pt="2">
            <ActionDropdownButton
                text={`Actions${selectedContacts.length == 0 ? '' : ` (${selectedContacts.length})`}`}
                isDisabled={buttonsReady}
                actions={actions}
                bg="white"
                color="dark"
                border="1px"
                borderColor="dark"
                icon={<ChevronDownIcon w="6" h="6" />}
            />
        </Box>
    );

    const contactsListViewCard = (
        <Skeleton isLoaded={!showSkeleton} display="flex" flexDirection={'column'} flex="1">
            <Box p="0" mb="8" display="flex" flexDirection={'column'} flex="1">
                <ListViewCard
                    headers={columnDefinitions}
                    displayedListItems={getDisplayedListItemsForContacts(sortedContacts)}
                    selectOptions={selectOptions}
                    totalItemCount={contactsReady ? contactsContext.contacts.length : 0}
                    emptyMessage={emptyMessageForContactsContextState(contactsContext)}
                    flexHeight={true}
                />
            </Box>
        </Skeleton>
    );

    return (
        <PageLayoutProvider>
            <ContactsPage contactsReady={contactsReady} isMobile={isMobile}>
                <TopBar>
                    <SearchBar queryString={queryString} setQueryString={setQueryString} isMobile={isMobile} />
                    <Spacer />
                    {!isMobile && (
                        <HStack>
                            <ImportContactsModal
                                triggerElement={onOpen => <ImportContactsButton isDisabled={!contactsReady} onClick={onOpen} />}
                            />

                            <ExportContactsButton isDisabled={!contactsReady} onClick={() => exportContactsToCSV(filteredContacts)} />

                            <CreateContactModal
                                triggerElement={onOpen => <AddContactsButton isDisabled={!contactsReady} onClick={onOpen} />}
                            />
                        </HStack>
                    )}
                </TopBar>
                {contactsListViewCard}
                {bottomBar}
            </ContactsPage>
        </PageLayoutProvider>
    );
};

{
    "released": true,
    "contractName": "MultiSend",
    "version": "1.3.0",
    "deployments": {
        "canonical": {
            "address": "0xA238CBeb142c10Ef7Ad8442C6D1f9E89e07e7761",
            "codeHash": "0x0208282bd262360d0320862c5ac70f375f5ed3b9d89a83a615b4d398415bdc83"
        },
        "eip155": {
            "address": "0x998739BFdAAdde7C933B942a68053933098f9EDa",
            "codeHash": "0x81db0e4afdf5178583537b58c5ad403bd47a4ac7f9bde2442ef3e341d433126a"
        },
        "zksync": {
            "address": "0x0dFcccB95225ffB03c6FBB2559B530C2B7C8A912",
            "codeHash": "0xd9aa004a59b3738a108e747e578ae409b84e9f3ffd689d81b10f4d96000c5f5c"
        }
    },
    "networkAddresses": {
        "1": ["canonical", "eip155"],
        "3": "canonical",
        "4": "canonical",
        "5": "canonical",
        "10": ["eip155", "canonical"],
        "11": "canonical",
        "12": "canonical",
        "14": "canonical",
        "16": "eip155",
        "18": "eip155",
        "19": "eip155",
        "25": ["eip155", "canonical"],
        "28": "eip155",
        "30": "canonical",
        "31": "canonical",
        "39": "canonical",
        "40": "canonical",
        "41": "canonical",
        "42": "canonical",
        "43": ["eip155", "canonical"],
        "44": ["eip155", "canonical"],
        "46": ["eip155", "canonical"],
        "50": "canonical",
        "51": "canonical",
        "56": ["canonical", "eip155"],
        "57": "eip155",
        "61": "eip155",
        "63": "eip155",
        "69": "eip155",
        "71": "canonical",
        "81": "eip155",
        "82": "eip155",
        "83": ["eip155", "canonical"],
        "96": "canonical",
        "97": "canonical",
        "100": ["canonical", "eip155"],
        "106": "eip155",
        "108": "eip155",
        "109": "eip155",
        "111": "eip155",
        "114": "canonical",
        "122": "canonical",
        "123": "canonical",
        "130": ["canonical", "eip155"],
        "133": "canonical",
        "137": ["canonical", "eip155"],
        "146": ["canonical", "eip155"],
        "148": "eip155",
        "155": "eip155",
        "169": "canonical",
        "177": "canonical",
        "179": "eip155",
        "195": "eip155",
        "196": ["eip155", "canonical"],
        "204": ["eip155", "canonical"],
        "240": "zksync",
        "246": "canonical",
        "250": ["canonical", "eip155"],
        "252": ["eip155", "canonical"],
        "255": "eip155",
        "274": "canonical",
        "280": "zksync",
        "282": "zksync",
        "288": "eip155",
        "291": "canonical",
        "300": "zksync",
        "314": "eip155",
        "321": "canonical",
        "322": "canonical",
        "324": "zksync",
        "336": ["canonical", "eip155"],
        "338": "eip155",
        "360": "eip155",
        "369": "canonical",
        "388": "zksync",
        "418": "canonical",
        "420": "eip155",
        "424": "canonical",
        "466": "canonical",
        "478": "canonical",
        "480": ["canonical", "eip155"],
        "545": "eip155",
        "570": "eip155",
        "588": "eip155",
        "592": "canonical",
        "595": "eip155",
        "599": "eip155",
        "648": ["canonical", "eip155"],
        "686": "eip155",
        "690": ["eip155", "canonical"],
        "747": "eip155",
        "787": "eip155",
        "919": ["canonical", "eip155"],
        "943": "canonical",
        "1001": "eip155",
        "1008": "canonical",
        "1030": "canonical",
        "1088": ["eip155", "canonical"],
        "1101": ["canonical", "eip155"],
        "1111": "eip155",
        "1112": "eip155",
        "1114": "canonical",
        "1115": "canonical",
        "1116": "canonical",
        "1135": ["canonical", "eip155"],
        "1230": "eip155",
        "1231": "eip155",
        "1284": "canonical",
        "1285": "canonical",
        "1287": "canonical",
        "1294": "eip155",
        "1329": "canonical",
        "1337": "eip155",
        "1442": "eip155",
        "1513": ["eip155", "canonical"],
        "1516": ["eip155", "canonical"],
        "1559": "eip155",
        "1663": "eip155",
        "1729": "canonical",
        "1807": "canonical",
        "1890": "canonical",
        "1891": "canonical",
        "1923": ["canonical", "eip155"],
        "1924": ["canonical", "eip155"],
        "1984": "canonical",
        "1998": "canonical",
        "2001": "canonical",
        "2002": "canonical",
        "2008": "canonical",
        "2019": "canonical",
        "2020": "canonical",
        "2021": "canonical",
        "2039": "canonical",
        "2192": ["canonical", "eip155"],
        "2221": ["canonical", "eip155"],
        "2222": ["canonical", "eip155"],
        "2331": "canonical",
        "2358": "eip155",
        "2810": ["eip155", "canonical"],
        "2818": ["canonical", "eip155"],
        "3338": "canonical",
        "3737": "canonical",
        "3776": "canonical",
        "4002": "canonical",
        "4078": "canonical",
        "4157": "eip155",
        "4202": "canonical",
        "4337": "canonical",
        "4460": "canonical",
        "4653": "eip155",
        "4661": "canonical",
        "4689": "eip155",
        "4918": "canonical",
        "4919": "canonical",
        "5000": ["eip155", "canonical"],
        "5001": "eip155",
        "5003": ["eip155", "canonical"],
        "5165": "canonical",
        "5700": ["eip155", "canonical"],
        "6001": "canonical",
        "6102": "eip155",
        "6398": "eip155",
        "6880": "canonical",
        "7000": ["eip155", "canonical"],
        "7001": "eip155",
        "7070": "canonical",
        "7332": "eip155",
        "7341": "canonical",
        "7560": ["canonical", "eip155"],
        "7700": "eip155",
        "8192": "eip155",
        "8194": "eip155",
        "8217": "eip155",
        "8329": "canonical",
        "8453": ["eip155", "canonical"],
        "8822": "eip155",
        "9000": ["canonical", "eip155"],
        "9001": ["canonical", "eip155"],
        "9728": "eip155",
        "10000": "eip155",
        "10001": "eip155",
        "10081": "eip155",
        "10200": "canonical",
        "10242": "eip155",
        "10243": "eip155",
        "10849": "canonical",
        "11011": "eip155",
        "11111": "canonical",
        "11235": "canonical",
        "11437": "canonical",
        "11820": "canonical",
        "11891": "canonical",
        "12324": "canonical",
        "12325": "canonical",
        "12357": "canonical",
        "12553": "canonical",
        "13337": "canonical",
        "13371": "eip155",
        "13473": "eip155",
        "14800": "eip155",
        "17000": ["canonical", "eip155"],
        "17069": ["eip155", "canonical"],
        "17172": "eip155",
        "18231": "canonical",
        "18233": "canonical",
        "22776": "canonical",
        "23294": "eip155",
        "23295": "eip155",
        "25327": "eip155",
        "28979": "canonical",
        "31611": "canonical",
        "33139": "eip155",
        "33401": "canonical",
        "34443": ["canonical", "eip155"],
        "35441": "canonical",
        "35443": "canonical",
        "37111": "zksync",
        "41455": ["canonical", "eip155"],
        "42161": ["canonical", "eip155"],
        "42170": "canonical",
        "42220": ["eip155", "canonical"],
        "42793": "eip155",
        "43111": ["eip155", "canonical"],
        "43113": ["canonical", "eip155"],
        "43114": ["eip155", "canonical"],
        "43288": "eip155",
        "44787": "canonical",
        "45000": "canonical",
        "47763": "canonical",
        "47805": "canonical",
        "48899": "eip155",
        "48900": ["canonical", "eip155"],
        "53457": "canonical",
        "54211": "eip155",
        "56288": "eip155",
        "57000": "eip155",
        "57054": "canonical",
        "57073": ["eip155", "canonical"],
        "58008": "canonical",
        "59140": ["canonical", "eip155"],
        "59144": ["canonical", "eip155"],
        "60808": ["canonical", "eip155"],
        "61166": "zksync",
        "71401": "eip155",
        "71402": "eip155",
        "73799": "canonical",
        "80001": "canonical",
        "80002": "canonical",
        "80084": "canonical",
        "80085": ["canonical", "eip155"],
        "80094": ["canonical", "eip155"],
        "81457": ["canonical", "eip155"],
        "83291": "canonical",
        "84531": "eip155",
        "84532": ["eip155", "canonical"],
        "97435": "canonical",
        "103454": "eip155",
        "111188": "canonical",
        "128123": ["eip155", "canonical"],
        "167000": ["eip155", "canonical"],
        "167008": "canonical",
        "167009": ["eip155", "canonical"],
        "175188": "canonical",
        "200101": "canonical",
        "200202": "canonical",
        "200810": "canonical",
        "314159": "eip155",
        "328527": "canonical",
        "333999": "canonical",
        "421611": "canonical",
        "421613": "canonical",
        "421614": "canonical",
        "490000": ["canonical", "eip155"],
        "534351": ["canonical", "eip155"],
        "534352": ["canonical", "eip155"],
        "534353": "eip155",
        "543210": "zksync",
        "555666": "canonical",
        "622277": "canonical",
        "656476": ["eip155", "canonical"],
        "660279": "canonical",
        "668668": "canonical",
        "713715": ["eip155", "canonical"],
        "763373": "eip155",
        "764984": "canonical",
        "808813": "eip155",
        "810180": "zksync",
        "978657": "canonical",
        "4457845": "zksync",
        "6038361": ["eip155", "canonical"],
        "7225878": "eip155",
        "7777777": ["canonical", "eip155"],
        "11155111": ["eip155", "canonical"],
        "11155420": "eip155",
        "12227332": "canonical",
        "94204209": ["canonical", "eip155"],
        "111557560": ["canonical", "eip155"],
        "123420111": ["canonical", "eip155"],
        "161221135": "canonical",
        "168587773": "canonical",
        "222000222": "canonical",
        "245022926": "eip155",
        "245022934": "eip155",
        "328527624": "canonical",
        "333000333": "canonical",
        "476462898": "canonical",
        "666666666": ["canonical", "eip155"],
        "888888888": "canonical",
        "999999999": ["canonical", "eip155"],
        "1313161554": ["canonical", "eip155"],
        "1313161555": "canonical",
        "1511670449": "canonical",
        "1666600000": "eip155",
        "1666700000": "eip155",
        "11297108099": "canonical",
        "11297108109": "canonical",
        "37714555429": "canonical",
        "88153591557": ["canonical", "eip155"],
        "920637907288165": "canonical"
    },
    "abi": [
        {
            "inputs": [],
            "stateMutability": "nonpayable",
            "type": "constructor"
        },
        {
            "inputs": [
                {
                    "internalType": "bytes",
                    "name": "transactions",
                    "type": "bytes"
                }
            ],
            "name": "multiSend",
            "outputs": [],
            "stateMutability": "payable",
            "type": "function"
        }
    ]
}

{
    "released": true,
    "contractName": "ProxyFactory",
    "version": "1.0.0",
    "deployments": {
        "canonical": {
            "address": "0x12302fE9c02ff50939BaAaaf415fc226C078613C",
            "codeHash": "0x84a375ad96ab395850d46cd601ed6354d3cf3fb67cec0caf18f34af5c9d1a7f0"
        }
    },
    "networkAddresses": {
        "1": "canonical",
        "4": "canonical",
        "5": "canonical",
        "42": "canonical",
        "100": "canonical"
    },
    "abi": [
        {
            "constant": false,
            "inputs": [
                {
                    "name": "_mastercopy",
                    "type": "address"
                },
                {
                    "name": "initializer",
                    "type": "bytes"
                },
                {
                    "name": "saltNonce",
                    "type": "uint256"
                }
            ],
            "name": "createProxyWithNonce",
            "outputs": [
                {
                    "name": "proxy",
                    "type": "address"
                }
            ],
            "payable": false,
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "constant": true,
            "inputs": [],
            "name": "proxyCreationCode",
            "outputs": [
                {
                    "name": "",
                    "type": "bytes"
                }
            ],
            "payable": false,
            "stateMutability": "pure",
            "type": "function"
        },
        {
            "constant": false,
            "inputs": [
                {
                    "name": "masterCopy",
                    "type": "address"
                },
                {
                    "name": "data",
                    "type": "bytes"
                }
            ],
            "name": "createProxy",
            "outputs": [
                {
                    "name": "proxy",
                    "type": "address"
                }
            ],
            "payable": false,
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "constant": true,
            "inputs": [],
            "name": "proxyRuntimeCode",
            "outputs": [
                {
                    "name": "",
                    "type": "bytes"
                }
            ],
            "payable": false,
            "stateMutability": "pure",
            "type": "function"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": false,
                    "name": "proxy",
                    "type": "address"
                }
            ],
            "name": "ProxyCreation",
            "type": "event"
        }
    ]
}

import { BullaInstantPayment } from '@bulla-network/contracts/typechain/BullaInstantPayment';
import { ContractTransaction } from '@ethersproject/contracts';
import { parseUnits } from '@ethersproject/units';
import { constants, Contract } from 'ethers';
import { useState } from 'react';
import { CreatePaymentFields } from '../components/modals/create-claim-modal/create-payment-modal';
import { InstantPaymentInfo, TAG_SEPARATOR } from '../data-lib/data-model';
import {
    createInstantPayment,
    getCreateInstantPaymentTransaction,
    getUpdateInstantPaymentTagTransaction,
    isPaymentFields,
    mapPaymentInputsToPaymentInfo,
    updateInstantPaymentTag,
} from '../data-lib/dto/bulla-instant-pay-dto';
import { getBullaInstantPaymentContract } from '../data-lib/dto/contract-interfaces';
import { TransactionResult } from '../data-lib/dto/events-dto';
import { EthAddress } from '../data-lib/ethereum';
import { ZERO_BIGNUMBER } from '../data-lib/helpers';
import { ChainId, TokenInfo } from '../data-lib/networks';
import { getTokenInfoFromTokenList } from '../data-lib/tokens';
import { useGnosisSafe } from '../state/gnosis-state';
import { isAttachmentReady, pinHash, resolveBullaAttachmentToCID } from '../tools/ipfs';
import { useAllowances } from './useAllowances';
import { AttachmentLinkGenerator, useCompanyDetailsRepo } from './useCompanyDetailsRepo';
import { MultisendTransaction } from './useGnosisMultisend';
import { useGnosisTransaction } from './useGnosisTransaction';
import { useSendTransaction } from './useSendTransaction';
import { useTokenRepo } from './useTokenRepo';
import { useCurrentChainUserData } from './useUserData';
import { useActingWalletAddress } from './useWalletAddress';
import { useWeb3 } from './useWeb3';
import { CreateClaimFields } from '../components/modals/create-claim-modal/create-claim-inputs';

export function buildCreateInstantPaymentMultisendTx(
    instantPaymentAddress: EthAddress,
    itemInfo: InstantPaymentInfo,
): MultisendTransaction {
    return {
        label: `Send Payment to ${itemInfo.creditor}`,
        transactionInput: getCreateInstantPaymentTransaction(
            instantPaymentAddress,
            itemInfo.creditor,
            itemInfo.paidAmount,
            itemInfo.tokenInfo.token.address,
            itemInfo.description,
            itemInfo.tags,
            itemInfo.ipfsHash,
        ),
        itemInfo,
        interaction: 'Pay',
        approvalNeeded: {
            amount: itemInfo.paidAmount,
            spendingContract: instantPaymentAddress,
        },
        token: itemInfo.tokenInfo.token,
        paymentAmount: itemInfo.paidAmount,
    };
}

export function buildUpdateInstantPaymentTagMultisendTx(
    instantPaymentAddress: EthAddress,
    itemInfo: InstantPaymentInfo,
    newTag: string,
): MultisendTransaction {
    return {
        label: `Update Tag`,
        transactionInput: getUpdateInstantPaymentTagTransaction(instantPaymentAddress, itemInfo.id, newTag),
        itemInfo,
        interaction: 'Update Tag',
    };
}

/**
 * @returns [executingTx, functions]
 */
export const useInstantPayment = () => {
    const [executingTx, setExecutingTx] = useState<boolean>(false);
    const { connectedNetwork, connectedNetworkConfig, userAddress } = useWeb3();
    const { getAttachmentGenerationLink } = useCompanyDetailsRepo();
    const actingWallet = useActingWalletAddress();
    const { safeInfo } = useGnosisSafe();
    const { executeTransactions } = useGnosisTransaction();
    const { instantPayments } = useCurrentChainUserData();
    const [approvalPending, { approveTokens, getAllowancesRequired }] = useAllowances('exact-allowance');
    const [pending, sendTransaction] = useSendTransaction();
    const { tokensByChainId } = useTokenRepo();

    const execute = async (
        claimFunction: (contract: BullaInstantPayment) => Promise<ContractTransaction>,
    ): Promise<TransactionResult | undefined> =>
        sendTransaction(
            signer => claimFunction(getBullaInstantPaymentContract(connectedNetworkConfig.bullaInstantPaymentAddress).connect(signer)),
            true,
        );

    const tokenList = tokensByChainId[connectedNetworkConfig.chainId];

    const functions = {
        getAllowancesRequired: getAllowancesRequired(connectedNetworkConfig.bullaInstantPaymentAddress, connectedNetwork),
        approveTokens: approveTokens(connectedNetworkConfig.bullaInstantPaymentAddress),
        createInstantPayment: async function (fieldValues: CreatePaymentFields | CreateClaimFields) {
            if (safeInfo) {
                setExecutingTx(true);
                try {
                    const { transaction, instantPaymentInfo } = await paymentInputToMultisendTxDTO(
                        connectedNetworkConfig.bullaInstantPaymentAddress,
                        tokenList,
                        userAddress,
                        fieldValues,
                        connectedNetwork,
                        getAttachmentGenerationLink,
                    );
                    const { transactionResult, success } = await executeTransactions(safeInfo, [transaction], true);
                    if (success && !!instantPaymentInfo.ipfsHash) pinHash(instantPaymentInfo.ipfsHash);
                    return transactionResult;
                } finally {
                    setExecutingTx(false);
                }
            } else {
                setExecutingTx(true);
                const values = await claimFieldsToInstantPaymentDTO(actingWallet, fieldValues, getAttachmentGenerationLink);
                return execute(async (contract: Contract) => createInstantPayment({ contract, ...values }))
                    .then(result => {
                        if (result && !!values.ipfsHash) pinHash(values.ipfsHash);
                        return result;
                    })
                    .finally(() => setExecutingTx(false));
            }
        },
        createInstantPayments: async function (fieldValues: (CreatePaymentFields | CreateClaimFields)[]) {
            if (safeInfo) {
                setExecutingTx(true);
                const transactions = await Promise.all(
                    fieldValues.map(
                        async fields =>
                            await paymentInputToMultisendTxDTO(
                                connectedNetworkConfig.bullaInstantPaymentAddress,
                                tokenList,
                                userAddress,
                                fields,
                                connectedNetwork,
                                getAttachmentGenerationLink,
                            ),
                    ),
                );

                try {
                    const { transactionResult, success } = await executeTransactions(
                        safeInfo,
                        transactions.map(tx => tx.transaction),
                        true,
                    );
                    if (success)
                        transactions
                            .map(tx => tx.instantPaymentInfo.ipfsHash)
                            .filter((x): x is string => !!x)
                            .forEach(pinHash);
                    return transactionResult;
                } finally {
                    setExecutingTx(false);
                }
            } else {
                setExecutingTx(true);
                let ipfsHashes: string[] = [];
                return execute(async (contract: BullaInstantPayment) => {
                    const REVERT_ON_FAIL = true;

                    const txs = await Promise.all(
                        fieldValues.map(async fields => {
                            let ipfsHash: string | undefined;
                            const amount = isPaymentFields(fields) ? fields.paymentAmount : fields.claimAmount;

                            if (isAttachmentReady(fields.attachment)) {
                                ipfsHash = await resolveBullaAttachmentToCID({
                                    attachment: fields.attachment,
                                    actingWallet,
                                    amount: amount,
                                    description: fields.description,
                                    recipient: fields.recipient,
                                    tokenSymbol: fields.token.symbol,
                                    type: 'Payment',
                                    attachmentLinkGenerator: getAttachmentGenerationLink,
                                });
                                ipfsHashes = [...ipfsHashes, ipfsHash];
                            }

                            return contract.interface.encodeFunctionData('instantPayment', [
                                fields.recipient,
                                parseUnits(amount, fields.token.decimals),
                                fields.token.address,
                                fields.description,
                                fields.tags.join(TAG_SEPARATOR),
                                ipfsHash ?? '',
                            ]);
                        }),
                    );
                    const nativeAmount = fieldValues.reduce(
                        (acc, fields) =>
                            fields.token.address === constants.AddressZero
                                ? acc.add(
                                      parseUnits(
                                          'paymentAmount' in fields ? fields.paymentAmount : fields.claimAmount,
                                          connectedNetworkConfig.nativeCurrency.decimals,
                                      ),
                                  )
                                : acc,
                        ZERO_BIGNUMBER,
                    );
                    return await contract.batch(txs, REVERT_ON_FAIL, { value: nativeAmount });
                })
                    .then(result => {
                        if (result) ipfsHashes.forEach(pinHash);
                        return result;
                    })
                    .finally(() => setExecutingTx(false));
            }
        },
        updateTag: async (instantPaymentId: string, newTag: string) => {
            const instantPayment = instantPayments.find(ip => ip.id.toLowerCase() === instantPaymentId.toLowerCase());
            if (!instantPayment) throw new Error(`Could not find instant payment with id ${instantPaymentId}`);

            if (safeInfo) {
                const { transactionResult } = await executeTransactions(
                    safeInfo,
                    [buildUpdateInstantPaymentTagMultisendTx(connectedNetworkConfig.bullaInstantPaymentAddress, instantPayment, newTag)],
                    false,
                );
                return transactionResult;
            } else {
                return execute((contract: Contract) =>
                    updateInstantPaymentTag({ contract, txAndLogIndexHash: instantPaymentId, tag: newTag }),
                );
            }
        },
    };

    return [pending || executingTx || approvalPending, functions] as const;
};

const paymentInputToMultisendTxDTO = async (
    instantPaymentAddress: EthAddress,
    supportedTokens: TokenInfo[],
    userAddress: EthAddress,
    fieldValues: CreatePaymentFields | CreateClaimFields,
    chainId: ChainId,
    attachmentLinkGenerator: AttachmentLinkGenerator,
): Promise<{ transaction: MultisendTransaction; instantPaymentInfo: InstantPaymentInfo }> => {
    const tokenInfo = getTokenInfoFromTokenList(fieldValues.token.address, supportedTokens);
    const instantPaymentInfo = await mapPaymentInputsToPaymentInfo(userAddress, tokenInfo!, fieldValues, chainId, attachmentLinkGenerator);

    return { transaction: buildCreateInstantPaymentMultisendTx(instantPaymentAddress, instantPaymentInfo), instantPaymentInfo };
};

const claimFieldsToInstantPaymentDTO = async (
    actingWallet: EthAddress,
    fields: CreatePaymentFields | CreateClaimFields,
    attachmentLinkGenerator: AttachmentLinkGenerator,
) => {
    const amount = isPaymentFields(fields) ? fields.paymentAmount : fields.claimAmount;
    const { description, recipient, tags: tag, token, attachment } = fields;

    return {
        to: recipient,
        amount: parseUnits(amount.toString(), token.decimals),
        description: description ?? '',
        tokenAddress: token.address,
        tag: tag.join(TAG_SEPARATOR),
        ipfsHash: isAttachmentReady(attachment)
            ? await resolveBullaAttachmentToCID({
                  attachment,
                  actingWallet,
                  amount: amount,
                  description,
                  recipient,
                  tokenSymbol: token.symbol,
                  type: 'Payment',
                  attachmentLinkGenerator,
              })
            : '',
    };
};

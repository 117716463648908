import { OwnerResponse } from '@safe-global/api-kit';
import { useSafeAppsSDK } from '@safe-global/safe-apps-react-sdk';
import React, { useEffect, useMemo } from 'react';
import { PendingInstantPaymentInfo } from '../data-lib/data-model';
import { PayClaimTransaction } from '../data-lib/domain/bulla-claim-domain';
import { addressEquality, EthAddress, isValidAddress, toChecksumAddress } from '../data-lib/ethereum';
import {
    BULLA_BANKER_MODULE_VERSION,
    deployAndEnableModule,
    getGnosisSafeInfo,
    getGnosisSafeURL,
    getSafeCache,
    getSafeService,
    GNOSIS_CONFIG,
    GnosisSafeInfo,
    isModuleOutdated,
    SafeCache,
    SUPPORTED_GNOSIS_NETWORKS,
} from '../data-lib/gnosis-tools/gnosis';
import { isClaim } from '../data-lib/helpers';
import { ChainId, NETWORKS } from '../data-lib/networks';
import { useSessionStorage } from '../hooks/useStorage';
import { useTokenRepo } from '../hooks/useTokenRepo';
import { useCurrentChainUserData } from '../hooks/useUserData';
import { useWeb3 } from '../hooks/useWeb3';
import { STORAGE_KEYS } from '../tools/storage';
import { useUIState } from './ui-state';

type LoadingState = { loading: boolean; progress: number; error: string | undefined };
export type GnosisState = LoadingState & {
    isGnosisSupported: boolean;
    safeInfo: GnosisSafeInfo | undefined;
    isSafeReady: boolean;
    inSafeApp: boolean;
    connectedSafeAddress: EthAddress | undefined;
    userSafes: Record<number, SafeCache> | 'init' | 'not-supported';
    pendingPayments: { payClaimTransactions: PayClaimTransaction[]; pendingInstantPaymentInfos: PendingInstantPaymentInfo[] };
    enableModule: () => Promise<boolean>;
    fetchSafeAppInfo: (safeAddress: EthAddress) => Promise<GnosisSafeInfo | undefined>;
    fetchUserSafes: () => Promise<Record<number, SafeCache> | undefined>;
    persistSafeInfo: React.Dispatch<React.SetStateAction<GnosisSafeInfo>>;
    resetsafeInfo: VoidFunction;
};

export const GnosisContext = React.createContext<GnosisState>({
    error: undefined,
    loading: false,
    progress: 0,
    isGnosisSupported: false,
    safeInfo: undefined,
    isSafeReady: false,
    inSafeApp: false,
    connectedSafeAddress: undefined,
    userSafes: 'init',
    pendingPayments: { payClaimTransactions: [], pendingInstantPaymentInfos: [] },
    enableModule: async () => false,
    fetchSafeAppInfo: async () => undefined,
    fetchUserSafes: async () => undefined,
    persistSafeInfo: () => undefined,
    resetsafeInfo: () => undefined,
});

export const GnosisProvider = ({ children }: { children: React.ReactNode }) => {
    const { provider, connectedNetwork, userAddress, connectedNetworkConfig, signer } = useWeb3();
    const { resolveTokenInfo } = useTokenRepo();
    const isGnosisSupported = SUPPORTED_GNOSIS_NETWORKS.includes(connectedNetwork);
    const { addAlert } = useUIState();
    const {
        connected: inSafeApp,
        safe: { safeAddress: safeAppsSafeAddress },
    } = useSafeAppsSDK();
    const [pendingPayments, setPendingPayments] = React.useState<{
        payClaimTransactions: PayClaimTransaction[];
        pendingInstantPaymentInfos: PendingInstantPaymentInfo[];
    }>({ payClaimTransactions: [], pendingInstantPaymentInfos: [] });
    const [userSafes, setUserSafes] = React.useState<Record<number, SafeCache> | 'init' | 'not-supported'>(
        isGnosisSupported ? 'init' : 'not-supported',
    );
    const [safeInfo, persistSafeInfo, resetsafeInfo] = useSessionStorage<GnosisSafeInfo>(
        `${connectedNetwork}:${userAddress}:${STORAGE_KEYS.safeInfo}`,
    );
    const [state, setState] = React.useState<LoadingState>({
        loading: false,
        progress: 0,
        error: undefined,
    });
    const { payables } = useCurrentChainUserData();
    const isSafeOwner = inSafeApp || !!(safeInfo?.owners && safeInfo.owners.some(o => addressEquality(o, userAddress)));
    const isSafeReady =
        isSafeOwner && (!!(safeInfo?.module?.moduleEnabled && !isModuleOutdated(safeInfo.module.moduleVersion)) || inSafeApp);

    const setError = (error?: string) => setState(state => ({ ...state, error }));
    const setLoading = (loading: boolean) => setState(state => ({ ...state, loading }));
    const incrementProgress = () => setState(state => ({ ...state, progress: state.progress + 1 }));
    const resetProgress = () => setState(state => ({ ...state, progress: 0 }));
    const connectedSafeAddress = safeInfo?.safeAddress ?? safeAppsSafeAddress;

    const beginWork = () => {
        resetProgress();
        setError();
        setLoading(true);
    };

    useEffect(() => {
        setUserSafes(isGnosisSupported ? 'init' : 'not-supported');
    }, [connectedNetwork]);

    const fetchUserSafes = async () => {
        beginWork();
        try {
            console.debug('fetching user safes');
            const fetchedSafesByChainId = await Promise.all(
                SUPPORTED_GNOSIS_NETWORKS.map(async chainId => {
                    let safes: OwnerResponse;
                    try {
                        safes = await getSafeService(NETWORKS[chainId]).getSafesByOwner(userAddress);
                    } catch (e) {
                        console.error('error while fetching safes: ', e);
                        safes = { safes: [] };
                    }
                    return [chainId, safes.safes];
                }),
            );

            const safesByChainId = fetchedSafesByChainId.reduce<Record<number, SafeCache>>(
                (acc, [chainId, fetchedSafes]) => ({
                    ...acc,
                    [chainId as ChainId]: {
                        ...(fetchedSafes as string[]).reduce<SafeCache>(
                            (acc, safe) => ({ ...acc, [safe]: { chainId: chainId as ChainId } }),
                            {},
                        ),
                        ...getSafeCache(chainId as ChainId, userAddress),
                    },
                }),
                {},
            );
            setUserSafes(safesByChainId);
            return safesByChainId;
        } catch (e: any) {
            setError(e?.message ?? 'Error fetching safes');
        } finally {
            setLoading(false);
            resetProgress();
        }
    };

    const fetchSafeAppInfo = async (_safeAddress: EthAddress) => {
        const safeAddress = toChecksumAddress(_safeAddress);
        if (isValidAddress(safeAddress)) {
            beginWork();
            try {
                const { owners, moduleEnabled, moduleVersion, moduleAddress, threshold, pendingPayments, prevModuleAddress } =
                    await getGnosisSafeInfo(signer, provider, connectedNetworkConfig, safeAddress, resolveTokenInfo);
                const isOwner = owners.some(address => addressEquality(address, userAddress));
                const safeInfo = {
                    safeAddress,
                    owners,
                    threshold: threshold.toNumber(),
                    module:
                        moduleAddress && !!moduleVersion
                            ? {
                                  moduleEnabled,
                                  moduleAddress: moduleAddress,
                                  moduleVersion: moduleVersion,
                              }
                            : undefined,
                    prevModuleAddress,
                    isOwner,
                    multisendAddress: GNOSIS_CONFIG[connectedNetworkConfig.chainId].multisendOverrideAddress,
                };
                persistSafeInfo(safeInfo);
                setPendingPayments(pendingPayments);
                return safeInfo;
            } catch (e: any) {
                console.log(e);
                setError(e?.message ?? 'Encoutered an error while fetching safe info');
                resetsafeInfo();
            } finally {
                resetProgress();
                setLoading(false);
            }
        } else {
            setError('Invalid address');
            resetsafeInfo();
        }
    };

    const enableModule = async () => {
        if (safeInfo) {
            try {
                beginWork();
                const { wait: waitForSafeTx, moduleAddress } = await deployAndEnableModule({
                    signer,
                    provider,
                    connectedNetworkConfig,
                    userAddress,
                    safeAddress: safeInfo.safeAddress,
                    resolveTokenInfo,
                });

                const cleanupPendingMessage = addAlert({
                    message: 'Gnosis transaction created. Please confirm with your signatories.',
                    link: getGnosisSafeURL(connectedNetworkConfig, safeInfo.safeAddress, 'transactions'),
                });
                await waitForSafeTx();
                incrementProgress();
                cleanupPendingMessage();
                const cleanupSuccessMessage = addAlert({ message: 'Gnosis transaction confirmed.', type: 'success' });
                setTimeout(cleanupSuccessMessage, 5000);
                persistSafeInfo({
                    ...safeInfo,
                    module: {
                        moduleAddress,
                        moduleEnabled: true,
                        moduleVersion: BULLA_BANKER_MODULE_VERSION,
                    },
                });
                return true;
            } catch (e: any) {
                console.error(e);
                setError(e.message);
                const cleanupError = addAlert({ message: e.message, type: 'error' });
                setTimeout(cleanupError, 5000);
                return false;
            } finally {
                resetProgress();
                setLoading(false);
            }
        } else return false;
    };

    useEffect(() => {
        if (userAddress && isGnosisSupported && !inSafeApp) fetchUserSafes();
        if (safeInfo || inSafeApp) fetchSafeAppInfo(inSafeApp ? safeAppsSafeAddress : safeInfo.safeAddress);
    }, [userAddress, provider, inSafeApp, safeAppsSafeAddress, payables.filter(x => !isClaim(x) || x.claimStatus === 'Pending').length]);

    const context = useMemo(() => {
        return {
            ...state,
            isGnosisSupported,
            safeInfo,
            isSafeReady,
            inSafeApp,
            userSafes,
            connectedSafeAddress,
            enableModule,
            fetchSafeAppInfo,
            pendingPayments,
            fetchUserSafes,
            persistSafeInfo,
            resetsafeInfo,
        };
    }, [state, userSafes, safeInfo, inSafeApp, pendingPayments, userAddress]);

    return <GnosisContext.Provider value={context}>{children}</GnosisContext.Provider>;
};

export const useGnosisSafe = () => {
    const context = React.useContext(GnosisContext);
    if (context === undefined) throw new Error('useGnosisSafe must me used within the GnosisProvider');
    return context;
};

import { Container, HStack, Box, Spacer, Text, VStack, Tooltip } from '@chakra-ui/react';
import { ArrowCircleLeft, ArrowCircleRight, FloppyDisk } from 'phosphor-react';
import React, { ReactNode } from 'react';
import { ContinueButton, WhiteButton } from '../../inputs/buttons';
import { BatchFooter } from './shared-components';
import { NewBatchWizardState, SavingStatus, SelectClaimTypeState, SelectWalletsTypeState } from './new-batch-wizard-modal';

interface SavingIndicatorProps {
    savingStatus: SavingStatus;
}

export const SavingIndicator: React.FC<SavingIndicatorProps> = ({ savingStatus }) => {
    return (
        <HStack mr="4">
            <Tooltip label="All changes saved">
                <FloppyDisk size={24} color="#414651" />
            </Tooltip>
            {savingStatus.showText && (
                <Text color="#414651" fontSize="lg" fontWeight={700}>
                    {savingStatus.isSaving ? 'Saving...' : 'Saved'}
                </Text>
            )}
        </HStack>
    );
};

type StepNumberCircleProps = {
    number: number;
    isActive?: boolean;
};

const StepNumberCircle: React.FC<StepNumberCircleProps> = ({ number, isActive = false }) => (
    <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="full"
        bg={isActive ? '#14282D' : 'gray.100'}
        w="52px"
        h="52px"
    >
        <Text color={isActive ? 'white' : 'gray.600'} fontSize="20px">
            {number}
        </Text>
    </Box>
);

type IconCircleProps = {
    icon: React.ReactElement;
};

const IconCircle: React.FC<IconCircleProps> = ({ icon }) => (
    <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="xl"
        border="1.5px solid #FEEBCB"
        w="50px"
        h="50px"
        mr="4"
        shadow="md"
    >
        {icon}
    </Box>
);

type BatchTypeOptionProps = {
    title: string;
    description: string;
    icon: React.ReactElement;
    onClick: () => void;
    isSelected?: boolean;
};

const BatchTypeOption: React.FC<BatchTypeOptionProps> = ({ title, description, icon, onClick, isSelected = false }) => (
    <Box
        _hover={{ cursor: 'pointer' }}
        border={isSelected ? '1.5px solid #EF6820' : '1.5px solid #e1e2e2'}
        onClick={onClick}
        borderRadius="xl"
        p={6}
        shadow="xs"
        w="50%"
        bg={isSelected ? '#FFF8F3' : 'white'}
    >
        <HStack>
            <IconCircle icon={icon} />
            <VStack align="start" spacing={1}>
                <Text fontWeight={700} color="#101828" fontSize="20px">
                    {title}
                </Text>
                <Text color="gray.600" fontSize="16px">
                    {description}
                </Text>
            </VStack>
        </HStack>
    </Box>
);

type StepCardProps = {
    stepNumber: number;
    title: string;
    isActive?: boolean;
    children?: ReactNode;
    onClick?: () => void;
    p?: string | number;
    gap?: string | number;
    rightElement?: ReactNode;
};

export const StepCard: React.FC<StepCardProps> = ({
    stepNumber,
    title,
    isActive = false,
    children,
    onClick = () => null,
    p = '8',
    gap = '8',
    rightElement,
}) => (
    <Box
        border={'1.5px solid #e1e2e2'}
        onClick={onClick}
        borderRadius="xl"
        display="flex"
        flexDirection={children ? 'column' : 'row'}
        justifyContent="left"
        shadow="xs"
        w="100%"
        p={children ? p : '0'}
        gap={children ? gap : '0'}
    >
        {children ? (
            <>
                <HStack>
                    <StepNumberCircle number={stepNumber} isActive={isActive} />
                    <Text fontWeight={700} color="#101828" w="fit-content" fontSize="20px" ml="2">
                        {title}
                    </Text>
                </HStack>
                {children}
            </>
        ) : (
            <HStack w="100%" px="8" py="6" justify="space-between">
                <HStack>
                    <StepNumberCircle number={stepNumber} isActive={isActive} />
                    <Text fontWeight={700} color="#101828" w="fit-content" fontSize="20px" ml="2">
                        {title}
                    </Text>
                </HStack>
                {rightElement}
            </HStack>
        )}
    </Box>
);

type BatchPaymentStepsProps = {
    state: SelectClaimTypeState;
    setWizardState: React.Dispatch<React.SetStateAction<NewBatchWizardState>>;
    onCancel: () => void;
    savingStatus: SavingStatus;
};

export const OpenedSelectClaimTypesCard = ({ state, setWizardState, onCancel, savingStatus }: BatchPaymentStepsProps) => {
    const handleSetPaymentType = (claimType: 'Payment' | 'Invoice') => {
        setWizardState({
            ...state,
            claimType,
        } as SelectClaimTypeState);
    };

    const handleContinue = () => {
        setWizardState({
            ...state,
            kind: 'SelectWallets',
        } as SelectWalletsTypeState);
    };

    return (
        <Container maxW="100%" p="0">
            <VStack>
                <StepCard stepNumber={1} title="Batch Payment or Batch Invoice" isActive={true} p="8" gap="8">
                    <HStack spacing={4} w="100%">
                        <BatchTypeOption
                            title="Batch Payment"
                            description="Payments will be instantly transferred to designated wallets."
                            icon={<ArrowCircleRight size={24} color="#EF6820" />}
                            onClick={() => handleSetPaymentType('Payment')}
                            isSelected={state.claimType === 'Payment'}
                        />
                        <BatchTypeOption
                            title="Batch Invoice"
                            description="Invoices will be scheduled and require another action to complete"
                            icon={<ArrowCircleLeft size={24} color="#EF6820" />}
                            onClick={() => handleSetPaymentType('Invoice')}
                            isSelected={state.claimType === 'Invoice'}
                        />
                    </HStack>
                </StepCard>
            </VStack>

            <BatchFooter>
                <Spacer />
                <SavingIndicator savingStatus={savingStatus} />
                <WhiteButton onClick={onCancel}>Cancel</WhiteButton>
                <ContinueButton isDisabled={!state.claimType} onClick={handleContinue} />
            </BatchFooter>
        </Container>
    );
};

interface ClosedSelectClaimTypesCardProps {
    setWizardState: React.Dispatch<React.SetStateAction<NewBatchWizardState>>;
}

export const ClosedSelectClaimTypesCard = ({ setWizardState }: ClosedSelectClaimTypesCardProps) => {
    return (
        <StepCard
            stepNumber={1}
            title="Batch Payment or Batch Invoice"
            rightElement={
                <WhiteButton
                    onClick={() =>
                        setWizardState(previousState => ({
                            ...(previousState as Omit<SelectClaimTypeState, 'kind'>),
                            kind: 'SelectClaimType',
                        }))
                    }
                >
                    Edit
                </WhiteButton>
            }
        />
    );
};

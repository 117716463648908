import { MenuItem, Modal, ModalContent, ModalOverlay, Tooltip, useDisclosure } from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import React from 'react';
import { ClaimInfo } from '../../../data-lib/data-model';
import { addressEquality } from '../../../data-lib/ethereum';
import { chainIds, MATIC_FACTORING_CONFIG_V2 } from '../../../data-lib/networks';
import { useBullaFactoring } from '../../../hooks/useBullaFactoring';
import { BullaFactoringEligibilityTerms, useCheckFactoringEligibility } from '../../../hooks/useCheckFactoringEligibility';
import { MultisendTransaction, useGnosisGlobalMultisend } from '../../../hooks/useGnosisMultisend';
import { useGnosisTransaction } from '../../../hooks/useGnosisTransaction';
import { SelectableOptions } from '../../../hooks/useSelection';
import { filterSelectedClaims, getMultisendTooltipLabel, useTransactionBundle } from '../../../hooks/useTransactionBundle';
import { useGlobalUserData } from '../../../hooks/useUserData';
import { useWeb3 } from '../../../hooks/useWeb3';
import { useGnosisSafe } from '../../../state/gnosis-state';
import { enableBatchFactoringExperience } from '../../../tools/featureFlags';
import { menuItemDisabledProps } from '../../inputs/buttons';
import { BatchFactoringWizard } from '../../modals/batch-factoring-wizard/batch-factoring-wizard';
import { ClaimsPage } from './claims-page-template';

export const unselectIfTrue = async (func: () => Promise<boolean | undefined>, selectableOptions: SelectableOptions<string>) => {
    const result = await func();
    if (result) {
        selectableOptions.resetSelection();
    }
};

export const Receivables = () => {
    const { receivables } = useGlobalUserData('exclude-originating-claims');
    const { connectedNetworkConfig } = useWeb3();
    const { connectedSafeAddress, safeInfo } = useGnosisSafe();
    const { isFull } = useGnosisGlobalMultisend();
    const { executeTransactions } = useGnosisTransaction();
    const { addRescindClaimTransactionsToBundle } = useTransactionBundle();
    const [selected, setSelected] = React.useState<string[]>([]);
    // @note allowing only v2 for new factoring
    const [, { approveInvoiceInputToMultisendTxDTO, fundInvoiceInputToMultisendTxDTO }] = useBullaFactoring(MATIC_FACTORING_CONFIG_V2);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [, { checkEligibility }] = useCheckFactoringEligibility();

    const handleRescindSelected = () =>
        addRescindClaimTransactionsToBundle(
            filterSelectedClaims(
                selected,
                receivables.filter((x): x is ClaimInfo => x.__type == 'Claim'),
            ),
        );

    const handleFactorSelected = async () => {
        // If the batch factoring experience is enabled, open the wizard modal
        if (enableBatchFactoringExperience) {
            onOpen();
            return false;
        }

        // Otherwise use the existing factoring flow
        if (!safeInfo) {
            console.log('no safe, returning');
            return;
        }
        const tcsPoolAddress = MATIC_FACTORING_CONFIG_V2.bullaFactoringToken.token.address;

        const claims = filterSelectedClaims(
            selected,
            receivables.filter((x): x is ClaimInfo => x.__type == 'Claim'),
        );

        if (!enableBatchFactoringExperience && claims.some(x => x.chainId !== chainIds.MATIC)) {
            console.log('not all claims are on Polygon');
            return;
        }

        const termsAndClaims = await claims.reduce<Promise<{ terms: BullaFactoringEligibilityTerms; claim: ClaimInfo }[]>>(
            async (_acc, claim) => {
                const acc = await _acc;
                console.log(`getting ${claim.description} approved for factoring`);

                const approvalResult = await checkEligibility(claim, tcsPoolAddress);

                console.log({ approvalResult });

                return approvalResult.type == 'eligible' ? [...acc, { terms: approvalResult.terms, claim }] : acc;
            },
            Promise.resolve([]),
        );

        console.log(`${termsAndClaims.length} out of ${claims.length} were approved for financing, creating gnosis transaction to sign`);

        const multisendTransactions = await termsAndClaims.reduce<Promise<MultisendTransaction[]>>(async (_acc, { terms, claim }) => {
            const acc = await _acc;
            const { transaction: approveInvoiceTransaction } = await approveInvoiceInputToMultisendTxDTO(
                connectedNetworkConfig.bullaClaimAddress,
                BigNumber.from(claim.id),
                tcsPoolAddress,
            );
            const { transaction: fundInvoiceTransaction } = await fundInvoiceInputToMultisendTxDTO(
                tcsPoolAddress,
                BigNumber.from(claim.id),
                terms.upfrontBps,
            );

            return [...acc, approveInvoiceTransaction, fundInvoiceTransaction];
        }, Promise.resolve([]));

        console.log(`adding ${multisendTransactions.length} txs to batch`);

        const { success } = await executeTransactions(safeInfo, multisendTransactions, true);
        return success;
    };

    const multisendTooltipLabel = getMultisendTooltipLabel(!!connectedSafeAddress, isFull);
    const buttonsDisabled = !connectedSafeAddress || isFull;

    const dropdownActions = (selectableOptions: SelectableOptions<string>) => [
        <Tooltip label={multisendTooltipLabel} key="rescind">
            <MenuItem
                onClick={async () => {
                    await unselectIfTrue(async () => {
                        handleRescindSelected();
                        return true;
                    }, selectableOptions);
                }}
                isDisabled={buttonsDisabled}
                {...(buttonsDisabled && menuItemDisabledProps)}
            >
                Rescind
            </MenuItem>
        </Tooltip>,
        ...(enableBatchFactoringExperience ||
        addressEquality(connectedSafeAddress?.toLowerCase() ?? '', '0xbbd837143cdc4816fcd7301731f6d4a366d0cba2')
            ? [
                  <Tooltip label={multisendTooltipLabel} key="factor">
                      <MenuItem
                          onClick={() =>
                              unselectIfTrue(async () => {
                                  const success = await handleFactorSelected();
                                  return success;
                              }, selectableOptions)
                          }
                          isDisabled={buttonsDisabled}
                          {...(buttonsDisabled && menuItemDisabledProps)}
                      >
                          Factor Invoices
                      </MenuItem>
                  </Tooltip>,
              ]
            : []),
    ];

    // Get selected claims for the BatchFactoringWizard
    const selectedClaims = React.useMemo(
        () =>
            filterSelectedClaims(
                selected,
                receivables.filter((x): x is ClaimInfo => x.__type == 'Claim'),
            ),
        [selected, receivables],
    );

    return (
        <>
            <ClaimsPage
                claims={receivables}
                tabName="receivables"
                dropdownActions={dropdownActions}
                selected={selected}
                setSelected={setSelected}
            />

            {/* Batch Factoring Wizard Modal */}
            {enableBatchFactoringExperience && (
                <Modal isOpen={isOpen} onClose={onClose} size="full" isCentered>
                    <ModalOverlay />
                    <ModalContent p={0} m={0} maxW="100vw" maxH="100vh" h="100vh">
                        <BatchFactoringWizard selectedClaims={selectedClaims} onClose={onClose} />
                    </ModalContent>
                </Modal>
            )}
        </>
    );
};

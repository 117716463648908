{
    "released": true,
    "contractName": "MultiSend",
    "version": "1.1.1",
    "deployments": {
        "canonical": {
            "address": "0x8D29bE29923b68abfDD21e541b9374737B49cdAD",
            "codeHash": "0xe4e9b4d4c1e3ff06cd51afe0b51eb1b22c0bab51eab38d428ee74540a5ff603e"
        }
    },
    "networkAddresses": {
        "1": "canonical",
        "4": "canonical",
        "5": "canonical",
        "42": "canonical",
        "88": "canonical",
        "100": "canonical",
        "246": "canonical",
        "73799": "canonical"
    },
    "abi": [
        {
            "inputs": [],
            "payable": false,
            "stateMutability": "nonpayable",
            "type": "constructor"
        },
        {
            "constant": false,
            "inputs": [
                {
                    "internalType": "bytes",
                    "name": "transactions",
                    "type": "bytes"
                }
            ],
            "name": "multiSend",
            "outputs": [],
            "payable": false,
            "stateMutability": "nonpayable",
            "type": "function"
        }
    ]
}

import { Box, Flex, HStack, Menu, MenuButton, MenuItem, MenuList, Spacer, Stack, Text } from '@chakra-ui/react';
import { HandCoins, Link, PaperPlaneRight, Receipt } from '@phosphor-icons/react';
import { ArrowCircleDown, ListBullets } from 'phosphor-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SwapCoins } from '../../assets/swap-coins';
import { NetworkLoadingSpinner } from '../../components/display/view-network-selector';
import { TabOptions, TabSwitcher } from '../../components/display/views/account-tag-view';
import { GettingStartedCard } from '../../components/display/views/getting-started';
import { LoanTable } from '../../components/display/views/loanTable';
import { NftTransfers } from '../../components/display/views/nft-flows';
import { Payables } from '../../components/display/views/payables';
import { NetFlows, Payments } from '../../components/display/views/payments';
import { Receivables } from '../../components/display/views/receivables';
import { SwapsTable } from '../../components/display/views/swaps-table';
import { MaxWidthWrapper, PageLayoutProvider } from '../../components/layout/page-layout';
import { CreateClaimModal } from '../../components/modals/create-claim-modal/create-claim-modal';
import { CreateItemLinkHandler } from '../../components/modals/create-claim-modal/create-link-handler';
import { CreateFrendlendModal } from '../../components/modals/create-frendlend-modal/create-frendlend-modal';
import { CreateSwapModal } from '../../components/modals/create-swap-modal/create-swap-modal';
import { WelcomePremiumData } from '../../components/modals/premium-pricing-modal';
import { WelcomePremiumModal } from '../../components/modals/welcome-premium-modal';
import { FinancedClaimInfo } from '../../data-lib/data-model';
import { isFinancingAccepted } from '../../data-lib/helpers';
import { useGettingStarted } from '../../hooks/useGettingStarted';
import { useMembership } from '../../hooks/useMembership';
import { useLocalStorage } from '../../hooks/useStorage';
import { useGlobalUserData } from '../../hooks/useUserData';
import { useActingWalletAddress } from '../../hooks/useWalletAddress';
import { useWeb3 } from '../../hooks/useWeb3';
import { useAppState } from '../../state/app-state';
import { bypassProForReportingExplorer, enableNewBatchPage, enableNewInvoiceCreation, enableSwaps } from '../../tools/featureFlags';
import { STORAGE_KEYS } from '../../tools/storage';
import { HomeDashboardTab, homeDashboardTabs, QuickActionProps } from './home-types';
import { CreatePaymentModal } from '../../components/modals/create-claim-modal/create-payment-modal';

const getHomeDashboardTypeDisplayName = (type: HomeDashboardTab): string => {
    switch (type) {
        case 'Payments':
            return 'Payments';
        case 'NetFlows':
            return 'Net Flows';
        case 'NftTransfers':
            return 'NFT Transfers';
        case 'Payables':
            return 'Payables';
        case 'Receivables':
            return 'Receivables';
        case 'LoanPayables':
            return 'Loan Payables';
        case 'LoanReceivables':
            return 'Loan Receivables';
        case 'LoanOffers':
            return 'Loan Offers';
        case 'Swaps':
            return 'Swaps';
        default:
            return type;
    }
};

const getPathnameForHomeTables = (tab: HomeDashboardTab) => {
    return {
        pathname: '/',
        search: `?tab=${tab}`,
    };
};

const homeTabOptions: TabOptions<HomeDashboardTab>[] = homeDashboardTabs.map(tab => ({
    label: getHomeDashboardTypeDisplayName(tab),
    value: tab,
}));

const quickActionShadowProps = { style: { filter: 'drop-shadow(0px 18px 40px rgba(112, 144, 176, 0.3))' } };

export const QuickAction = ({ text, Icon, onClick, isDisabled, menuItems, blimpCount }: QuickActionProps) => (
    <Menu>
        <MenuButton
            onClick={!!isDisabled ? undefined : onClick}
            _hover={{ cursor: !!isDisabled ? 'not-allowed' : 'pointer', textDecoration: 'underline' }}
        >
            <Stack w="52px" alignItems={'center'} textAlign={'center'} position="relative">
                {blimpCount && blimpCount > 0 && (
                    <Box p="0.5" bg="red" borderRadius={'50%'} position="absolute" zIndex={10} right="-7px" top={'-7px'} minW="21.5px">
                        <Text color={'white'} fontSize="12px" fontWeight={'700'}>
                            {blimpCount}
                        </Text>
                    </Box>
                )}
                <Box borderRadius={'50%'} bg="#FCEDE2" {...quickActionShadowProps} p="4" color="brand.bulla_orange">
                    <Icon size={'24px'} />
                </Box>
                <Text fontWeight={600} noOfLines={2} color="#1B2559">
                    {text}
                </Text>
            </Stack>
        </MenuButton>
        {menuItems && (
            <MenuList>
                {menuItems.map((item, index) => (
                    <MenuItem key={index} onClick={item.action}>
                        {item.label}
                    </MenuItem>
                ))}
            </MenuList>
        )}
    </Menu>
);

export const NewHome: React.FC = () => {
    const { showGettingStarted, setGettingStartedEnabled } = useGettingStarted();
    const actingWallet = useActingWalletAddress();
    const [welcomePremiumData, _, clearWelcomePremiumData] = useLocalStorage<WelcomePremiumData>(
        `${STORAGE_KEYS.welcomePremium}#${actingWallet}`,
        { isWelcome: false, tier: null },
    );
    const navigate = useNavigate();
    const { readyToTransact } = useAppState();
    const premiumMembership = useMembership();
    const { payables, receivables, frendLends, swapsWithUSDMark } = useGlobalUserData('exclude-originating-claims');
    const [openNewLoanModal, setOpenNewLoanModal] = useState(false);
    const [openNewSwapModal, setOpenNewSwapModal] = useState(false);
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const activeTab = (params.get('tab') as HomeDashboardTab | undefined) || homeDashboardTabs[0];

    const {
        connectedNetworkConfig: { frendlendAddress },
    } = useWeb3();

    const noticeCounts = useMemo(() => {
        const pendingPayablesCount = payables.filter(payable => payable.__type === 'Claim' && payable.claimStatus === 'Pending').length;
        const pendingReceivablesCount = receivables.filter(
            receivable => receivable.__type === 'Claim' && receivable.claimStatus === 'Pending',
        ).length;
        const pendingLoanOffersCount = frendLends.filter(lend => lend.status === 'Offered').length;
        const PayableLoans = payables.filter(
            (item): item is FinancedClaimInfo => item.__type == 'Claim' && item.claimStatus == 'Repaying' && isFinancingAccepted(item),
        ).length;
        const ReceivableLoans = receivables.filter(
            (item): item is FinancedClaimInfo => item.__type == 'Claim' && item.claimStatus == 'Repaying' && isFinancingAccepted(item),
        ).length;
        const Swaps = swapsWithUSDMark.filter(x => x.status == 'Pending').length;

        return {
            Payables: pendingPayablesCount,
            Receivables: pendingReceivablesCount,
            LoanOffers: pendingLoanOffersCount,
            LoanPayables: PayableLoans,
            LoanReceivables: ReceivableLoans,
            Swaps,
        };
    }, [payables, receivables, frendLends]);

    const setTab = (tab: HomeDashboardTab) => {
        navigate(getPathnameForHomeTables(tab), { replace: true });
    };

    const renderActiveTabContent = () => {
        switch (activeTab) {
            case 'Payments':
                return <Payments />;
            case 'NetFlows':
                return <NetFlows />;
            case 'NftTransfers':
                return <NftTransfers />;
            case 'Payables':
                return <Payables />;
            case 'Receivables':
                return <Receivables />;
            case 'LoanPayables':
                return <LoanTable type="payables" />;
            case 'LoanReceivables':
                return <LoanTable type="receivables" />;
            case 'LoanOffers':
                return <LoanTable type="offers" />;
            case 'Swaps':
                return <SwapsTable />;
        }
    };

    return (
        <PageLayoutProvider>
            <GettingStartedCard isOpen={showGettingStarted} onClose={() => setGettingStartedEnabled(false)} />
            <Box px="12" flex="1" flexDir={'column'}>
                <MaxWidthWrapper pb="12">
                    <HStack py="12">
                        <Text fontSize={'30px'} fontWeight="600" h="fit-content" my="auto">
                            Explorer
                        </Text>
                        <Box pl="2">
                            <NetworkLoadingSpinner />
                        </Box>
                        <Spacer />
                        <Stack>
                            <Flex direction={'row'} wrap="wrap" gap={'6'}>
                                <CreatePaymentModal
                                    triggerElement={onOpen => (
                                        <QuickAction text="Send" Icon={PaperPlaneRight} onClick={onOpen} isDisabled={!readyToTransact} />
                                    )}
                                />
                                {enableNewInvoiceCreation ? (
                                    <QuickAction
                                        text="Request"
                                        Icon={Receipt}
                                        onClick={() => navigate('/new-invoice')}
                                        isDisabled={!readyToTransact}
                                    />
                                ) : (
                                    <CreateClaimModal
                                        claimType="Invoice"
                                        triggerElement={onOpen => (
                                            <QuickAction text="Request" Icon={Receipt} onClick={onOpen} isDisabled={!readyToTransact} />
                                        )}
                                    />
                                )}

                                {enableNewBatchPage ? (
                                    <QuickAction text="Batch" Icon={ListBullets} onClick={() => navigate('/batch')} />
                                ) : (
                                    <QuickAction
                                        text="Batch"
                                        Icon={ListBullets}
                                        menuItems={[
                                            { label: 'Batch Payment', action: () => navigate('/batch-payment') },
                                            { label: 'Batch Invoice', action: () => navigate('/batch-invoice') },
                                        ]}
                                    />
                                )}
                                {!!frendlendAddress && (
                                    <QuickAction text="Loan" Icon={HandCoins} onClick={() => setOpenNewLoanModal(true)} />
                                )}
                                {enableSwaps && <QuickAction text="Swap" Icon={SwapCoins} onClick={() => setOpenNewSwapModal(true)} />}
                                {(premiumMembership?.tier == 'pro' || bypassProForReportingExplorer) && (
                                    <QuickAction text="Export" Icon={ArrowCircleDown} onClick={() => navigate('/reporting')} />
                                )}
                            </Flex>
                        </Stack>
                    </HStack>
                    <TabSwitcher
                        tab={activeTab}
                        setTab={setTab}
                        options={homeTabOptions}
                        activeColor="brand.bulla_blue"
                        inactiveBorderColor="white"
                        mb="8"
                        noticeCounts={noticeCounts}
                    />
                    {renderActiveTabContent()}
                </MaxWidthWrapper>
            </Box>
            {welcomePremiumData.isWelcome && welcomePremiumData.tier && (
                <WelcomePremiumModal
                    modalOpen={welcomePremiumData.isWelcome}
                    closeModal={clearWelcomePremiumData}
                    tier={welcomePremiumData.tier}
                />
            )}
            {!!frendlendAddress && <CreateFrendlendModal isOpen={openNewLoanModal} onClose={() => setOpenNewLoanModal(false)} />}
            {!!enableSwaps && <CreateSwapModal isOpen={openNewSwapModal} onClose={() => setOpenNewSwapModal(false)} />}
            <CreateItemLinkHandler />
        </PageLayoutProvider>
    );
};

import { ChainId } from '../data-lib/networks';
import { useGnosisSafe } from '../state/gnosis-state';
import { endpoints } from '../tools/common';
import { useBullaBackendHttpClient } from './useHttpClient';
import { useWeb3 } from './useWeb3';

type ContactInfo = {
    name: string;
    email?: string;
    chainIds?: ChainId[];
    groups?: string[];
};

export type ContactRecord = {
    [key: string]: ContactInfo;
};

const bullaFunds = ['0xB17f273e15F8907965f64E3fE8a1A16E10d499De', '0xC09b8198dF96B676bC0CFd5c4CCE468391230240'];

export const useContactsApi = () => {
    const { connectedSafeAddress } = useGnosisSafe();
    const { connectedNetwork, userAddress } = useWeb3();
    const httpClient = useBullaBackendHttpClient();

    const gnosisSafeQueryArgs = !!connectedSafeAddress ? `?account_type=gnosis&chain_id=${connectedNetwork}` : '';
    const actingWallet = !!connectedSafeAddress ? connectedSafeAddress : userAddress;

    const fetchContacts = async (): Promise<Record<string, ContactRecord>> => {
        try {
            const response = await httpClient.get(`${endpoints.contactsApi}/contacts/${actingWallet}${gnosisSafeQueryArgs}`, {
                withCredentials: true,
            });
            return JSON.parse(response.data);
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const saveContacts = async (contacts: ContactRecord): Promise<void> => {
        try {
            await httpClient.post(`${endpoints.contactsApi}/contacts/${actingWallet}${gnosisSafeQueryArgs}`, contacts, {
                withCredentials: true,
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const deleteContacts = async (address: string[]): Promise<void> => {
        try {
            await httpClient.delete(`${endpoints.contactsApi}/contacts/${actingWallet}${gnosisSafeQueryArgs}`, {
                data: address,
                withCredentials: true,
            });
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const isContactOfBullaFund = async (userAddress: string): Promise<boolean> => {
        const responses = await Promise.all(
            bullaFunds.map(bullaFund =>
                httpClient
                    .get(`${endpoints.contactsApi}/check/${bullaFund}/knows/${userAddress}`)
                    .then(e => e.status == 200)
                    .catch(e => {
                        console.warn(e);
                        return false;
                    }),
            ),
        );
        return responses.some(x => x == true);
    };

    return {
        fetchContacts,
        saveContacts,
        deleteContacts,
        isContactOfBullaFund,
    };
};

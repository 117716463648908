import { BatchCreate } from '@bulla-network/contracts/typechain/BatchCreate';
import { BullaBanker } from '@bulla-network/contracts/typechain/BullaBanker';
import { BullaBankerModule } from '@bulla-network/contracts/typechain/BullaBankerModule';
import { parseUnits } from '@ethersproject/units';
import { AssertionError } from 'assert';
import { BigNumber, Contract, ContractTransaction, ethers } from 'ethers';
import React from 'react';
import { CreateClaimFieldsNew } from '../components/display/views/new-invoice';
import { CreateClaimFields } from '../components/modals/create-claim-modal/create-claim-inputs';
import { ClaimInfo, ClaimType } from '../data-lib/data-model';
import { createBullaClaim, createClaimsBatch, updateBullaTag } from '../data-lib/dto/bulla-banker-dto';
import { BullaClaimDto, createClaim } from '../data-lib/dto/bulla-claim-dto';
import { createFinanciableInvoice, FinancingTerms, termLengthToDays } from '../data-lib/dto/bulla-finance-dto';
import {
    getBatchCreateContract,
    getBullaBankerContract,
    getBullaBankerModuleContract,
    getBullaClaimContract,
    getBullaFinanceContract,
} from '../data-lib/dto/contract-interfaces';
import { TransactionResult } from '../data-lib/dto/events-dto';
import { daysToSeconds } from '../data-lib/helpers';
import { getCIDFromMultihashStruct, getMultihashStructFromCID } from '../data-lib/multihash';
import { ChainId, TokenDto } from '../data-lib/networks';
import { tokenAmountToUSD } from '../data-lib/tokens';
import { useGnosisSafe } from '../state/gnosis-state';
import { isAttachmentReady, pinHashes, resolveBullaAttachmentToCID, uploadMetadataToIpfs } from '../tools/ipfs';
import { financeableInvoiceToMultisendTxDTO } from './useBullaClaim';
import { useTokenPrices } from './useChainData';
import { AttachmentLinkGenerator, useCompanyDetailsRepo } from './useCompanyDetailsRepo';
import { useGnosisTransaction } from './useGnosisTransaction';
import { useSendTransaction } from './useSendTransaction';
import { useTokenRepo } from './useTokenRepo';
import { useActingWalletAddress } from './useWalletAddress';
import { useWeb3 } from './useWeb3';

export type CreateClaimsBatch = (
    claimType: ClaimType,
    params: (CreateClaimFieldsNew | CreateClaimFields)[],
) => Promise<TransactionResult | undefined>;
export type BatchCreator = { maxClaimsPerBatch: number; createClaimsBatch: CreateClaimsBatch };

export function requireBatchCreate(batchCreate: string | BatchCreator): asserts batchCreate is BatchCreator {
    if (typeof batchCreate === 'string') throw new AssertionError({ message: 'Batch create not supported. Should never happen' });
}

export const useBullaBanker = () => {
    const {
        signer,
        provider,
        connectedNetworkConfig,
        connectedNetworkConfig: { bullaBankerLatest, label: networkLabel, batchCreate, chainId },
    } = useWeb3();
    const { safeInfo, inSafeApp } = useGnosisSafe();
    const { getTokenPrice, hash } = useTokenPrices();
    const { getAttachmentGenerationLink } = useCompanyDetailsRepo();
    const useGnosisModule = !!safeInfo?.module?.moduleAddress && !inSafeApp;
    const { getTokenByChainIdAndAddress, allTokensLength } = useTokenRepo();

    const userAddress = useActingWalletAddress();
    const [pending, sendTransaction] = useSendTransaction();

    const getUsdValue = React.useCallback(
        (chainId: ChainId, token: TokenDto, claimAmount: BigNumber) => {
            const tokenInfo = getTokenByChainIdAndAddress(chainId)(token.address);
            return tokenAmountToUSD({ amount: claimAmount, tokenInfo }, getTokenPrice);
        },
        [hash, allTokensLength],
    );

    const bankerContract = useGnosisModule
        ? getBullaBankerModuleContract(safeInfo!.module!.moduleAddress)
        : getBullaBankerContract(bullaBankerLatest);

    const batchCreateContract = batchCreate
        ? {
              contract: useGnosisModule
                  ? getBullaBankerModuleContract(safeInfo!.module!.moduleAddress)
                  : getBatchCreateContract(batchCreate.address),
              maxClaims: batchCreate.maxClaims,
          }
        : 'not-supported';

    const execute = async <TContract extends { connect: (signer: ethers.Signer) => TContract }>(
        unconnectedContract: TContract,
        claimFunction: (contract: TContract) => Promise<ContractTransaction>,
    ): Promise<TransactionResult | undefined> => sendTransaction(signer => claimFunction(unconnectedContract.connect(signer)), false);

    const batchCreateObject =
        batchCreateContract == 'not-supported'
            ? 'not-supported'
            : {
                  maxClaimsPerBatch: batchCreateContract.maxClaims,
                  createClaimsBatch: (claimType: ClaimType, params: (CreateClaimFieldsNew | CreateClaimFields)[]) =>
                      execute(batchCreateContract.contract, async (contract: BatchCreate | BullaBankerModule) =>
                          Promise.all(
                              params.map(x =>
                                  toBullaClaimDto(claimType, userAddress, networkLabel, chainId, x, getAttachmentGenerationLink),
                              ),
                          ).then(claimParams =>
                              createClaimsBatch({ claimParams, contract })
                                  .then(transaction => {
                                      claimParams.forEach((claim, i) => {
                                          pinHashes(getCIDFromMultihashStruct(claimParams[i].ipfsHash), claim.tokenURI);
                                      });
                                      return transaction;
                                  })
                                  .catch((e: Error) => {
                                      throw e?.message === 'bytes32 string must be less than 32 bytes'
                                          ? new Error('Categories are too long. Please use less and shorter categories')
                                          : e;
                                  }),
                          ),
                      ),
              };

    const { executeTransactions } = useGnosisTransaction();

    const functions = {
        addAccountTag: (claimInfo: ClaimInfo, newTag: string) =>
            execute(bankerContract, (contract: Contract) =>
                updateBullaTag({ contract, claimInfo, newTag }).catch((e: Error) => {
                    throw e?.message === 'bytes32 string must be less than 32 bytes'
                        ? new Error('Categories are too long. Please use less and shorter categories')
                        : e;
                }),
            ),
        createClaim: (claimType: ClaimType, params: CreateClaimFieldsNew | CreateClaimFields, financingTerms?: FinancingTerms) =>
            execute(bankerContract, (contract: BullaBankerModule | BullaBanker) =>
                toBullaClaimDto(claimType, userAddress, networkLabel, chainId, params, getAttachmentGenerationLink).then(async claim => {
                    try {
                        let transaction;
                        if (financingTerms) {
                            if (!connectedNetworkConfig.bullaFinanceAddress)
                                throw new Error('BullaFinance is not supported on this network');

                            transaction = createFinanciableInvoice({
                                ...claim,
                                interestBPS: financingTerms.interestRateBPS,
                                minDownPaymentBPS: financingTerms.downPaymentBPS,
                                termLength: daysToSeconds(termLengthToDays(financingTerms.termLength)),
                                contract: getBullaFinanceContract(connectedNetworkConfig.bullaFinanceAddress).connect(signer),
                            });
                        } else if (!claim.tags) {
                            // if there are no tags, we can use BullaClaim directly
                            transaction = createClaim({
                                ...claim,
                                contract: getBullaClaimContract(connectedNetworkConfig.bullaClaimAddress).connect(signer),
                            });
                        } else {
                            // a claim with tags needs BullaBanker
                            transaction = createBullaClaim({ ...claim, contract });
                        }

                        await transaction;
                        pinHashes(getCIDFromMultihashStruct(claim.ipfsHash), claim.tokenURI);
                        return transaction;
                    } catch (e: any) {
                        throw e?.message === 'bytes32 string must be less than 32 bytes'
                            ? new Error('Categories are too long. Please use less and shorter categories')
                            : e?.message?.includes('insufficient funds') //TODO prevent this by disabling the "create button"
                            ? new Error('Insufficient Funds for Fee')
                            : e;
                    }
                }),
            ),
        createClaimWithSafeAndFinancing: (
            claimType: ClaimType,
            params: CreateClaimFieldsNew | CreateClaimFields,
            financingTerms: FinancingTerms,
        ): Promise<TransactionResult | undefined> =>
            toBullaClaimDto(claimType, userAddress, networkLabel, chainId, params, getAttachmentGenerationLink).then(
                async (claim: BullaClaimDto) => {
                    try {
                        if (!connectedNetworkConfig.bullaFinanceAddress) throw new Error('BullaFinance is not supported on this network');

                        const { transaction: multiSendTransaction } = await financeableInvoiceToMultisendTxDTO({
                            contract: getBullaFinanceContract(connectedNetworkConfig.bullaFinanceAddress).connect(provider),
                            claim: claim,
                            financingTerms: financingTerms,
                        });

                        const { transactionResult } = await executeTransactions(safeInfo!, [multiSendTransaction], true);

                        return transactionResult;
                    } catch (e: any) {
                        throw e?.message === 'bytes32 string must be less than 32 bytes'
                            ? new Error('Categories are too long. Please use less and shorter categories')
                            : e?.message?.includes('insufficient funds') //TODO prevent this by disabling the "create button"
                            ? new Error('Insufficient Funds for Fee')
                            : e;
                    }
                },
            ),

        batchCreate: batchCreateObject,
    };

    async function toBullaClaimDto(
        claimType: ClaimType,
        userAddress: string,
        networkLabel: string,
        chainId: ChainId,
        {
            claimAmount,
            token,
            recipient,
            attachment,
            dueBy,
            description,
            tags,
            customCreditorAndDebtor,
        }: CreateClaimFieldsNew | CreateClaimFields,
        attachmentLinkGenerator: AttachmentLinkGenerator,
    ) {
        const claimAmountBig = parseUnits(claimAmount, token.decimals);
        const usdValue = getUsdValue(chainId, token, claimAmountBig);

        const [creditor, debtor] = customCreditorAndDebtor
            ? [customCreditorAndDebtor.creditor, customCreditorAndDebtor.debtor]
            : claimType === 'Invoice'
            ? [userAddress, recipient]
            : [recipient, userAddress];

        const attachmentCID = isAttachmentReady(attachment)
            ? await resolveBullaAttachmentToCID({
                  attachment,
                  recipient,
                  amount: claimAmount,
                  description,
                  actingWallet: userAddress,
                  tokenSymbol: token.symbol,
                  type: claimType,
                  date: dueBy,
                  attachmentLinkGenerator,
              })
            : undefined;
        const tokenURI = await uploadMetadataToIpfs(
            networkLabel,
            {
                claimAmount,
                ipfsHash: attachmentCID,
                dueBy,
                creditor,
                debtor,
                token,
                description,
                recipient,
            },
            usdValue,
        );

        return {
            claimAmount: claimAmountBig,
            token,
            creditor,
            debtor,
            description,
            tags,
            dueBy,
            ipfsHash: attachmentCID ? getMultihashStructFromCID(attachmentCID) : emptyMultihash,
            tokenURI,
        };
    }

    return [pending, functions] as const;
};

export const emptyMultihash = {
    hash: ethers.utils.formatBytes32String(''),
    hashFunction: 0,
    size: 0,
};

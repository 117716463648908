{
    "released": true,
    "contractName": "DaimoP256Verifier",
    "version": "0.2.0",
    "networkAddresses": {
        "1": "0xc2b78104907F722DABAc4C69f826a522B2754De4",
        "10": "0xc2b78104907F722DABAc4C69f826a522B2754De4",
        "137": "0xc2b78104907F722DABAc4C69f826a522B2754De4",
        "4078": "0xc2b78104907F722DABAc4C69f826a522B2754De4",
        "8453": "0xc2b78104907F722DABAc4C69f826a522B2754De4",
        "42161": "0xc2b78104907F722DABAc4C69f826a522B2754De4",
        "80002": "0xc2b78104907F722DABAc4C69f826a522B2754De4",
        "84532": "0xc2b78104907F722DABAc4C69f826a522B2754De4",
        "421614": "0xc2b78104907F722DABAc4C69f826a522B2754De4",
        "11155111": "0xc2b78104907F722DABAc4C69f826a522B2754De4",
        "11155420": "0xc2b78104907F722DABAc4C69f826a522B2754De4"
    },
    "abi": [
        {
            "stateMutability": "nonpayable",
            "type": "fallback"
        }
    ]
}

import { useEffect, useState } from 'react';
import { isUserReady, useAuth } from '../state/auth-state';
import { useCompanyDetailsRepo } from './useCompanyDetailsRepo';
import { useActingWalletAddress } from './useWalletAddress';

export type AttachmentGenerationState = 'unauthenticated' | 'fetching' | 'no-details' | 'ready';

export const useAttachmentGenerationState = () => {
    const actingAddress = useActingWalletAddress();
    const { user } = useAuth();
    const { getCompanyDetails, companyDetails, hasCompanyDetails } = useCompanyDetailsRepo();
    const [attachmentGenerationState, setAttachmentGenerationState] = useState<AttachmentGenerationState>('unauthenticated');

    useEffect(() => {
        (async () => {
            if (attachmentGenerationState === 'ready') return 'ready';
            if (!isUserReady(user)) return 'unauthenticated';

            // If we already have company details in the context, use them
            if (hasCompanyDetails) {
                return 'ready';
            }

            // Otherwise check if we're already fetching
            if (companyDetails === 'fetching') {
                return 'fetching';
            }

            // If no company details, look for them
            setAttachmentGenerationState('fetching');
            const details = await getCompanyDetails();
            return !!details ? 'ready' : 'no-details';
        })().then(setAttachmentGenerationState);
    }, [user, actingAddress, companyDetails]);

    return { attachmentGenerationState, setAttachmentGenerationState, getCompanyDetails } as const;
};

import { Box, Flex, Heading, HStack, Spacer, Stack, Text, Tooltip } from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { ArrowLeft } from 'phosphor-react';
import React, { SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { WarningIconWithTooltip } from '../../../assets/warning';
import { weiToDisplayAmt } from '../../../data-lib/ethereum';
import { intToDate } from '../../../data-lib/helpers';
import { ChainId, NETWORKS, TokenInfo } from '../../../data-lib/networks';
import { isContactsReady, useExtendedContacts } from '../../../hooks/useExtendedContacts';
import { _1099PaymentEntryDto, Build1099Response } from '../../../hooks/useExternalTransactionsApi';
import { Priced, PriceFetchResult, useHistoricalPrices } from '../../../hooks/useHistoricalPrices';
import { usePagination } from '../../../hooks/usePagination';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { TokenSafetyContext, useTokenSafety } from '../../../hooks/useTokenSafety';
import { useWeb3 } from '../../../hooks/useWeb3';
import { toDateDisplay, toUSD } from '../../../tools/common';
import { handleExport, toReportDateTimeString } from '../../../tools/excelExport';
import ExportMenu from '../../../tools/exportMenu';
import { AddressLabel, ContactNameOrAddress } from '../../base/address-label';
import { PageSelector } from '../../display/claim-table';
import { ResponsiveStack } from '../../display/responsive-stack';
import { TokenMultiselect } from '../../display/token-multiselect';
import { checkDate, TableFilters } from '../../display/views/filters/claim-filter';
import { ClearFilterPillsStack, DateSelect, PillProps, SetDate, TableFilter } from '../../display/views/filters/common';
import { EntryDescription } from '../../entry-description';
import { BullaBlueTextButton } from '../../inputs/buttons';
import { ColumnDefinition, ListItemProps, ListViewCard, ShadowBox } from '../../layout/cards';
import { MaxWidthWrapper, SummaryPanel } from '../../layout/page-layout';
import { CreateContactModal } from '../create-contact-modal';

type ExportFilterValues = {
    date: { startDate?: Date; endDate?: Date };
    selectedTokenSymbols: Set<string>;
};

type FilterProps = {
    filters: ExportFilterValues;
    setFilters: React.Dispatch<SetStateAction<ExportFilterValues>>;
    selectableTokenSymbols: Set<string>;
};

type _1099PaymentEntry = Priced<Omit<_1099PaymentEntryDto, 'token' | 'timestamp'>> & {
    timestamp: Date;
    token: TokenInfo;
    usdValue: PriceFetchResult;
    displayAmount: number;
};

const ExportFilter = ({ setFilters, filters, selectableTokenSymbols }: FilterProps) => {
    const setDate: SetDate = (type, date) => setFilters(filters => ({ ...filters, date: { ...filters.date, [type]: date } }));
    const resetDates = () => setFilters(filters => ({ ...filters, date: {} }));

    return (
        <Box w="100%" pb="4">
            <ResponsiveStack>
                <DateSelect
                    startDate={filters.date.startDate}
                    endDate={filters.date.endDate}
                    setDate={setDate}
                    resetDates={resetDates}
                    paymentTimestamps={[]}
                />
                <TokenMultiselect
                    seletectableTokenSymbols={selectableTokenSymbols}
                    selectedTokenSymbols={filters.selectedTokenSymbols}
                    toggleTokenSymbol={_symbol => {
                        const symbol = _symbol.toUpperCase();
                        setFilters(filters => {
                            const newSelected = new Set(filters.selectedTokenSymbols);
                            if (newSelected.has(symbol)) {
                                newSelected.delete(symbol);
                            } else {
                                newSelected.add(symbol);
                            }
                            return { ...filters, selectedTokenSymbols: newSelected };
                        });
                    }}
                />
            </ResponsiveStack>
        </Box>
    );
};

const PAYMENT_HEADERS: ColumnDefinition[] = [
    { label: 'DATE', relativeColumnWidth: 'auto' },
    { label: 'FROM' },
    { label: 'TO' },
    { label: 'DESCRIPTION', relativeColumnWidth: '1fr' },
    { label: 'CHAIN', relativeColumnWidth: '1fr' },
    { label: 'TOKEN AMOUNT' },
    { label: 'SYMBOL', relativeColumnWidth: '0.5fr' },
    { label: 'USD MARK', relativeColumnWidth: '1fr' },
    { label: 'USD AMOUNT', relativeColumnWidth: '1fr' },
];

const paymentEntryToRow = (entry: _1099PaymentEntry, isTokenInfoSafe: TokenSafetyContext['isTokenInfoSafe']): ListItemProps => {
    const chainId = entry.chainId as ChainId;
    const isTokenSafe = isTokenInfoSafe(entry.token);
    return {
        columnValues: [
            <Text noOfLines={1} whiteSpace={'nowrap'}>
                {toDateDisplay(entry.timestamp)}
            </Text>,
            <ContactNameOrAddress chainId={chainId} hideAddressIfFound hideActions>
                {entry.from}
            </ContactNameOrAddress>,
            <ContactNameOrAddress chainId={chainId} hideAddressIfFound hideActions>
                {entry.to}
            </ContactNameOrAddress>,
            <EntryDescription description={entry.description} />,
            NETWORKS[chainId].label,
            toUSD(entry.displayAmount, true).replace('$', ''),
            <Tooltip label={entry.token.token.symbol}>
                <HStack>
                    {!isTokenSafe && (
                        <WarningIconWithTooltip
                            label="Beware of using airdropped scam tokens."
                            warningOverrides={{ color: 'red', w: '14px', h: '14px' }}
                        />
                    )}
                    <Text noOfLines={1} textOverflow={'ellipsis'} fontWeight={'bold'}>
                        {entry.token.token.symbol}
                    </Text>
                </HStack>
            </Tooltip>,
            typeof entry.usdMark !== 'string' ? toUSD(entry.usdMark) : 'N/A',
            typeof entry.usdValue !== 'string' ? toUSD(entry.usdValue) : 'N/A',
        ],
        isUnsafe: !isTokenSafe,
    };
};

type RecipientCardProps = {
    address: string;
    entries: _1099PaymentEntry[];
    handleExport: (recipient: string, entries: _1099PaymentEntry[]) => (method: 'csv' | 'excel') => Promise<void>;
};
const RecipientCard = ({ address, entries, handleExport }: RecipientCardProps) => {
    const contactsContext = useExtendedContacts();
    const { connectedNetwork } = useWeb3();
    const { isTokenInfoSafe } = useTokenSafety();

    const contact = isContactsReady(contactsContext) ? contactsContext.getContact(address) : 'loading';

    const totalUSDOut = React.useMemo(() => {
        const totalInUsd = entries.reduce((acc, item) => acc + (typeof item.usdValue == 'number' ? item.usdValue : 0), 0);
        return toUSD(totalInUsd);
    }, [entries]);

    const pageSelectorProps = usePagination(entries);
    const visibleEntries = pageSelectorProps.shownItems;
    const rows = React.useMemo(() => visibleEntries.map(x => paymentEntryToRow(x, isTokenInfoSafe)), [visibleEntries]);
    return (
        <Stack>
            <ShadowBox p="0">
                <HStack px="5" py="4" spacing={'4'}>
                    <Stack spacing={'3'}>
                        <Box
                            borderRadius={'16px'}
                            border="1px"
                            borderColor={'#F3B685'}
                            bg="#FCEDE2"
                            fontSize={'12px'}
                            fontWeight="500"
                            color="#DF5907"
                            py="1"
                            px="2"
                        >
                            {`${entries.length} transaction${entries.length == 1 ? '' : 's'}`}
                        </Box>
                        {contact != 'not-found' && (
                            <ContactNameOrAddress chainId={connectedNetwork} hideAddressIfFound withIcon={false} hideActions>
                                {address}
                            </ContactNameOrAddress>
                        )}
                        <AddressLabel withIcon={false}>{address}</AddressLabel>
                        {contact == 'not-found' && (
                            <CreateContactModal
                                defaults={{ walletAddress: address }}
                                triggerElement={onOpen => (
                                    <BullaBlueTextButton onClick={onOpen} textDecoration="initial">
                                        Add to contacts
                                    </BullaBlueTextButton>
                                )}
                            />
                        )}
                    </Stack>
                    <Box borderRadius={'6px'} border="1px" borderColor="gray.300" py="3" px="3">
                        <Text fontWeight={'600'} fontSize="14px">
                            TOTAL OUT (USD)
                        </Text>
                        <Text fontWeight={700} fontSize="20px">
                            {totalUSDOut}
                        </Text>
                    </Box>
                    <Spacer />
                    <ExportMenu handleExport={handleExport(address, entries)} />
                </HStack>
                <ListViewCard
                    headers={PAYMENT_HEADERS}
                    displayedListItems={rows}
                    removeBorderRadius
                    overflowY={'hidden'}
                    alternateRowColor="#F4F6F9"
                    emptyMessage="No payments found"
                />
            </ShadowBox>
            <PageSelector {...pageSelectorProps} justifySelf="center" pt="6" />
        </Stack>
    );
};

const exportFilterToPills = (setFilters: React.Dispatch<SetStateAction<ExportFilterValues>>) => (filters: ExportFilterValues) => {
    const dateFilterPills: PillProps[] = [
        ...(!!filters.date.startDate
            ? [
                  {
                      label: `From: ${toDateDisplay(filters.date.startDate)}`,
                      clear: () => setFilters(filters => ({ ...filters, date: { ...filters.date, startDate: undefined } })),
                  },
              ]
            : []),
        ...(!!filters.date.endDate
            ? [
                  {
                      label: `To: ${toDateDisplay(filters.date.endDate)}`,
                      clear: () => setFilters(filters => ({ ...filters, date: { ...filters.date, endDate: undefined } })),
                  },
              ]
            : []),
    ];

    const tokenPills: PillProps[] = [...filters.selectedTokenSymbols].map(symbol => ({
        label: symbol,
        clear: () =>
            setFilters(filters => {
                const selectedTokenSymbols = new Set([...filters.selectedTokenSymbols]);
                selectedTokenSymbols.delete(symbol);
                return { ...filters, selectedTokenSymbols };
            }),
    }));

    return [...dateFilterPills, ...tokenPills];
};

const buildExportFilters = (filterValues: ExportFilterValues): TableFilters<_1099PaymentEntry> => {
    const {
        date: { startDate, endDate },
        selectedTokenSymbols,
    } = filterValues;
    const filterByDate =
        !startDate && !endDate ? undefined : ({ timestamp }: _1099PaymentEntry) => checkDate(timestamp, startDate, endDate);

    const filterByToken =
        selectedTokenSymbols.size == 0 ? undefined : (payment: _1099PaymentEntry) => selectedTokenSymbols.has(payment.token.token.symbol);

    return [filterByDate, filterByToken].filter((x): x is TableFilter<_1099PaymentEntry> => x !== undefined);
};

const initialExportFilterValues = (): ExportFilterValues => {
    const now = new Date();
    const year = now.getFullYear();
    const yearToDisplay = now.getMonth() >= 5 ? year : year - 1;
    return {
        date: { startDate: new Date(yearToDisplay, 0, 1), endDate: new Date(yearToDisplay, 11, 31) },
        selectedTokenSymbols: new Set(),
    };
};

const headers = ['Date', 'Chain', 'From', 'To', 'Token', 'Amount', 'USD Mark', 'USD Amount', 'Description', 'Categories', 'TXHash'];

type _1099SuccessCardProps = { recipients: Set<string>; data: Build1099Response; reportName: string };
export const _1099SuccessCard = ({ recipients, data, reportName }: _1099SuccessCardProps) => {
    const navigate = useNavigate();
    const [filters, setFilters] = React.useState<ExportFilterValues>({
        date: { startDate: undefined, endDate: undefined },
        selectedTokenSymbols: new Set(),
    });
    const contactsContext = useExtendedContacts();

    // Workaround, it seems the date picker bricks when it starts with a default value
    React.useEffect(() => {
        setFilters(initialExportFilterValues());
    }, []);

    const { getTokenByChainIdAndAddress } = useTokenRepo();
    const { getHistoricalTokenPrice, USDMark } = useHistoricalPrices();

    const pricedEntries: _1099PaymentEntry[] = React.useMemo(
        () =>
            data.flatMap(x => {
                const token = getTokenByChainIdAndAddress(x.chainId as ChainId)(x.token.address);
                if (!token) return [];

                const timestamp = intToDate(x.timestamp);
                const usdMark = getHistoricalTokenPrice({
                    chainId: x.chainId as ChainId,
                    timestamp,
                    tokenAddress: x.token.address,
                });

                const displayAmount = weiToDisplayAmt({ amountWei: BigNumber.from(x.amountWei), decimals: token.token.decimals });

                const entry: _1099PaymentEntry = {
                    ...x,
                    usdMark,
                    token: token,
                    usdValue: typeof usdMark == 'number' ? usdMark * displayAmount : usdMark,
                    displayAmount,
                    timestamp,
                };

                return [entry];
            }),
        [data, USDMark],
    );

    const selectableTokenSymbols = React.useMemo(
        () => new Set(pricedEntries.map(x => x.token.token.symbol.toUpperCase())),
        [pricedEntries],
    );

    const aggregatedFilter = React.useMemo(() => buildExportFilters(filters), [filters]);

    const filteredPricedEntries: _1099PaymentEntry[] = React.useMemo(
        () =>
            aggregatedFilter
                .reduce((entries, filterFunc) => entries.filter(filterFunc), pricedEntries)
                .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime()),
        [pricedEntries, aggregatedFilter],
    );

    const totalUsdOut = React.useMemo(() => {
        const totalInUsd = filteredPricedEntries.reduce((acc, item) => acc + (typeof item.usdValue == 'number' ? item.usdValue : 0), 0);
        return toUSD(totalInUsd);
    }, [filteredPricedEntries]);

    const entriesPerRecipient = React.useMemo(
        () =>
            filteredPricedEntries.reduce<Record<string, _1099PaymentEntry[]>>((acc, item) => {
                const recipient = item.to.toLowerCase();
                return { ...acc, [recipient]: [...(acc[recipient] ?? []), item] };
            }, Object.fromEntries([...recipients].map(x => [x.toLowerCase(), []]))),
        [filteredPricedEntries],
    );

    const handleExportForRecipient = React.useCallback(
        (recipient: string, entries: _1099PaymentEntry[]) => async (method: 'csv' | 'excel') => {
            const filteredItemsEarliestToLatest = [...entries].sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());

            const dataRows = filteredItemsEarliestToLatest.map(payment => {
                const tags = payment.categories.filter(x => x !== '');
                const chainLabel = NETWORKS[payment.chainId].label;
                const formattedDate = toReportDateTimeString(payment.timestamp);

                return [
                    formattedDate,
                    chainLabel,
                    payment.from,
                    payment.to,
                    payment.token.token.symbol,
                    payment.displayAmount,
                    typeof payment.usdMark == 'string' ? 'N/A' : payment.usdMark,
                    typeof payment.usdValue == 'string' ? 'N/A' : payment.usdValue,
                    payment.description,
                    tags.length == 0 ? '' : `"${tags.join(',')}"`,
                    payment.txHash,
                ];
            });
            const contactNameOrUndefined = () => {
                if (typeof contactsContext !== 'string') {
                    const contact = contactsContext.getContact(recipient);
                    if (contact !== 'not-found') {
                        return contact.name;
                    }
                }
            };
            await handleExport(method, `1099-${reportName}-${contactNameOrUndefined() ?? ''}-${recipient}`, headers, dataRows);
        },
        [contactsContext],
    );

    const handleExportPayments = React.useCallback(
        async (method: 'excel' | 'csv') => {
            Promise.all(
                Object.entries(entriesPerRecipient).map(async ([recipient, entries]) => {
                    // All reports should be sorted earliest to latest
                    await handleExportForRecipient(recipient, entries)(method);
                }),
            );
        },
        [entriesPerRecipient, contactsContext],
    );

    return (
        <Flex p="12" direction="column" flex="1">
            <SummaryPanel>
                <HStack cursor="pointer" onClick={() => navigate('/reporting')} mb="6" color="gray.600">
                    <ArrowLeft size={18} weight="bold" />
                    <Text fontWeight="semibold" fontSize="md">
                        Back to Reporting Explorer
                    </Text>
                </HStack>
                <Flex>
                    <Heading textStyle="bullaViewHeader">1099 Export</Heading>
                    <Spacer />
                    <ExportMenu handleExport={handleExportPayments} />
                </Flex>
            </SummaryPanel>
            <MaxWidthWrapper display={'flex'} flexDirection="column" flex="1">
                <Flex direction={'column'} flex="1" py="8">
                    <ExportFilter filters={filters} setFilters={setFilters} selectableTokenSymbols={selectableTokenSymbols} />
                    <ClearFilterPillsStack
                        clearAll={() =>
                            setFilters({
                                date: { startDate: undefined, endDate: undefined },
                                selectedTokenSymbols: new Set(),
                            })
                        }
                        filters={filters}
                        filtersToPills={exportFilterToPills(setFilters)}
                    />
                    <ShadowBox my="8" px="6" py="8" display={'flex'} flexDirection={'row'} alignItems="center">
                        <Heading size="md" h="fit-content">
                            Summary
                        </Heading>
                        <Spacer />
                        <Box>
                            <Text fontWeight={'600'} fontSize="14px">
                                TOTAL OUT (USD)
                            </Text>
                            <Text fontWeight={700} fontSize="20px">
                                {totalUsdOut}
                            </Text>
                        </Box>
                    </ShadowBox>
                    <Stack spacing={10}>
                        {Object.entries(entriesPerRecipient).map(([address, entries]) => (
                            <RecipientCard
                                key={address}
                                address={address}
                                entries={entries}
                                handleExport={handleExportForRecipient}
                            ></RecipientCard>
                        ))}
                    </Stack>
                </Flex>
            </MaxWidthWrapper>
        </Flex>
    );
};

import { useSafeAppsSDK } from '@safe-global/safe-apps-react-sdk';
import { SafeMultisigTransactionResponse } from '@safe-global/types-kit';
import { BigNumber, BigNumberish, constants, providers, Signer } from 'ethers';
import { MetaTransaction } from 'ethers-multisend';
import { MultisendPayTransaction, MultisendTransaction } from '../../hooks/useGnosisMultisend';
import { IERC20, IGnosisSafe_1_3, IPoster } from '../dto/contract-interfaces';
import { addressEquality, AddressOne, EthAddress, toChecksumAddress, weiToDisplayAmt } from '../ethereum';
import { ZERO_BIGNUMBER } from '../helpers';
import { NetworkConfig, TokenDto } from '../networks';
import { getGnosisTooling, GNOSIS_CONFIG, POSTER_ADDRESS } from './gnosis';

const getPaymentDescription = (tx: MultisendPayTransaction) =>
    `${weiToDisplayAmt({
        amountWei: tx.paymentAmount,
        token: tx.token,
    })} ${tx.token.name} to ${tx.itemInfo.creditor} for: "${tx.itemInfo.description}"`;

const getMultisendDescription = (tx: MultisendTransaction) => {
    switch (tx.itemInfo.__type) {
        case 'Claim':
            return `${tx.interaction == 'Update Tag' ? 'Update Tag for' : tx.interaction} claim #${tx.itemInfo.id}${
                tx.interaction == 'Pay' ? ` for ${getPaymentDescription(tx)}` : ''
            }`;
        case 'InstantPayment':
            switch (tx.interaction) {
                case 'Pay':
                    return `Send ${getPaymentDescription(tx)}`;
                case 'Update Tag':
                    return `Update Tag for payment ${tx.itemInfo.id}`;
                case 'Accept Financing':
                case 'Rescind':
                case 'Reject':
                    throw new Error('Instant payment does not support Reject, Rescind, or Accept Financing');
            }
        case 'FrendLend':
            return tx.label;
    }
};

// describes a multisend transaction in plain text
export const getMultisendDescriptionForTransactions = (transactions: MultisendTransaction[]) => {
    const transactionStrings = transactions.map(getMultisendDescription);

    return `${transactionStrings.slice(0, -1).join(', ')}${transactionStrings.length > 1 ? ' and ' : ''}${
        transactionStrings[transactionStrings.length - 1]
    }. View in Safe App for more details.`;
};

/** this is a smart contrct that allows us to interact create an arbitrary "Post" event by calling a function. The gnosis UI can read these transactions, so we can use them to label them https://github.com/onPoster/contract */
export const getPosterTx = ({ description, address, safeAddress }: { description: string; address?: string; safeAddress?: string }) => ({
    to: address ? toChecksumAddress(address) : POSTER_ADDRESS,
    value: '0',
    operation: 0,
    data: IPoster.encodeFunctionData('post', [description, safeAddress ? `From Gnosis-Safe: ${safeAddress}` : '']),
});

export const getEnableModuleTransaction = (_safeAddress: EthAddress, _moduleAddress: EthAddress) => {
    const safeAddress = toChecksumAddress(_safeAddress);
    const moduleAddress = toChecksumAddress(_moduleAddress);
    return {
        to: safeAddress,
        value: '0',
        operation: 0,
        data: IGnosisSafe_1_3.encodeFunctionData('enableModule', [moduleAddress]),
    };
};

/** modules are stored in a linked-list, so we need to query for all the modules, then find the previous link to override */
export const getDisableModuleTransaction = async (
    providerOrSigner: providers.Provider | Signer,
    networkConfig: NetworkConfig,
    _safeAddress: EthAddress,
    _moduleToDisable: EthAddress,
) => {
    const safeAddress = toChecksumAddress(_safeAddress);
    const moduleToDisable = toChecksumAddress(_moduleToDisable);
    const safeContract = GNOSIS_CONFIG[networkConfig.chainId].getSafeContract(_safeAddress).connect(providerOrSigner);

    // for backwards compatibility with 1.1.1 safes
    const safeModules =
        'getModules' in safeContract
            ? await safeContract.callStatic.getModules()
            : (await safeContract.callStatic.getModulesPaginated(AddressOne, 100))[0];

    if (safeModules.length > 0) {
        const moduleIndex = safeModules.findIndex(address => addressEquality(address, moduleToDisable));
        const prevModuleAddress = toChecksumAddress(moduleIndex === 0 ? AddressOne : safeModules[moduleIndex - 1]);
        return {
            to: safeAddress,
            value: '0',
            operation: 0,
            data: IGnosisSafe_1_3.encodeFunctionData('disableModule', [prevModuleAddress, moduleToDisable]),
        };
    }
};

export const getApproveERC20Transaction = (_addressToApprove: EthAddress, _tokenAddress: EthAddress, amount: BigNumberish) => {
    const tokenAddress = toChecksumAddress(_tokenAddress);
    const addressToApprove = toChecksumAddress(_addressToApprove);
    return {
        to: tokenAddress,
        value: '0',
        operation: 0,
        data: IERC20.encodeFunctionData('approve', [addressToApprove, amount]),
    };
};

export const getApprovalTransactions = (items: { spendingContract: EthAddress; amount: BigNumber; token: TokenDto }[]) => {
    const amounts = items.reduce<{ [tokenAddress: EthAddress]: BigNumber }>((acc, item) => {
        const tokenAddress = item.token.address;
        return tokenAddress && tokenAddress !== constants.AddressZero
            ? { ...acc, [tokenAddress]: (acc[tokenAddress] || ZERO_BIGNUMBER).add(item.amount) }
            : acc;
    }, {});

    return Object.entries(amounts).map(([tokenAddress, amount], i) =>
        getApproveERC20Transaction(items[i].spendingContract, tokenAddress, amount),
    );
};

export const awaitSafeAppTransaction = async (sdk: ReturnType<typeof useSafeAppsSDK>['sdk'], safeTxHash: string): Promise<string> => {
    /** Poll for gnosis tx every 4s */
    const queueRetry = (fn: () => Promise<string>) => new Promise<string>(resolve => setTimeout(() => fn().then(resolve), 4000));
    try {
        await new Promise(resolve => setTimeout(resolve, 2000));
        const tx = await sdk.txs.getBySafeTxHash(safeTxHash);
        if (tx.txStatus === 'FAILED' || tx.txStatus === 'CANCELLED') throw new Error(`Gnosis transaction reverted`);
        return tx.txStatus === 'SUCCESS' && tx.txHash ? tx.txHash : queueRetry(() => awaitSafeAppTransaction(sdk, safeTxHash));
    } catch (e: any) {
        if (e?.message?.includes('Gnosis transaction reverted')) return Promise.reject(`Gnosis transaction reverted`);
        console.log('awaitGnosisTransaction: ', e);
        return await queueRetry(() => awaitSafeAppTransaction(sdk, safeTxHash));
    }
};

/**
 *  gasless propsition of a gnosis tx
 * @returns a wait function which will poll for the completion of the transaction on gnosis
 */
export const proposeSafeTx = async ({
    signer,
    connectedNetworkConfig,
    senderAddress: _senderAddress,
    safeAddress: _safeAddress,
    transaction: _transaction,
}: {
    signer: Signer;
    connectedNetworkConfig: NetworkConfig;
    senderAddress: EthAddress;
    safeAddress: EthAddress;
    transaction: MetaTransaction[] | MetaTransaction;
}) => {
    const senderAddress = toChecksumAddress(_senderAddress);
    const safeAddress = toChecksumAddress(_safeAddress);
    const { safeSdk, safeService } = await getGnosisTooling(signer, connectedNetworkConfig, safeAddress);
    const transaction = Array.isArray(_transaction) ? _transaction : [_transaction];
    const safeTransaction = await safeSdk.createTransaction({ transactions: transaction, onlyCalls: true });

    const safeTxHash = await safeSdk.getTransactionHash(safeTransaction);
    const senderSignatureTx = await safeSdk.signHash(safeTxHash);

    await safeService.proposeTransaction({
        safeAddress,
        senderAddress,
        safeTxHash,
        safeTransactionData: safeTransaction.data,
        senderSignature: senderSignatureTx.data,
    });

    const waitForSafeTx = async (): Promise<SafeMultisigTransactionResponse> => {
        const queueRetry = (fn: () => Promise<SafeMultisigTransactionResponse>) =>
            new Promise<SafeMultisigTransactionResponse>(resolve => setTimeout(() => fn().then(resolve), 4000));
        const retryQuery = () => queueRetry(() => waitForSafeTx());

        try {
            const tx = await safeService.getTransaction(safeTxHash);
            console.log(tx);
            if (tx.isSuccessful === false) throw new Error(`Gnosis transaction reverted`);
            return tx.isSuccessful === true && tx ? tx : retryQuery();
        } catch (e: any) {
            if (e?.message?.includes('Gnosis transaction reverted')) throw new Error(`Gnosis transaction reverted`);
            console.log('awaitGnosisTransaction: ', e);
            return await retryQuery();
        }
    };
    return waitForSafeTx;
};

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { deleteBullaCookie, reloadWindow } from '../tools/common';
import { useWeb3 } from './useWeb3';

/**
 * Custom hook that provides an abstraction around axios HTTP requests for Bulla backend APIs
 * with built-in handling for cookie invalidation (401 status + "CookieInvalidated" response)
 */
export const useBullaBackendHttpClient = () => {
    const { userAddress } = useWeb3();
    /**
     * Execute an HTTP request with automatic handling for cookie invalidation
     * @param requestFn A lambda function that takes an axios instance and performs the request
     * @returns The axios response with the result
     */
    const executeRequest = useCallback(
        async <T = any,>(requestFn: (axiosInstance: AxiosInstance) => Promise<AxiosResponse<T>>): Promise<AxiosResponse<T>> => {
            try {
                return await requestFn(axios);
            } catch (error: any) {
                // Check if the error is due to cookie invalidation
                if (error?.response?.status === 401 && error?.response?.data === 'CookieInvalidated') {
                    console.log('Cookie invalidated, refreshing application...');

                    deleteBullaCookie(userAddress);
                    // Refresh the web app
                    reloadWindow();
                    // This is to ensure the promise stays pending while the page refreshes
                    return new Promise(() => {});
                }
                // Re-throw the error for other error cases
                throw error;
            }
        },
        [],
    );

    /**
     * Wrapper for GET requests
     */
    const get = useCallback(
        <T = any,>(url: string, config?: AxiosRequestConfig) => {
            return executeRequest<T>(axiosInstance => axiosInstance.get(url, config));
        },
        [executeRequest],
    );

    /**
     * Wrapper for POST requests
     */
    const post = useCallback(
        <T = any,>(url: string, data?: any, config?: AxiosRequestConfig) => {
            return executeRequest<T>(axiosInstance => axiosInstance.post(url, data, config));
        },
        [executeRequest],
    );

    /**
     * Wrapper for PUT requests
     */
    const put = useCallback(
        <T = any,>(url: string, data?: any, config?: AxiosRequestConfig) => {
            return executeRequest<T>(axiosInstance => axiosInstance.put(url, data, config));
        },
        [executeRequest],
    );

    /**
     * Wrapper for DELETE requests
     */
    const remove = useCallback(
        <T = any,>(url: string, config?: AxiosRequestConfig) => {
            return executeRequest<T>(axiosInstance => axiosInstance.delete(url, config));
        },
        [executeRequest],
    );

    /**
     * General-purpose method for custom axios requests
     */
    const request = useCallback(
        <T = any,>(config: AxiosRequestConfig) => {
            return executeRequest<T>(axiosInstance => axiosInstance.request(config));
        },
        [executeRequest],
    );

    return {
        executeRequest,
        get,
        post,
        put,
        delete: remove,
        request,
    };
};

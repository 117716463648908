import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    HStack,
    IconButton,
    Input,
    InputGroup,
    InputLeftAddon,
    InputRightElement,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { Field, FieldProps, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FiMinus, FiPlus, FiUploadCloud } from 'react-icons/fi';
import * as Yup from 'yup';
import { getMultihashStructFromCID } from '../../data-lib/multihash';
import { chainIds, FactoringConfig, NETWORKS, TokenDto, TokenVariant } from '../../data-lib/networks';
import { TOKEN_ROUNDING } from '../../data-lib/tokens';
import { useAllowances } from '../../hooks/useAllowances';
import { useBullaFactoring } from '../../hooks/useBullaFactoring';
import { useTokenBalances } from '../../hooks/useChainData';
import { useCompanyDetailsRepo } from '../../hooks/useCompanyDetailsRepo';
import { useGnosisTransaction } from '../../hooks/useGnosisTransaction';
import { useTokenRepo } from '../../hooks/useTokenRepo';
import { useActingWalletAddress } from '../../hooks/useWalletAddress';
import { useWeb3 } from '../../hooks/useWeb3';
import { useAppState } from '../../state/app-state';
import { useGnosisSafe } from '../../state/gnosis-state';
import { isAttachmentReady, pinHash, resolveBullaAttachmentToCID } from '../../tools/ipfs';
import { shortAddress } from '../base/address-label';
import { AlertInfo } from '../base/alert';
import { WithSkeleton } from '../base/skeleton';
import { BullaOrangeTextButton, OrangeButton } from '../inputs/buttons';
import { CloseModalButton } from './common';
import {
    BullaFileObject,
    BullaItemAttachment,
    EmailField,
    FileUploadField,
    FormLabelWithRequiredness,
    isNumberInputValid,
    UploadedFile,
} from './create-claim-modal/create-claim-inputs';
import { TokenApprovalModal } from './token-approval.modal';

interface UploadSectionProps {
    isDisabled: boolean;
    isLoading: boolean;
}

export const UploadSection: React.FC<UploadSectionProps> = ({ isDisabled, isLoading }) => {
    return (
        <VStack my="4">
            <Box borderRadius={'xl'} border="1px solid" borderColor={'gray.300'} shadow="sm" p="3">
                <FiUploadCloud size="24px" color={isDisabled ? 'gray.300' : 'orange.500'} />
            </Box>
            <HStack>
                <BullaOrangeTextButton isDisabled={isDisabled || isLoading} mt="2">
                    Click to upload
                </BullaOrangeTextButton>
                <Text mt="2" color={isDisabled ? 'gray.300' : 'gray.600'}>
                    or drag and drop
                </Text>
            </HStack>
        </VStack>
    );
};

const DepositSummary = ({
    depositAmount,
    pricePerShare,
    poolUnderlyingTokenDecimals,
    poolUnderlyingTokenSymbol,
}: {
    depositAmount: string;
    pricePerShare: BigNumber | null;
    poolUnderlyingTokenDecimals: number;
    poolUnderlyingTokenSymbol: string;
}) => {
    const convert = (amount: string, price: BigNumber | null) => {
        if (!price) return '0';
        const parsedAmount = parseUnits(amount, poolUnderlyingTokenDecimals);
        const scaleFactor = BigNumber.from(10).pow(poolUnderlyingTokenDecimals);
        const convertedAmount = parsedAmount.mul(scaleFactor).div(price);
        return formatUnits(convertedAmount, poolUnderlyingTokenDecimals);
    };

    return (
        <VStack spacing="4" borderRadius={'xl'} p="5" border="1px solid" borderColor={'gray.200'} my="2" alignItems={'left'}>
            <Text fontWeight={'600'}>To receive</Text>
            <HStack>
                <FiPlus color="#3b7a52" width={'50px'} />
                <Text color="#3b7a52" ml="-1">
                    {convert(depositAmount, pricePerShare)}
                </Text>
                <Text>Bulla Fund Token</Text>
            </HStack>
            <HStack>
                <FiMinus color="#a53124" width={'50px'} />
                <Text color="#a53124" ml="-1">
                    {parseFloat(depositAmount[0] === '.' ? '0' + depositAmount : depositAmount)}
                </Text>
                <Text>{poolUnderlyingTokenSymbol}</Text>
            </HStack>
        </VStack>
    );
};

const DepositValidationSchema = Yup.object().shape({
    depositAmount: Yup.number().required('Deposit amount is required'),
    emailAddress: Yup.string().email('Invalid email address'),
});

export const DepositFundsModal: React.FC<{ modalOpen: boolean; closeModal: () => void; factoringConfig: FactoringConfig }> = ({
    modalOpen,
    closeModal,
    factoringConfig,
}) => {
    const { connectedNetwork } = useWeb3();
    const poolChainId = factoringConfig.bullaFactoringToken.chainId;
    const actingWallet = useActingWalletAddress();
    const poolUnderlyingTokenInfo = factoringConfig.poolUnderlyingToken;
    const poolUnderlyingTokenAddress = poolUnderlyingTokenInfo.token.address;
    const poolUnderlyingTokenSymbol = poolUnderlyingTokenInfo.token.symbol;
    const poolUnderlyingTokenDecimals = poolUnderlyingTokenInfo.token.decimals;
    const bullaFundAddress = factoringConfig.bullaFactoringToken.token.address;
    const { safeInfo } = useGnosisSafe();
    const { readyToTransact } = useAppState();
    const [executing, { depositHandler, depositInputToMultisendTxDTO, getPricePerShare, getPoolInfo, previewDeposit }] =
        useBullaFactoring(factoringConfig);
    const [approvalPending, { getAllowanceForTokenAddress, approveTokens, approveModalOpen, closeApproveModal }] =
        useAllowances('exact-allowance');
    const [allowance, setAllowance] = React.useState<'init' | BigNumber>('init');
    const [approved, setApproved] = React.useState(false);
    const [executingSafeTx, setExecutingSafeTx] = useState<boolean>(false);
    const { executeTransactions } = useGnosisTransaction();
    const blockExplorer = connectedNetwork ? NETWORKS[connectedNetwork].blockExplorer : undefined;
    const { getTokenByChainIdAndAddress } = useTokenRepo();
    const tokenBalances = useTokenBalances({ chainId: connectedNetwork, poll: modalOpen });
    const usePreviewDeposit = factoringConfig.bullaFactoringToken.chainId === chainIds.SEPOLIA;

    const tokenRouding =
        TOKEN_ROUNDING[getTokenByChainIdAndAddress(poolChainId)(poolUnderlyingTokenAddress)?.variant ?? TokenVariant.UNKNOWN];

    const _currentTokenBalance = tokenBalances.getBalanceForToken(poolUnderlyingTokenAddress);

    const fetchingBalance = _currentTokenBalance == undefined;

    const currentTokenBalance = _currentTokenBalance?.toFixed(tokenRouding + 2).toString() ?? '0';

    const isLoading = approvalPending || allowance == 'init' || executing || executingSafeTx;
    const isDisabled = !readyToTransact;

    const [pricePerShare, setPricePerShare] = React.useState<BigNumber | null>(null);
    const [poolName, setPoolName] = React.useState<string>('');

    const { getAttachmentGenerationLink } = useCompanyDetailsRepo();

    const getPriceFromPreviewDeposit = async () => {
        if (!usePreviewDeposit) return undefined;
        const amount = parseUnits('1000000', poolUnderlyingTokenDecimals);
        const shares = await previewDeposit(amount);
        if (shares) {
            const pricePerShare = parseUnits(amount.toString(), poolUnderlyingTokenDecimals).div(shares);
            return pricePerShare;
        }
    };

    React.useEffect(() => {
        const fetchPricePerShare = async () => {
            const poolInfo = await getPoolInfo();
            setPricePerShare(poolInfo?.price ?? null);
            setPoolName(poolInfo?.name ?? 'null');
        };

        const setPricePerShareWithPreviewDeposit = async () => {
            const price = await getPriceFromPreviewDeposit();
            if (price) {
                setPricePerShare(price);
            }
        };

        usePreviewDeposit ? setPricePerShareWithPreviewDeposit() : fetchPricePerShare();
    }, [connectedNetwork]);

    const handleDeposit = async (
        token: TokenDto,
        amount: string,
        setFieldError: (field: string, message: string) => void,
        attachment: BullaItemAttachment,
    ) => {
        if (amount === '') return;

        const priceUpdated = usePreviewDeposit ? await getPriceFromPreviewDeposit() : await getPricePerShare();

        if (priceUpdated && pricePerShare && !priceUpdated.eq(pricePerShare)) {
            setFieldError('depositAmount', 'Price per share has been updated. Please close window and try again.');
            return;
        }

        const parsedAmount = parseUnits(amount, poolUnderlyingTokenDecimals);

        const attachmentCID = isAttachmentReady(attachment)
            ? await resolveBullaAttachmentToCID({
                  attachment,
                  actingWallet,
                  amount: amount,
                  description: 'Deposit in Bulla Fund',
                  recipient: actingWallet,
                  tokenSymbol: token.symbol,
                  type: 'Payment',
                  attachmentLinkGenerator: getAttachmentGenerationLink,
              })
            : '';

        const ipfsHash = isAttachmentReady(attachment) ? getMultihashStructFromCID(attachmentCID) : undefined;

        if (safeInfo) {
            setExecutingSafeTx(true);
            try {
                const { transaction } = await depositInputToMultisendTxDTO(
                    bullaFundAddress,
                    poolUnderlyingTokenInfo,
                    actingWallet,
                    parsedAmount,
                    ipfsHash,
                );
                const { transactionResult, success } = await executeTransactions(safeInfo, [transaction], true);
                if (!!attachmentCID) pinHash(attachmentCID);
                if (success && closeModal) closeModal();
                return transactionResult;
            } finally {
                setExecutingSafeTx(false);
            }
        }

        if (approved) {
            await depositHandler(parsedAmount, actingWallet, ipfsHash).then(success => {
                if (!!attachmentCID) pinHash(attachmentCID);
                if (closeModal && success) closeModal();
            });
        } else {
            approveTokens(bullaFundAddress)([{ amount: parsedAmount, token }]).then(({ success }) => {
                if (success) {
                    setApproved(true);
                    setAllowance(parsedAmount);
                }
            });
        }
    };

    return (
        <>
            <Modal
                isCentered
                isOpen={modalOpen}
                onClose={closeModal}
                motionPreset="slideInBottom"
                size={'2xl'}
                closeOnEsc
                scrollBehavior="inside"
            >
                <ModalOverlay />
                <ModalContent>
                    <Formik
                        initialValues={{
                            depositAmount: '',
                            emailAddress: '',
                            attachment: { file: 'not-uploaded' } as BullaFileObject | UploadedFile,
                        }}
                        validationSchema={DepositValidationSchema}
                        onSubmit={(values, actions) => {
                            const { depositAmount } = values;
                            const token = poolUnderlyingTokenInfo.token;

                            handleDeposit(token, depositAmount, actions.setFieldError, values.attachment).then(() => {
                                actions.setSubmitting(false);
                            });
                        }}
                    >
                        {({ errors, touched, handleBlur, setFieldValue, values, setFieldError }) => {
                            const [insufficientFunds, setInsufficientFunds] = React.useState<boolean>();

                            const handleAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
                                const { value } = e.target;

                                if (isNumberInputValid(value) && !value.match(/^\.$/)) {
                                    const decimalIndex = value.indexOf('.');
                                    if (decimalIndex !== -1 && value.length - decimalIndex - 1 > poolUnderlyingTokenDecimals) {
                                        const limitedValue = value.slice(0, decimalIndex + poolUnderlyingTokenDecimals + 1);
                                        setFieldValue('depositAmount', limitedValue);
                                    } else {
                                        setFieldValue('depositAmount', value);
                                    }
                                }
                            };

                            useEffect(() => {
                                if (tokenBalances && values.depositAmount != '') {
                                    const insufficientFundsResult: boolean =
                                        +(tokenBalances.getBalanceForToken(poolUnderlyingTokenAddress) ?? 0) < +values.depositAmount;
                                    setInsufficientFunds(insufficientFundsResult);
                                }
                            }, [values.depositAmount]);

                            useEffect(() => {
                                getAllowanceForTokenAddress(
                                    bullaFundAddress,
                                    connectedNetwork,
                                )(poolUnderlyingTokenAddress).then(setAllowance);

                                if (values.depositAmount === '') return;
                                const parsedDepositAmount = parseUnits(values.depositAmount, poolUnderlyingTokenDecimals);

                                if (allowance !== 'init' && allowance.gt(parsedDepositAmount)) {
                                    setApproved(true);
                                }
                            }, [allowance, values.depositAmount, connectedNetwork]);

                            const setMax = () => {
                                setFieldValue('depositAmount', currentTokenBalance);
                            };

                            return (
                                <Form placeholder={''}>
                                    <ModalHeader>
                                        <Text color="gray.700" fontWeight={'700'} fontSize="25px" alignSelf="center" maxW={'95%'} mb="3">
                                            Deposit Funds to: {poolName} {shortAddress(bullaFundAddress)}
                                            <IconButton
                                                as={Link}
                                                href={`${blockExplorer}address/${bullaFundAddress}`}
                                                isExternal
                                                icon={<ExternalLinkIcon />}
                                                aria-label="Open in explorer"
                                                size="md"
                                                variant="ghost"
                                                _hover={{ background: 'transparent' }}
                                                mb="1"
                                            />
                                        </Text>
                                        <Box mx={-6}>
                                            <Divider width="calc(100%)" sx={{ height: '0.5px' }} />
                                        </Box>
                                    </ModalHeader>
                                    <CloseModalButton onClose={() => closeModal()} />
                                    <ModalBody>
                                        <Stack spacing="3">
                                            <AlertInfo
                                                message={
                                                    <HStack>
                                                        <Text>
                                                            When you deposit USDC into the Bulla Finance Fund, you will receive the
                                                            equivalent amount of Bulla Fund Tokens.
                                                        </Text>
                                                    </HStack>
                                                }
                                            />
                                            <Field name="depositAmount">
                                                {({ field }: FieldProps) => (
                                                    <FormControl isRequired={true} isInvalid={!!errors.depositAmount && !!touched}>
                                                        <Flex justifyContent={'space-between'} w="100%">
                                                            <FormLabelWithRequiredness
                                                                fieldName={field.name}
                                                                isRequired={true}
                                                                label={'Amount'}
                                                            />
                                                            <Spacer />
                                                            <HStack fontSize="sm" color="gray.400">
                                                                <Text>Current balance:</Text>
                                                                <WithSkeleton isLoading={fetchingBalance} fixedWidth="50px">
                                                                    {currentTokenBalance}
                                                                </WithSkeleton>
                                                            </HStack>
                                                        </Flex>

                                                        <InputGroup size="md">
                                                            <InputLeftAddon px="4" bg="transparent">
                                                                {poolUnderlyingTokenSymbol}
                                                            </InputLeftAddon>
                                                            <Input
                                                                {...field}
                                                                id="depositAmount"
                                                                placeholder="Enter deposit amount"
                                                                onChange={handleAmount}
                                                                onBlur={handleBlur}
                                                                isInvalid={!!errors.depositAmount && touched.depositAmount}
                                                                isDisabled={isDisabled}
                                                            />
                                                            <InputRightElement w="fit-content" px="2">
                                                                <Button size="xs" onClick={setMax} mx="1">
                                                                    max
                                                                </Button>
                                                            </InputRightElement>
                                                        </InputGroup>
                                                        {errors.depositAmount && touched.depositAmount && (
                                                            <FormErrorMessage>
                                                                <>{errors.depositAmount}</>
                                                            </FormErrorMessage>
                                                        )}
                                                    </FormControl>
                                                )}
                                            </Field>
                                            {values.depositAmount != '' && (
                                                <DepositSummary
                                                    depositAmount={
                                                        values.depositAmount[0] == '.' ? '0' + values.depositAmount : values.depositAmount
                                                    }
                                                    pricePerShare={pricePerShare}
                                                    poolUnderlyingTokenDecimals={poolUnderlyingTokenDecimals}
                                                    poolUnderlyingTokenSymbol={poolUnderlyingTokenSymbol}
                                                />
                                            )}
                                            <Field name="emailAddress">
                                                {({ field }: FieldProps) => (
                                                    <EmailField
                                                        {...{
                                                            field,
                                                            isDisabled: isDisabled,
                                                            error: errors.emailAddress,
                                                            touched: touched.emailAddress,
                                                            label: 'Confirmation Email',
                                                            required: false,
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            <Field name="attachment">
                                                {({ field }: FieldProps) => (
                                                    <FileUploadField
                                                        attachment={values.attachment}
                                                        setAttachment={(file: BullaItemAttachment | undefined) =>
                                                            setFieldValue('attachment', file)
                                                        }
                                                        renderUploadSection={(isDisabled, isLoading) => (
                                                            <UploadSection isDisabled={isDisabled} isLoading={isLoading} />
                                                        )}
                                                        uploadToIpfsOnAccept
                                                        error={errors.attachment}
                                                        label="Attachment"
                                                        field={field}
                                                        isDisabled={isDisabled || isLoading}
                                                        required={false}
                                                        setErrorOn={() => setFieldError('attachment', 'Error uploading file')}
                                                        setErrorOff={() => setFieldError('attachment', undefined)}
                                                    />
                                                )}
                                            </Field>
                                        </Stack>
                                    </ModalBody>
                                    <ModalFooter>
                                        <>
                                            <OrangeButton
                                                isLoading={isLoading}
                                                isDisabled={isDisabled || isLoading || Object.keys(errors).length > 0 || insufficientFunds}
                                                type="submit"
                                            >
                                                {insufficientFunds
                                                    ? 'Insufficient funds'
                                                    : approved || safeInfo
                                                    ? 'Deposit'
                                                    : 'Deposit (Approve Token)'}
                                            </OrangeButton>
                                            {allowance != 'init' && (
                                                <TokenApprovalModal
                                                    modalOpen={approveModalOpen}
                                                    closeModal={closeApproveModal}
                                                    approvalInfos={[
                                                        {
                                                            approvalAmount:
                                                                values.depositAmount != ''
                                                                    ? parseUnits(values.depositAmount, poolUnderlyingTokenDecimals)
                                                                    : BigNumber.from('0'),
                                                            token: poolUnderlyingTokenInfo.token,
                                                        },
                                                    ]}
                                                />
                                            )}
                                        </>
                                    </ModalFooter>
                                </Form>
                            );
                        }}
                    </Formik>
                </ModalContent>
            </Modal>
        </>
    );
};

import { BigNumber } from 'ethers';
import React from 'react';
import { ClaimStatus, ClaimType, InstantPaymentInfo } from '../data-lib/data-model';
import { intToDate } from '../data-lib/helpers';
import { ChainId, TokenInfo } from '../data-lib/networks';
import { OffchainInvoiceDto, TokenStrategyInfo } from './useExternalTransactionsApi';
import { useTokenRepo } from './useTokenRepo';

type OffchainInvoiceStatus =
    | { kind: 'Paid'; instantPayment: InstantPaymentInfo }
    | { kind: 'Pending' }
    | { kind: 'Rescinded'; cancelledDate: Date };

export class OffchainInvoiceInfo {
    readonly __type = 'OffchainInvoiceInfo';
    readonly claimType: ClaimType;
    readonly creditor: string;
    readonly debtor: string;
    readonly chainId: ChainId;
    readonly id: string;
    readonly email: string;
    readonly dueBy: Date;
    readonly description: string;
    readonly tags: string[];
    readonly notes: string;
    readonly created: Date;
    readonly status: OffchainInvoiceStatus;
    readonly tokenStrategy: TokenStrategyInfo;
    readonly tokenInfo: TokenInfo;
    readonly claimAmount: BigNumber;
    readonly paidAmount: BigNumber;
    readonly claimStatus: ClaimStatus;
    readonly txHash: string | undefined;
    readonly file: { name: string } | undefined;

    constructor(invoice: OffchainInvoiceDto, tokenStrategy: TokenStrategyInfo, instantPayment?: InstantPaymentInfo) {
        this.creditor = invoice.creditor;
        this.id = invoice.id;
        this.email = invoice.email;
        this.debtor = invoice.debtor ?? invoice.email;
        this.dueBy = intToDate(invoice.dueDate);
        this.description = invoice.description;
        this.tags = invoice.categories;
        this.notes = invoice.notes ?? '';
        this.created = intToDate(invoice.created);
        const cancellationDate = invoice.cancellationDate;
        this.status = instantPayment
            ? { kind: 'Paid', instantPayment }
            : cancellationDate
            ? { kind: 'Rescinded', cancelledDate: intToDate(cancellationDate) }
            : { kind: 'Pending' };
        this.tokenStrategy = tokenStrategy;
        this.chainId = tokenStrategy.tokenInfo.chainId;
        this.tokenInfo = tokenStrategy.tokenInfo;
        this.claimAmount = BigNumber.from(tokenStrategy.amountStrategy.amount);
        this.claimStatus = this.status.kind as ClaimStatus;
        this.paidAmount = instantPayment ? instantPayment.paidAmount : BigNumber.from(0);
        this.claimType = 'Invoice';
        this.txHash = instantPayment ? instantPayment.txHash : undefined;
        this.file = invoice.file;
    }
}

export type OffchainInvoiceFactory = (invoice: OffchainInvoiceDto, instantPayment?: InstantPaymentInfo) => OffchainInvoiceInfo | undefined;
export const useOffchainInvoiceFactory = () => {
    const { getTokenByChainIdAndAddress } = useTokenRepo();

    return React.useMemo(() => {
        const createOffchainInvoiceInfo: OffchainInvoiceFactory = (invoice, instantPayment) => {
            const tokenInfo = getTokenByChainIdAndAddress(invoice.tokenStrategy.chainId as ChainId)(invoice.tokenStrategy.tokenAddress);
            if (!tokenInfo) return undefined;
            const tokenStrategy: TokenStrategyInfo = { ...invoice.tokenStrategy, tokenInfo };
            return new OffchainInvoiceInfo(invoice, tokenStrategy, instantPayment);
        };
        return { createOffchainInvoiceInfo };
    }, [getTokenByChainIdAndAddress]);
};

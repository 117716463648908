import {
    OwnerResponse,
    ProposeTransactionProps,
    SafeMultisigTransactionEstimate,
    SafeMultisigTransactionEstimateResponse,
    SafeMultisigTransactionListResponse,
} from '@safe-global/api-kit';
import { SafeMultisigTransactionResponse } from '@safe-global/types-kit';
import axios, { AxiosResponse } from 'axios';
import { TransactionHash } from '../../state/ui-state';
import { EthAddress, isValidAddress, toChecksumAddress } from '../ethereum';
import { NetworkConfig } from '../networks';
import { GNOSIS_CONFIG } from './gnosis';

export class CustomSafeService {
    // @dev the gnosis-safe-service on RSK mainnet does not support the estimation endpoint, so instead we've created a dummy safe to estimate the transaction on testnet. Please reach out to @colinnielsen if this feature needs maintaining.
    RSK_ESTIMATION_SAFE = '0xFC461E15f48274e6bCEE220Cd03b748D4cE7B71B';
    safeServiceURL: string;
    networkConfig: NetworkConfig;

    constructor(networkConfig: NetworkConfig) {
        this.safeServiceURL = GNOSIS_CONFIG[networkConfig.chainId].safeServiceURL;
        this.networkConfig = networkConfig;
    }

    async getTransaction(safeTxHash: TransactionHash) {
        if (safeTxHash === '') throw new Error('Invalid safeTxHash');
        return (
            await axios.get<string, AxiosResponse<SafeMultisigTransactionResponse>>(`${this.safeServiceURL}/transactions/${safeTxHash}/`)
        ).data;
    }

    async getSafesByOwner(owner: EthAddress) {
        if (!isValidAddress(owner)) throw new Error('Invalid owner');
        let res = await axios.get<string, AxiosResponse<OwnerResponse>>(`${this.safeServiceURL}/owners/${owner}/safes/`).catch(() => {
            return { data: { safes: [] } };
        });
        return res.data;
    }

    async estimateSafeTransaction(safeAddress: EthAddress, { data, value, operation, to }: SafeMultisigTransactionEstimate) {
        if (!isValidAddress(safeAddress)) throw new Error('Invalid safe address');
        const baseURL = this.safeServiceURL;
        const safe = safeAddress;

        const response = (
            await axios.post<string, AxiosResponse<SafeMultisigTransactionEstimateResponse>>(
                `${baseURL}/safes/${safe}/multisig-transactions/estimations/`,
                {
                    to,
                    data,
                    value,
                    operation: operation ?? 0,
                },
            )
        ).data;
        return response;
    }

    async getPendingTransactions(safeAddress: EthAddress) {
        const requestURL = `${this.safeServiceURL}/safes/${safeAddress}/multisig-transactions/`;
        return (await axios.get<string, AxiosResponse<SafeMultisigTransactionListResponse>>(requestURL)).data;
    }

    async proposeTransaction({ safeAddress, senderAddress, safeTransactionData, senderSignature, safeTxHash }: ProposeTransactionProps) {
        if (safeAddress === '') throw new Error('Invalid Safe address');
        if (safeTxHash === '') throw new Error('Invalid safeTxHash');

        const transaction = {
            ...safeTransactionData,
            contractTransactionHash: safeTxHash,
            sender: senderAddress,
            signature: senderSignature,
        };
        await axios.post(`${this.safeServiceURL}/safes/${toChecksumAddress(safeAddress)}/multisig-transactions/`, transaction);
    }
}

import { useSafeAppsSDK } from '@safe-global/safe-apps-react-sdk';
import React from 'react';
import { getOnlyBullaItemRelatedLogs } from '../data-lib/data-transforms';
import { getEventsFromReceipt, TransactionResult } from '../data-lib/dto/events-dto';
import { getGnosisSafeURL, gnosis_executePayments, GnosisSafeInfo } from '../data-lib/gnosis-tools/gnosis';
import { getMultisendDescriptionForTransactions } from '../data-lib/gnosis-tools/transactions';
import { useAppState } from '../state/app-state';
import { useUIState } from '../state/ui-state';
import { MultisendTransaction } from './useGnosisMultisend';
import { useWaitForItemEventFromTx } from './useWaitForEvent';
import { useWeb3 } from './useWeb3';

export const useGnosisTransaction = () => {
    const { signer, userAddress, connectedNetworkConfig } = useWeb3();
    const { addAlert } = useUIState();
    const { addEventsToAppState } = useAppState();
    const [searching, eventFound, waitForLogFromTx] = useWaitForItemEventFromTx();

    const { sdk, connected: inSafeApp } = useSafeAppsSDK();
    const [state, setState] = React.useState<{ loading: boolean; progress: number; error: string | undefined }>({
        loading: false,
        progress: 0,
        error: undefined,
    });
    const loading = state.loading || (searching && !eventFound);

    const setError = (error?: string) => setState(state => ({ ...state, error }));
    const setLoading = (loading: boolean) => setState(state => ({ ...state, loading }));
    const incrementProgress = () => setState(state => ({ ...state, progress: state.progress + 1 }));
    const resetProgress = () => setState(state => ({ ...state, progress: 0 }));

    const beginWork = () => {
        resetProgress();
        setError();
        setLoading(true);
    };

    const executeTransactions = async (
        safeInfo: GnosisSafeInfo,
        transactions: MultisendTransaction[],
        includeDescription: boolean,
    ): Promise<{ success: boolean; transactionResult: TransactionResult | undefined }> => {
        try {
            beginWork();
            const { wait: waitForSafeTx } = await gnosis_executePayments({
                signer,
                connectedNetworkConfig,
                userAddress,
                safeInfo,
                transactions,
                transactionDescription: includeDescription ? getMultisendDescriptionForTransactions(transactions) : undefined,
                sdk: inSafeApp ? sdk : undefined,
            });

            const cleanupPendingMessage = addAlert({
                message: 'Gnosis transaction created. Please confirm with your signatories.',
                link: getGnosisSafeURL(connectedNetworkConfig, safeInfo.safeAddress, 'transactions'),
            });
            const response = await waitForSafeTx();
            incrementProgress();
            cleanupPendingMessage();
            const cleanupSuccessMessage = addAlert({ message: 'Gnosis transaction confirmed.', type: 'success' });
            setTimeout(cleanupSuccessMessage, 5000);
            const txHash = typeof response === 'object' ? response.transactionHash : response;
            if (!txHash) {
                throw new Error('Transaction hash is null');
            }
            const receipt = await waitForLogFromTx(txHash);
            const events = getEventsFromReceipt(receipt);
            addEventsToAppState(getOnlyBullaItemRelatedLogs(events));

            const transactionResult: TransactionResult = {
                ...receipt,
                events,
            };

            return { success: true, transactionResult };
        } catch (e: any) {
            addAlert({ message: e.message, type: 'error' });
            setError('Transaction estimation failed.');
            return { success: false, transactionResult: undefined };
        } finally {
            resetProgress();
            setLoading(false);
        }
    };

    return { ...state, loading, executeTransactions };
};

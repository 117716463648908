import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, chakra, HStack, IconButton, Image, Spinner, Text, useBoolean, usePrevious } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from 'url:../../assets/delete.svg';
import { useIsMobile } from '../../hooks/useIsMobile';
import { DEFAULT_QUICK_HASH, quickHash } from '../../tools/common';
import { openIpfsLink, uploadFileToIpfs } from '../../tools/ipfs';
import { BullaBlueTextButton } from './buttons';
import { BullaFileObject, UploadedFile } from '../modals/create-claim-modal/create-claim-inputs';
const FILE_SIZE_MB = 100 * 1000 * 1024; //TODO: what should this be?

export const OpenIPFSFile = ({ ipfsHash }: { ipfsHash: string }) => (
    <BullaBlueTextButton onClick={() => openIpfsLink(ipfsHash)} mr="2" fontWeight={700} fontSize="14px" textDecor={'none'}>
        View File <ExternalLinkIcon ml="2px" mt={'-2px'} />
    </BullaBlueTextButton>
);

export const IPFSLink = ({ ipfsHash }: { ipfsHash: string }) => {
    const hasFile = !!ipfsHash;
    return <>{hasFile ? <OpenIPFSFile ipfsHash={ipfsHash} /> : <Text color={'gray.300'}>No file associated with this item.</Text>}</>;
};

export const FileNameLabel = ({ filename }: { filename: string }) => (
    <Text maxW="48" fontWeight="500" textStyle="underline" mr="2" textOverflow="ellipsis" whiteSpace="nowrap" overflow="clip">
        {filename}
    </Text>
);

export const BullaAttachmentInput = ({
    attachment,
    setAttachment,
    onError,
    onAccept,
    isDisabled,
    uploadToIpfsOnAccept,
    renderUploadSection,
}: {
    attachment: BullaFileObject;
    setAttachment: (file: BullaFileObject | UploadedFile) => void;
    onError: VoidFunction;
    onAccept: VoidFunction;
    isDisabled?: boolean;
    uploadToIpfsOnAccept?: boolean;
    renderUploadSection?: (isDisabled: boolean, isLoading: boolean) => React.ReactNode;
}) => {
    const [isLoading, setLoading] = useBoolean();
    const isMobile = useIsMobile();
    // create a file identifier to save react time comparing a diff in the useEffect
    const fileIdentifier =
        attachment.file === 'not-uploaded'
            ? DEFAULT_QUICK_HASH
            : quickHash(attachment.file.name ?? '' + attachment.file.lastModified ?? '' + attachment?.file.size ?? '');
    const previousIpfsHash = usePrevious(fileIdentifier);

    useEffect(() => {
        if (previousIpfsHash !== DEFAULT_QUICK_HASH && !attachment) setAttachment({ file: 'not-uploaded' });
    }, [fileIdentifier]);

    const onDrop = async ([acceptedFile]: File[]) => {
        const buffer = await acceptedFile.arrayBuffer();
        const file = new File([new Uint8Array(buffer)], acceptedFile.name);

        if (uploadToIpfsOnAccept) {
            setLoading.on();
            const ipfsHash = await uploadFileToIpfs(file);
            setAttachment({ fileName: file.name, ipfsHash, fileType: file.type });
            setLoading.off();
        } else {
            setAttachment({ file });
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        maxSize: FILE_SIZE_MB,
        onDropAccepted: onAccept,
        onDropRejected: onError,
    });

    return (
        <>
            {attachment.file !== 'not-uploaded' ? (
                <HStack>
                    <FileNameLabel filename={attachment.file.name ?? 'Uploaded file'} />
                    <IconButton
                        isDisabled={isDisabled}
                        mx="0"
                        aria-label="delete"
                        onClick={() => setAttachment({ file: 'not-uploaded' })}
                        icon={<Image src={DeleteIcon} color="gray.800" />}
                        colorScheme="gray"
                        size="sm"
                    />
                </HStack>
            ) : (
                <Box
                    _disabled={{ color: 'gray.300' }}
                    py="2"
                    px="3"
                    w="100%"
                    h="100%"
                    border={`${isDragActive ? '2px' : '1px'} dashed #C4C0BC`}
                    transition="all .2s"
                    borderRadius="8"
                    bg={'white'}
                    _hover={{ border: '1px dashed #898683', cursor: 'pointer' }}
                    {...getRootProps()}
                >
                    <HStack mx="auto" w={'fit-content'}>
                        {renderUploadSection ? (
                            renderUploadSection(!!isDisabled, isLoading)
                        ) : (
                            <>
                                {!isMobile && <chakra.span color={isDisabled ? 'gray.300' : 'inherit'}>Drag and drop here or</chakra.span>}
                                <BullaBlueTextButton color={isDisabled ? 'gray.300' : 'brand.bulla_blue'} isDisabled={isDisabled}>
                                    Upload File
                                </BullaBlueTextButton>
                            </>
                        )}
                        <input disabled={isDisabled || isLoading} {...getInputProps()} />
                        {isLoading && <Spinner />}
                    </HStack>
                </Box>
            )}
        </>
    );
};

import axios, { AxiosResponse } from 'axios';
import { create } from 'ipfs-http-client';
import { jsPDF } from 'jspdf';
import { ClaimType } from '../data-lib/data-model';
import { EthAddress } from '../data-lib/ethereum';
import { AttachmentLinkGenerator } from '../hooks/useCompanyDetailsRepo';
import { toDateDisplay, toDateWithTime, toUSD } from './common';
import { IN_DEV_ENV } from './env-constants';
import { importFontsIfNotInitialized, jsPDFSingleton, PDF_WINDOW_WIDTH } from './pdf';
import {
    BullaItemAttachment,
    BullaFileObject,
    UploadedFile,
    CreateClaimFields,
} from '../components/modals/create-claim-modal/create-claim-inputs';

export type ErrorMessage = { errorMessage: string };
export const isErrorMessage = (x: any | ErrorMessage): x is ErrorMessage => (x as ErrorMessage)?.errorMessage !== undefined;

export const openIpfsLink = (hash: string) => window.open(`https://bulla-network.infura-ipfs.io/ipfs/${hash}`, '_blank');

export const uploadFileToIpfs = async (file: File | string): Promise<string> => {
    const client = create({
        url: 'https://ipfs.infura.io:5001/api/v0',
        headers: { authorization: 'Basic MkRVcHFkVDA4aHJvTFUwS3hXUHBVN1E4UkJ2OjY2NzUwMTZmOTRkOTE4Mzk0OWZlNDdjMDE5NDczYjBj' },
    });
    const { path: ipfsHash } = await client.add(file);
    return ipfsHash;
};

export const isAttachmentReady = (attachment: BullaItemAttachment | undefined): attachment is BullaFileObject | UploadedFile | 'generate' =>
    attachment !== undefined &&
    (attachment == 'generate' ||
        ('file' in attachment && attachment.file !== 'not-uploaded') ||
        ('ipfsHash' in attachment && attachment.ipfsHash !== undefined));

export const isAttachmentInMemory = (attachment: BullaItemAttachment | undefined): attachment is BullaFileObject =>
    attachment !== undefined && attachment !== 'generate' && 'file' in attachment && attachment.file !== undefined;

export const isAttachmentUploadFile = (attachment: BullaItemAttachment | undefined): attachment is UploadedFile =>
    attachment !== undefined && attachment !== 'generate' && 'ipfsHash' in attachment && attachment.ipfsHash !== undefined;

export const generateAttachment = async (url: string, filename: string) => {
    await importFontsIfNotInitialized();

    const { data } = await axios.get(url, { responseType: 'text', withCredentials: true });
    const pdfLoading = await new Promise<jsPDF>(resolve =>
        jsPDFSingleton.html(data, { filename, callback: resolve, windowWidth: PDF_WINDOW_WIDTH, width: PDF_WINDOW_WIDTH }),
    );
    const blob = pdfLoading.output('blob');
    return new File([blob], filename, { type: 'application/pdf' });
};

export const resolveBullaAttachmentToCID = async ({
    attachment,
    actingWallet,
    type,
    recipient,
    amount,
    tokenSymbol,
    date = new Date(),
    description,
    attachmentLinkGenerator,
}: {
    attachment: BullaFileObject | UploadedFile | 'generate';
    actingWallet: EthAddress;
    type: ClaimType;
    recipient: EthAddress;
    amount: string;
    tokenSymbol: string;
    date?: Date;
    description: string;
    attachmentLinkGenerator: AttachmentLinkGenerator;
}): Promise<string> => {
    let hash: string;

    if (attachment === 'generate') {
        const url = attachmentLinkGenerator(type, recipient, amount, tokenSymbol, description);
        const filename = `Bulla${type}--${description}-from${actingWallet}`;
        const file = await generateAttachment(url, filename);

        hash = await uploadFileToIpfs(file);
    } else if ('file' in attachment) {
        const file = attachment.file;
        hash = await uploadFileToIpfs(file);
    } else {
        hash = attachment.ipfsHash;
    }

    return hash;
};

const pinHashEndpoint = 'https://bulla-newtork-pinata-functions.azurewebsites.net/api/pinhashv2';
export const pinHash = async (hash: string): Promise<AxiosResponse | string | undefined> => {
    try {
        if (!IN_DEV_ENV) {
            const response = await axios.post(pinHashEndpoint, { hash });
            return response;
        }
    } catch (e) {
        console.error(e);
        return 'Request failed';
    }
};

export function pinHashes(ipfsHash: string | undefined, tokenURI: string) {
    if (ipfsHash) pinHash(ipfsHash);
    pinHash(tokenURI);
}

export const uploadMetadataToIpfs = async (
    networkLabel: string,
    {
        debtor,
        claimAmount,
        token,
        ipfsHash,
        creditor,
        dueBy,
    }: Omit<CreateClaimFields, 'tags' | 'attachment' | 'instantPayment'> & {
        creditor: EthAddress;
        debtor: EthAddress;
        ipfsHash?: string;
    },
    usdValue: number,
) => {
    try {
        const CID = await uploadFileToIpfs(
            JSON.stringify({
                name: `Bulla Claim on the ${networkLabel} network`,
                image: 'QmSPwTThv5TiuLnwKJoxkA2rMrrTxgfA9B3w7Xq8RgwvQ9',
                description: `Claim between ${creditor} as a original creditor, and ${debtor} as a debtor for ${claimAmount} ${
                    token.symbol
                }.${ipfsHash ? '\nView attachment: https://bulla.mypinata.cloud/ipfs/' + ipfsHash : ''}\nUSD value on ${toDateWithTime(
                    new Date(),
                )}: ${toUSD(usdValue)}\nDue by ${toDateDisplay(dueBy)}.\nPay claim at https://banker.bulla.network`,
                attributes: [
                    { trait_type: 'Currency', value: token.symbol },
                    { trait_type: 'Amount', value: claimAmount },
                ],
            }),
        );
        return CID;
    } catch (error) {
        console.error('Failed to upload metadata to IPFS:', error);
        return '';
    }
};

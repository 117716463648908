import React, { useContext } from 'react';
import { allNetworks } from '../data-lib/networks';

export type FactoringVersionMap = {
    [addressAndChainId: string]: string | null;
};

const FactoringVersionContext = React.createContext<FactoringVersionMap | null>(null);

export function FactoringVersionProvider({ children }: { children: React.ReactNode }) {
    const mapping: FactoringVersionMap = {};

    for (const network of allNetworks) {
        const chainId = network.chainId;
        const factoringConfigs = network.factoringConfig || [];

        for (const config of factoringConfigs) {
            const address = config.bullaFactoringToken.token.address.toLowerCase();
            const key = `${address}-${chainId}`;
            mapping[key] = `Bulla Factoring V${config.version}`;
        }
    }

    return <FactoringVersionContext.Provider value={mapping}>{children}</FactoringVersionContext.Provider>;
}

export function useFactoringVersions() {
    const context = useContext(FactoringVersionContext);
    if (!context) {
        throw new Error('useFactoringVersions must be used within a FactoringVersionProvider');
    }
    return context;
}

export function useFactoringVersion(address: string, chainId: number | string) {
    const versions = useFactoringVersions();
    const key = `${address.toLowerCase()}-${chainId}`;
    return versions[key] || null;
}

export function useIsFactoringToken() {
    const versions = useFactoringVersions();
    return (address: string, chainId: number | string) => {
        const key = `${address.toLowerCase()}-${chainId}`;
        return !!versions[key];
    };
}

import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
    Heading,
    HStack,
    Spacer,
    Stack,
    Text,
    Tooltip,
} from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { ArrowLeft } from 'phosphor-react';
import React, { SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WarningIconWithTooltip } from '../../../assets/warning';
import { addressEquality, weiToDisplayAmt } from '../../../data-lib/ethereum';
import { intToDate } from '../../../data-lib/helpers';
import { ChainId, NETWORKS, TokenInfo } from '../../../data-lib/networks';
import { ExternalLinkIconButton } from '../../../data-lib/tokens';
import {
    Build8949Response,
    CashDisbursementDto,
    OutGroupingDto,
    SalesLotDto,
    TaxLotDto,
    Token8949ReportDto,
} from '../../../hooks/useExternalTransactionsApi';
import { usePagination } from '../../../hooks/usePagination';
import { BftTcsPricesByTxHashType, usePoolDetailsRepo } from '../../../hooks/usePoolDetailsRepo';
import { useTokenRepo } from '../../../hooks/useTokenRepo';
import { TokenSafetyContext, useTokenSafety } from '../../../hooks/useTokenSafety';
import { toDateDisplay, toUSD } from '../../../tools/common';
import { ContactNameOrAddress, TXHashLabel } from '../../base/address-label';
import { PageSelector } from '../../display/claim-table';
import { ResponsiveStack } from '../../display/responsive-stack';
import { TokenMultiselect } from '../../display/token-multiselect';
import { TabOptions, TabSwitcher } from '../../display/views/account-tag-view';
import { checkDate, TableFilters } from '../../display/views/filters/claim-filter';
import { ClearFilterPillsStack, DateSelect, PillProps, SetDate, TableFilter } from '../../display/views/filters/common';
import { SecondaryButton } from '../../inputs/buttons';
import { ColumnDefinition, ListItemProps, ListViewCard, ShadowBox } from '../../layout/cards';
import { MaxWidthWrapper, SummaryPanel } from '../../layout/page-layout';
import { EntryDescription } from '../../entry-description';
import { isBftToken, isTcsToken } from '../common';
import { DomainCashDisbursement } from '../ledger-export-wizard-modal/ledger-export-success-card';
import { _8949ExportModal } from './export-modal';
import { TRANSACTION_IN_HEADERS, TRANSACTION_MATCHING_HEADERS, TRANSACTION_OUT_HEADERS, TRANSFER_HEADERS } from './headers';
import { _8949Tab, _8949Tabs, get8949TypeDisplayName } from './tabs-type';

export type DomainSalesLot = Omit<SalesLotDto, 'timestamp' | 'token'> & { timestamp: Date; token: TokenInfo; displayAmount: number };
export type DomainTaxLot = Omit<TaxLotDto, 'timestamp' | 'token'> & { timestamp: Date; token: TokenInfo; displayAmount: number };

type DomainInTransfer = {
    transfer: DomainTaxLot;
    proceeds: string | null;
    cost: string | null;
    gainLoss: string | null;
    afterInBalance: BigNumber;
    afterOutBalance: BigNumber;
    usedAmount: BigNumber;
};
export type DomainOutGrouping = { transfer: DomainSalesLot; ins: DomainInTransfer[]; missingAmount: BigNumber | null };

export type DomainTransfer = Omit<DomainCashDisbursement, 'id' | '__typename'> & { __typename: 'DomainTransfer' };

export type DomainToken8949Report = {
    ins: DomainTaxLot[];
    outs: DomainSalesLot[];
    token: TokenInfo;
    chainId: number;
    outGroupings: DomainOutGrouping[] | null;
};

const exportFilterToPills = (setFilters: React.Dispatch<SetStateAction<ExportFilterValues>>) => (filters: ExportFilterValues) => {
    const dateFilterPills: PillProps[] = [
        ...(!!filters.date.startDate
            ? [
                  {
                      label: `From: ${toDateDisplay(filters.date.startDate)}`,
                      clear: () => setFilters(filters => ({ ...filters, date: { ...filters.date, startDate: undefined } })),
                  },
              ]
            : []),
        ...(!!filters.date.endDate
            ? [
                  {
                      label: `To: ${toDateDisplay(filters.date.endDate)}`,
                      clear: () => setFilters(filters => ({ ...filters, date: { ...filters.date, endDate: undefined } })),
                  },
              ]
            : []),
    ];

    const tokenPills: PillProps[] = [...filters.selectedTokenSymbols].map(symbol => ({
        label: symbol,
        clear: () =>
            setFilters(filters => {
                const selectedTokenSymbols = new Set([...filters.selectedTokenSymbols]);
                selectedTokenSymbols.delete(symbol);
                return { ...filters, selectedTokenSymbols };
            }),
    }));

    return [...dateFilterPills, ...tokenPills];
};

type ExportFilterValues = {
    date: { startDate?: Date; endDate?: Date };
    selectedTokenSymbols: Set<string>;
};

const initialExportFilterValues = (): ExportFilterValues => {
    const now = new Date();
    const year = now.getFullYear();
    const yearToDisplay = now.getMonth() >= 5 ? year : year - 1;
    return {
        date: { startDate: new Date(yearToDisplay, 0, 1), endDate: new Date(yearToDisplay, 11, 31) },
        selectedTokenSymbols: new Set(),
    };
};

const getPathnameFor8949Report = (tab: _8949Tab) => {
    return {
        pathname: '/8949-wizard',
        search: `?tab=${tab}`,
    };
};

type SetFilter = (key: keyof ExportFilterValues, value: ExportFilterValues[keyof ExportFilterValues]) => void;

type FilterProps = {
    filters: ExportFilterValues;
    setFilters: React.Dispatch<SetStateAction<ExportFilterValues>>;
    selectableTokenSymbols: Set<string>;
};

const ExportFilter = ({ setFilters, filters, selectableTokenSymbols }: FilterProps) => {
    const setFilter: SetFilter = (key, value) => setFilters(filters => ({ ...filters, [key]: value }));
    const setDate: SetDate = (type, date) => setFilters(filters => ({ ...filters, date: { ...filters.date, [type]: date } }));
    const resetDates = () => setFilters(filters => ({ ...filters, date: {} }));

    return (
        <Box w="100%" pb="4">
            <ResponsiveStack>
                <DateSelect
                    startDate={filters.date.startDate}
                    endDate={filters.date.endDate}
                    setDate={setDate}
                    resetDates={resetDates}
                    paymentTimestamps={[]}
                />
                <TokenMultiselect
                    seletectableTokenSymbols={selectableTokenSymbols}
                    selectedTokenSymbols={filters.selectedTokenSymbols}
                    toggleTokenSymbol={_symbol => {
                        const symbol = _symbol.toUpperCase();
                        return setFilter(
                            'selectedTokenSymbols',
                            filters.selectedTokenSymbols.has(symbol)
                                ? (() => {
                                      filters.selectedTokenSymbols.delete(symbol);
                                      return filters.selectedTokenSymbols;
                                  })()
                                : filters.selectedTokenSymbols.add(symbol),
                        );
                    }}
                />
            </ResponsiveStack>
        </Box>
    );
};

const getHeaderForActiveTab = (activeTab: _8949Tab): (string | ColumnDefinition)[] => {
    switch (activeTab) {
        case 'transactionsIn':
            return TRANSACTION_IN_HEADERS;
        case 'transactionsOut':
            return TRANSACTION_OUT_HEADERS;
        case 'transactionMatching':
            return TRANSACTION_MATCHING_HEADERS;
        case 'transfers':
            return TRANSFER_HEADERS;
        case 'errors':
            return ['Token', 'Tokens OUT', 'Tokens IN', 'Tokens in Match', 'Status'];
    }
};

const buildExportFilters = (filterValues: ExportFilterValues): TableFilters<DomainSalesLot | DomainTaxLot> => {
    const {
        date: { startDate, endDate },
        selectedTokenSymbols,
    } = filterValues;
    const filterByDate =
        !startDate && !endDate ? undefined : ({ timestamp }: DomainSalesLot | DomainTaxLot) => checkDate(timestamp, startDate, endDate);

    const filterByToken =
        selectedTokenSymbols.size == 0
            ? undefined
            : (payment: DomainSalesLot | DomainTaxLot) => selectedTokenSymbols.has(payment.token.token.symbol.toUpperCase());

    return [filterByDate, filterByToken].filter((x): x is TableFilter<DomainSalesLot | DomainTaxLot> => x !== undefined);
};

type SummaryColProps = { label: string; value: number };
const SummaryCol = ({ value, label }: SummaryColProps) => {
    return (
        <Box>
            <Text fontWeight={'600'} fontSize="14px">
                {label}
            </Text>
            <Text fontWeight={700} fontSize="20px">
                {value.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }) || '-'}
            </Text>
        </Box>
    );
};

const inOrOutToRow = (
    entry: DomainSalesLot | DomainTaxLot | DomainTransfer,
    isTokenInfoSafe: TokenSafetyContext['isTokenInfoSafe'],
): ListItemProps => {
    const chainId = entry.chainId as ChainId;
    const isTokenSafe = isTokenInfoSafe(entry.token);

    const { blockExplorer } = NETWORKS[chainId];

    return {
        columnValues: [
            ...(entry.__typename === 'DomainTransfer' ? [] : [entry.lotNumber]),
            <Text noOfLines={1} whiteSpace={'nowrap'}>
                {toDateDisplay(entry.timestamp)}
            </Text>,
            NETWORKS[chainId].label,
            <ContactNameOrAddress chainId={chainId}>{entry.from}</ContactNameOrAddress>,
            <ContactNameOrAddress chainId={chainId}>{entry.to}</ContactNameOrAddress>,
            <Tooltip label={entry.token.token.symbol}>
                <HStack>
                    {!isTokenSafe && (
                        <WarningIconWithTooltip
                            label="Beware of using airdropped scam tokens."
                            warningOverrides={{ color: 'red', w: '14px', h: '14px' }}
                        />
                    )}
                    <Text noOfLines={1} textOverflow={'ellipsis'} fontWeight={'bold'}>
                        {entry.token.token.symbol}
                    </Text>
                    <ExternalLinkIconButton token={entry.token} />
                </HStack>
            </Tooltip>,
            toUSD(entry.displayAmount, true).replace('$', ''),
            entry.usdValue !== null ? `$${entry.usdValue}` : 'N/A',
            entry.usdMark !== null ? `$${entry.usdMark}` : 'N/A',
            <EntryDescription description={entry.description} />,
            <TXHashLabel blockExplorerURL={blockExplorer} txHash={entry.txHash} />,
        ],
        isUnsafe: !isTokenSafe,
    };
};

const toMatchesRow = (outGrouping: DomainOutGrouping, isTokenInfoSafe: TokenSafetyContext['isTokenInfoSafe']): ListItemProps[] => {
    const chainId = outGrouping.transfer.chainId as ChainId;
    const isTokenSafe = isTokenInfoSafe(outGrouping.transfer.token);
    const { blockExplorer } = NETWORKS[chainId];

    // Create rows for each inTransfer
    const rows = outGrouping.ins.map(inTransfer => {
        return {
            columnValues: [
                NETWORKS[chainId].label,
                <ContactNameOrAddress chainId={chainId} excludeYouBadge>
                    {outGrouping.transfer.from}
                </ContactNameOrAddress>,
                <ContactNameOrAddress chainId={chainId} excludeYouBadge>
                    {outGrouping.transfer.to}
                </ContactNameOrAddress>,
                <Tooltip label={outGrouping.transfer.token.token.symbol}>
                    <HStack>
                        {!isTokenSafe && (
                            <WarningIconWithTooltip
                                label="Beware of using airdropped scam tokens."
                                warningOverrides={{ color: 'red', w: '14px', h: '14px' }}
                            />
                        )}
                        <Text noOfLines={1} textOverflow={'ellipsis'} fontWeight={'bold'}>
                            {outGrouping.transfer.token.token.symbol}
                        </Text>
                        <ExternalLinkIconButton token={outGrouping.transfer.token} />
                    </HStack>
                </Tooltip>,
                weiToDisplayAmt({ amountWei: inTransfer.usedAmount, decimals: outGrouping.transfer.token.token.decimals }),
                <Text noOfLines={1} whiteSpace={'nowrap'}>
                    {toDateDisplay(outGrouping.transfer.timestamp)}
                </Text>,
                <Text noOfLines={1} whiteSpace={'nowrap'}>
                    {toDateDisplay(inTransfer.transfer.timestamp)}
                </Text>,
                inTransfer.proceeds !== null ? `$${inTransfer.proceeds.toString()}` : 'N/A',
                inTransfer.cost !== null ? `$${inTransfer.cost.toString()}` : 'N/A',
                inTransfer.gainLoss !== null ? `$${inTransfer.gainLoss.toString()}` : 'N/A',
                outGrouping.transfer.lotNumber,
                inTransfer.transfer.lotNumber,
                outGrouping.transfer.usdMark !== null ? `$${outGrouping.transfer.usdMark.toString()}` : 'N/A',
                inTransfer.transfer.usdMark !== null ? `$${inTransfer.transfer.usdMark.toString()}` : 'N/A',
                inTransfer.transfer.displayAmount,
                weiToDisplayAmt({ amountWei: inTransfer.afterInBalance, decimals: outGrouping.transfer.token.token.decimals }),
                outGrouping.transfer.displayAmount,
                weiToDisplayAmt({ amountWei: inTransfer.afterOutBalance, decimals: outGrouping.transfer.token.token.decimals }),
                <TXHashLabel blockExplorerURL={blockExplorer} txHash={outGrouping.transfer.txHash} />,
                <TXHashLabel blockExplorerURL={blockExplorer} txHash={inTransfer.transfer.txHash} />,
                <ContactNameOrAddress chainId={chainId} excludeYouBadge>
                    {outGrouping.transfer.from}
                </ContactNameOrAddress>,
            ],
            isUnsafe: !isTokenSafe,
        };
    });

    // Add an additional row if there is a missing amount
    if (outGrouping.missingAmount && !outGrouping.missingAmount.isZero()) {
        const missingAmountDisplay = weiToDisplayAmt({
            amountWei: outGrouping.missingAmount,
            decimals: outGrouping.transfer.token.token.decimals,
        });

        const missingRow = {
            columnValues: [
                NETWORKS[chainId].label,
                <ContactNameOrAddress chainId={chainId} excludeYouBadge>
                    {outGrouping.transfer.from}
                </ContactNameOrAddress>,
                <ContactNameOrAddress chainId={chainId} excludeYouBadge>
                    {outGrouping.transfer.to}
                </ContactNameOrAddress>,
                <Tooltip label={outGrouping.transfer.token.token.symbol}>
                    <HStack>
                        {!isTokenSafe && (
                            <WarningIconWithTooltip
                                label="Beware of using airdropped scam tokens."
                                warningOverrides={{ color: 'red', w: '14px', h: '14px' }}
                            />
                        )}
                        <Text noOfLines={1} textOverflow={'ellipsis'} fontWeight={'bold'}>
                            {outGrouping.transfer.token.token.symbol}
                        </Text>
                        <ExternalLinkIconButton token={outGrouping.transfer.token} />
                    </HStack>
                </Tooltip>,
                missingAmountDisplay,
                <Text noOfLines={1} whiteSpace={'nowrap'}>
                    {toDateDisplay(outGrouping.transfer.timestamp)}
                </Text>,
                <Text color="red.500" fontWeight="bold">
                    MISSING INS
                </Text>,
                'N/A',
                'N/A',
                'N/A',
                outGrouping.transfer.lotNumber,
                <Text color="red.500" fontWeight="bold">
                    MISSING
                </Text>,
                outGrouping.transfer.usdMark !== null ? `$${outGrouping.transfer.usdMark.toString()}` : 'N/A',
                'N/A',
                <Text color="red.500" fontWeight="bold">
                    MISSING
                </Text>,
                'N/A',
                outGrouping.transfer.displayAmount,
                'N/A',
                <TXHashLabel blockExplorerURL={blockExplorer} txHash={outGrouping.transfer.txHash} />,
                '-',
                <ContactNameOrAddress chainId={chainId} excludeYouBadge>
                    {outGrouping.transfer.from}
                </ContactNameOrAddress>,
            ],
            isUnsafe: !isTokenSafe,
        };

        // Return all the regular rows followed immediately by the missing row
        return [...rows, missingRow];
    }

    return rows;
};

const getPriceForTxHash = (bftTcsPricesByTxHash: BftTcsPricesByTxHashType, chainId: number, txHash: string): BigNumber | undefined => {
    const chainIdKey = chainId as ChainId;

    if (bftTcsPricesByTxHash[chainIdKey] && bftTcsPricesByTxHash[chainIdKey][txHash]) {
        return bftTcsPricesByTxHash[chainIdKey][txHash];
    }
    return undefined;
};

const inDtoToDomain = (token: TokenInfo, x: TaxLotDto, bftTcsPricesByTxHash: BftTcsPricesByTxHashType): DomainTaxLot => {
    const timestamp = intToDate(x.timestamp);

    const displayAmount = weiToDisplayAmt({ amountWei: BigNumber.from(x.amountWei), decimals: token.token.decimals });
    const isTcs = isTcsToken(x.chainId, x.token.address);
    const isBft = isBftToken(x.chainId, x.token.address);

    let usdMark = x.usdMark;
    let usdValue = x.usdValue;

    const bftPrice = getPriceForTxHash(bftTcsPricesByTxHash, x.chainId, x.txHash);
    if (isBft && bftPrice) {
        const displayPrice = weiToDisplayAmt({ amountWei: bftPrice, decimals: token.token.decimals });
        usdMark = displayPrice.toString();
        usdValue = (displayAmount * displayPrice).toString();
    } else if (isTcs) {
        usdMark = '0.1';
        usdValue = (displayAmount * 0.1).toString();
    }

    return {
        ...x,
        timestamp,
        displayAmount,
        token,
        usdMark,
        usdValue,
    };
};

const outDtoToDomain = (token: TokenInfo, x: SalesLotDto, bftTcsPricesByTxHash: BftTcsPricesByTxHashType): DomainSalesLot => {
    const timestamp = intToDate(x.timestamp);

    const displayAmount = weiToDisplayAmt({ amountWei: BigNumber.from(x.amountWei), decimals: token.token.decimals });
    const isTcs = isTcsToken(x.chainId, x.token.address);
    const isBft = isBftToken(x.chainId, x.token.address);

    let usdMark = x.usdMark;
    let usdValue = x.usdValue;

    const bftPrice = getPriceForTxHash(bftTcsPricesByTxHash, x.chainId, x.txHash);

    if (isBft && bftPrice) {
        const displayPrice = weiToDisplayAmt({ amountWei: bftPrice, decimals: token.token.decimals });
        usdMark = displayPrice.toString();
        usdValue = (displayAmount * displayPrice).toString();
    } else if (isTcs) {
        usdMark = '0.1';
        usdValue = (displayAmount * 0.1).toString();
    }

    return {
        ...x,
        timestamp,
        displayAmount,
        token,
        usdMark,
        usdValue,
    };
};

const outGroupingToDomain =
    (token: TokenInfo, bftTcsPricesByTxHash: BftTcsPricesByTxHashType) =>
    (x: OutGroupingDto): DomainOutGrouping => {
        const transfer = outDtoToDomain(token, x.transfer, bftTcsPricesByTxHash);
        const isTcs = isTcsToken(x.transfer.chainId, token.token.address);
        const isBft = isBftToken(x.transfer.chainId, token.token.address);

        const ins = x.ins.map((inTransfer): DomainInTransfer => {
            const usedAmount = BigNumber.from(inTransfer.usedAmount);
            const displayAmount = weiToDisplayAmt({
                amountWei: usedAmount,
                decimals: token.token.decimals,
            });

            let proceeds = inTransfer.proceeds?.toString() ?? null;
            let cost = inTransfer.cost?.toString() ?? null;
            let gainLoss = inTransfer.gainLoss?.toString() ?? null;

            if (isTcs) {
                proceeds = (displayAmount * 0.1).toString();
                cost = (displayAmount * 0.1).toString();
                gainLoss = '0';
            } else if (isBft) {
                const bftPrice = getPriceForTxHash(bftTcsPricesByTxHash, x.transfer.chainId, x.transfer.txHash);

                if (bftPrice) {
                    const displayPrice = weiToDisplayAmt({ amountWei: bftPrice, decimals: token.token.decimals });
                    proceeds = (displayAmount * displayPrice).toString();

                    const inBftPrice = getPriceForTxHash(bftTcsPricesByTxHash, inTransfer.transfer.chainId, inTransfer.transfer.txHash);

                    if (inBftPrice) {
                        const inDisplayPrice = weiToDisplayAmt({ amountWei: inBftPrice, decimals: token.token.decimals });
                        cost = (displayAmount * inDisplayPrice).toString();
                        gainLoss = (displayAmount * (displayPrice - inDisplayPrice)).toString();
                    }
                }
            }

            return {
                transfer: inDtoToDomain(token, inTransfer.transfer, bftTcsPricesByTxHash),
                usedAmount,
                afterInBalance: BigNumber.from(inTransfer.afterInBalance),
                afterOutBalance: BigNumber.from(inTransfer.afterOutBalance),
                proceeds,
                cost,
                gainLoss,
            };
        });

        const missingAmount = x.missingAmount ? BigNumber.from(x.missingAmount) : null;
        return { transfer, ins, missingAmount };
    };

const toDomain =
    (getTokenByChainIdAndAddress: (chainId: ChainId) => (address: string) => TokenInfo, bftTcsPricesByTxHash: BftTcsPricesByTxHashType) =>
    (report: Token8949ReportDto): DomainToken8949Report[] => {
        const token = getTokenByChainIdAndAddress(report.chainId as ChainId)(report.token.address);
        if (!token) return [];

        const ins = report.ins.map(x => inDtoToDomain(token, x, bftTcsPricesByTxHash));
        const outs = report.outs.map(x => outDtoToDomain(token, x, bftTcsPricesByTxHash));

        const outGroupings = report.outGroupings ? report.outGroupings.map(outGroupingToDomain(token, bftTcsPricesByTxHash)) : null;

        return [{ ins, outs, token, chainId: report.chainId, outGroupings }];
    };

const transferToDomain =
    (getTokenByChainIdAndAddress: (chainId: ChainId) => (address: string) => TokenInfo, bftTcsPricesByTxHash: BftTcsPricesByTxHashType) =>
    (x: CashDisbursementDto): DomainTransfer[] => {
        const token = getTokenByChainIdAndAddress(x.chainId as ChainId)(x.token.address);
        if (!token) return [];

        const timestamp = intToDate(x.timestamp);

        const displayAmount = weiToDisplayAmt({ amountWei: BigNumber.from(x.amountWei), decimals: token.token.decimals });
        const isTcs = isTcsToken(x.chainId, x.token.address);
        const isBft = isBftToken(x.chainId, x.token.address);

        let usdMark = x.usdMark;
        let usdValue = x.usdValue;

        const bftPrice = getPriceForTxHash(bftTcsPricesByTxHash, x.chainId, x.txHash);

        if (isBft && bftPrice) {
            const displayPrice = weiToDisplayAmt({ amountWei: bftPrice, decimals: token.token.decimals });
            usdMark = displayPrice.toString();
            usdValue = (displayAmount * displayPrice).toString();
        } else if (isTcs) {
            usdMark = '0.1';
            usdValue = (displayAmount * 0.1).toString();
        }

        return [
            {
                ...x,
                token,
                timestamp,
                displayAmount,
                usdMark,
                usdValue,
                __typename: 'DomainTransfer',
                amount: x.amountWei,
            },
        ];
    };

const calculateTokenBalances = (ins: DomainTaxLot[], outs: DomainSalesLot[]) => {
    // Process ins to create initial balances map
    const insBalances = ins.reduce(
        (acc, entry) => {
            const key = `${entry.chainId}-${entry.token.token.address.toLowerCase()}`;
            const existingBalance = acc.get(key);

            // Create a new balance object rather than mutating
            acc.set(key, {
                tokenInfo: entry.token,
                inAmount: (existingBalance?.inAmount || 0) + entry.displayAmount,
                outAmount: existingBalance?.outAmount || 0,
                hasPriceDefinedAtLeastOnce: existingBalance?.hasPriceDefinedAtLeastOnce || entry.usdMark !== null,
            });

            return acc;
        },
        new Map<
            string,
            {
                tokenInfo: TokenInfo;
                inAmount: number;
                outAmount: number;
                hasPriceDefinedAtLeastOnce: boolean;
            }
        >(),
    );

    // Process outs to update balances map without mutating existing entries
    const completeBalances = outs.reduce((acc, entry) => {
        const key = `${entry.chainId}-${entry.token.token.address.toLowerCase()}`;
        const existingBalance = acc.get(key);

        // Create a new balance object rather than mutating
        acc.set(key, {
            tokenInfo: entry.token,
            inAmount: existingBalance?.inAmount || 0,
            outAmount: (existingBalance?.outAmount || 0) + entry.displayAmount,
            hasPriceDefinedAtLeastOnce: existingBalance?.hasPriceDefinedAtLeastOnce || entry.usdMark !== null,
        });

        return acc;
    }, insBalances);

    return completeBalances;
};

const calculateMatchedVsOutBalances = (groupings: DomainOutGrouping[], outs: DomainSalesLot[]) => {
    // Process groupings to create initial balances map
    const matchedBalances = groupings.reduce(
        (acc, grouping) => {
            const key = `${grouping.transfer.chainId}-${grouping.transfer.token.token.address.toLowerCase()}`;
            const existingBalance = acc.get(key);

            // Create a new balance object rather than mutating
            acc.set(key, {
                tokenInfo: grouping.transfer.token,
                matchedAmount: (existingBalance?.matchedAmount || 0) + grouping.transfer.displayAmount,
                outAmount: existingBalance?.outAmount || 0,
                hasPriceDefinedAtLeastOnce: existingBalance?.hasPriceDefinedAtLeastOnce || grouping.transfer.usdMark !== null,
            });

            return acc;
        },
        new Map<
            string,
            {
                tokenInfo: TokenInfo;
                matchedAmount: number;
                outAmount: number;
                hasPriceDefinedAtLeastOnce: boolean;
            }
        >(),
    );

    // Process outs to update balances map without mutating existing entries
    const completeBalances = outs.reduce((acc, entry) => {
        const key = `${entry.chainId}-${entry.token.token.address.toLowerCase()}`;
        const existingBalance = acc.get(key);

        // Create a new balance object rather than mutating
        acc.set(key, {
            tokenInfo: entry.token,
            matchedAmount: existingBalance?.matchedAmount || 0,
            outAmount: (existingBalance?.outAmount || 0) + entry.displayAmount,
            hasPriceDefinedAtLeastOnce: existingBalance?.hasPriceDefinedAtLeastOnce || entry.usdMark !== null,
        });

        return acc;
    }, matchedBalances);

    return completeBalances;
};

type _8949SuccessCardProps = { data: Build8949Response; reportName: string };
export const _8949SuccessCard = ({ data: { reports: data, transfers: _transfers }, reportName }: _8949SuccessCardProps) => {
    const navigate = useNavigate();
    const _8949TabOptions: TabOptions<_8949Tab>[] = _8949Tabs.map(tab => ({
        label: get8949TypeDisplayName(tab),
        value: tab,
    }));
    const setTab = (tab: _8949Tab) => {
        setActiveTab(tab);
        navigate(getPathnameFor8949Report(tab), { replace: true });
    };
    const [activeTab, setActiveTab] = useState<_8949Tab>(_8949Tabs[0]);
    const { getTokenByChainIdAndAddress } = useTokenRepo();
    const [filters, setFilters] = React.useState<ExportFilterValues>({
        date: { startDate: undefined, endDate: undefined },
        selectedTokenSymbols: new Set(),
    });
    const { isTokenInfoSafe } = useTokenSafety();
    const { bftTcsPricesByTxHash } = usePoolDetailsRepo();

    React.useEffect(() => {
        setFilters(initialExportFilterValues());
    }, []);

    const aggregatedFilter = React.useMemo(() => buildExportFilters(filters), [filters]);

    const domainReports = React.useMemo(() => data.map(toDomain(getTokenByChainIdAndAddress, bftTcsPricesByTxHash)).flat(), [data]);
    const domainTransfers = React.useMemo(
        () =>
            _transfers
                .map(transferToDomain(getTokenByChainIdAndAddress, bftTcsPricesByTxHash))
                .flat()
                .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime()),
        [data],
    );

    const ins = React.useMemo(
        () => domainReports.flatMap(x => x.ins).sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime()),
        [domainReports],
    );
    const outs = React.useMemo(
        () => domainReports.flatMap(x => x.outs).sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime()),
        [domainReports],
    );

    const selectableTokenSymbols = React.useMemo(
        () =>
            new Set([
                ...domainReports.map(x => x.token.token.symbol.toUpperCase()),
                ...domainTransfers.map(x => x.token.token.symbol.toUpperCase()),
            ]),
        [domainReports, domainTransfers],
    );

    const groupings = React.useMemo(
        () =>
            domainReports
                .flatMap(x => x.outGroupings)
                .filter((x): x is DomainOutGrouping => x !== null)
                .sort((a, b) => a.transfer.lotNumber - b.transfer.lotNumber),
        [domainReports],
    );

    const filteredIns = React.useMemo(
        () => aggregatedFilter.reduce((entries, filterFunc) => entries.filter(filterFunc), ins),
        [ins, aggregatedFilter],
    );

    const filteredOuts = React.useMemo(
        () => aggregatedFilter.reduce((entries, filterFunc) => entries.filter(filterFunc), outs),
        [outs, aggregatedFilter],
    );

    const filteredGroupings = React.useMemo(
        () => aggregatedFilter.reduce((entries, filterFunc) => entries.filter(x => filterFunc(x.transfer)), groupings),
        [groupings, aggregatedFilter],
    );

    const filteredInRows = React.useMemo(() => filteredIns.map(entry => inOrOutToRow(entry, isTokenInfoSafe)), [filteredIns]);
    const filteredOutRows = React.useMemo(() => filteredOuts.map(entry => inOrOutToRow(entry, isTokenInfoSafe)), [filteredOuts]);
    const filteredMatchingRows = React.useMemo(() => filteredGroupings.flatMap(x => toMatchesRow(x, isTokenInfoSafe)), [filteredGroupings]);
    const transferRows = React.useMemo(() => domainTransfers.map(x => inOrOutToRow(x, isTokenInfoSafe)), [domainTransfers]);

    const { totalProceeds, totalCosts, totalGainLoss } = React.useMemo(() => {
        const totalProceeds = filteredGroupings
            .flatMap(x => x.transfer.usdValue)
            .filter((x): x is string => x !== null)
            .map(x => +x)
            .reduce((a, b) => a + b, 0);

        const totalCosts = filteredGroupings
            .flatMap(x => x.ins)
            .map(x => (x.cost ? +x.cost : 0))
            .reduce((a, b) => a + b, 0);

        const totalGainLoss = filteredGroupings
            .flatMap(x => x.ins)
            .map(x => (x.gainLoss ? +x.gainLoss : 0))
            .reduce((a, b) => a + b, 0);

        return { totalProceeds, totalCosts, totalGainLoss };
    }, [filteredGroupings]);
    const errorRows: ListItemProps[] = React.useMemo(() => {
        const inOutBalances = calculateTokenBalances(ins, outs);
        const matchedBalances = calculateMatchedVsOutBalances(groupings, outs);

        // First create combined balances from inOutBalances
        const initialCombinedBalances = Array.from(inOutBalances.entries()).reduce(
            (acc, [key, value]) => {
                acc.set(key, {
                    ...value,
                    matchedAmount: 0,
                });
                return acc;
            },
            new Map<
                string,
                {
                    tokenInfo: TokenInfo;
                    inAmount: number;
                    outAmount: number;
                    matchedAmount: number;
                    hasPriceDefinedAtLeastOnce: boolean;
                }
            >(),
        );

        // Then merge in matchedBalances
        const combinedBalances = Array.from(matchedBalances.entries()).reduce((acc, [key, value]) => {
            const existing = acc.get(key) || {
                ...value,
                inAmount: 0,
                outAmount: 0,
            };
            acc.set(key, { ...existing, matchedAmount: value.matchedAmount });
            return acc;
        }, initialCombinedBalances);

        return Array.from(combinedBalances.values()).map(
            ({ tokenInfo, inAmount, outAmount, matchedAmount, hasPriceDefinedAtLeastOnce }) => {
                const errors = [];
                if (inAmount < outAmount) {
                    errors.push('Tokens IN less than Tokens OUT');
                }
                if (Math.abs(matchedAmount - outAmount) > 0.0001) {
                    errors.push('Tokens Out not all in match');
                }

                const status =
                    errors.length === 0 ? (
                        <Text color="green.500">Status: OK</Text>
                    ) : (
                        <Text color="red.500" fontWeight="bold">
                            Status: {errors.length} Errors ({errors.join(', ')})
                        </Text>
                    );

                const tokenInstance = [...ins, ...outs].find(
                    x => x.chainId === tokenInfo.chainId && addressEquality(x.token.token.address, tokenInfo.token.address),
                )?.token;
                const isTokenSafe = hasPriceDefinedAtLeastOnce || (tokenInstance && isTokenInfoSafe(tokenInstance));
                const blockExplorer = NETWORKS[tokenInfo.chainId].blockExplorer;
                return {
                    columnValues: [
                        <Tooltip label={tokenInfo.token.symbol}>
                            <HStack>
                                {!isTokenSafe && (
                                    <WarningIconWithTooltip
                                        label="Beware of using airdropped scam tokens."
                                        warningOverrides={{ color: 'red', w: '14px', h: '14px' }}
                                    />
                                )}
                                <Text fontWeight={'bold'}>{tokenInfo.token.symbol}</Text>
                                <ExternalLinkIconButton token={tokenInfo} />
                            </HStack>
                        </Tooltip>,
                        <Text>{outAmount.toFixed(8)}</Text>,
                        <Text>{inAmount.toFixed(8)}</Text>,
                        <Text>{matchedAmount.toFixed(8)}</Text>,
                        status,
                    ],
                    isUnsafe: !isTokenSafe,
                };
            },
        );
    }, [ins, outs, groupings, isTokenInfoSafe]);

    const rows = React.useMemo(() => {
        switch (activeTab) {
            case 'transactionsIn':
                return filteredInRows;
            case 'transactionsOut':
                return filteredOutRows;
            case 'transactionMatching':
                return filteredMatchingRows;
            case 'transfers':
                return transferRows;
            case 'errors':
                return errorRows;
        }
    }, [activeTab, filteredInRows, filteredOutRows, filteredMatchingRows, transferRows, errorRows]);

    const pageSelectorOptions = usePagination(rows, 10);
    const rowsToDisplay = pageSelectorOptions.shownItems;

    const getSummaryColsForActiveTab = React.useCallback(
        (activeTab: _8949Tab) => {
            switch (activeTab) {
                case 'transactionsIn':
                    return [{ label: 'COST', value: filteredIns.map(x => (x.usdValue ? +x.usdValue : 0)).reduce((a, b) => a + b, 0) }];
                case 'transactionsOut':
                    return [
                        {
                            label: 'PROCEEDS SALE',
                            value: filteredOuts.map(x => (x.usdValue ? +x.usdValue : 0)).reduce((a, b) => a + b, 0),
                        },
                    ];
                case 'transactionMatching':
                    return [
                        {
                            label: 'PROCEEDS SALE',
                            value: totalProceeds,
                        },
                        {
                            label: 'COST',
                            value: totalCosts,
                        },
                        {
                            label: 'GAIN/LOSS',
                            value: totalGainLoss,
                        },
                    ];
                case 'transfers':
                    return [];
                case 'errors':
                    return [];
            }
        },
        [filteredIns, filteredOuts, totalProceeds, totalCosts, totalGainLoss],
    );

    return (
        <Flex p="12" direction="column" flex="1">
            <SummaryPanel>
                <HStack cursor="pointer" onClick={() => navigate('/reporting')} mb="6" color="gray.600">
                    <ArrowLeft size={18} weight="bold" />
                    <Text fontWeight="semibold" fontSize="md">
                        Back to Reporting Explorer
                    </Text>
                </HStack>
                <Flex>
                    <Heading textStyle="bullaViewHeader">8949 Report</Heading>
                    <Spacer />
                    <_8949ExportModal
                        triggerElement={onClick => (
                            <SecondaryButton onClick={onClick} h="10" px="6">
                                Export
                            </SecondaryButton>
                        )}
                        filteredIns={filteredIns}
                        filteredOuts={filteredOuts}
                        filteredGroupings={filteredGroupings}
                        transfers={domainTransfers}
                    />
                </Flex>
            </SummaryPanel>
            <MaxWidthWrapper display={'flex'} flexDirection="column" flex="1">
                <Stack pos="relative" spacing="0" overflow={'auto'} mb="8">
                    <HStack mt="4" alignItems="flex-end">
                        <TabSwitcher tab={activeTab} setTab={setTab} options={_8949TabOptions} activeColor="brand.bulla_blue" />
                    </HStack>
                </Stack>
                <ExportFilter filters={filters} setFilters={setFilters} selectableTokenSymbols={selectableTokenSymbols} />
                <ClearFilterPillsStack
                    clearAll={() => setFilters({ date: { startDate: undefined, endDate: undefined }, selectedTokenSymbols: new Set() })}
                    filters={filters}
                    filtersToPills={exportFilterToPills(setFilters)}
                />
                <ShadowBox my="8" px="6" py="8" display={'flex'} flexDirection={'row'} alignItems="center">
                    <Heading size="md" h="fit-content">
                        Summary
                    </Heading>
                    <Spacer />
                    <Stack direction="row" spacing={4}>
                        {getSummaryColsForActiveTab(activeTab).map((x, i) => (
                            <SummaryCol key={i} label={x.label} value={x.value} />
                        ))}
                    </Stack>
                </ShadowBox>

                {(() => {
                    // Determine which rows to show in the main list
                    const displayRows = activeTab === 'errors' ? rowsToDisplay.filter(row => !row.isUnsafe) : rowsToDisplay;

                    // Get unsafe rows for the Errors tab
                    const unsafeRows = activeTab === 'errors' ? rows.filter(row => row.isUnsafe) : [];

                    return (
                        <>
                            <ListViewCard
                                headers={getHeaderForActiveTab(activeTab)}
                                displayedListItems={displayRows}
                                emptyMessage="No data available"
                                alternateRowColor="#F4F6F9"
                                showOverflowX
                            />

                            {activeTab === 'errors' && unsafeRows.length > 0 && (
                                <Accordion allowToggle mt={6} mb={4}>
                                    <AccordionItem border="none">
                                        <h2>
                                            <AccordionButton py={3}>
                                                <Box flex="1" textAlign="left" fontWeight="bold" color="black">
                                                    Hidden Tokens ({unsafeRows.length})
                                                </Box>
                                                <AccordionIcon />
                                            </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                            <Text mb={3} color="gray.600">
                                                These tokens have been identified as potentially unsafe. Exercise caution when interacting
                                                with them.
                                            </Text>
                                            <ListViewCard
                                                headers={getHeaderForActiveTab(activeTab)}
                                                displayedListItems={unsafeRows}
                                                emptyMessage="No unsafe tokens"
                                                alternateRowColor="#F4F6F9"
                                                showOverflowX
                                            />
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            )}
                        </>
                    );
                })()}

                <PageSelector {...pageSelectorOptions} pt="6" />
            </MaxWidthWrapper>
        </Flex>
    );
};

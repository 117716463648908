import { DeleteIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Center,
    Checkbox,
    Divider,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    Fade,
    Flex,
    HStack,
    IconButton,
    Spacer,
    Stack,
    Text,
    useBoolean,
    useDisclosure,
} from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import React, { useEffect } from 'react';
import { ClaimInfo } from '../../data-lib/data-model';
import { ZERO_BIGNUMBER } from '../../data-lib/helpers';
import { TokenInfo } from '../../data-lib/networks';
import { pendingClaimAmountUSD } from '../../data-lib/tokens';
import { TokenBalancesDictionary, useTokenBalances, useTokenPrices } from '../../hooks/useChainData';
import { MultisendContext, MultisendTransaction, useGnosisGlobalMultisend } from '../../hooks/useGnosisMultisend';
import { useTokenRepo } from '../../hooks/useTokenRepo';
import { useActingWalletAddress } from '../../hooks/useWalletAddress';
import { useWeb3 } from '../../hooks/useWeb3';
import { useGnosisSafe } from '../../state/gnosis-state';
import { AddressLabel } from '../base/address-label';
import { TokenAmount } from '../currency/token-display-amount';

type TransactionArray = {
    transactionArray: MultisendTransaction[];
};
type TransactionListProps = TransactionArray & Pick<MultisendContext, 'removeTransaction'>;
type MultisendFooterProps = Pick<MultisendContext, 'sendTransaction' | 'isLoading' | 'error'> & TransactionArray;

const TransactionList = ({ transactionArray, removeTransaction }: TransactionListProps) => {
    const {
        connectedNetworkConfig: { chainId },
    } = useWeb3();
    const { getTokenByChainIdAndAddress } = useTokenRepo();
    return (
        <>
            {transactionArray.map((txn, index) => {
                const handleDelete = () => removeTransaction(txn);
                const tokenInfo = 'token' in txn ? getTokenByChainIdAndAddress(chainId)(txn.token.address) : undefined;
                const amount = 'paymentAmount' in txn ? txn.paymentAmount : ZERO_BIGNUMBER;

                return (
                    <React.Fragment key={index}>
                        <Box py="6">
                            <HStack justify={'space-between'}>
                                <Text fontWeight={600} fontSize={'16px'}>
                                    {txn.label}
                                </Text>
                                <HStack>
                                    {tokenInfo && (
                                        <>
                                            <TokenAmount ml="4" fontWeight={600} amount={amount} tokenInfo={tokenInfo} />
                                            <Text>To:</Text>
                                            <AddressLabel>{txn.itemInfo.creditor}</AddressLabel>
                                        </>
                                    )}
                                    <IconButton
                                        onClick={handleDelete}
                                        aria-label="Delete transaction"
                                        icon={<DeleteIcon />}
                                        isRound
                                        variant="ghost"
                                    />
                                </HStack>
                            </HStack>
                        </Box>
                        <Divider />
                    </React.Fragment>
                );
            })}
        </>
    );
};

export const useInsufficientFundsChecker = () => {
    const { connectedNetwork } = useWeb3();
    const tokenBalances = useTokenBalances({ chainId: connectedNetwork, poll: true });

    return (claims: ClaimInfo[]) => {
        const totals = claims.reduce<TokenBalancesDictionary>((acc, claim) => {
            const address = claim.tokenInfo?.token.address.toLowerCase();
            const token = claim.tokenInfo?.token;
            if (address && token) {
                const claimAmount = +formatUnits(claim.claimAmount, token.decimals);
                return { ...acc, [address]: (acc[address] ?? 0) + claimAmount };
            } else return acc;
        }, {});

        return tokenBalances
            ? Object.entries(totals).some(([address, total]) => (total ?? 0) > (tokenBalances.getBalanceForToken(address) ?? 0))
            : true;
    };
};

const MultisendFooter = ({ error, isLoading, sendTransaction, transactionArray }: MultisendFooterProps) => {
    const { connectedNetwork } = useWeb3();
    const [includeDescription, setIncludeDescription] = useBoolean(true);
    const { getTokenPrice } = useTokenPrices();
    const hasInsufficientFunds = useInsufficientFundsChecker();
    const payableTransactions = transactionArray.filter(x => x.label.includes('Pay'));

    const insufficientFunds = hasInsufficientFunds(payableTransactions.map(x => x.itemInfo).filter((x): x is ClaimInfo => x !== undefined));

    const getMultisendTotal = () => {
        const claims = payableTransactions.map(txn => txn.itemInfo).filter((claim): claim is ClaimInfo => !!claim);
        const total = pendingClaimAmountUSD(claims, getTokenPrice);
        return total.toLocaleString();
    };

    const tokenInfoByTokenAddress = payableTransactions
        .map(txn => txn.itemInfo)
        .filter((itemInfo): itemInfo is ClaimInfo => !!itemInfo && 'tokenInfo' in itemInfo)
        .map(claim => ({
            address: claim.tokenInfo?.token.address.toLowerCase(),
            tokenInfo: claim.tokenInfo,
        }))
        .reduce<Record<string, TokenInfo | undefined>>(
            (acc, { address, tokenInfo }) => ({
                ...acc,
                [address]: tokenInfo ?? undefined,
            }),
            {},
        );

    const groupedTransactions = transactionArray.reduce<Record<string, { tokenInfo: TokenInfo | undefined; totalAmount: BigNumber }>>(
        (acc, txn) => {
            if ('token' in txn && 'paymentAmount' in txn) {
                const tokenAddress = txn.token.address.toLowerCase();
                return {
                    ...acc,
                    [tokenAddress]: {
                        tokenInfo: tokenInfoByTokenAddress[tokenAddress],
                        totalAmount: (acc[tokenAddress]?.totalAmount || ZERO_BIGNUMBER).add(txn.paymentAmount),
                    },
                };
            }
            return acc;
        },
        {},
    );

    return (
        <Stack w="100%" align={'flex-end'} spacing="8">
            <Box w="100%" textAlign={'end'}>
                <Flex justifyContent="space-between" alignItems="flex-end">
                    <Stack spacing={0.5} alignItems={'flex-start'} w="fit-content" key="asset" mb="2">
                        <Text fontWeight={700} fontSize={'1.2em'} color="#353739" pb="1" whiteSpace={'nowrap'}>
                            Asset Breakdown
                        </Text>
                        {Object.entries(groupedTransactions).map(amountByTokenAddress => {
                            const address = amountByTokenAddress[0];
                            const amount = amountByTokenAddress[1].totalAmount;

                            return (
                                <Box key={address}>
                                    <HStack>
                                        <Text fontWeight={400} fontSize={'1.2em'} color={'#353739'}>
                                            {formatUnits(amount, tokenInfoByTokenAddress[address]?.token.decimals ?? 18)}
                                        </Text>
                                        <Text fontWeight={700} fontSize={'1.2em'} color={'#353739'}>
                                            {tokenInfoByTokenAddress[address]?.token.symbol ?? '---'}
                                        </Text>
                                    </HStack>
                                </Box>
                            );
                        })}
                    </Stack>
                    <Text fontWeight={700} fontSize={'1.2em'} color={'#353739'} mb="2">
                        Total: ${getMultisendTotal()}
                    </Text>
                </Flex>
                <Divider />
                <Fade in={!!error || insufficientFunds} unmountOnExit>
                    <Text fontSize={'xs'} color="red">
                        {error && (
                            <>
                                {error}{' '}
                                {(!error?.includes('denied message') || !error.includes('rejected')) &&
                                    '- Please ensure all transactions are valid.'}
                            </>
                        )}
                        {insufficientFunds && 'Insufficient funds'}
                    </Text>
                </Fade>
            </Box>
            <HStack w="100%">
                <HStack>
                    <Text>Include a description of these transactions?</Text>
                    <Checkbox isChecked={includeDescription} onChange={setIncludeDescription.toggle} />
                </HStack>
                <Spacer w="100%" />

                <Button
                    colorScheme="orange"
                    h="12"
                    isDisabled={insufficientFunds || transactionArray.length === 0}
                    onClick={() => sendTransaction(includeDescription)}
                    isLoading={isLoading}
                >
                    Submit Transaction
                </Button>
            </HStack>
        </Stack>
    );
};

export const MultisendMenu = () => {
    const { isSafeReady } = useGnosisSafe();
    const { transactions, error, isLoading, sendTransaction: _sendTransaction, removeTransaction, isFull } = useGnosisGlobalMultisend();
    const actingWallet = useActingWalletAddress();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const openButtonRef = React.useRef(null);
    const transactionArray = Object.values(transactions);
    const sendTransaction = (includeDescription: boolean, theseTransactions?: MultisendTransaction[] | undefined) =>
        _sendTransaction(includeDescription, theseTransactions).then(({ success }) => {
            success && onClose();
            return { success };
        });

    useEffect(() => {
        transactionArray.forEach(transaction => {
            removeTransaction(transaction);
        });
    }, [actingWallet]);

    return (
        <>
            {isSafeReady && (
                <>
                    <Fade in={transactionArray.length > 0 && !isOpen}>
                        <Button
                            pos={'fixed'}
                            borderRadius={'full'}
                            bottom="6"
                            right="6"
                            h="16"
                            ref={openButtonRef}
                            colorScheme="orange"
                            onClick={onOpen}
                            zIndex={'1500'}
                            boxShadow={'2xl'}
                        >
                            <Box pos="relative">
                                <Flex
                                    top="-6"
                                    right="-2"
                                    position={'absolute'}
                                    bg={isFull ? 'red' : 'black'}
                                    borderRadius={'50%'}
                                    px="2"
                                    minW="20px"
                                    h="20px"
                                    justify={'center'}
                                    align={'center'}
                                    boxShadow={'md'}
                                >
                                    {transactionArray.length}
                                </Flex>
                                <Text>Transaction</Text>
                                <Text>bundle</Text>
                            </Box>
                        </Button>
                    </Fade>
                    <Drawer
                        isOpen={isOpen}
                        placement="right"
                        onClose={onClose}
                        finalFocusRef={openButtonRef}
                        size={'lg'}
                        portalProps={{ appendToParentPortal: true }}
                    >
                        <DrawerContent my="4" borderLeftRadius={'xl'} boxShadow={'2xl'}>
                            <DrawerHeader color="black">
                                <Text color="icon_dark" textStyle="labelLg">
                                    Gnosis Safe Multisend Transactions
                                </Text>
                            </DrawerHeader>
                            <DrawerBody color={'black'}>
                                <Box h="100%">
                                    {transactionArray.length ? (
                                        <TransactionList removeTransaction={removeTransaction} transactionArray={transactionArray} />
                                    ) : (
                                        <Center h="100%" w="100%">
                                            <Text color="gray.500">No transactions</Text>
                                        </Center>
                                    )}
                                </Box>
                            </DrawerBody>
                            <DrawerFooter>
                                {isOpen && <MultisendFooter {...{ transactionArray, sendTransaction, isLoading, error }} />}
                            </DrawerFooter>
                        </DrawerContent>
                    </Drawer>
                </>
            )}
        </>
    );
};

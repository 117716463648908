{
    "released": true,
    "contractName": "FCLP256Verifier",
    "version": "0.2.0",
    "networkAddresses": {
        "1": "0x445a0683e494ea0c5AF3E83c5159fBE47Cf9e765",
        "10": "0x445a0683e494ea0c5AF3E83c5159fBE47Cf9e765",
        "137": "0x445a0683e494ea0c5AF3E83c5159fBE47Cf9e765",
        "4078": "0x445a0683e494ea0c5AF3E83c5159fBE47Cf9e765",
        "8453": "0x445a0683e494ea0c5AF3E83c5159fBE47Cf9e765",
        "42161": "0x445a0683e494ea0c5AF3E83c5159fBE47Cf9e765",
        "80002": "0x445a0683e494ea0c5AF3E83c5159fBE47Cf9e765",
        "84532": "0x445a0683e494ea0c5AF3E83c5159fBE47Cf9e765",
        "421614": "0x445a0683e494ea0c5AF3E83c5159fBE47Cf9e765",
        "11155111": "0x445a0683e494ea0c5AF3E83c5159fBE47Cf9e765",
        "11155420": "0x445a0683e494ea0c5AF3E83c5159fBE47Cf9e765"
    },
    "abi": [
        {
            "stateMutability": "nonpayable",
            "type": "fallback"
        }
    ]
}

import React from 'react';
import {
    Container,
    Box,
    VStack,
    Spacer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    HStack,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
} from '@chakra-ui/react';
import { ContinueButton, CreateSmallWhiteButton, WhiteButton } from '../../inputs/buttons';
import { SavingIndicator, StepCard } from './select-claim-types-card';
import { BatchFooter } from './shared-components';
import { InvoiceDetailsNew, NewBatchWizardState, PaymentDetailsNew, SavingStatus, SelectWalletsTypeState } from './new-batch-wizard-modal';
import { ContactSelectionModal, ImportFromPreviousBatchModal } from './import-modals';
import { MinusCircle } from 'phosphor-react';
import { OneTagPillOrMore } from '../../inputs/account-tag-input';
import { isValidAddress } from '../../../data-lib/ethereum';
import { ImportRecipientsModal } from '../import-recipients-modal';
import { DefaultBatchClaimValues } from './batch-state';
import { useBullaToast } from '../../base/toast';

const AddFromContactsButton = CreateSmallWhiteButton('Add from contacts');
const AddRecipientManuallyButton = CreateSmallWhiteButton('Add recipient manually');
const ImportFromCSVButton = CreateSmallWhiteButton('Import from CSV');
const ImportFromPreviousBatchButton = CreateSmallWhiteButton('Import from previous batch');

interface ClosedSelectWalletsCardProps {
    setWizardState: React.Dispatch<React.SetStateAction<NewBatchWizardState>>;
    wizardState: NewBatchWizardState;
}

export const ClosedSelectWalletsCard = ({ setWizardState, wizardState }: ClosedSelectWalletsCardProps) => {
    const displayEditButton = wizardState.kind !== 'SelectClaimType';

    return (
        <StepCard
            stepNumber={2}
            title="Select Wallets for Batch"
            rightElement={
                displayEditButton && (
                    <WhiteButton
                        onClick={() =>
                            setWizardState(previousState => ({
                                ...(previousState as Omit<SelectWalletsTypeState, 'kind'>),
                                kind: 'SelectWallets',
                            }))
                        }
                    >
                        Edit
                    </WhiteButton>
                )
            }
        />
    );
};

type OpenedSelectWalletsCardProps = {
    state: SelectWalletsTypeState;
    setWizardState: React.Dispatch<React.SetStateAction<NewBatchWizardState>>;
    onCancel: () => void;
    initialDefaultValuesWithToken: DefaultBatchClaimValues;
    savingStatus: SavingStatus;
};

const ContactsTable = ({ contacts, onRemove }: { contacts: PaymentDetailsNew[]; onRemove: (index: number) => void }) => {
    return (
        <Box border="1px solid" borderColor="gray.200" borderRadius="md" boxShadow="sm" overflow="hidden">
            <Table size="sm" variant="simple">
                <Thead bg="#FAFAFA">
                    <Tr>
                        <Th textTransform="none" fontSize="sm" fontWeight="600" color="gray.500" py={3}>
                            Name
                        </Th>
                        <Th textTransform="none" fontSize="sm" fontWeight="600" color="gray.500" py={3}>
                            Wallet Address
                        </Th>
                        <Th textTransform="none" fontSize="sm" fontWeight="600" color="gray.500" py={3}>
                            Email
                        </Th>
                        <Th textTransform="none" fontSize="sm" fontWeight="600" color="gray.500" py={3}>
                            Group
                        </Th>
                        <Th width="50px"></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {contacts.map((contact, index) => (
                        <Tr key={index} _hover={{ bg: 'gray.50' }}>
                            <Td py={3}>{contact.name || ''}</Td>
                            <Td py={3} color="gray.600" isTruncated maxW="200px">
                                {contact.walletAddress}
                            </Td>
                            <Td py={3} color="gray.600">
                                {contact.emailAddress || ''}
                            </Td>
                            <Td py={3} color="gray.600">
                                <OneTagPillOrMore tags={contact.groups ?? []} />
                            </Td>
                            <Td py={3}>
                                <Box as="button" onClick={() => onRemove(index)} cursor="pointer">
                                    <MinusCircle size={24} color="#A4A7AE" />
                                </Box>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    );
};

export const filterBatchSearchBar = (contacts: PaymentDetailsNew[], searchQuery: string) => {
    if (!searchQuery.trim()) return contacts;

    const query = searchQuery.toLowerCase().trim();
    return contacts.filter(contact => {
        const searchFields = [contact.name, contact.walletAddress, contact.emailAddress, ...(contact.groups || [])];
        return searchFields.some(field => String(field).toLowerCase().includes(query));
    });
};

export const OpenedSelectWalletsCard: React.FC<OpenedSelectWalletsCardProps> = ({
    state,
    setWizardState,
    onCancel,
    initialDefaultValuesWithToken,
    savingStatus,
}) => {
    const [newWalletAddress, setNewWalletAddress] = React.useState('');
    const [addWalletManually, setAddWalletManually] = React.useState(false);
    const [isAddressInvalid, setIsAddressInvalid] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState('');
    const isDisabled = !state.claimDetails || state.claimDetails?.length === 0;

    const handleContinue = () => {
        setWizardState({
            ...state,
            kind: 'AddPaymentDetails',
        });
    };

    const handleRemoveContact = (index: number) => {
        setWizardState(prev => ({
            ...prev,
            claimDetails: (prev as SelectWalletsTypeState).claimDetails?.filter((_, i) => i !== index),
        }));
    };

    const handleAddWallet = () => {
        if (!isValidAddress(newWalletAddress)) {
            setIsAddressInvalid(true);
            return;
        }

        setWizardState(
            prev =>
                ({
                    ...prev,
                    claimDetails: [
                        ...((prev as SelectWalletsTypeState).claimDetails || []),
                        {
                            walletAddress: newWalletAddress,
                            token: initialDefaultValuesWithToken.defaultToken,
                            dueDate: initialDefaultValuesWithToken.defaultDueDate,
                            description: '',
                        },
                    ],
                } as SelectWalletsTypeState),
        );
        setAddWalletManually(false);
    };

    const handleWalletInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewWalletAddress(e.target.value);
        setIsAddressInvalid(false);
    };

    return (
        <Container maxW="100%" p="0">
            <VStack align="stretch">
                <StepCard stepNumber={2} title="Select Wallets for Batch" isActive={true}>
                    <HStack>
                        {state.claimDetails && state.claimDetails.length > 0 && (
                            <Input
                                placeholder="Find by name, wallet, email, or group..."
                                value={searchQuery}
                                maxW="500px"
                                onChange={e => setSearchQuery(e.target.value)}
                            />
                        )}
                        <ContactSelectionModal
                            onAdd={contacts => {
                                const newDetails = contacts.map(
                                    (contact): InvoiceDetailsNew => ({
                                        name: contact.name,
                                        walletAddress: contact.walletAddress,
                                        emailAddress: contact.emailAddress ?? '',
                                        confirmationEmailAddress: '',
                                        groups: contact.groups ?? [],
                                        token: initialDefaultValuesWithToken.defaultToken,
                                        dueDate: initialDefaultValuesWithToken.defaultDueDate,
                                        description: '',
                                    }),
                                );
                                setWizardState(prev => ({ ...prev, claimDetails: [...newDetails, ...(state.claimDetails || [])] }));
                            }}
                            triggerElement={onOpen => <AddFromContactsButton onClick={onOpen} w="fit-content" />}
                        />
                        <AddRecipientManuallyButton onClick={() => setAddWalletManually(true)} />
                        <ImportRecipientsModal
                            triggerElement={onOpen => <ImportFromCSVButton onClick={onOpen} />}
                            save={recipients => {
                                const newDetails = recipients.map(
                                    ({
                                        walletAddress,
                                        emailAddress,
                                        confirmationEmailAddress,
                                        name,
                                        amount,
                                        description,
                                        category: tag,
                                        token,
                                    }): InvoiceDetailsNew => ({
                                        walletAddress,
                                        emailAddress: emailAddress ?? '',
                                        confirmationEmailAddress: confirmationEmailAddress ?? '',
                                        name: name ?? '',
                                        amount,
                                        description,
                                        groups: !!tag ? [tag] : undefined,
                                        token: token?.token,
                                        dueDate: initialDefaultValuesWithToken.defaultDueDate,
                                    }),
                                );
                                setWizardState(prev => ({
                                    ...prev,
                                    claimDetails: [...newDetails, ...(prev as SelectWalletsTypeState).claimDetails],
                                }));
                            }}
                        />
                        <ImportFromPreviousBatchModal
                            claimType={state.claimType}
                            onAdd={paymentDetails =>
                                setWizardState(prev => ({
                                    ...prev,
                                    claimDetails: [
                                        ...paymentDetails.map(detail => ({
                                            ...detail,
                                            groups: detail.tags,
                                        })),
                                        ...((prev as SelectWalletsTypeState).claimDetails || []),
                                    ],
                                }))
                            }
                            triggerElement={onOpen => <ImportFromPreviousBatchButton onClick={onOpen} />}
                        />
                    </HStack>
                    {addWalletManually && (
                        <HStack w="500px" mt="4" mb="-4">
                            <FormControl isInvalid={isAddressInvalid}>
                                <Input
                                    value={newWalletAddress}
                                    onChange={handleWalletInputChange}
                                    placeholder="Enter wallet address (0x...)"
                                />
                                {isAddressInvalid && <FormErrorMessage>Please enter a valid wallet address</FormErrorMessage>}
                            </FormControl>
                            <Button colorScheme="orange" onClick={handleAddWallet}>
                                Add
                            </Button>
                        </HStack>
                    )}
                    {state.claimDetails && state.claimDetails.length > 0 && (
                        <ContactsTable contacts={filterBatchSearchBar(state.claimDetails, searchQuery)} onRemove={handleRemoveContact} />
                    )}
                </StepCard>
            </VStack>

            <BatchFooter>
                <Spacer />
                <SavingIndicator savingStatus={savingStatus} />
                <WhiteButton onClick={onCancel}>Cancel</WhiteButton>
                <ContinueButton isDisabled={isDisabled} onClick={handleContinue} />
            </BatchFooter>
        </Container>
    );
};

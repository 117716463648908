import { useGnosisSafe } from '../state/gnosis-state';
import { endpoints, PremiumTier } from '../tools/common';
import { useBullaBackendHttpClient } from './useHttpClient';
import { useActingWalletAddress } from './useWalletAddress';
import { useWeb3 } from './useWeb3';

export type FullMembershipInfoDto = {
    id: string;
    mainAddress: string;
    tier: PremiumTier;
    additionalAddresses: string[];
    startDate: number;
    expiry: number;
    instantPaymentId: string;
    chainId: number;
};

export const useAuthApi = () => {
    const { authApi } = endpoints;
    const { connectedSafeAddress } = useGnosisSafe();
    const { connectedNetwork } = useWeb3();
    const actingWallet = useActingWalletAddress();
    const httpClient = useBullaBackendHttpClient();

    const gnosisSafeQueryArgs = !!connectedSafeAddress ? `?account_type=gnosis&chain_id=${connectedNetwork}` : '';

    const verifyAddress = async (address: string, signature: string) => {
        const { data } = await httpClient.post<{ message: string }>(`${authApi}/verify/${address}`, signature, {
            headers: { 'Content-Type': 'text/plain' },
            withCredentials: true,
        });

        return data.message;
    };

    const getFullMembershipInfo = async (membershipId: string) => {
        try {
            const { data } = await httpClient.get<FullMembershipInfoDto>(
                `${authApi}/${actingWallet}/membership/${membershipId}${gnosisSafeQueryArgs}`,
                {
                    withCredentials: true,
                },
            );

            return data;
        } catch (e) {
            console.warn('failed to fetch membership', e);
            return undefined;
        }
    };

    const editAdditionalAddresses = async (membershipId: string, additionalAddresses: string[]) => {
        await httpClient.post<FullMembershipInfoDto>(
            `${authApi}/${actingWallet}/membership/${membershipId}/addresses${gnosisSafeQueryArgs}`,
            additionalAddresses,
            {
                withCredentials: true,
            },
        );
    };

    return { verifyAddress, getFullMembershipInfo, editAdditionalAddresses };
};
